import useOnClickOutsideRef from 'event-handler/outside-click';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { a11y, A11Y_IDS, focusTrap, tabIndexes } from 'constants/a11y';
import StaticStrings from 'StaticStrings';
import { useDevice } from 'function-library/hooks';
import variables from '../../../redux/variables';
import { bool, func, string } from 'prop-types';

const GptFeedbackPopUp = (props) => {
  const { t } = useTranslation();
  const {
    heading,
    IsShown,
    likeBtn,
    textContent,
    showThanks,
    buttonContent1,
    buttonContent2,
    onAction
  } = props;
  const [feedbackButtons, setFeedbackButtons] = useState({
    feedbackGivenAcc: false,
    feedbackGivenAcc2: false
  });
  const { isDeviceMobile } = useDevice();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [feedback, setFeedback] = useState('');
  const modalRef = useOnClickOutsideRef(() => {
    setFeedbackButtons({
      feedbackGivenAcc: false,
      feedbackGivenAcc2: false
    });
    setFeedback('');
  }, 'popup');
  const maxCharacters = 300;

  useEffect(() => {
    setIsSubmitDisabled(
      !feedbackButtons.feedbackGivenAcc &&
        !feedbackButtons.feedbackGivenAcc2 &&
        feedback.trim().length === 0
    );
  }, [feedbackButtons.feedbackGivenAcc, feedbackButtons.feedbackGivenAcc2, feedback]);

  useEffect(() => {
    focusTrap(IsShown);
  }, [IsShown]);

  const closePopup = () => {
    onAction('close');
  };
  const openThanksModal = () => {
    const feedbackTags = [];

    if (feedbackButtons.feedbackGivenAcc) {
      feedbackTags.push(buttonContent1);
    }

    if (feedbackButtons.feedbackGivenAcc2) {
      feedbackTags.push(buttonContent2);
    }
    onAction('openthanks', feedback, feedbackTags);
    setFeedbackButtons({
      feedbackGivenAcc: false,
      feedbackGivenAcc2: false
    });
  };

  const handleButtonClick = (buttonKey) => {
    setFeedbackButtons((prevButtons) => ({
      ...prevButtons,
      [buttonKey]: !prevButtons[buttonKey]
    }));
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length >= maxCharacters) {
      setFeedback(inputValue.slice(0, maxCharacters));
    } else {
      setFeedback(inputValue);
    }
  };

  return (
    IsShown && (
      <div
        ref={modalRef}
        className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-4 su__px-sm-1"
      >
        <div
          role={a11y.ROLES.DIALOG}
          aria-labelledby="dialog1_label"
          aria-modal="true"
          id={A11Y_IDS.trap}
          className={`su__modal-inner ${
            !showThanks
              ? isDeviceMobile
                ? 'su__mobile-modal'
                : 'su__new-modal'
              : 'su__thanks-modal'
          } su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1`}
        >
          <div className="su__popup-text">
            <div className="su__d-flex">
              <div className={!showThanks ? 'su__mt-30' : ''}>
                {!showThanks && heading ? (
                  <h2
                    id={'dialog1_label'}
                    tabIndex={tabIndexes.tabIndex_0}
                    lang={variables.searchCallVariables.langAttr}
                    className="su__cancel su__heading-feedback su__my-0"
                  >
                    {heading}
                  </h2>
                ) : null}
                {showThanks ? (
                  <svg
                    className="su__thanks-check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.448"
                    height="38.448"
                    viewBox="0 0 38.448 38.448"
                  >
                    <path
                      id="Icon_ionic-ios-checkmark-circle"
                      data-name="Icon ionic-ios-checkmark-circle"
                      d="M22.6,3.375A19.224,19.224,0,1,0,41.823,22.6,19.221,19.221,0,0,0,22.6,3.375Zm9.843,13.91L20.085,29.7h-.009A1.669,1.669,0,0,1,19,30.206a1.618,1.618,0,0,1-1.081-.527L12.747,24.5a.368.368,0,0,1,0-.527l1.645-1.645a.357.357,0,0,1,.518,0l4.1,4.1L30.289,15.076a.365.365,0,0,1,.259-.111h0a.336.336,0,0,1,.259.111l1.617,1.673A.364.364,0,0,1,32.442,17.285Z"
                      transform="translate(-3.375 -3.375)"
                      fill="#5bb543"
                    />
                  </svg>
                ) : null}
                {textContent ? (
                  <div className={showThanks ? 'su__top-new su__margin-thanks' : 'su__pt-new '}>
                    <div
                      tabIndex={tabIndexes.tabIndex_0}
                      aria-live="polite"
                      lang={variables.searchCallVariables.langAttr}
                      className={`su__new-padding su__font-14 ${
                        !showThanks ? 'su__margin-norm' : 'su__margin-thanks-color'
                      } su__text-black su__f-normal su__flex-1 su__pb-new`}
                    >
                      {!showThanks ? textContent : 'Thank You! Your feedback helps'}
                    </div>
                  </div>
                ) : null}
              </div>
              {!showThanks && (
                <div>
                  {likeBtn ? (
                    <svg
                      className="su__mr-mt-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 61 61"
                    >
                      <g
                        id="Group_19861"
                        data-name="Group 19861"
                        transform="translate(-6311 -1202)"
                      >
                        <g
                          id="Group_19788"
                          data-name="Group 19788"
                          transform="translate(6311.638 1202.639)"
                          opacity="0.06"
                        >
                          <circle
                            id="Ellipse_6"
                            data-name="Ellipse 6"
                            cx="30.5"
                            cy="30.5"
                            r="30.5"
                            transform="translate(-0.638 -0.639)"
                            fill="#919bb0"
                          />
                        </g>
                        <path
                          id="svgexport-12"
                          d="M19.237,2.9A1.778,1.778,0,0,1,21,2.014l.807.1a7.112,7.112,0,0,1,5.916,9.149l-.979,3.183h1.277a7.112,7.112,0,0,1,6.862,8.984l-2.425,8.89A7.112,7.112,0,0,1,25.6,37.562H8.334A5.334,5.334,0,0,1,3,32.227V19.781a5.334,5.334,0,0,1,5.334-5.334H11.89a1.286,1.286,0,0,0,1.116-.648Zm-3.791,31.11H25.6a3.556,3.556,0,0,0,3.431-2.621l2.425-8.89A3.556,3.556,0,0,0,28.022,18H24.337a1.778,1.778,0,0,1-1.7-2.3l1.687-5.484a3.557,3.557,0,0,0-2.6-4.511l-5.632,9.856a4.842,4.842,0,0,1-2.426,2.1V32.227A1.778,1.778,0,0,0,15.447,34.006ZM10.112,18V32.227a5.326,5.326,0,0,0,.3,1.778H8.334a1.778,1.778,0,0,1-1.778-1.778V19.781A1.778,1.778,0,0,1,8.334,18Z"
                          transform="translate(6322 1213)"
                          fill="#919bb0"
                          fillRule="evenodd"
                          opacity="0.35"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="su__mr-mt-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 61 61"
                    >
                      <g
                        id="Group_19860"
                        data-name="Group 19860"
                        transform="translate(-6308 -1205)"
                      >
                        <g
                          id="Group_19788"
                          data-name="Group 19788"
                          transform="translate(6308.638 1205.639)"
                          opacity="0.06"
                        >
                          <circle
                            id="Ellipse_6"
                            data-name="Ellipse 6"
                            cx="30.5"
                            cy="30.5"
                            r="30.5"
                            transform="translate(-0.638 -0.639)"
                            fill="#919bb0"
                          />
                        </g>
                        <path
                          id="svgexport-12"
                          d="M18.9,36.666a1.778,1.778,0,0,1-1.764.882l-.807-.1A7.112,7.112,0,0,1,10.414,28.3l.979-3.183H10.116a7.112,7.112,0,0,1-6.862-8.984l2.425-8.89A7.112,7.112,0,0,1,12.541,2H29.8a5.334,5.334,0,0,1,5.334,5.334V19.781A5.334,5.334,0,0,1,29.8,25.115H26.248a1.286,1.286,0,0,0-1.116.648Zm3.791-31.11H12.541A3.556,3.556,0,0,0,9.11,8.177l-2.425,8.89a3.556,3.556,0,0,0,3.431,4.492H13.8a1.778,1.778,0,0,1,1.7,2.3l-1.687,5.484a3.557,3.557,0,0,0,2.6,4.511L22.044,24a4.842,4.842,0,0,1,2.426-2.1V7.334A1.778,1.778,0,0,0,22.691,5.556Zm5.334,16V7.334a5.326,5.326,0,0,0-.3-1.778H29.8a1.778,1.778,0,0,1,1.778,1.778V19.781A1.778,1.778,0,0,1,29.8,21.559Z"
                          transform="translate(6319 1216)"
                          fill="#919bb0"
                          fillRule="evenodd"
                          opacity="0.35"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              )}
            </div>

            {!showThanks ? (
              <div className="su__Conform-block  su__flex-vcenter su__bottom-padding su__mr-18px">
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  aria-label={buttonContent1}
                  className={`su_new-btn  su__rtlml-3 su__rtlmr-0 su__radius su__cursor a11y-btn ${
                    feedbackButtons.feedbackGivenAcc ? 'su__add-click' : ''
                  } `}
                  onClick={() => handleButtonClick('feedbackGivenAcc')}
                >
                  {buttonContent1}
                </button>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  aria-label={buttonContent2}
                  className={` su_new-btn su__second-btn su__radius su__cursor a11y-btn ${
                    feedbackButtons.feedbackGivenAcc2 ? 'su__add-click' : ''
                  } `}
                  onClick={() => handleButtonClick('feedbackGivenAcc2')}
                >
                  {buttonContent2}
                </button>
              </div>
            ) : null}
          </div>
          {!showThanks && (
            <div className="su__d-flex su__justify-content-between">
              <span
                tabIndex={tabIndexes.tabIndex_0}
                lang={variables.searchCallVariables.langAttr}
                className="su__say-more"
              >
                Would you like to say more?
              </span>
              <span className="su__font-10 su__mr-30 su__ml-30px">
                <span className="su__black-color">{feedback.length}</span>
                <span className="su__grey-color">/{maxCharacters}</span>
              </span>
            </div>
          )}
          {!showThanks && (
            <textarea
              lang={variables.searchCallVariables.langAttr}
              placeholder="Share your feedback"
              name="feeback-txtarea"
              onChange={handleChange}
              className="su__feedtext-area-gpt  su__box-sizing"
              id="su__feedtext-area"
              rows="4"
              value={feedback}
              maxLength={maxCharacters}
            ></textarea>
          )}
          {!showThanks && (
            <div className=" su__flex-vcenter su__bottom-padding-button su__justify-content-center">
              <button
                lang={variables.searchCallVariables.langAttr}
                type="button"
                role={a11y.ROLES.BTN}
                tabIndex={tabIndexes.tabIndex_0}
                aria-label={
                  isSubmitDisabled ? t(StaticStrings.SUBMIT_DISABLED) : t(StaticStrings.SUBMIT)
                }
                className={`su__btn-gpt su__submit su__rtlml-3 su__rtlmr-0 su__radius su__cursor ${
                  isSubmitDisabled ? 'disabled-btn su__bookmark_SavedResult_opacity ' : ''
                }`}
                onClick={openThanksModal}
              >
                {t(StaticStrings.submit)}
              </button>
              <button
                lang={variables.searchCallVariables.langAttr}
                type="button"
                role={a11y.ROLES.BTN}
                tabIndex={tabIndexes.tabIndex_0}
                aria-label={t(StaticStrings.cancel)}
                className=" su__btn-gpt su__reused-btn  su__radius su__cursor"
                onClick={closePopup}
              >
                {t(StaticStrings.cancel)}
              </button>
            </div>
          )}
        </div>
        <div className="su__overlay su__zindex-1" onClick={closePopup} />
      </div>
    )
  );
};

GptFeedbackPopUp.propTypes = {
  heading: string,
  IsShown: bool,
  likeBtn: bool,
  textContent: string,
  showThanks: bool,
  buttonContent1: string,
  buttonContent2: string,
  onAction: func
};

GptFeedbackPopUp.defaultProps = {
  heading: '',
  IsShown: false,
  likeBtn: false,
  textContent: '',
  showThanks: false,
  buttonContent1: '',
  buttonContent2: '',
  onAction: () => {}
};

export default GptFeedbackPopUp;
