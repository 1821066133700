/* global client */
import React from 'react';
import variables from '../../../redux/variables';
import { tabIndexes } from '../../../constants/a11y';
import { useSelector } from 'react-redux';

const Title = (props) => {
  try {
    let { item, linkOpened, index, viewedResult, setTitleClicked, urlOpensInNewTab } = props;
    let searchResults = useSelector((state) => state.searchResult);
    searchResults.result.hits.forEach((item) => {
      if (
        variables.searchClientType == 16 &&
        item.objName == 'tickets' &&
        urlOpensInNewTab === true
      ) {
        item.allowlinkopennewtab = true;
      } else if (
        variables.searchClientType == 16 &&
        item.objName == 'tickets' &&
        urlOpensInNewTab === false
      ) {
        item.allowlinkopennewtab = false;
      } else {
        item.allowlinkopennewtab = true;
      }
    });
    const handleClick = (item, index) => {
      setTitleClicked(index);
    };

    /**
     * This function gets called when clicked on title and it open the ticket in subtab by using zendesk api
     * @param {*} item
     * @param {*} index
     */
    const ticketClicked = (item, index) => {
      linkOpened(item, index + 1);
      client.invoke('routeTo', 'ticket', item._id);
      handleClick(item, index);
    };
    return (
      !variables.toggleDisplayKeys[0].hideEye && (
        <span
          onClick={() => handleClick(item, index + 1)}
          onContextMenu={() => handleClick(item, index + 1)}
          className={`${
            viewedResult ? 'su__viewed-results' : ''
          } su__text-truncate su__text_align su__mt-025  `}
        >
          {item.allowlinkopennewtab === true ? (
            <a
              tabIndex={tabIndexes.tabIndex_0}
              aria-label={item.highlight.TitleToDisplay[0] || item.href}
              className="su__text-decoration su__text-black su__font-14 su__font-bold su__remove-hover-color"
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={(event) => {
                if (event.button == 1) linkOpened(item, index + 1);
              }}
              onContextMenu={() => linkOpened(item, index + 1)}
              onClick={() => linkOpened(item, index + 1)}
            >
              <h2
                className="su__text-truncate su__my-0 su__font-14 su__line-height-n"
                dangerouslySetInnerHTML={{ __html: item.highlight.TitleToDisplay[0] || item.href }}
              ></h2>
            </a>
          ) : (
            <a
              tabIndex={tabIndexes.tabIndex_0}
              aria-label={item.highlight.TitleToDisplay[0] || item.href}
              className="su__text-decoration su__text-black su__font-14 su__font-bold su__remove-hover-color su__cursor"
              rel="noopener"
              onMouseDown={(event) => {
                if (event.button == 1) linkOpened(item, index + 1);
              }}
              onContextMenu={() => ticketClicked(item, index + 1)}
              onClick={() => ticketClicked(item, index + 1)}
            >
              <h2
                className="su__text-truncate su__my-0 su__font-14 su__line-height-n test-build"
                dangerouslySetInnerHTML={{ __html: item.highlight.TitleToDisplay[0] || item.href }}
              ></h2>
            </a>
          )}
        </span>
      )
    );
  } catch (e) {
    console.log('Error in Title component', e);
    return <div></div>;
  }
};

export default React.memo(Title);
