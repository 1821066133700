import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import Icons from '../../../assets/svg-icon/svg';
import { useTranslation } from 'react-i18next';
import { mergeFilters, mergeFilterClicked } from '../../../mergeFacet';
import StaticStrings from 'StaticStrings';
import IconColors from 'IconColors';
import Menu from '@material-ui/core/Menu';
import { A11Y_IDS, focusTrap, a11y, tabIndexes } from '../../../constants/a11y';
import { useDevice } from 'function-library/hooks';
import { array } from 'prop-types';

const handleClick = (event, id, setAnchorEl, contentDetails, a) => {
  setAnchorEl(event.currentTarget);
  let checkId = setInterval(function () {
    if (document.getElementById('su__MCS' + id)) {
      clearInterval(checkId);
      let clickedOpen = document.getElementById('su__MCS' + id);
      clickedOpen.classList.add('su__d-block');
      let mergedCsList = document.getElementsByClassName('su__merge-options-item');
      if (a?.initialTab.contentName && (!contentDetails || !contentDetails.activeTabIndex)) {
        [...mergedCsList].forEach((removeClasses) => {
          removeClasses.classList.remove('su__merge-options-active');
          if (removeClasses.classList.contains(a.initialTab.contentName)) {
            removeClasses.classList.add('su__merge-options-active');
          }
        });
      } else if ((contentDetails || contentDetails.activeTabIndex) !== a?.initialTab.contentName) {
        [...mergedCsList].forEach((removeClasses) => {
          removeClasses.classList.remove('su__merge-options-active');
          if (removeClasses.classList.contains(contentDetails.contentName)) {
            removeClasses.classList.add('su__merge-options-active');
          }
        });
      }
    }
  });
};
const FacetPreference = (props) => {
  try {
    var a = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
    let searchResult = props.data;
    let mergedFacet = props.mergedFacet;
    let smartAggregation = props.smartFacetsAggregation;
    let allContentHideFacet = props.isAllContentShow;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let [metadataKeys, toggleDisplayValue] = useState(variables.toggleDisplayKeys);
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const [editPageLayout, toggleEditMode] = useState(false);
    const [isConfirm, setToggleConfirm] = useState(false);
    const [contentDetails, setContentName] = useState({
      'contentName': a && a.initialTab.contentName ? a && a.initialTab.contentName :'all',
      'index': a && a.initialTab.index ? a && a.initialTab.index : '_index',
      'activeTabIndex': a && a.initialTab.activeTabIndex ? a && a.initialTab.activeTabIndex : 'all'
    });
    const [rearrangedArray, setRearrangedArray] = useState(searchResult ? searchResult : []);
    const { isDeviceMobile, isTouchScreen } = useDevice();
    const handleHideDropdown = (e) => {
      (e.key === 'Escape') && (
        setIsComponentVisible(false),
        toggleEditMode(false),
        setToggleConfirm(false)
      );
    };
    let parsed_array = mergedFacet && JSON.parse(mergedFacet).length ? JSON.parse(mergedFacet) : [];
    parsed_array.length && parsed_array.forEach(function (o) {
      mergeFilters(o, searchResult);
    });

    variables.searchCallVariables.smartFacets && smartAggregation && smartAggregation.forEach((hide) => {
      rearrangedArray.forEach((item) => {
        if (item.key == hide.key) {
          item.smartHidden = true;
        }
      });
    });

    var arr = [];
    var hiddenFilters = a && a.hiddenFilters ? a.hiddenFilters : [];
    var dragId, dropId, old_index, new_index;
    const [ dragging, setDragging ] = useState(null);
    var c = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
    if (c && c.facetsOrder && searchResult) {
      for (var i = 0; i < c.facetsOrder.length; i++) {
        for (var j = 0; j < rearrangedArray.length; j++) {
          if (c.facetsOrder[i] == rearrangedArray[j].label) {
            rearrangedArray[j].index = i;
          }
        }
      }
    }
    useEffect(() => {
      if (a) {
        if (a.facetsOrder) {
          setRearrangedArray(a.facetsOrder);
        }
      } else {
        setRearrangedArray(searchResult);
      }
      document.addEventListener('keydown', handleHideDropdown);
      
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
      };
    }, []);

    const toggleKeys = (key) => {
      let index = metadataKeys.findIndex((element) => element.key === key);
      let tempArray = JSON.parse(JSON.stringify(metadataKeys));
      tempArray[index]['hideEye'] = !tempArray[index]['hideEye'];
      toggleDisplayValue(tempArray);
    };

    const cancel = (event) => {
      event.preventDefault();
    };

    /**
     * dragstart - event handler for onDragStart / onTouchStart
     * @param {TouchEvent | DragEvent} event
     * @returns {void}
     */
    const dragstart = (event) => {
      if(isTouchScreen){document.body.style.overflow = 'hidden';}
      dragId = event?.target?.getAttribute('data-drag-id');
      setDragging(dragId);
      event?.dataTransfer?.setData('dragStart', dragId);
    };

    /**
     * onDragOver - event handler for onDragOver / onTouchMove
     * @param {TouchEvent | DragEvent} event
     * @returns {void}
     */
    const onDragOver = (event) => {
      !isTouchScreen && event.preventDefault();
    };

    /**
     * drop - event handler for draggable element onDrop / onTouchEnd
     * @param {TouchEvent | DragEvent} event
     * @returns {void}
     */
    const drop = (event) => {
      document.body.style.overflow = 'auto';
      setDragging(()=>null);
      dropId = isTouchScreen ? document?.elementFromPoint(event?.changedTouches[0]?.clientX, event?.changedTouches[0]?.clientY)?.getAttribute('data-drag-id') : event?.target?.dataset?.dragId;
      dropId != undefined && array_move(arr);
      !isTouchScreen && event.preventDefault();
    };

    /**
     * array_move - shifts the position of facet preferences for a valid drop
     * @param {Array} arr - Array of facet order preference
     * @returns {void}
     */
    const array_move = () => {
      if(!dragging || !dropId) return;
      old_index = parseInt(dragging);
      new_index = parseInt(dropId);
      // make array copy
      let arr = [...rearrangedArray];
      // switch positions
      const buffer = {...arr[old_index]};
      arr[old_index] = arr[new_index];
      arr[new_index] = buffer;
      setRearrangedArray(arr);
    };


    const getContentResults = (e, label, item, child) => {
      var obj = e.currentTarget.parentNode.children;
      var values = Object.keys(obj).map((e) => obj[e]);
      let tabsContentActive = document.getElementsByClassName('su__tabs su__getClassName initialTab');
      if(tabsContentActive){
        Array.prototype.forEach.call(tabsContentActive, function(el) {
          el.classList.remove('initialTab');
        });
      }
      let tabsContentInActive = document.getElementsByClassName(item + ' su__tabs su__getClassName')[0];
      if(tabsContentInActive){
        tabsContentInActive.classList.add('initialTab');
      }
      if(child) {
        values.find((element) => {
          if (element.classList.contains('su__merge-options-active')) {
            element.classList.remove('su__merge-options-active');
          }
        });
        e.currentTarget.classList.add('su__merge-options-active');
      }

      if (e.currentTarget.innerHTML === 'All Content') {
        setContentName({
          'contentName': 'all',
          'index': '_index',
          'activeTabIndex': 'all'
        });
      } else {
        setContentName({
          'contentName': e.currentTarget.classList[0],
          'index': label,
          'activeTabIndex': item
        });
      }
    };

    const toggleHideEye = (label) => {
      var tempArr = JSON.parse(JSON.stringify(rearrangedArray));
      for (var counter = 0; counter < tempArr.length; counter++) {
        if (tempArr[counter].label == label) {
          if (!tempArr[counter].hideEye) {
            tempArr[counter].hideEye = true;
          } else {
            tempArr[counter].hideEye = !tempArr[counter].hideEye;
          }
          break;
        }
      }
      setRearrangedArray(tempArr);
    };

    const openConfirmbox = () => {
      setToggleConfirm(true);
    };

    const reset = () => {
      localStorage.removeItem('theme' + variables.searchCallVariables.uid);
      let tempArray = [...metadataKeys];
      tempArray.forEach((el) => { el.hideEye = false; });
      toggleDisplayValue(tempArray);
      variables.toggleDisplayKeys = [...metadataKeys];
      setToggleConfirm(false);
      toggleEditMode(true);
      props.toggleEditMode(false);
      variables.allSelected = true;
      variables.activeType = 'all';
      for (let count = 0; count < searchResult[0].values.length; count++) {
        searchResult[0].values[count].selected = false;
      }
      variables.searchCallVariables.aggregations = [];
      variables.searchSource = 'facetPreference';
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      dispatch(search.start(variables.searchCallVariables));
    };

    const openFacetPreferences = () => {
      toggleEditMode(!editPageLayout);
      setIsComponentVisible(!isComponentVisible);
      if (a) {
        if (a.facetsOrder) {
          setRearrangedArray(a.facetsOrder);
        }
      } else {
        setRearrangedArray(searchResult);
      }
    };
    const applyTheme = () => {
      var tempArr1 = JSON.parse(JSON.stringify(rearrangedArray));
      for(var count = 0;count < tempArr1.length; count++){
        if(tempArr1[count].hideEye){
          let kk = variables.searchCallVariables.aggregations;
          for(var abc = 0;abc < kk.length;abc++){
            if(tempArr1[count].key === kk[abc].type){
              variables.searchCallVariables.aggregations.splice(abc,1);
            }
          }
       
        }
      }
      props.toggleEditMode(false);
      if(a){
        if(a.initialTab.contentName != contentDetails.contentName){
          variables.searchCallVariables.aggregations = [];
        }
      }
      let merge = false;
      let arr = [];
      if(variables.activeType !== contentDetails.contentName){
        variables.searchCallVariables.aggregations = [{ type: contentDetails.index, filter: arr }];        
      }
    
      if (contentDetails && contentDetails.contentName.indexOf('merged_') > -1) {
        var filterChecked = true;
        mergeFilterClicked(contentDetails.contentName, arr, searchResult[0].values, filterChecked);
        merge = true;
      } else{
        arr.push(contentDetails && contentDetails.contentName);
      }

      if (contentDetails && contentDetails.contentName !== 'all' && contentDetails.contentName != '') {
        if((contentDetails.index == '_index' || contentDetails.index == '_type') && variables.searchCallVariables.aggregations.length == 0) {
          variables.searchCallVariables.aggregations = [{ type: contentDetails.index, filter: arr }];
        }
        variables.allSelected = false;
      } else if (contentDetails && contentDetails.contentName == 'all') {
        if(variables.activeType !== contentDetails.contentName)
          variables.searchCallVariables.aggregations = [];
        variables.allSelected = true;
      }
  
      variables.searchSource = 'facetPreference';

      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
    
      variables.activeType = contentDetails.contentName;
      dispatch(search.start(variables.searchCallVariables));
      variables.toggleDisplayKeys = [...metadataKeys];
  
      /* Save the variables to localStorage */
      let defaultValue = contentDetails && contentDetails.contentName != '' ? contentDetails.contentName : 'all';
      let value = merge ? arr : defaultValue;

      rearrangedArray.forEach((item) => {
        if (item.smartHidden) {
          item.smartHidden = false;
        }
      });
      
      let b = {
        'hideObject': variables.toggleDisplayKeys,
        'initialTab': (contentDetails ? {
          'contentName': value,
          'index': contentDetails.index,
          'mergeContentName': contentDetails.contentName,
          'activeTabIndex': contentDetails.contentName
        } : {
          'index': '_index',
          'contentName': 'all',
          'activeTabIndex': 'all'
        }),
        'hiddenFilters': hiddenFilters,
        'facetsOrder': rearrangedArray
      };
      localStorage.setItem('theme' + variables.searchCallVariables.uid, JSON.stringify(b));
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event, id) => {
      setAnchorEl(event.currentTarget);
      var checkId = setInterval(function() {
        if(document.getElementById('su__MCS'+id)) {
          clearInterval(checkId);
          var clickedOpen = document.getElementById('su__MCS'+id);
          clickedOpen.classList.add('su__d-block');
          var mergedCsList = document.getElementsByClassName('su__merge-options-item');
          if(a && a.initialTab.contentName && (!contentDetails || !contentDetails.activeTabIndex)) {
            [...mergedCsList].forEach((removeClasses) => {
              removeClasses.classList.remove('su__merge-options-active');
              if(removeClasses.classList.contains(a.initialTab.contentName)) {
                removeClasses.classList.add('su__merge-options-active');
              }
            });
          } else if((contentDetails || contentDetails.activeTabIndex) !== (a && a.initialTab.contentName)) {
            [...mergedCsList].forEach((removeClasses) => {
              removeClasses.classList.remove('su__merge-options-active');
              if(removeClasses.classList.contains(contentDetails.contentName)) {
                removeClasses.classList.add('su__merge-options-active');
              }
            });
          }
        }
      });
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // Focus trap
    useEffect(()=>{
      focusTrap(!editPageLayout);
    },[editPageLayout]);

    /* Horizontal slider */
    var box = document.querySelector('.box-inner-wrapper');
    var arrows = document.querySelectorAll('.arrow-btn');

    arrows.forEach(function(arrow) {
      arrow.addEventListener('click', function() {
        var x;
        if (arrow.classList.contains('arrow-right-btn')) {
          x = (box.clientWidth / 2) + box.scrollLeft;
          box.scrollTo({
            left: x,
            behavior: 'smooth'
          });
        } else {
          x = (box.clientWidth / 2) - box.scrollLeft;
          box.scrollTo({
            left: -x,
            behavior: 'smooth'
          });
        }
      });
    });

    useEffect(()=>{
      focusTrap(isConfirm);
    },[isConfirm]);

    /**
     * PreviewTile - Preview tile for facet preference
     * @returns {JSX.Element}
     */
    const PreviewTile = ({ metadataKeys }) => (
      <div className="su__list-items su__border su__bg-white su__sm-shadow su__radius-1 su__position-relative su__p-3 su__mb-3">
        <div className="su__list-item-row su__media su__w-100">
          {!metadataKeys[4].hideEye && (
            <div className="su__list-item-img su__mr-3 su__rtlml-3 su__rtlmr-0  ">
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#0082ca">
                </path>
              </svg>
            </div>
          )}
          <div className="su__list-item-text su__media-body su__overflow-hide su__word-break">
            <h3 className="su__list-item-title su__text-black su__text-truncate su__m-0 su__text-decoration  su__font-14 su__font-bold">
              {!metadataKeys[0].hideEye && (
                <span className="su__pref-title">What is Lorem Ipsum?</span>
              )}
              {!metadataKeys[5].hideEye && variables.searchCallVariables.showContentTag && (
                <span
                  title="item.contentTag"
                  className="su__ribbon-title su__ribbon-list su__bg-blue su__font-12 su__font-bold su__px-2 su__rtlmr-1 su__ml-1 su__rtlml-0 su__d-inline-block su__radius-3 custom-text-custom"
                >
                  {' '}
                  Lorem Text{' '}
                </span>
              )}
            </h3>

            {!metadataKeys[2].hideEye && (
              <p className="su__list-item-url su__w-100 su__my-1 su__font-12 ">
                <a className="su__text-decoration su__color-lgray su__font-italic">
                  https://www.lipsum.com/
                </a>
              </p>
            )}
            {!metadataKeys[1].hideEye && (
              <p className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__mb-2 su__font-12">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p>
            )}
            {!metadataKeys[3].hideEye && (
              <div className="su__meta-item su__d-flex su__flex-wrap su__align-content-between su__mt-2 su__font-12">
                <div className="su__meta-date su__word-break ">
                  <span
                    lang={variables.searchCallVariables.langAttr}
                    className="su__font-bold su__color-blue su__mr-2 su__rtlmr-0 su__rtlml-2"
                    style={{color:'#0000CC'}}
                  >
                    {t(StaticStrings.answered)}:
                  </span>
                  <span className="su__color-lgray su__mr-2 su__rtlmr-0 su__rtlml-2  ">
                    {t('true')}
                  </span>
                </div>
                <div className="su__meta-date su__word-break">
                  <span
                    lang={variables.searchCallVariables.langAttr}
                    className="su__font-bold su__color-blue su__mr-2 su__rtlmr-0 su__rtlml-2"
                    style={{color:'#0000CC'}}
                  >
                    {t(StaticStrings.created_date)}:
                  </span>
                  <span className="su__color-lgray su__mr-2 su__rtlmr-0 su__rtlml-2  ">
                    4/22/2019
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );

      
    return (
      <Fragment>
        {!editPageLayout && (
          <Fragment>
            <div className="su__position-fixed su__trbl-0 su__zindex-3 su__flex-vcenter">
              <div id={A11Y_IDS.trap} className={`su__editClient su__shadow-lg su__bg-white su__radius-1 su__mx-auto su__zindex-3 su__p-3 ${isDeviceMobile ? 'su__full-screen' : ''} ${(isTouchScreen && dragging!== null) ? 'su__no-overflow' : ''} `}>
                <div className="editClient su__flex-vcenter su__rtlmb-3">
                  <h2 lang={variables.searchCallVariables.langAttr} tabIndex={tabIndexes.tabIndex_0} className="su-edit su__flex-1 su__color-gray su__font-regular su__font-16 su__f-normal su__my-0 custom-editpage-layout">{t(StaticStrings.edit_page)}</h2>
                  <div className="editLayout  su__d-inline-block su__float-right su__cursor">
                    <button lang={variables.searchCallVariables.langAttr} role={a11y.ROLES.BTN} tabIndex={tabIndexes.tabIndex_0} className="apply su__border_skyblue su__text-blue su__p-2 su__font-regular su__font-14 su__radius-0 su__cursor su__mr-3 su__rtlml-3 su__line-height-n su__bg-transparent custom-reset-color" onClick={openConfirmbox}>{t("Reset Changes")}
                    </button>
                    <button lang={variables.searchCallVariables.langAttr} role={a11y.ROLES.BTN} tabIndex={tabIndexes.tabIndex_0} onClick={applyTheme} className="apply su__mr-3 su__text-white su__bg-blue-grd su__radius-0 su__font-regular su__font-14 su__p-2 su__cursor border-0 su__line-height-n custom-page-layout"> {t("Apply Changes")}</button>
                    <button lang={variables.searchCallVariables.langAttr} className="a11y-btn p-0 custom-margin-cross" role={a11y.ROLES.BTN} aria-label={t(StaticStrings.close_popup)} tabIndex={tabIndexes.tabIndex_0} onClick={() => props.toggleEditMode(false)}>
                      <Icons className="su__cursor" IconName="Close" width="12" height="12" color={IconColors.FacetPreferenceCrossIcon} />
                    </button>
                  </div>
                </div>
                <div className="su__facet-pref-blocks su__d-flex">
                  <div className={(allContentHideFacet && variables.allSelected) ? 'su__allSelected-pref-yes su-customizes su__d-none' : 'su__allSelected-pref-ncol- su-draggableFilters su__col-md-3 su__p-3 su__pt-0 su__overflow-hide'}>
                    <div lang={variables.searchCallVariables.langAttr} className="resultSection su__font-14 su__f-regular su__text-black su__pb-3 su__font-14 su__mb-2 custom-rearrange-facet">{t(StaticStrings.rearrange_facets)}</div>
                    <div id="filterList" className="sortable su__pl-0 su__overflow-auto su__minscroller su__word-break-all">
                      <div>
                        {searchResult && [...rearrangedArray].sort((a, b) => { return a.index - b.index; })
                          .map((item, index) => (
                            (item.order !== 0)  &&
                            <div data-drag-id={index} key={index} onTouchStart={dragstart} onTouchMove={onDragOver} onTouchEnd={drop} onDragEnter={cancel} onDrop={drop} draggable="true" onDragOver={onDragOver} onDragStart={dragstart} className={`su__font-regular su__font-14 su__border-gray su__radius-1 draggableList ${item.smartHidden ? 'su__fp_indicator' : ''} ${(isTouchScreen && dragging == index)  ? 'su__touch-scroll-control' : ''}`}>
                              <div data-drag-id={index} className={`${item.smartHidden ? 'su__d-flex su__align-items-start' : 'su__flex-vcenter'} su__justify-content-between`}>
                                <span data-drag-id={index} >{t(item.label)}</span>
                                <>                              
                                  {!item.hideEye ? (
                                    <button aria-live="assertive" aria-atomic="true" lang={variables.searchCallVariables.langAttr} aria-label={`${t(item.label)} ${(item.hideEye) ? (t(StaticStrings.hide)) : (t(StaticStrings.show))}`} role={a11y.ROLES.BTN} tabIndex={tabIndexes.tabIndex_0} className={`su__line-height-n a11y-btn ${item.smartHidden && 'disabled'}`} onClick={() => toggleHideEye(item.label)}>
                                      <Icons className="su__cursor" IconName="showEye" width="18" height="18" color={IconColors.FacetPreferenceShow_HideEye} />
                                    </button>
                                  ) :
                                    <button aria-live="assertive" aria-atomic="true" lang={variables.searchCallVariables.langAttr} aria-label={`${t(item.label)} ${(item.hideEye) ? (t(StaticStrings.hide)) : (t(StaticStrings.show))}`} role={a11y.ROLES.BTN} tabIndex={tabIndexes.tabIndex_0} className={`a11y-btn ${item.smartHidden && 'disabled'}`} onClick={() => toggleHideEye(item.label)}>
                                      <Icons className="su__cursor" IconName="hideEye" width="18" height="18" color={IconColors.FacetPreferenceShow_HideEye} />
                                    </button>
                                  }
                                </>
                              </div>
                              {item.smartHidden && item.hideEye ? <div lang={variables.searchCallVariables.langAttr} className="su__w-100 su__font-12">{t(StaticStrings.facet_still_visible)}</div>
                                : item.smartHidden && !item.hideEye ? <div lang={variables.searchCallVariables.langAttr} className="su__w-100 su__font-12">{t(StaticStrings.facet_not_visible)}</div>: ''}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={(allContentHideFacet && variables.allSelected) ? 'su__allSelected-pref-yes su-customizes su__col-md-12 su__p-2' : 'su__allSelected-pref-no su__col-md-9 su-customizes su__p-3'}>
                    <div className="navbar-filter" ng-repeat="filter in clientFilters">
                      <div lang={variables.searchCallVariables.langAttr} className="resultSection su__font-14 su__f-regular su__text-black su__pb-3 su__mb-2 custom-pre-select"> {t(StaticStrings.preselect_tab)}</div>
                       
                      <div className="su__tabsSection su__border-gray su__radius-1 su__slider-outer">
                        {searchResult && searchResult.filter((item) => item.order == 0).map((item, index) => (
                          <React.Fragment key={index}>
                            <button lang={variables.searchCallVariables.langAttr} aria-label={t(StaticStrings.slide_left)} className="arrow-left arrow-btn su__slider-button su__slider-button-left"></button>
                            <div key={index} className="box-inner-wrapper su-tabsSection su__w-100 su__border-b su__text-nowrap su__overflow-auto su__minscroller su__slider-list">
                              <button lang={variables.searchCallVariables.langAttr} aria-current={''+!!(contentDetails.activeTabIndex == 'all')} tabIndex={tabIndexes.tabIndex_0} className={((a && a.initialTab.activeTabIndex == 'all') || !a ? 'a11y-btn all-content su__tabs su__getClassName initialTab custom-selected-source' : 'a11y-btn all-content su__tabs su__getClassName')} onClick={(e) => getContentResults(e, item.key, 'all-content')}>
                                {t(StaticStrings.all_content)}
		    			              </button>
                              {React.Children.toArray(item.values.map((value, index) => (
                                <button aria-current={''+!!((contentDetails.activeTabIndex == value.Contentname) || (value.merged && value.showChild && value.filterList.indexOf(contentDetails.contentName) > -1)) } key={`${value.displayName}__${index}`} lang={variables.searchCallVariables.langAttr} tabIndex={tabIndexes.tabIndex_0} className={value.Contentname + ' su__tabs su__getClassName a11y-btn '
                                + (a && a.initialTab.activeTabIndex == value.Contentname || (value.merged && value.showChild && value.filterList.indexOf(a && a.initialTab.contentName) > -1) ? 'initialTab' : '')}
                                onClick={(e) => !value.merged || (value.merged && value.showChild != 1) ? getContentResults(e, item.key, value.Contentname) : null}>
                                  {!value.merged || value.showChild != 1 ? value.displayName : '' }
                                  {value.merged && value.showChild == 1 ?
                                    <div className="su__mergedFilter-CS-tab" >
                                      <div className="su__mergedFilter-CS-button" onClick={(e) => handleClick(e, value.Contentname)}>
                                        <span className="su__mergedFilter-CS-svg">
                                          {value.displayName}
                                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 11.175 6.584">
                                            <path className="su__mergedFilter-CS-svg-path" d="M9.462.292,5.582,4.172,1.7.292A1,1,0,0,0,.292,1.7l4.59,4.589a1,1,0,0,0,1.41,0L10.882,1.7a1,1,0,0,0,0-1.41A1.017,1.017,0,0,0,9.462.292Z" />
                                          </svg>
                                        </span>
                                      </div>
                                      <Menu id={'su__MCS'+value.Contentname} className="su__merge-options su__d-none" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose} getContentAnchorEl={null} transformOrigin={{ horizontal: 'left', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                                        {React.Children.toArray(value.childArray.map((child) => (
                                          <div className={child.Contentname + ' su__merge-options-item'} onClick={(e) => getContentResults(e, item.key, value.Contentname, child.Contentname)}>
                                            {child.displayName || child.Contentname}
                                          </div>
                                        )))}
                                      </Menu>
                                    </div> 
                                    : null}
                                </button>
                              )))}
                            </div>
                            <button lang={variables.searchCallVariables.langAttr} aria-label={t(StaticStrings.slide_right)} className="arrow-right-btn arrow-btn su__slider-button su__slider-button-right"></button>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <div className="hideFacets su__pt-3 su__pb-3 su__word-break-all">
                      <div lang={variables.searchCallVariables.langAttr} className="resultSection su__font-14 su__f-regular su__text-black su__pb-4 su__font-14 custom-search-result">{t(StaticStrings.search_result)}</div>
                      {React.Children.toArray(metadataKeys.filter((item) => {
                        if(!variables.searchCallVariables.showContentTag && item.key === 'Tag'){
                          return false;
                        }
                        return true;
                      }).map((item) => (
                        <span className="su-display su__radius-0 custom-display">
                          <span className="toggleDisplay su__font-regular su__font-12 su__text-black">
                            {item.key}
                          </span>
                          <button type="button" aria-live="assertive" aria-atomic="true" lang={variables.searchCallVariables.langAttr} aria-label={`${item.key} ${(item.hideEye) ? (t(StaticStrings.hide)) : (t(StaticStrings.show))}`} role={a11y.ROLES.BTN} tabIndex={tabIndexes.tabIndex_0} onClick={() => toggleKeys(item.key)} className={`su__eye su__flex-hcenter a11y-btn ${!item.hideEye ? 'su__eye-show' : 'su__eye-hide' }`}>
                            {!item.hideEye && 
                              <Icons className="su__cursor su__eye-open" IconName="showEye" width="18" height="18" color={IconColors.FacetPreferenceShow_HideEye} />
                            }
                            {item.hideEye && (
                              <Icons className="su__cursor su__eye-close" IconName="hideEye" width="18" height="18" color={IconColors.FacetPreferenceShow_HideEye} />
                            )}
                          </button> 
                        </span>
                      )))}
                    </div>
                  { !isDeviceMobile && <PreviewTile metadataKeys={metadataKeys} /> }
                  </div>
                </div>
                  { isDeviceMobile && <PreviewTile metadataKeys={metadataKeys} /> }
              </div>
              <div className="su__bg-overlay su__position-fixed" onClick={() => props.toggleEditMode(false)}></div>
            </div>
          </Fragment>
        )}
        {/* confirm box start */}
        {isConfirm && (
          <Fragment>
            <div className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-4 su__px-sm-1">
              <div id={A11Y_IDS.childTrap} className="su__modal-inner su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1">
                <div className="su__bookmark-popup-title su__px-4 su__py-3 su__flex-vcenter">
                  <div lang={variables.searchCallVariables.langAttr} className="su__font-14 su__text-black su__f-regular su__flex-1 su__position-relative su__mb-3">
                    {t(StaticStrings.reset_changes)}
                  </div>
                </div>
                <div className="su__Conform-block su__flex-vcenter su__p-3 su__justify-content-end">
                  <button lang={variables.searchCallVariables.langAttr} tabIndex={tabIndexes.tabIndex_0} className="su__btn su__bg-gray su__bg-gray su__px-4 su__py-2 su__mr-3 su__rtlml-3 su__rtlmr-0 su__radius su__cursor" onClick={() => setToggleConfirm(false)}>{t(StaticStrings.su_no)}</button>
                  <button lang={variables.searchCallVariables.langAttr} tabIndex={tabIndexes.tabIndex_0} className="su__btn su__bg-gray su__px-4 su__py-2 su__radius su__cursor" onClick={reset}>{t(StaticStrings.su_yes)}</button>
                </div>
              </div>
              <div className="su__overlay su__zindex-1" onClick={() => setToggleConfirm(false)} />
            </div>
          </Fragment>
        )}
        {/* confirm box end */}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in facet-preference component', e);
    return (<div></div>);
  }
};

export default FacetPreference;