import React, { Fragment } from 'react';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';
const Summary = (props) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    let { item, summaryData, openSummary, index } = props;
    return (
      !variables.toggleDisplayKeys[1].hideEye && (
        <Fragment>
          {variables.searchCallVariables.showMoreSummary ? (
            <Fragment>
              {!summaryData[index] ? (
                <span
                  className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__font-12 su__loading-view su_letter_space"
                  dangerouslySetInnerHTML={{
                    __html: item.highlight.SummaryToDisplay.join('#<span><br>')
                      .substring(0, variables.searchCallVariables.minSummaryLength)
                      .split('#')
                  }}
                />
              ) : (
                <span
                  className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__font-12 su__loading-view su_letter_space"
                  dangerouslySetInnerHTML={{
                    __html: item.highlight.SummaryToDisplay.join('#<span><br>').split('#')
                  }}
                />
              )}
              <span
                lang={variables.searchCallVariables.langAttr}
                className={`su__font-12 su__f-regular su__color-blue su___show-more-summary su__loading-view`}
                onClick={() => {
                  openSummary(index);
                }}
              >
                {summaryData[index]
                  ? ' ' + t(StaticStrings.show_less)
                  : ' ' + t(StaticStrings.show_more)}
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <span
                className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__font-12 su__loading-view su_letter_space"
                dangerouslySetInnerHTML={{
                  __html: item.highlight.SummaryToDisplay.join('#<span><br>').split('#')
                }}
              />
            </Fragment>
          )}
        </Fragment>
      )
    );
  } catch (e) {
    console.log('Error in Summary component', e);
    return <div></div>;
  }
};

export default React.memo(Summary);
