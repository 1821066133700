import React, { Fragment } from 'react';
import IconColors from '../../../IconColors';
import { string } from 'prop-types';

const UnderConstruction = (props) => {
  return (
    <Fragment>
      {
        <div className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-4 su__px-sm-1">
          <div
            style={{ border: '2px solid #008fd3' }}
            className="su__modal-inner su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1"
          >
            <div
              style={{ textAlign: 'center', padding: '10px' }}
              className="su__bookmark-popup-title su__px-3 su__py-2"
            >
              <div style={{ width: '100%', padding: '10px' }}>
                {props.component} is under Construction
              </div>
              <div style={{ width: '100%', padding: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  fill={IconColors.UnderConstructionsvgFill}
                  width="48px"
                  height="48px"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <g>
                      <path d="M9,15c-2.67,0-8,1.34-8,4v2h16v-2C17,16.34,11.67,15,9,15z" />
                      <path d="M13.75,8H13c0-1.48-0.81-2.75-2-3.45V5.5C11,5.78,10.78,6,10.5,6S10,5.78,10,5.5V4.14C9.68,4.06,9.35,4,9,4 S8.32,4.06,8,4.14V5.5C8,5.78,7.78,6,7.5,6S7,5.78,7,5.5V4.55C5.81,5.25,5,6.52,5,8H4.25v1h9.5V8z" />
                      <path d="M9,13c1.86,0,3.41-1.28,3.86-3H5.14C5.59,11.72,7.14,13,9,13z" />
                      <path d="M21.98,6.23l0.93-0.83l-0.75-1.3l-1.19,0.39c-0.14-0.11-0.3-0.2-0.47-0.27L20.25,3h-1.5L18.5,4.22 c-0.17,0.07-0.33,0.16-0.48,0.27L16.84,4.1l-0.75,1.3l0.93,0.83C17,6.4,17,6.58,17.02,6.75L16.09,7.6l0.75,1.3l1.2-0.38 c0.13,0.1,0.28,0.18,0.43,0.25L18.75,10h1.5l0.27-1.22c0.16-0.07,0.3-0.15,0.44-0.25l1.19,0.38l0.75-1.3l-0.93-0.85 C22,6.57,21.99,6.4,21.98,6.23z M19.5,7.75c-0.69,0-1.25-0.56-1.25-1.25s0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25 S20.19,7.75,19.5,7.75z" />
                      <path d="M19.4,10.79l-0.85,0.28c-0.1-0.08-0.21-0.14-0.33-0.19L18.04,10h-1.07l-0.18,0.87c-0.12,0.05-0.24,0.12-0.34,0.19 l-0.84-0.28l-0.54,0.93l0.66,0.59c-0.01,0.13-0.01,0.25,0,0.37l-0.66,0.61l0.54,0.93l0.86-0.27c0.1,0.07,0.2,0.13,0.31,0.18 L16.96,15h1.07l0.19-0.87c0.11-0.05,0.22-0.11,0.32-0.18l0.85,0.27l0.54-0.93l-0.66-0.61c0.01-0.13,0.01-0.25,0-0.37l0.66-0.59 L19.4,10.79z M17.5,13.39c-0.49,0-0.89-0.4-0.89-0.89c0-0.49,0.4-0.89,0.89-0.89s0.89,0.4,0.89,0.89 C18.39,12.99,17.99,13.39,17.5,13.39z" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="su__font-14 su__text-white su__f-regular su__flex-1 su__position-relative"></div>
              <div className="su__Conform-block su__flex-vcenter su__p-3 su__justify-content-end"></div>
            </div>
          </div>
          <div className=" su__overlay su__zindex-1" />
        </div>
      }
    </Fragment>
  );
};

UnderConstruction.propTypes = {
  component: string
};

UnderConstruction.defaultProps = {
  component: ''
};

export default UnderConstruction;
