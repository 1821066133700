import React from 'react';
const LiveCounts = (props) => {
  try {
    let { item } = props;
    return (
      item.boardName && (
        <div className="su__color-black su__mr-1 su__rtlmr-0 su__rtlml-2 su__line-height-n su__font-12">
          <span>Kudos {item.liveCounts?.kudos}</span>
          <span>Replies {item.liveCounts?.replies}</span>
          <span>Views {item.liveCounts?.views}</span>
        </div>
      )
    );
  } catch (e) {
    console.log('Error in Live Counts component', e);
    return <div></div>;
  }
};

export default React.memo(LiveCounts);
