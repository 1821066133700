import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import FilterList from 'components/feature-components/filter-list/index.jsx';
import FacetAlternateUI from 'components/feature-components/under-consruction/dummy-factes-UI.jsx';
import { mergeFilters } from '../../../mergeFacet';

const Facets = (props) => {
  try {
    let [searchResult] = useSelector((state) => {
      return [state.searchResult];
    });

    let smartAggregation = props.smartFacetsAggregation;
    let parsed_array =
      searchResult?.result && searchResult.merged_facets
        ? JSON.parse(searchResult.merged_facets)
        : [];
    parsed_array.length &&
      parsed_array.forEach(function (o) {
        mergeFilters(o, searchResult.aggregationsArray);
      });

    let a = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
    if (a?.facetsOrder && searchResult?.aggregationsArray) {
      for (let element of a.facetsOrder) {
        if (element.hideEye) {
          for (let i of searchResult.aggregationsArray) {
            if (i.label == element.label) {
              i.hideEye = element.hideEye;
            }
          }
        }
      }

      for (let i = 0; i < a.facetsOrder.length; i++) {
        for (let element of searchResult.aggregationsArray) {
          if (a.facetsOrder[i].label == element.label) {
            element.index = i;
          }
        }
      }
    }

    const addPathName = (parent, childArray) => {
      childArray.forEach((o) => {
        o.path = JSON.parse(JSON.stringify(parent));
        if (o.childArray && o.childArray.length) {
          let p = JSON.parse(JSON.stringify(parent));
          p.push(o.Contentname);
          addPathName(p, o.childArray);
        }
      });
    };

    searchResult.aggregationsArray &&
      searchResult.aggregationsArray.filter(function (o) {
        if (o.key.indexOf('_nested') > -1 || o.key.indexOf('_navigation') > -1) {
          o.values.forEach((l) => {
            if (l.childArray && l.childArray.length) {
              let parent = [];
              parent.push(l.Contentname);
              addPathName(parent, l.childArray);
            }
          });
        }
      });

    variables.searchCallVariables.smartFacets &&
      smartAggregation &&
      smartAggregation.forEach((hide) => {
        searchResult.aggregationsArray.forEach((item) => {
          if (item.key == hide.key) {
            item.hideEye = false;
          }
        });
      });

    if (searchResult.searchClientSettings?.hiddenFacet && variables.allSelected) {
      searchResult.aggregationsArray.forEach((item) => {
        searchResult.searchClientSettings.hiddenFacet.forEach((y) => {
          if (item.key == y) {
            item.hideFacetAdmin = true;
          }
        });
      });
    }

    return (
      <div
        id="facets-section"
        className="su__left-sidebar su__border su__border-t-none su__radius-1 su__bg-white"
      >
        {searchResult.result &&
          [...searchResult.aggregationsArray]
            .sort((a, b) => {
              return a.index - b.index;
            })
            .filter((item) => item.order !== 0 && !item.hideEye && !item.hideFacetAdmin)
            .map((item, index) => (
              <Fragment key={item?.order}>
                {item.values.length != 0 && <FilterList item={item} index={index} />}
              </Fragment>
            ))}
      </div>
    );
  } catch (e) {
    console.log('Error in Facets component', e);
    return (
      <div>
        <FacetAlternateUI />
        <FacetAlternateUI />
        <FacetAlternateUI />
      </div>
    );
  }
};

export default Facets;
