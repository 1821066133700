import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { mergeFilterClicked, facetClickNested } from '../../../mergeFacet';
import { useTranslation } from 'react-i18next';
import setCookies from '../../../setCookie/setCookie';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { a11y, tabIndexes } from '../../../constants/a11y';
import { setWildcardToggle } from 'function-library/commonFunctions';
import { htmlSanitizer } from 'function-library/dataFormatter';
import { v4 as uuid } from 'uuid';

const StickyFacets = (props) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchResult = useSelector((state) => state.searchResult);
    const [facets, showmoreFacets] = useState({
      expanded: false,
      itemsToShow: 4
    });

    let item = props.item;
    let idx = props.index;
    let standOut = props.standOut;
    let check;
    /**
     * Call search result function if filter changes
     * @param {Clear filter} contentName
     * @param {Clear type} Clear_type
     */
    const clearReset = (filter, item) => {
      setCookies.setSmartFacetOff();
      const aggIndex = variables.searchCallVariables.aggregations.findIndex(
        (filterType) => filterType.type === item.key
      );
      variables.currentClickedOrder = searchResult.aggregationsArray.findIndex(
        (f) => f.order === item.order
      );
      if (standOut) {
        setWildcardToggle(false);
      } else if (['exactPhrase', 'withOneOrMore', 'withoutTheWords'].includes(item.key)) {
        variables.searchCallVariables[item.key] = '';
      } else {
        const filterValue = variables.searchCallVariables.aggregations.find(
          (filterType) => filterType.type === item.key
        );
        const parentFacetIndex = variables.searchCallVariables.aggregations.findIndex(
          (filterType) => filterType.type === item.key
        );
        if (
          filterValue &&
          (filterValue.type.indexOf('_nested') > 0 || filterValue.type.indexOf('_navigation') > 0)
        ) {
          facetClickNested(filter, item.key, item.order, filter.level, filter.path, searchResult);
        } else if (filter.Contentname && filter.Contentname.indexOf('merged_') > -1) {
          mergeFilterClicked(
            filter.Contentname,
            variables.searchCallVariables.aggregations[aggIndex].filter,
            searchResult.aggregationsArray[variables.currentClickedOrder].values,
            false
          );
        } else if (filterValue) {
          if (filterValue.type === 'post_time') {
            filterValue.filter.splice(0, 1);
          } else {
            const index = filterValue.filter.indexOf(filter.Contentname);
            filterValue.filter.splice(index, 1);
          }
          if (filterValue.filter.length === 0) {
            variables.searchCallVariables.aggregations.splice(parentFacetIndex, 1);
          }
        }
        variables.selectedStickyFilters = [];
        for (let element of searchResult.aggregationsArray[0].values) {
          element.selected = false;
        }
      }
      //remove empty aggregations
      variables.searchCallVariables.aggregations =
        variables.searchCallVariables.aggregations.filter(function (facet) {
          if (!facet.sort) {
            if (
              facet.filter &&
              !facet.filter.length &&
              (!facet.children || (facet.children && !facet.children.length))
            ) {
              return false;
            }
          } else {
            if (
              facet.filter &&
              !facet.filter.length &&
              (!facet.children || (facet.children && !facet.children.length))
            ) {
              delete facet.filter;
              delete facet.children;
            }
          }
          return true;
        });
      if (item.merged && item.order == 0) {
        if (item.values) {
          item.values.forEach((element) => {
            if (variables.searchCallVariables.aggregations) {
              variables.searchCallVariables.aggregations.forEach((filter) => {
                if (!check) {
                  check = filter.filter.includes(element.Contentname);
                }
              });
            }
          });
        }
      }

      if ((item.key == '_index' || item.key == '_type') && item.order == 0 && !check) {
        variables.allSelected = true;
        variables.activeType = 'all';
      }
      variables.searchSource = 'sticky-facets';
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.pagingAggregation = [];

      dispatch(search.start(variables.searchCallVariables));
    };

    /**
     *
     * @param {show more sticky facets} facet
     */
    const showMore = (item) => {
      facets.itemsToShow === 4
        ? showmoreFacets({ itemsToShow: item.values.length, expanded: true })
        : showmoreFacets({ itemsToShow: 4, expanded: false });
    };

    return (
      <Fragment key={idx}>
        {item.values.slice(0, facets.itemsToShow).map((value) => (
          <div
            key={uuid()}
            className={`su__sticky__filters su__text-nowrap su__radius su__font-12 su__border su__mr-2 su__rtlmr-0 su__rtlml-2 ${
              !standOut ? 'su__mb-1' : ''
            } su__text-black su__bg-white`}
          >
            <span
              className="su__sticky__filters__ellipses"
              dangerouslySetInnerHTML={{
                __html: htmlSanitizer(
                  value.sticky_name ||
                    value.Contentname_short ||
                    value.displayName ||
                    value.Contentname
                )
              }}
            ></span>
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              aria-label={`${t(StaticStrings.remove)} ${
                value.sticky_name ||
                value.Contentname_short ||
                value.displayName ||
                value.Contentname
              }`}
              className="su__cancel__sticky su__cursor a11y-btn border-left-inherit"
              onClick={() => clearReset(value, item)}
            >
              &#x2715;
            </button>
          </div>
        ))}
        {item.values.length > 4 ? (
          <button
            type="button"
            lang={variables.searchCallVariables.langAttr}
            role={a11y.ROLES.BTN}
            tabIndex={tabIndexes.tabIndex_0}
            className="su__see-more-less su__clear__filters__button su__font-12 su__text-black su__text-decoration-none su__cursor su__radius su__loading-view a11y-btn p-0"
            onClick={() => showMore(item)}
          >
            {facets.expanded ? (
              <Fragment>
                <span className="su__position-relative su__more-icon">
                  <svg
                    id="round-remove_red_eye-24px"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path id="Path_1542" data-name="Path 1542" fill="none" d="M0,0H18V18H0Z" />
                    <path
                      id="Path_1543"
                      data-name="Path 1543"
                      fill={IconColors.StickyFacetsSvgFill}
                      d="M9.259,4.5A8.881,8.881,0,0,0,1,10.165a8.853,8.853,0,0,0,16.518,0A8.881,8.881,0,0,0,9.259,4.5Zm0,9.442a3.777,3.777,0,1,1,3.754-3.777A3.767,3.767,0,0,1,9.259,13.942Zm0-6.043a2.266,2.266,0,1,0,2.252,2.266A2.256,2.256,0,0,0,9.259,7.9Z"
                      transform="translate(-0.259 -1.165)"
                    />
                  </svg>
                </span>
                <span
                  lang={variables.searchCallVariables.langAttr}
                  className="su__ml-1 su__rtlmr-1 su__rtlml-0"
                >
                  {' '}
                  {t(StaticStrings.show_less)}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <span className="su__position-relative su__more-icon">
                  <svg
                    id="round-remove_red_eye-24px"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path id="Path_1542" data-name="Path 1542" fill="none" d="M0,0H18V18H0Z" />
                    <path
                      id="Path_1543"
                      data-name="Path 1543"
                      fill={IconColors.StickyFacetsSvgFill}
                      d="M9.259,4.5A8.881,8.881,0,0,0,1,10.165a8.853,8.853,0,0,0,16.518,0A8.881,8.881,0,0,0,9.259,4.5Zm0,9.442a3.777,3.777,0,1,1,3.754-3.777A3.767,3.767,0,0,1,9.259,13.942Zm0-6.043a2.266,2.266,0,1,0,2.252,2.266A2.256,2.256,0,0,0,9.259,7.9Z"
                      transform="translate(-0.259 -1.165)"
                    />
                  </svg>
                </span>
                <span className="su__ml-1 su__rtlmr1 su__rtlml-0"></span>
                <span lang={variables.searchCallVariables.langAttr}>
                  {t(StaticStrings.see)} {item.values.length - 4} {t(StaticStrings.moreInSmall)}
                </span>
              </Fragment>
            )}
          </button>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in stickFacet-facets component', e);
    return <div></div>;
  }
};

export default StickyFacets;
