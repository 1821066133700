import React, { Fragment } from 'react';
import variables from '../../../redux/variables';
import { a11y } from '../../../constants/a11y';

const Icon = (props) => {
  try {
    let { item } = props;
    return (
      <Fragment>
        {item.icon && !variables.toggleDisplayKeys[4].hideEye && (
          <div className="su__mt-025 su_w-20p su__mr-2 su__rtlml-2 su__rtlmr-0">
            <img
              className="su__left-item-img su__w-100"
              src={item.icon}
              alt={item.icon}
              role={a11y.ROLES.IMAGE}
              aria-label={item.icon}
            />
          </div>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Icon component', e);
    return <div></div>;
  }
};

export default React.memo(Icon);
