/* eslint-disable react/prop-types */
import React, { useState, Fragment, useEffect, useRef } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useDispatch } from 'react-redux';
import { search } from '../../../redux/ducks';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import UnderConstruction from 'components/feature-components/under-consruction/index.jsx';
import setCookies from '../../../setCookie/setCookie';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import { a11y, A11Y_IDS, useFocusTrap, tabIndexes } from '../../../constants/a11y';
import { setWildcardToggle } from 'function-library/commonFunctions';
import { savedResultReq } from '../../../redux/searchClientTypes';
import { v4 as uuid } from 'uuid';

const ListBookmarks = (props) => {
  /**
   *  Change Languages state
   */
  try {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookmarkSelect, setBookmark] = useState(false);
    const [savedResultSelect, setSavedResult] = useState(false);
    const [bookmarkAndSavedResultTab, setBookmarkAndSavedResultTab] = useState('bookmark');
    const [focusTrap, resetTrap] = useFocusTrap();
    const storedData = localStorage.getItem(
      'bookmark_searches_' + variables.searchCallVariables.uid
    );
    const initialBookmarkListing = storedData ? JSON.parse(storedData) : [];
    const [bookmarkListing, setList] = useState(initialBookmarkListing);
    let initialOopsIndex;
    if (bookmarkListing.length === 0) {
      initialOopsIndex = tabIndexes.tabIndex_minus_1;
    } else {
      initialOopsIndex = tabIndexes.tabIndex_0;
    }
    const [oopsIndex, setOopsIndex] = useState(initialOopsIndex);
    const [savedResultListing, setsavedResultList] = useState(
      JSON.parse(localStorage.getItem('savedResult_' + variables.searchCallVariables.uid) || '[]')
    );
    const [loading, setLoading] = useState(null);
    const isFirstRender = useRef(true);
    const parseEntities = (txt) => new DOMParser().parseFromString(txt, 'text/html').body.innerText;
    /**
     *  Press Esc button Hide funtion
     */
    const handleHideDropdown = (e) => {
      e.key === 'Escape' && setIsModalOpen(false);
    };

    const parentfun = () => {
      props.savedResultBookmark();
    };
    useEffect(() => {
      if (!isFirstRender.current) {
        let savedResultList = JSON.parse(
          localStorage.getItem('savedResult_' + variables.searchCallVariables.uid) || '[]'
        );
        if (savedResultList) {
          setsavedResultList(savedResultList);
        }
        if (savedResultList.length >= 50) {
          savedResultReqFunc();
          setIsModalOpen(true);
          setBookmarkAndSavedResultTab('savedResult');
        }
      } else {
        isFirstRender.current = false;
      }
    }, [props.limitReached]);

    useEffect(() => {
      if (props.bookmark.length) {
        setList(props.bookmark);
      }
      let savedResultList = JSON.parse(
        localStorage.getItem('savedResult_' + variables.searchCallVariables.uid) || '[]'
      );
      if (savedResultList.length) {
        setsavedResultList(savedResultList);
      } else {
        setsavedResultList(savedResultList.length);
      }
      document.addEventListener('keydown', handleHideDropdown);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
      };
    }, [props.bookmark, props.bookmarkListIconActive]);

    useEffect(() => {
      focusTrap(isModalOpen);
    }, [isModalOpen]);
    /**
     *  Checkbox check change call
     */
    const bookmarkCheck = (index, event) => {
      let bookmarkCheck = -1;
      if (bookmarkAndSavedResultTab === 'bookmark') {
        bookmarkListing[index].bookmark = event.target.checked;
        bookmarkListing.some((list, index) => {
          if (list.bookmark === true) {
            bookmarkCheck = index;
            return true;
          }
        });
        bookmarkCheck > -1 ? setBookmark(true) : setBookmark(false);
      } else {
        savedResultListing[index].savedResult = event.target.checked;
        savedResultListing.some((list, index) => {
          if (list.savedResult === true) {
            bookmarkCheck = index;
            return true;
          }
        });
        bookmarkCheck > -1 ? setSavedResult(true) : setSavedResult(false);
      }
    };

    const removeBookmarkSubmit = (e) => {
      e.key === 'Enter' && setIsModalOpen(false);
    };

    /**
     * Open the bookmark popup
     */
    const openBookmarkPopup = () => {
      bookmarkListing.map((item) => {
        if (item.bookmark) {
          item.bookmark = false;
        }
      });
      savedResultListing &&
        savedResultListing.map((item) => {
          item.savedResult = !item.savedResult;
        });
      let savedResultData = JSON.parse(
        localStorage.getItem('savedResult_' + variables.searchCallVariables.uid)
      );
      if (savedResultData !== null) {
        setsavedResultList(savedResultData);
      } else {
        setsavedResultList([]);
      }
      setBookmarkAndSavedResultTab('bookmark');
      setBookmark(false);
      setSavedResult(false);
      setIsModalOpen(true);

      /** updating bookmarList state every time popup opens **/
      const list = JSON.parse(
        localStorage.getItem('bookmark_searches_' + variables.searchCallVariables.uid) || `[]`
      );
      setList(list);
    };

    /**
     * Remove Bookmarks from local Storage
     */
    const removeBookmark = () => {
      if (bookmarkAndSavedResultTab === 'savedResult') {
        const updatedList = savedResultListing.filter((item) => !item.savedResult);
        setsavedResultList(updatedList);
        localStorage.setItem(
          'savedResult_' + variables.searchCallVariables.uid,
          JSON.stringify(updatedList)
        );
        setSavedResult(false);
      } else {
        const updatedList = bookmarkListing.filter((item) => !item.bookmark);
        setList(updatedList);
        localStorage.setItem(
          'bookmark_searches_' + variables.searchCallVariables.uid,
          JSON.stringify(updatedList)
        );
        if (updatedList.length === 0) {
          setTimeout(() => {
            document?.getElementById('oopsText')?.focus();
          });
        }
        setBookmark(false);
      }
      props.callBack(
        bookmarkAndSavedResultTab === 'savedResult' ? savedResultListing : bookmarkListing
      );
      parentfun();
    };

    /**
     * Search call on bookmark list click
     */
    const searchCall = (index) => {
      let click = JSON.parse(
        localStorage.getItem('bookmark_searches_' + variables.searchCallVariables.uid) || '[]'
      );
      variables.searchSource = 'listBookmark';
      variables.searchCallVariables = click[index].href;
      setWildcardToggle(click[index].toggle);
      setCookies.setSmartFacetOff();
      dispatch(search.start(variables.searchCallVariables));
      if (variables.searchCallVariables.aggregations.length) {
        if (
          !variables.searchCallVariables.aggregations.find(
            (f) => f.type == props.getArrAggregation[0].key
          )
        ) {
          variables.allSelected = true;
          variables.activeType = 'all';
        } else {
          variables.allSelected = false;
          variables.activeType = variables.searchCallVariables.aggregations[0].filter[0];
        }
      } else {
        variables.allSelected = true;
        variables.activeType = 'all';
      }
      setIsModalOpen(false);
    };

    const savedResultReqFunc = () => {
      let savedResultData = JSON.parse(
        localStorage.getItem('savedResult_' + variables.searchCallVariables.uid)
      );
      let queryPassed = {};
      queryPassed.uid = variables.searchCallVariables.uid;
      if ([6, 11, 12, 16, 19, 25, 26, 28].includes(variables.searchClientType)) {
        queryPassed.accessToken = variables.searchCallVariables.accessToken;
      }
      if ([11, 12, 16].includes(variables.searchClientType)) {
        queryPassed.email = variables.searchCallVariables.email;
      }
      queryPassed.recordIds =
        savedResultData?.map((item) => ({
          indexName: item.csIndexName,
          _id: item.savedResultId,
          objectName: item.objName,
          uniqueField: item.uniqueField
        })) || [];
      (async function () {
        setLoading(true);
        let paramsUrlReq = await savedResultReq(variables.searchClientType, queryPassed);
        try {
          const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
          if (!response.ok) {
            throw Error(response.statusText);
          }
          const resp = response;
          const data = await resp.json();
          if (data.statusCode === 200) {
            savedResultData.forEach((savedResult) => {
              const foundResult = data.result.hits.find(
                (item) =>
                  savedResult.savedResultId === item._id &&
                  savedResult.csIndexName === item.sourceName &&
                  savedResult.objName === item.objName
              );
              savedResult.savedResultExists = 1;
              if (foundResult) {
                savedResult.title = foundResult.highlight.TitleToDisplay[0] || foundResult.href;
                savedResult.savedResultExists = 0;
              }
            });

            setsavedResultList(() => {
              const updatedList = savedResultData;
              return updatedList;
            });
            localStorage.setItem(
              'savedResult_' + variables.searchCallVariables.uid,
              JSON.stringify(savedResultData)
            );
          }
        } catch (err) {
          console.log('Error in facet searching', err);
        }
        setLoading(false);
      })();
    };

    const bookmarkOrSavedResult = (value) => {
      setBookmarkAndSavedResultTab(value);
      if (value === 'savedResult') {
        savedResultReqFunc();
      }
    };

    return (
      <Fragment>
        <div className="su__d-md-none su__d-xs-block su__col su__mob-search su__text-center su__font-9 su__font-bold">
          <button
            type="button"
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(StaticStrings.bookmark)}
            role={a11y.ROLES.BTN}
            tabIndex={tabIndexes.tabIndex_0}
            className={`su__mob-search-iner a11y-btn p-0 ${
              isModalOpen || (savedResultListing && savedResultListing.length)
                ? 'su__mob-active'
                : ''
            }`}
            onClick={() => openBookmarkPopup()}
          >
            <div className="su__mob-icon">
              <Icons
                className="su__savebokmark-icon su__bg-white-hover-50"
                IconName="Savebookmark"
                width="24"
                height="24"
                color={
                  bookmarkListing.length
                    ? IconColors.ListBookMarksNotEmptyIcon
                    : IconColors.ListBookmarksEmptyIcon
                }
                IconClass="su__active-path"
              />
            </div>
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__mob-txt su__line-height-n su__active-text su__font-9 su__font-bold"
            >
              {t(StaticStrings.bookmark)}
            </div>
          </button>
        </div>
        <Tooltip
          text={t(StaticStrings.saved_bookmarks_and_result)}
          position="top"
          className="position-relative"
        >
          <button
            type="button"
            lang={variables.searchCallVariables.langAttr}
            role={a11y.ROLES.BTN}
            aria-label={t(StaticStrings.saved_bookmarks_and_result)}
            tabIndex={tabIndexes.tabIndex_0}
            className="su__save-bookmarks su__flex-hcenter su__d-md-flex su__d-xs-none a11y-btn p-0"
            onClick={() => openBookmarkPopup()}
          >
            <span
              className={`su__bookmark-list su__mt-1 su__ml-2 su__rtlmr-2 su__rtlml-0 su__cursor su__outline-none su__bg-white-hover-50 su__radius-50 bookmark-list-dots ${
                bookmarkListing.length || (savedResultListing && savedResultListing.length)
                  ? 'su__active-bookmark-list-icon'
                  : ''
              }`}
            >
              {/* <Icons
                className="su__savebookmark-icon su__bg-white-hover-50"
                IconName="Savebookmark"
                width="24"
                height="24"
                color="#535355"
              /> */}
              <svg className="su__savebookmark-icon su__bg-white-hover-50 abc" width="24" height="24" viewBox="0 0 24 24"><path d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#9aa3bf"></path></svg>
            </span>
          </button>
        </Tooltip>
        {isModalOpen && (
          <form
            onSubmit={removeBookmarkSubmit}
            id={A11Y_IDS.trap}
            role={a11y.ROLES.DIALOG}
            aria-labelledby="dialog1_label"
            aria-modal="true"
          >
            <div className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-3 su__px-sm-1">
              <div className="su__modal-inner su__radius su__animate-fadown su__zindex-3 su__shadow-lg su__bg-white su__radius-1">
                <div className="su__bookmark-popup-title su__px-3 su__py-2 su__flex-vcenter su__bg-blue-grd">
                  {/* <Icons
                    className="su__savebookmark-icon su__p-2 su__mr-2 su__rtlmr-0 su__rtlml-2 su__bg-white-circle su__radius-50"
                    IconName="Savebookmark"
                    width="40"
                    height="40"
                    color="#fff"
                  /> */}
                    <svg title="Bookmarks" width="40" height="40" viewBox="0 0 24 24" className="su__savebookmark-icon su__p-2 su__mr-2 su__rtlmr-0 su__rtlml-2 su__radius-50">
                        <defs>
                          <clipPath id="clip-Saved_Book_mark-88">
                            <rect width="24" height="24"></rect>
                          </clipPath>
                        </defs>
                        <g id="Saved_Book_mark" data-name="Saved Book mark" clip-path="url(#clip-Saved_Book_mark-88)">
                          <g id="Group_1350" data-name="Group 1350" transform="translate(3977 10079)">
                            <g id="Group_1345" data-name="Group 1345" transform="translate(-5280 -10120)">
                              <g id="round-collections_bookmark-24px" transform="translate(1303 41)">
                                <path id="Path_1546" data-name="Path 1546" d="M0,0H24V24H0Z" fill="none"></path>
                                <path id="Path_1547" data-name="Path 1547" d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#fff"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                  <h2
                    id={'dialog1_label'}
                    lang={variables.searchCallVariables.langAttr}
                    className="su__font-16 su__text-white su__f-bold su__flex-1 su__position-relative su__my-0 su__font_save_bookmark"
                  >
                    {t(StaticStrings.saved_bookmarks_and_result)}
                  </h2>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    className="a11y-btn p-0 su__close_bookmark"
                    aria-label={t(StaticStrings.close_popup)}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    onClick={() => setIsModalOpen(false)}
                  >
                    {' '}
                    <Icons
                      className="su__close-icon su__cursor"
                      IconName="Close"
                      width="18"
                      height="18"
                      color="#fff"
                    />
                  </button>
                </div>
                <div role={a11y.ROLES.ALERT} className="su__bookmark-inner su__font-14">
                  <div className="su__w-100 su__border-b">
                    <button
                      lang={variables.searchCallVariables.langAttr}
                      aria-label={t(StaticStrings.bookmarks)}
                      role={a11y.ROLES.BTN}
                      className={
                        bookmarkAndSavedResultTab === 'bookmark'
                          ? 'su__tabs su__tabs_bookmarkList su__cursor su__border-none su__bg-white su__ml-3 su__BookmarkActive-tab'
                          : 'su__tabs su__tabs_bookmarkList su__cursor su__border-none su__bg-white su__ml-2'
                      }
                      type="button"
                      onClick={() => bookmarkOrSavedResult('bookmark')}
                    >
                      {t(StaticStrings.bookmarks)}
                    </button>
                    <button
                      lang={variables.searchCallVariables.langAttr}
                      aria-label={t(StaticStrings.Results)}
                      role={a11y.ROLES.BTN}
                      className={
                        bookmarkAndSavedResultTab === 'savedResult'
                          ? 'su__tabs su__tabs_bookmarkList su__cursor su__border-none su__bg-white su__ml-2 su__BookmarkActive-tab'
                          : 'su__tabs su__tabs_bookmarkList su__cursor su__border-none su__bg-white'
                      }
                      type="button"
                      onClick={() => bookmarkOrSavedResult('savedResult')}
                    >
                      {t(StaticStrings.Results)}
                    </button>
                  </div>
                  <div
                    className={` ${
                      bookmarkAndSavedResultTab === 'savedResult' ? '' : 'su__d-none'
                    }`}
                  >
                    {!loading && savedResultListing && savedResultListing.length >= 50 && (
                      <div
                        className="su__savedResultLimitReached su__mb-1"
                        lang={variables.searchCallVariables.langAttr}
                        aria-label={t(
                          StaticStrings.Limit_Reached_Please_delete_to_save_new_results
                        )}
                      >
                        {' '}
                        {t(StaticStrings.Limit_Reached_Please_delete_to_save_new_results)}
                      </div>
                    )}
                  </div>
                  <div className="su__p-0 su__bookmark-ul su__minscroller">
                    <div
                      className={`${bookmarkAndSavedResultTab === 'bookmark' ? '' : 'su__d-none'}`}
                    >
                      {bookmarkListing.length !== 0 ? (
                        React.Children.toArray(
                          bookmarkListing.map((item, index) => (
                            // eslint-disable-next-line react/jsx-key
                            <div
                              key={uuid()}
                              tabIndex={tabIndexes.tabIndex_0}
                              className="su__flex-vcenter su__px-2 su__mb-1 su__border-b"
                            >
                              <div className="su__flex-1 su__cursor su__w-75">
                                <button
                                  type="button"
                                  tabIndex={tabIndexes.tabIndex_0}
                                  role={a11y.ROLES.LNK}
                                  className=" su__bookmark_SavedResult_RTL su__text-bold su__text-decoration su__d-block su__p-2 su__text-black su__font-regular su__text-truncate a11y-btn su__width-100 su__text-left"
                                  onClick={() => searchCall(index)}
                                >
                                  <span className="su__w-100 su__font-14">{item.title}</span>
                                </button>
                              </div>
                              <div className="su__ml-3 su__w-savebook su__rtlmr-3 su__rtlml-0 su__outline-none su__cursor">
                                <div className="su__filter-checkbox ">
                                  <div className="su__filter-toggle su__border-color su__font-18 su__position-relative ">
                                    <input
                                      tabIndex={tabIndexes.tabIndex_0}
                                      aria-label={item.title}
                                      id={item.title}
                                      className=" su__toggle-input su__position-absolute su__cursor"
                                      type="checkbox"
                                      key={item.title}
                                      defaultChecked={item.bookmark === true}
                                      name={item.title}
                                      value={item.title}
                                      onChange={(e) => bookmarkCheck(index, e)}
                                    />
                                    {/* <label htmlFor={item.title} className="su__filter-label-hidden su__d-inline-block su__line-height-n su__flex-1 su__text-truncate su__font-12 su__ml-2 su__rtlmr-2 su__mr-3 su__rtlml-3 su__cursor">{item.title}</label> */}
                                    <span className="su__toggle-label su__d-inline-flex su__cursor">
                                      <span className="su__toggle__text" />
                                      <span className="su__nested__text su__loading su__d-none su__position-absolute" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      ) : (
                        <div
                          id={'oopsText'}
                          role="status"
                          tabIndex={oopsIndex}
                          onFocus={() => resetTrap()}
                          onBlur={() => {
                            setOopsIndex(tabIndexes.tabIndex_minus_1);
                            resetTrap(true);
                          }}
                          className="su__bookmark-inner su__p-3"
                        >
                          <div className="su__w-100 su__text-center su__mt-3">
                            {/* <Icons
                              className="su__savebokmark-icon"
                              IconName="Savebookmark"
                              width="71"
                              height="75"
                              color="#d3d6de"
                            /> */}
                            <svg class="su__savebokmark-icon" width="71" height="75" viewBox="0 0 24 24">
                                <path d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#d3d6de"></path>
                            </svg>
                          </div>
                          <div
                            lang={variables.searchCallVariables.langAttr}
                            className="su__w-100 su__font-32 su__text-center su__text-black su__font-bold su__line-height-n su__mb-2 su__font_bookmark"
                          >
                            {' '}
                            {t(StaticStrings.oops)}!
                          </div>
                          <div
                            lang={variables.searchCallVariables.langAttr}
                            className="su__w-100 su__font-14 su__text-center su__text-black su__font-bold su__line-height-n su__mb-3 su__font_bookmark_text"
                          >
                            {t(StaticStrings.nothing_here)}
                            <br />
                            {t(StaticStrings.create_first)}
                          </div>
                        </div>
                      )}
                    </div>

                    {loading && bookmarkAndSavedResultTab === 'savedResult' && (
                      <div className="loading">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    )}

                    <div
                      className={` ${
                        bookmarkAndSavedResultTab === 'savedResult' ? '' : 'su__d-none'
                      }`}
                    >
                      {!loading &&
                        (savedResultListing && savedResultListing.length !== 0 ? (
                          React.Children.toArray(
                            savedResultListing.map((item, index) => (
                              // eslint-disable-next-line react/jsx-key
                              <div
                                key={uuid()}
                                tabIndex={tabIndexes.tabIndex_0}
                                className="su__flex-vcenter su__px-2 su__mb-1 su__border-b"
                              >
                                <div className="su__flex-1  su__w-75">
                                  <Tooltip
                                    savedTooltip={item.savedResultExists}
                                    text={
                                      item.savedResultExists === 0
                                        ? item.title
                                        : 'This result is not available anymore.'
                                    }
                                    position="bottom"
                                    className="position-relative"
                                  >
                                    <div
                                      className={`${
                                        item.savedResultExists === 1
                                          ? 'su__bookmark_SavedResult_opacity'
                                          : ''
                                      } su__text-decoration su__bookmark_SavedResult_RTL su__d-block su__p-2 su__text-black su__font-regular su__text-truncate a11y-btn su__width-100 su__text-left`}
                                    >
                                      {item.savedResultExists === 0 && (
                                        <a
                                          href={item.href}
                                          target="_blank"
                                          className="su__cursor su__w-100 su__font-14  su__bookmarkSavedResultText"
                                          rel="noreferrer"
                                        >
                                          {parseEntities(item.title)}
                                        </a>
                                      )}
                                      {item.savedResultExists === 1 && (
                                        <a className="su__w-100 su__font-14  su__deletedSavedResultText">
                                          {parseEntities(item.title)}
                                        </a>
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="su__ml-3 su__w-savebook su__rtlmr-3 su__rtlml-0 su__outline-none su__cursor">
                                  <div className="su__filter-checkbox ">
                                    <div className="su__filter-toggle su__border-color su__font-18 su__position-relative ">
                                      <input
                                        tabIndex={tabIndexes.tabIndex_0}
                                        aria-label={item.title}
                                        id={item.title}
                                        className=" su__toggle-input su__position-absolute su__cursor"
                                        type="checkbox"
                                        name={item.title}
                                        value={item.title}
                                        key={item.title}
                                        defaultChecked={item.savedResult === true}
                                        onChange={(e) => bookmarkCheck(index, e)}
                                      />
                                      <span className="su__toggle-label su__d-inline-flex su__cursor">
                                        <span className="su__toggle__text" />
                                        <span className="su__nested__text su__loading su__d-none su__position-absolute" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )
                        ) : (
                          <div
                            id={'oopsText'}
                            role="status"
                            tabIndex={oopsIndex}
                            onFocus={() => resetTrap()}
                            onBlur={() => {
                              setOopsIndex(tabIndexes.tabIndex_minus_1);
                              resetTrap(true);
                            }}
                            className="su__bookmark-inner su__p-3"
                          >
                            <div className="su__w-100 su__text-center su__mt-3">
                              {/* <Icons
                                className="su__savebokmark-icon"
                                IconName="Savebookmark"
                                width="71"
                                height="75"
                                color="#d3d6de"
                              /> */}
                               <svg class="su__savebokmark-icon" width="71" height="75" viewBox="0 0 24 24">
                                <path d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#d3d6de"></path>
                            </svg>
                            </div>
                            <div
                              lang={variables.searchCallVariables.langAttr}
                              className="su__w-100 su__font-32 su__text-center su__text-black su__font-bold su__line-height-n su__mb-2 su__font_bookmark"
                            >
                              {' '}
                              {t(StaticStrings.oops)}!
                            </div>
                            <div
                              lang={variables.searchCallVariables.langAttr}
                              className="su__w-100 su__font-14 su__text-center su__text-black su__font-bold su__line-height-n su__mb-3 su__font_bookmark_text"
                            >
                              {t(StaticStrings.nothing_here)}
                              <br />
                              {t(StaticStrings.save_first)}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={`${bookmarkAndSavedResultTab === 'bookmark' ? '' : 'su__d-none'}`}>
                  {bookmarkListing.length !== 0 ? (
                    <div className="su__bookmark-btn su__flex-vcenter su__justify-content-between su__p-3">
                      <button
                        lang={variables.searchCallVariables.langAttr}
                        aria-label={t(StaticStrings.remove)}
                        type="button"
                        className="su__btn su__bg-blue-grd su__py-3 su__w-100 su__btn su__p-2 su__bg-gray-40 su__radius-1 su__bookinput-h su__bookmark-active"
                        disabled={!bookmarkSelect}
                        onClick={removeBookmark}
                      >
                        {t( StaticStrings.remove_bookmark)}
                      </button>
                    </div>
                  ) : null}
                </div>
                <div
                  className={`${bookmarkAndSavedResultTab === 'savedResult' ? '' : 'su__d-none'}`}
                >
                  {!loading &&
                    (savedResultListing && savedResultListing.length !== 0 ? (
                      <div className="su__bookmark-btn su__flex-vcenter su__justify-content-between su__p-3">
                        <button
                          lang={variables.searchCallVariables.langAttr}
                          aria-label={t(StaticStrings.remove)}
                          type="button"
                          className="su__btn su__bg-blue-grd su__py-3 su__w-100 su__btn su__p-2 su__bg-gray-40 su__radius-1 su__bookinput-h su__bookmark-active"
                          disabled={!savedResultSelect}
                          onClick={removeBookmark}
                        >
                          {t( StaticStrings.remove_bookmark)}
                        </button>
                      </div>
                    ) : null)}
                </div>
              </div>
              <div className="su__overlay su__zindex-1" onClick={() => setIsModalOpen(false)} />
            </div>
          </form>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in List bookmark Component', e);
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookmarkListing, setList] = useState(
      JSON.parse(
        localStorage.getItem('bookmark_searches_' + variables.searchCallVariables.uid) || '[]'
      )
    );
    useEffect(() => {
      if (props.bookmark.length) {
        setList(props.bookmark);
      }
    }, [props.bookmark]);
    const showPopUp = () => {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1500);
    };
    return (
      <Fragment>
        <div className="su__d-md-none su__d-xs-block su__col su__mob-search su__text-center su__font-9 su__font-bold">
          <div
            className={`su__mob-search-iner ${isModalOpen ? 'su__mob-active' : ''}`}
            onClick={() => showPopUp()}
          >
            <div
              className={`su__mob-icon ${
                bookmarkListing.length ? 'su__bookmarkListIconActive' : ''
              }`}
            >
              <Icons
                className="su__savebokmark-icon su__bg-white-hover-50"
                IconName="Savebookmark"
                width="24"
                height="24"
                color="#333"
                IconClass="su__active-path"
              />
            </div>
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__mob-txt su__line-height-n su__active-text"
            >
              {t(StaticStrings.bookmark)}
            </div>
          </div>
        </div>
        <div
          className="su__save-bookmarks su__flex-hcenter su__d-md-flex su__d-xs-none"
          onClick={() => showPopUp()}
        >
          <span
            lang={variables.searchCallVariables.langAttr}
            tabIndex={tabIndexes.tabIndex_0}
            className={`su__bookmark-list su__mt-1 su__ml-2 su__rtlmr-2 su__rtlml-0 su__cursor su__outline-none su__bg-white-hover-50 su__radius-50 bookmark-list-dots ${
              bookmarkListing.length ? 'mob_bookmark_list-icon' : ''
            }`}
          >
            <Tooltip
              text={t(StaticStrings.show_bookmark)}
              position="top"
              className="position-relative"
            >
              <Icons
                className="su__savebookmark-icon su__bg-white-hover-50"
                IconName="Savebookmark"
                width="24"
                height="24"
                color={
                  bookmarkListing.length
                    ? IconColors.ListBookMarksNotEmptyIcon
                    : IconColors.ListBookmarksEmptyIcon
                }
              />
            </Tooltip>
          </span>
        </div>
        {isModalOpen && <UnderConstruction component="Show Bookmarks" />}
      </Fragment>
    );
  }
};
export default ListBookmarks;