import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './redux/configureStore';
import 'isomorphic-fetch';
import '../src/assets/css/style.css';

const store = configureStore();

let suApp = setInterval(function () {
  if (
    document.querySelector(`[su-container]`) ||
    document.querySelector(`[ng-app = "searchUnifyApp"]`)
  ) {
    clearInterval(suApp);
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.querySelector(`[su-container]`) ||
        document.querySelector(`[ng-app = "searchUnifyApp"]`)
    );
  }
}, 5);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
