import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { any, node, string } from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired
};

const Tooltip = ({ text, position, savedTooltip, children }) => {
  const node = useRef();
  const parseEntities = (txt) => new DOMParser().parseFromString(txt, 'text/html').body.innerText;
  const [isVisible, setState] = useState(false);
  const handleClick = ({ target }) => {
    if (node.current.contains(target)) {
      // inside click
      return;
    }
    // outside click
    setState(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const showTooltip = (e) => {
    if (e) {
      if (e.code === 'Escape') {
        setState(false);
      }
    }
  };
  const hideTooltip = (e) => {
    if (e) {
      setState(false);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', showTooltip);
    return () => {
      document.removeEventListener('keydown', showTooltip);
    };
  });

  return (
    <div
      className="su__position-relative"
      data-testid="tooltip"
      ref={node}
      onKeyPress={() => setState(!isVisible)}
      onMouseEnter={() => setState(!isVisible)}
      onMouseLeave={() => setState(false)}
      onFocus={() => setState(!isVisible)}
      onBlur={(event) => hideTooltip(event)}
    >
      <div data-testid="tooltip-placeholder" className="su__d-flex su__tooltipIconsOutline">
        {children}
      </div>
      {isVisible && (
        <div
          className={`su__tooltip su__d-inline-block ${
            savedTooltip === 0 ? 'su__savedResultTooltip su__savedResultTooltipArabic' : ''
          }  ${
            savedTooltip === 1 ? 'savedResultDontExist su__savedResultTooltipArabic' : ''
          } su__line-height-n su__tooltip-${
            savedTooltip != 0 && savedTooltip != 1 ? position : ''
          }`}
          data-testid="tooltip-content"
        >
          <div className={`su__flex-vcenter`}>
            {savedTooltip != 0 && savedTooltip != 1 && (
              <span
                className={`su__position-absolute su__tooltip-tringle su__tringle-${position}`}
              ></span>
            )}
            {parseEntities(text)}
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  position: string,
  savedTooltip: any,
  children: node
};

Tooltip.defaultProps = {
  position: 'top',
  children: <></>
};

Tooltip.propTypes = propTypes;

export default Tooltip;
