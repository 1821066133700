import React from 'react';
import { useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { useTranslation } from 'react-i18next';
import StaticStrings from '../../../StaticStrings';
import Tooltip from 'components/feature-components/tooltip/index.jsx';

const SortBy = (props) => {
  try {
    const { t } = useTranslation();
    const [hasActiveDocs, setHasActiveDocs] = React.useState('docs');

    React.useEffect(() => {
        if (typeof window !== 'undefined' && window.location) {
            let urlParams =  new URLSearchParams(window.location.search);
            const activeTypeParam = urlParams.get('activeType');
            setHasActiveDocs(activeTypeParam);
        }
    }, [typeof window !== 'undefined' ? window.location.search : null]);

    /**
     * @param {sortby-value} Value
     */
    const handleChange = (e) => {
      let sortValue = e.target.value;
      variables.searchCallVariables.sortby = sortValue;
      variables.searchSource = 'sort-by';
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
    };

    const dispatch = useDispatch();
    // variables?.sortingOrder?.sortPreference?.keyLabelMapping.push({"label":"Updated Date","value":"last_modified","Updated Date":"Updated Date"})

    return (
      <div className="su__fillter-sortby-layout-2  su__flex-vcenter su__position-relative su__height-37px su__mx-2 su__relevance_mb_view su__mr_sort">
        {/* <Tooltip text={t(StaticStrings.sort_by_label)} position="top" className="position-relative"> */}
          <div id="su__sortByLabel">
          <select
  lang={variables.searchCallVariables.langAttr}
  id="su__relevance"
  value={variables.searchCallVariables.sortby}
  className="su__btn-filters su__key-focus su__select-control su__w-auto su__fillter-select su__radius-1 su__p-1 su__font-12 su__border su__bg-white su__p-sm-1 su__cursor su__mobile_btn_width"
  onChange={handleChange}
  aria-label={t(StaticStrings.sort_by_label)}
>
  {variables?.sortingOrder?.sortPreference?.keyLabelMapping
    .filter((item) => {
      // Filter options based on the value of hasActiveDocs
      if (hasActiveDocs === 'docs') {
        return item.value !== 'post_time'; // Exclude 'post_time' if docs are active
      } else {
        return item.value !== 'last_modified'; // Exclude 'last_modified' otherwise
      }
    })
    .map((item) => (
      <option
        key={item.value}
        lang={variables.searchCallVariables.langAttr}
        value={item.value}
      >
        {"Sort by: " + t(item.label)}
      </option>
    ))}
</select>

          </div>
        {/* </Tooltip>  */}
      </div>
    );
  } catch (e) {
    console.log('Error in sort by component', e);
    return <div></div>;
  }
};

export default SortBy;
