import React, { Fragment } from 'react';
import { a11y } from '../../../constants/a11y';

const Image = (props) => {
  try {
    let { item, linkOpened, index } = props;
    let titleForScreenReader = item.highlight.TitleToDisplay.toString().replace(
      /<\/?span[^>]*>/g,
      ''
    );
    return (
      <Fragment>
        {item.imageSource ? (
          <div className="su__featured-thumbnail">
            <div className="su__mr-2 su__rtlmr-0 su__rtlml-2 su__thumbnail_align">
              <a
                aria-label={titleForScreenReader}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={(event) => {
                  if (event.button == 1) linkOpened(item, index + 1);
                }}
                onContextMenu={() => linkOpened(item, index + 1)}
                onClick={() => linkOpened(item, index + 1)}
              >
                <img
                  src={item.imageSource}
                  className="su__img-featured"
                  alt="featured-thumbnail"
                  role={a11y.ROLES.IMAGE}
                  aria-label={item.imageSource}
                />
              </a>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Image component', e);
    return <div></div>;
  }
};

export default React.memo(Image);
