import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { useTranslation } from 'react-i18next';
import StaticStrings from '../../../StaticStrings';

const LoadMoreResults = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    let searchSuggest = useSelector((state) => state.searchResult);
    let totalResults = Math.ceil(
      (Object.keys(searchSuggest).length && searchSuggest.result.total) /
        variables.searchCallVariables.pageSize
    );
    const moreResults = () => {
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageSize =
        parseInt(variables.searchCallVariables.pageSize) + 10;
      variables.searchCallVariables.resultsPerPage = variables.searchCallVariables.pageSize;
      variables.searchSource = 'pagination';
      dispatch(search.start(variables.searchCallVariables));
    };
    const dispatch = useDispatch();

    return (
      <div>
        {totalResults > 1 ? (
          <div className="su__w-100 su__flex-hcenter">
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__more-btn su__cursor su__bg-white su__bg-gray-hover su__border su__font-12 su__px-3 su__py-1 su__radius-3"
              onClick={moreResults}
            >
              {t(StaticStrings.MORERESULTS)}
            </div>
          </div>
        ) : null}
      </div>
    );
  } catch (e) {
    console.log('Error in load-more component', e);
    return <div></div>;
  }
};

export default LoadMoreResults;
