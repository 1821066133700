import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { usePagination } from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import { a11y, tabIndexes } from '../../../constants/a11y';
import StaticStrings from 'StaticStrings';
import { v4 as uuid } from 'uuid';

const NavigatePagination = () => {
  try {
    const { t } = useTranslation();
    let searchSuggest = useSelector((state) => state.searchResult);
    let totalResults = Math.ceil(
      (Object.keys(searchSuggest).length && searchSuggest.result.total) /
        parseInt(variables.searchCallVariables.pageSize)
    );

    const pageChanged = (event, page) => {
      variables.searchCallVariables.pageNo = page;
      variables.searchCallVariables.from = (page - 1) * variables.searchCallVariables.pageSize;
      variables.searchCallVariables.pagingAggregation = [];
      variables.searchSource = 'pagination';
      dispatch(search.start(variables.searchCallVariables));
      let focus =
        document.querySelector(`[su-container]`) ||
        document.querySelector(`[ng-app = "searchUnifyApp"]`);
      window.scroll({
        top: focus.offsetTop,
        behavior: 'smooth'
      });
      focus.scrollIntoView(); // Injected custom code
    };
    const { items } = usePagination({
      count: totalResults,
      siblingCount: 1,
      onChange: pageChanged,
      page: parseInt(variables.searchCallVariables.pageNo)
    });

    if (variables.searchCallVariables.pageNo) {
      items.forEach((element) => {
        if (element.page == variables.searchCallVariables.pageNo) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });
    }

    const dispatch = useDispatch();

    return (
      <Fragment>
        {searchSuggest?.result && totalResults > 1 ? (
          <div
            className="su__d-inline-block su__pagination-row su__pagination-section su__my-4 su__pagination_mobile"
            role={a11y.ROLES.NAVIGATION}
          >
            <ul
              tabIndex={tabIndexes.tabIndex_0}
              aria-label={StaticStrings.pagination}
              className="su__pagination su__justify-content-end su__loading-view a11y-pagination"
            >
              {items.map(({ page, type, selected, ...item }) => {
                let children;
                if (type == 'previous') {
                  type = 'Previous';
                } else if (type == 'next') {
                  type = 'Next';
                }
                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = (
                    <span
                      role={a11y.ROLES.BTN}
                      tabIndex={tabIndexes.tabIndex_0}
                      className="su__appearance-none"
                      type="button"
                      disabled
                    >
                      …
                    </span>
                  );
                } else if (type === 'page') {
                  children = (
                    <button
                      lang={variables.searchCallVariables.langAttr}
                      role={a11y.ROLES.BTN}
                      aria-label={`${t(StaticStrings.page)} ${t(page)}`}
                      tabIndex={tabIndexes.tabIndex_0}
                      type="button"
                      className={`a11y-btn ${
                        selected ? 'su__pagination-active su__appearance-none' : ''
                      }`}
                      {...item}
                    >
                      {t(page)}
                    </button>
                  );
                } else {
                  children = (
                    <button
                      aria-label={t(type)}
                      role={a11y.ROLES.BTN}
                      tabIndex={tabIndexes.tabIndex_0}
                      className="su__appearance-none a11y-btn"
                      type="button"
                      {...item}
                    >
                      {t(type)}
                    </button>
                  );
                }
                return (
                  <li key={uuid()}>
                    <span key={uuid()}>{page <= 500 ? children : null}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in navigation paginaition component', e);
    return <div></div>;
  }
};

export default NavigatePagination;