/* global gza */
/* eslint-disable react/jsx-key */
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { v4 as uuid } from 'uuid';

const KnowledgeGraph = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    let searchSuggest = useSelector((state) => state.searchResult);
    const [knowledgeGraphResponseRecorded, setKnowledgeGraphFeedback] = useState(false);
    useEffect(() => {
      setKnowledgeGraphFeedback(false);
    }, [searchSuggest]);

    const sendKnowledgeGraphFeedback = (feedback, result) => {
      gza('knowledgeGraph', {
        searchString: variables.searchCallVariables.searchString,
        url: result.link,
        t: result.title || result.link,
        uid: variables.uid,
        feedback: feedback
      });
      setKnowledgeGraphFeedback(true);
    };
    return (
      <Fragment>
        {searchSuggest.metaGraph && Object.keys(searchSuggest.metaGraph).length !== 0 ? (
          <div className="">
            <div className="su__sm-shadow su__bg-white su__radius-1">
              <div className="su__px-2 su__border-b">
                <div className="su__d-inline-block su__my-2 su__w-100 su__position-relative su__pb-0">
                  {searchSuggest.metaGraph && Object.keys(searchSuggest.metaGraph).length !== 0 ? (
                    <div className="su__overflow-hide su__word-break">
                      <div className="su__text-truncate su__m-0 su__loading-view">
                        <a
                          className="su__text-decoration su__font-14 su__text-black su__font-bold"
                          href={searchSuggest.metaGraph.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: searchSuggest.metaGraph.title || searchSuggest.metaGraph.link
                            }}
                          ></span>
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {searchSuggest.metaGraph.subtitle ? (
                    <div
                      className="su__mt-1 su__font-14 su__word-break su__loading-view"
                      dangerouslySetInnerHTML={{ __html: searchSuggest.metaGraph.subtitle }}
                    ></div>
                  ) : null}
                  {searchSuggest.metaGraph.link ? (
                    <div className="su__list-item-desc su__w-100 su__m-0 su__mt-1 su__font-12 su__word-break su__loading-view">
                      <cite className="su__text-decoration su__color-lgray su__font-12">
                        {searchSuggest.metaGraph.link}
                      </cite>
                    </div>
                  ) : null}
                  {searchSuggest.metaGraph.img ? (
                    <div className="su__my-2 su__loading-view">
                      <img
                        className="su__img-fluid"
                        src={searchSuggest.metaGraph.img}
                        alt={searchSuggest.metaGraph.img}
                      ></img>
                    </div>
                  ) : null}
                  {searchSuggest.metaGraph.description ? (
                    <div
                      className="su__list-item-desc su__w-100 su__m-0 su__mt-1 su__font-12 su__word-break su__loading-view"
                      dangerouslySetInnerHTML={{ __html: searchSuggest.metaGraph.description }}
                    ></div>
                  ) : null}
                  {searchSuggest.metaGraph.metaFields ? (
                    <div className="su__meta-row" key={uuid()}>
                      {React.Children.toArray(
                        searchSuggest.metaGraph.metaFields.map((item) => (
                          <div className="su__mt-1 su__font-12" key={uuid()}>
                            <span className="metaDataKey  su__font-bold su__color-blue su__mr-2 su__rtlmr-0 su__rtlml-2 su__loading-view">
                              {item.key}
                            </span>
                            :
                            <span
                              className="su__color-lgray su__loading-view"
                              dangerouslySetInnerHTML={{ __html: item.value }}
                            ></span>
                          </div>
                        ))
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {searchSuggest.relatedTiles &&
              Object.keys(searchSuggest.relatedTiles).length &&
              searchSuggest.relatedTiles.length !== 0
                ? React.Children.toArray(
                    searchSuggest.relatedTiles.map((item) => (
                      <div className="su__py-1" key={uuid()}>
                        <div className="su__w-100 su__px-2">
                          <div className="su__font-13 text-black">{item.tagLine}</div>
                          {item.relatedFields && item.relatedFields.length !== 0
                            ? React.Children.toArray(
                                item.relatedFields.map((item) => (
                                  <div key={uuid()} className="su__overflow-hide su__word-break">
                                    <div className="su__text-truncate su__font-14 su__m-0 su__pl-0 su__rtlpr-0 su__loading-view">
                                      <a
                                        className="su__text-decoration su__font-12 su__color-blue su__cursor hover-color-dblue"
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.heading1}
                                      </a>
                                    </div>
                                  </div>
                                ))
                              )
                            : null}
                        </div>
                      </div>
                    ))
                  )
                : null}
              {searchSuggest.metaGraph && Object.keys(searchSuggest.metaGraph).length !== 0 ? (
                <div className="su__d-inline-block su__w-100">
                  {!knowledgeGraphResponseRecorded ? (
                    <div className="su-featured-text su__flex-vcenter su__px-3 su__py-1 su__bg-blue-grd su__loading-view">
                      <div
                        lang={variables.searchCallVariables.langAttr}
                        className="su__font-12 su__mt-1 su__text-white su__mr-3 su__rtlmr-0 su__rtlml-3 su__loading-view"
                      >
                        {t(StaticStrings.was_helpful)}?
                      </div>
                      <div className="su__knowledge-feedback-row su__d-flex">
                        <span
                          className="su__knowledge-feedback su__cursor su__loading-view"
                          onClick={() => sendKnowledgeGraphFeedback(1, searchSuggest.metaGraph)}
                        >
                          <Icons
                            IconName="ThumbsUp"
                            width="20"
                            height="20"
                            color={IconColors.KnowledgeGraphThumbsup_down}
                          />
                        </span>
                        <span
                          className="su__knowledge_downlikeicon su__cursor su__loading-view"
                          onClick={() => sendKnowledgeGraphFeedback(0, searchSuggest.metaGraph)}
                        >
                          <Icons
                            IconName="ThumbsDown"
                            width="20"
                            height="20"
                            color={IconColors.KnowledgeGraphThumbsup_down}
                          />
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {knowledgeGraphResponseRecorded ? (
                    <div className="su__knowledge-thankyou su__text-success su__font-14 su__p-3 su__position-relative su__loading-view">
                      <div className="su__flex-vcenter su__mr-2 su__rtlmr-0 su__font-12 su__rtlml-2">
                        <svg
                          className="ft-green-tick su__animate-zoom su__mr-2"
                          height="100"
                          width="100"
                          viewBox="0 0 48 48"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle className="su__circle" fill="#5bb543" cx="24" cy="24" r="22" />
                          <path
                            className="su__tick"
                            fill="none"
                            stroke="#FFF"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            d="M14 27l5.917 4.917L34 17"
                          />
                        </svg>
                        <span
                          lang={variables.searchCallVariables.langAttr}
                          className="su__pl-3 su__thankyou-text"
                        >
                          {t(StaticStrings.thanks_response)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Knowledge graph component', e);
    return <div></div>;
  }
};

export default KnowledgeGraph;
