/* eslint-disable no-constant-condition */
import React, { useState, useEffect, useRef } from 'react';
import variables from '../../../redux/variables';
import { useSelector, useDispatch } from 'react-redux';
import { suGPTSearch } from 'redux/ducks';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';

import { a11y, tabIndexes } from '../../../constants/a11y';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import GptFeedbackPopUp from 'components/feature-components/gpt-feedback-popup/index.jsx';

/**
 * FeaturedSnippetAnimation - AKA ( SU GPT )
 */
const FeaturedSnippetAnimation = ({
  setPreview,
  closeCitationModal,
  showPortal,
  modalRef,
  isDeviceMobile,
  setGptResponse
}) => {
  try {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const POPUP_CLOSE_TIMEOUT = 2000;
    const FAIL_TEXT = 'Unable to generate';
    const EMPTY_SEARCH_STRING = 'Run a query to generate a response.';
    const CACHE_SIZE = 10;
    const [noAnswerArray, setnoAnswerArray] = useState([]);
    const [gptClassList, setGptClassList] = useState('');
    const [{ hasError, loader, printing }, setTriggers] = useState({
      loader: false,
      hasError: false,
      printing: false
    });
    const suGPTContentRef = useRef(null);
    const searchResult = useSelector((state) => state.searchResult);
    const gptContext = useSelector(
      (state) =>
        state.searchResult &&
        state.searchResult.searchClientSettings &&
        state.searchResult.searchClientSettings.gptConfig &&
        state.searchResult.searchClientSettings.gptConfig.gptContext
    );
    const gptLinks = useSelector(
      (state) =>
        state.searchResult &&
        state.searchResult.searchClientSettings &&
        state.searchResult.searchClientSettings.gptConfig &&
        state.searchResult.searchClientSettings.gptConfig.gptLinks
    );
    const suGPTStream = useSelector((state) => state.suGPTStream);
    const [showMore, setShowMore] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [feedbackGivenlike, setFeedbackGivenlike] = useState(false);
    const [integerVal, setIntegerVal] = useState(0);
    const [feedbackGivendislike, setFeedbackGivendislike] = useState(false);
    const [likeBtn, setLikeBtn] = useState(false);
    const [showThankModal, setShowThankModal] = useState(false);
    const [buttonContent1, setButtonContent1] = useState('');
    const [buttonContent2, setButtonContent2] = useState('');
    const [suGPTSays, setSuGPTSays] = useState('');
    const [showLike, setShowLike] = useState(false);
    const [showDislike, setShowDisike] = useState(false);

    const [llmId, setLlmId] = useState('');
    const [errorObject, setErrorObject] = useState({
      show: false,
      statusCode: '',
      showRetry: false,
      message: ''
    });
    const latestEffectId = useRef(null);
    const [cache, setCache] = useState({});
    let animatorRefs = [];
    const scrollableAreaRef = useRef(null);
    variables.gptStreaming = true; // set it to true to enable stream ( multiple chunks of data instead of one )
    const closeTimeout = useRef(null);

    /**
     * This function closes modal after below mentioned timeout.
     * @param null
     */
    const scheduleClose = () => {
      clearCloseTimeOut();
      closeTimeout.current = setTimeout(() => {
        closeCitationModal();
      }, 50);
    };

    /**
     * This function clears setTimeOut that is currently running if user pointer moves over modal
     */
    const clearCloseTimeOut = () => {
      clearTimeout(closeTimeout.current);
    };

    useEffect(() => {
      if (hasError) {
        setGptClassList(() => 'su__noResult_container');
      } else {
        setGptClassList(() => (printing ? 'su__typing_annimation' : 'su__position-relative'));
      }
    }, [hasError, printing]);

    /**
     * handleHoverStates - It opens citation preview modal if user hovers on citation svg
     * @param {void}
     * @returns {void}
     */

    useEffect(() => {
      const handleHoverStates = (event) => {
        if (!isDeviceMobile) {
          if (
            event.target.tagName === 'BUTTON' &&
            event.target.classList.contains('su_citation') &&
            event.target.hasAttribute('data-url')
          ) {
            clearCloseTimeOut();
            setPreview(event);
          } else if (modalRef?.current?.contains?.(event.target)) {
            clearCloseTimeOut();
          } else if (showPortal && event.keyCode !== 9) {
            clearCloseTimeOut();
            scheduleClose();
          }
        }
      };
      document.addEventListener('mouseover', handleHoverStates);
      window.addEventListener('scroll', closeCitationModal);
      return () => {
        document.removeEventListener('mouseover', handleHoverStates);
        window.removeEventListener('scroll', closeCitationModal);
      };
    });

    /**
     * handleClickStates - It works for mobile view and open citation preview on click
     * @param {void}
     * @returns {void}
     */
    useEffect(() => {
      const handleClickStates = (event) => {
        if (isDeviceMobile) {
          if (
            event.target.tagName === 'BUTTON' &&
            event.target.classList.contains('su_citation') &&
            event.target.hasAttribute('data-url')
          ) {
            setPreview(event);
          }
        }
      };
      document.addEventListener('click', handleClickStates);
      return () => document.removeEventListener('click', handleClickStates);
    });
    /**
     * CloseCitationModalOnOutsideClick - This function closes the modal in mobile view if user clicks outside
     * the modal.
     * @param {void}
     * @returns {void}
     */

    useEffect(() => {
      const CloseCitationModalOnOutsideClick = (event) => {
        if (
          // event.target !== modalRef.current &&
          isDeviceMobile &&
          !event.target.classList.contains('su_citation') &&
          !modalRef?.current?.contains?.(event.target)
        ) {
          closeCitationModal();
        }
      };

      document.addEventListener('click', CloseCitationModalOnOutsideClick);
      return () => document.removeEventListener('click', CloseCitationModalOnOutsideClick);
    }, [modalRef.current]);

    /**
     * clearCache - maintain a cache upto CACHE_SIZE
     * @param {void}
     * @returns {void}
     */
    const clearCache = () => {
      const keys = Object.keys(cache);
      if (keys.length < CACHE_SIZE) return;
      const keyToDelete = keys.reduce((minKey, currentKey) => {
        const minValue = parseInt(currentKey.split('-').pop(), 10);
        return minValue < parseInt(minKey.split('-').pop(), 10) ? currentKey : minKey;
      }, keys[0]);
      setCache((prev) => {
        const newCache = { ...prev };
        delete newCache[keyToDelete];
        return newCache;
      });
    };
    const removeCitationButtons = (htmlContent) => {
      const regex = /<sup><button class="su_citation a11y-btn"[^>]*>.*?<\/button><\/sup>/g;
      return htmlContent.replace(regex, '');
    };
    const handleCopyToClipboard = () => {
      if (suGPTContentRef.current) {
        let contentToCopy = suGPTContentRef.current.innerHTML;
        contentToCopy = removeCitationButtons(contentToCopy);
        // Use a DOM parser to convert the cleaned HTML back to plain text
        const parser = new DOMParser();
        const doc = parser.parseFromString(contentToCopy, 'text/html');
        const plainTextContent = doc.body.textContent || '';
        navigator.clipboard
          .writeText(plainTextContent.trim())
          .then(() => {
            console.log('Text copied to clipboard:', contentToCopy);
            setShowCopy(true);
            setTimeout(() => {
              setShowCopy(false);
            }, 1000);
          })
          .catch((error) => {
            console.error('Error copying text to clipboard:', error);
          });
      }
    };
    useEffect(() => {
      if (feedbackGivenlike) {
        setIntegerVal(0);
      } else {
        setIntegerVal(1);
      }
    }, [feedbackGivenlike]);

    const checkForError = (inputString, errorArray) => {
      if (errorArray) {
        let filteredArray = errorArray.filter((x) => x.startsWith(inputString));
        if (filteredArray && filteredArray.length) {
          setTriggers((prevState) => ({ ...prevState, hasError: true }));
        } else {
          setTriggers((prevState) => ({ ...prevState, hasError: false }));
        }
      }
    };

    /**
     * getNextCacheOrder - gets next number in-line for cache
     * @param {void}
     * @returns {number} - next order
     */
    const getNextCacheOrder = () => {
      const keys = Object.keys(cache);
      const maxNumber = keys.reduce((max, currentKey) => {
        const currentValue = parseInt(currentKey.split('-').pop(), 10);
        return currentValue > max ? currentValue : max;
      }, 0);
      return maxNumber + 1;
    };
    const handlePopupAction = (action, feedback, feedbackTags) => {
      if (action === 'close') {
        closePopup();
      }
      if (action === 'openthanks') {
        sendFeedbackAnalytics(feedback, feedbackTags);
        showThankyouModal();
        closePopupAfterDelay();
        handleLikeReaction();
      }
    };

    const closePopup = () => {
      setShowPopup(false);
    };

    const sendFeedbackAnalytics = (feedback, feedbackTags) => {
      let gptResponseWithoOutCitation = suGPTContentRef?.current?.innerHTML;
      gptResponseWithoOutCitation = removeCitationButtons(gptResponseWithoOutCitation);
      window.gza('llm_response_feedback', {
        uid: variables.searchCallVariables.uid,
        sid_session: variables.searchCallVariables.sid,
        text_entered: variables.searchCallVariables.searchString,
        llm_response: gptResponseWithoOutCitation,
        feedback: (feedback && feedback.trim()) || '',
        reaction: integerVal,
        email: variables.searchCallVariables.email || window.su_utm,
        feedback_tags: feedbackTags || []
      });
    };

    const showThankyouModal = () => {
      setShowPopup(true);
      setShowThankModal(true);
    };

    const closePopupAfterDelay = () => {
      setTimeout(() => {
        setShowPopup(false);
      }, POPUP_CLOSE_TIMEOUT);
    };

    const handleLikeReaction = () => {
      if (integerVal === 0) {
        setShowLike(true);
        const { cacheKey, cacheObj } = getCache(
          variables.searchCallVariables.searchString,
          gptContext
        );
        updateCache(cacheKey, cacheObj);
      } else if (integerVal === 1) {
        setShowDisike(true);
        const { cacheKey, cacheObj } = getCache(
          variables.searchCallVariables.searchString,
          gptContext
        );
        updateCacheDislike(cacheKey, cacheObj);
      }
    };

    const updateCache = (cacheKey, cacheObj) => {
      setCache((prev) => {
        const newCache = {
          ...prev,
          [cacheKey]: { ...cacheObj, showLike: true, showDislike: false }
        };
        return newCache;
      });
    };
    const updateCacheDislike = (cacheKey, cacheObj) => {
      setCache((prev) => {
        const newCache = {
          ...prev,
          [cacheKey]: { ...cacheObj, showLike: false, showDislike: true }
        };
        return newCache;
      });
    };

    /**
     * getCache - gets answer from cache for a query & context
     * @param {string} query
     * @param {string} context
     * @returns {string} cached answer
     */

    const getCache = (query, context) => {
      const keys = Object.keys(cache);
      const cacheKey = keys.find((key) => key.includes(`${query}-${context}`));
      return cacheKey
        ? {
            cacheKey,
            cacheObj: cache[cacheKey]
          }
        : {};
    };

    /**
     * createCache - creates GPT cache for last n ( CACHE_SIZE ) answers
     * @param {string} query
     * @param {string} context
     * @param {string} answer
     */
    const createCache = (query, context, answer, showlike, showdislike) => {
      setGptResponse(answer);
      clearCache();
      const order = getNextCacheOrder();
      const key = `${query}-${context}-${order}`;
      setCache((prev) => ({
        ...prev,
        [key]: {
          answer,
          showLike: showlike,
          showDislike: showdislike
        }
      }));
    };

    /**
     * breakCheck - checks if current run is relevant
     * @param {*} effectId - running effect ID
     * @returns {bool} flag to exit
     */
    const breakCheck = (effectId) => {
      const exit = effectId != latestEffectId.current;
      // clear old animator refs from memory
      exit && animatorRefs.forEach((ref) => clearTimeout(ref));
      // return exit flag
      return exit;
    };

    /**
     * animateText - typing animation for custom text
     * @param {string} str - input string to animate
     * @param {*} effectId - unique ID for current run
     */
    const animateText = (str, effectId) => {
      setTriggers((prev) => ({ ...prev, loader: false, printing: true }));
      setSuGPTSays(() => '');
      setShowMore(true);
      const printer = async () => {
        setSuGPTSays(() => str); // if you want the data to come altogethere
        if (str === EMPTY_SEARCH_STRING || str === FAIL_TEXT) {
          setTriggers((prevState) => ({ ...prevState, hasError: true }));
        } else {
          checkForError(str, noAnswerArray);
        }

        setTriggers((prev) => ({ ...prev, printing: false }));
      };

      if (breakCheck(effectId)) return; //break check
      // clear old animator refs from memory
      animatorRefs.forEach((ref) => clearTimeout(ref));
      // add fresh entry of animator ref to memory
      animatorRefs.push(setTimeout(() => printer()));
    };
    const openModal = () => {
      setShowPopup(true);
      setLikeBtn(true);
      setShowThankModal(false);
      setFeedbackGivenlike(true);
      setFeedbackGivendislike(false);
      setButtonContent1('Accurate');
      setButtonContent2('Comprehensive');
    };
    const openModalDislike = () => {
      setShowPopup(true);
      setLikeBtn(false);
      setShowThankModal(false);
      setFeedbackGivenlike(false);
      setFeedbackGivendislike(true);
      setButtonContent1('Offensive');
      setButtonContent2('Incorrect');
    };

    /**
     * The effect listens to search calls and conditionally dispatches GPT API call action
     */
    useEffect(() => {
      /**
       * gptRoutine - triggers GPT routine after search response
       * @param {*} effectId - unique ID for current run
       * @returns {void}
       */
      async function gptRoutine(effectId) {
        // Allowed to make calls only on page number 1

        if (String(variables.searchCallVariables.pageNo) == '1' && searchResult) {
          setTriggers((prev) => ({ ...prev, hasError: false, loader: true }));
          setSuGPTSays(() => '');
          // Check if the answer exists in cache (saves API calls)
          const { cacheObj } = getCache(variables.searchCallVariables.searchString, gptContext);

          // If found render it with animation
          if (cacheObj && cacheObj.answer) {
            const cachedShowLike = cacheObj.showLike;
            const cachedShowDislike = cacheObj.showDislike;

            setShowLike(cachedShowLike);
            setShowDisike(cachedShowDislike);
            animateText(cacheObj.answer, effectId);
          } else {
            // Dispatch GPT action in case answer is not cached
            variables.gptContext = gptContext;
            variables.gptLinks = gptLinks;
            dispatch(suGPTSearch.start());
            setShowLike(false);
            setShowDisike(false);
          }
        }
        if (String(variables.searchCallVariables.pageNo) !== '1' && searchResult) {
          setShowMore(true);
        }
      }
      // Create a random unique ID
      const effectId = Math.random();
      latestEffectId.current = effectId;
      // EARLY EXIT - if search string is empty render a custom text
      if (variables.searchCallVariables.searchString == '' && searchResult) {
        setTriggers((prev) => ({ ...prev, hasError: true }));
        animateText(EMPTY_SEARCH_STRING, effectId);
        return;
      }
      /* Start GPT routine */
      gptRoutine(effectId);
      setGptResponse(suGPTSays);
    }, [searchResult]);

    /**
     * An effect that reads gpt stream
     */
    useEffect(() => {
      if (!suGPTStream || variables.searchCallVariables.searchString == '') return;
      const effectId = Math.random();
      latestEffectId.current = effectId;
      if (suGPTStream.includes?.('ERROR_GENERATING')) {
        setTriggers((prev) => ({ ...prev, loader: false, hasError: true }));
        animateText(FAIL_TEXT, effectId);
        return;
      }

      /**
       * readStream - reads gpt stream
       * @param {*} effectId - unique ID for current run
       * @returns {void}
       */
      async function readStream(effectId) {
        setTriggers((prev) => ({ ...prev, hasError: false, loader: true }));
        if (breakCheck(effectId)) return; //break check

        /* Populate Text */
        setSuGPTSays(() => '');

        const reader = await suGPTStream.body.getReader();
        setTriggers((prev) => ({ ...prev, loader: false, printing: true }));
        const decoder = new TextDecoder('utf8');
        let i;
        let firstChunk = false;
        let noAnswer;
        let gptAnswer = '';
        let partialAccumulator = '';
        let errorCodes = {
          401: { message: 'Unable to fetch a response for the query.', showButton: false },
          500: { message: 'Response can not be generated at the moment.', showButton: false },
          400: { message: 'Response can not be generated at the moment.', showButton: false },
          502: { message: 'Response can not be generated at the moment.', showButton: false },
          429: { message: 'Response can not be generated at the moment.', showButton: false },
          503: { message: 'Response can not be generated at the moment.', showButton: false },
          408: { message: 'Unable to fetch a response for the query.', showButton: true }
        };
        setTimeout(() => {
          setShowMore(true);
        }, 5);
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          // /* GET content in array */
          let dataArray = [];
          decoder
            ?.decode?.(value)
            ?.split(variables.STREAM_DELIMITER)
            .filter(Boolean)
            .forEach((data) => {
              let output = '';
              let jsonString = data;
              try {
                let validJson = partialAccumulator + data;
                let errorHandlingParsedJSON = JSON.parse(validJson);
                if (
                  Object.keys(errorCodes)
                    .map(Number)
                    .includes(JSON.parse(errorHandlingParsedJSON.status))
                ) {
                  setErrorObject({
                    ...errorObject,
                    show: true,
                    statusCode: errorHandlingParsedJSON.status,
                    message: errorCodes[errorHandlingParsedJSON.status].message,
                    showRetry: errorCodes[errorHandlingParsedJSON.status].showButton
                  });
                } else {
                  setErrorObject({
                    ...errorObject,
                    show: false,
                    statusCode: '',
                    message: '',
                    showRetry: false
                  });
                }
                validJson
                  .split(variables.STREAM_DELIMITER)
                  .filter(Boolean)
                  .forEach((i) => {
                    jsonString = i;
                    const parsedJSON = JSON.parse(i);
                    partialAccumulator = '';
                    noAnswer = parsedJSON && parsedJSON.data && parsedJSON.data.no_answer;
                    setnoAnswerArray(noAnswer);
                    if (!firstChunk) {
                      let llmRequestId = parsedJSON && parsedJSON.data && parsedJSON.data.id;
                      setLlmId(llmRequestId);
                      firstChunk = true;
                    }
                    dataArray.push(parsedJSON?.data?.choices?.[0]?.delta?.content || '');
                  });
              } catch (e) {
                partialAccumulator += jsonString || '';
              }
              return output;
            });
          /* Iterate and update ( automatically animates ) */
          i = 0;
          if (variables.gptStreaming) {
            while (i < dataArray.length) {
              if (breakCheck(effectId)) return; //break check
              gptAnswer += dataArray[i] || '';
              setSuGPTSays(() => gptAnswer);
              i++;
              checkForError(gptAnswer, noAnswer);
            }
          } else animateText(dataArray[0], effectId); // animate single chunk ( for streaming OFF )
          if (breakCheck(effectId)) return; //break check
        }
        if (!gptAnswer) {
          setTriggers((prev) => ({ ...prev, loader: false, hasError: true }));
          animateText(FAIL_TEXT, effectId);
          return;
        }

        setTriggers((prev) => ({ ...prev, printing: false }));

        /* Create cache for a given query, context and answer */
        createCache(
          variables.searchCallVariables.searchString,
          gptContext,
          gptAnswer,
          showLike,
          showDislike
        );
      }
      try {
        // clear old animator refs from memory
        animatorRefs.forEach((ref) => clearTimeout(ref));
        // add fresh entry of animator ref to memory
        animatorRefs.push(setTimeout(() => readStream(effectId)));
      } catch (e) {
        console.log(e);
      }
    }, [suGPTStream]);

    /**
     * This function is used to make scroll when annimation is taking place otherwise scrollbar is
     * going downwards along with content
     * @param {void}
     * @returns {void}
     */

    const handleShowMoreClick = () => {
      setShowMore(false);
      document.getElementById('setFocusOnGPTResponse').focus();
      window.gza('llm_show_more', {
        llmRequestId: llmId,
        searchId: window._gza_analytics_id,
        uid: variables.searchCallVariables.uid,
        sid_session: variables.searchCallVariables.sid
      });
    };
    return (
      <>
        {variables && variables.searchCallVariables && variables.searchCallVariables.pageNo == 1 ? (
          <div className={`su__center-gpt-widget su__mt-1 ${isDeviceMobile ? 'su__w-100' : ''}`}>
            <div
              tabIndex={tabIndexes.tabIndex_0}
              aria-label={t(StaticStrings.ongpt)}
              className={` su__center-gpt-widget su__snippets_container ${
                !loader && !hasError ? 'su__show-more-height-min' : ''
              }`}
            >
              <div
                className={`su__w-100 su__mt-0${
                  showMore && !loader && !errorObject.show && !hasError
                    ? ' su__typing-animation su__height-showmore'
                    : ' su__typing-animation'
                }
                su__typing-animation  su__mt-0 ${errorObject.show ? 'error-displayed' : ''}
                `}
              >
                <span
                  id="scrollableArea"
                  ref={scrollableAreaRef}
                  className={` ${
                    suGPTSays && !hasError && !loader ? 'typing-text su__typed_text' : ''
                  } ${hasError || !printing ? 'removeCursor' : ''} ${
                    showMore && !loader && !errorObject.show && !hasError
                      ? 'su__show-more-opacity'
                      : ''
                  }`}
                >
                  {loader && (
                    <>
                      <div className="su__d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="20"
                          height="21"
                          viewBox="0 0 22 23"
                        >
                          <defs>
                            <clipPath id="clip-Generating_icon">
                              <rect width="22" height="23" />
                            </clipPath>
                          </defs>
                          <g
                            id="Generating_icon"
                            data-name="Generating icon"
                            clipPath="url(#clip-Generating_icon)"
                          >
                            <g id="Group_19724" data-name="Group 19724" transform="translate(1 1)">
                              <g id="Group_18524" data-name="Group 18524">
                                <path
                                  id="Path_18304"
                                  data-name="Path 18304"
                                  d="M19.565,8.127l.795-1.785,1.761-.806a.513.513,0,0,0,0-.928L20.36,3.8l-.795-1.8a.5.5,0,0,0-.916,0l-.795,1.785L16.084,4.6a.513.513,0,0,0,0,.928l1.761.806.795,1.8A.508.508,0,0,0,19.565,8.127Zm-8.009,1.53-1.6-3.571a1,1,0,0,0-1.831,0l-1.6,3.571L3,11.279a1.026,1.026,0,0,0,0,1.857l3.522,1.622,1.6,3.571a1,1,0,0,0,1.831,0l1.6-3.571,3.522-1.622a1.026,1.026,0,0,0,0-1.857Zm7.083,6.631-.795,1.785-1.761.806a.513.513,0,0,0,0,.928l1.761.806.795,1.8a.5.5,0,0,0,.916,0l.795-1.785,1.771-.806a.513.513,0,0,0,0-.928l-1.761-.806-.795-1.8A.508.508,0,0,0,18.639,16.288Z"
                                  transform="translate(-2.415 -1.707)"
                                  fill="#1770d4"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <p className="su__font-14 su__line-height-15 loader-text su__mt-0 su__mb-0">
                          Generating...
                        </p>
                      </div>
                      <ul className="su__loader_snippet su__pl-0">
                        <li className="">
                          <div className="skeleton-box"></div>
                          <div className="skeleton-box"></div>
                        </li>
                      </ul>
                    </>
                  )}
                  {errorObject.show && !loader && (
                    <div className="retry-section">
                      <p
                        lang={variables.searchCallVariables.langAttr}
                        tabIndex={tabIndexes.tabIndex_0}
                        className={`${
                          isDeviceMobile
                            ? 'su__snippet_heading_color su__font-13'
                            : 'su__snippet_heading_color su__font-14'
                        }`}
                      >
                        {errorObject.message}
                      </p>
                      {errorObject.showRetry && (
                        <button
                          onClick={() => dispatch(suGPTSearch.start())}
                          lang={variables.searchCallVariables.langAttr}
                          role={a11y.ROLES.BTN}
                          tabIndex={tabIndexes.tabIndex_0}
                          type="button"
                          className="retry-btn su__cursor"
                        >
                          {t(StaticStrings.retry)}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.001"
                            height="12"
                            viewBox="0 0 12.001 12"
                          >
                            <path
                              id="refresh_FILL0_wght300_GRAD0_opsz24"
                              d="M187.572-768a5.838,5.838,0,0,1-4.279-1.743A5.774,5.774,0,0,1,181.54-774a5.778,5.778,0,0,1,1.753-4.257A5.836,5.836,0,0,1,187.572-780a5.933,5.933,0,0,1,2.669.624,5.707,5.707,0,0,1,2.094,1.761V-779.4a.579.579,0,0,1,.174-.427.586.586,0,0,1,.43-.172.585.585,0,0,1,.43.172.579.579,0,0,1,.173.427v3.569a.7.7,0,0,1-.209.515.705.705,0,0,1-.518.208h-3.588a.585.585,0,0,1-.43-.173.579.579,0,0,1-.173-.428.578.578,0,0,1,.173-.427.586.586,0,0,1,.43-.172H191.8a4.719,4.719,0,0,0-1.763-1.825,4.754,4.754,0,0,0-2.465-.667,4.666,4.666,0,0,0-3.418,1.4,4.617,4.617,0,0,0-1.407,3.4,4.617,4.617,0,0,0,1.407,3.4,4.666,4.666,0,0,0,3.418,1.4,4.727,4.727,0,0,0,2.567-.732,4.733,4.733,0,0,0,1.765-1.954.63.63,0,0,1,.336-.305.645.645,0,0,1,.452-.01.524.524,0,0,1,.344.32.526.526,0,0,1-.016.462,5.969,5.969,0,0,1-2.205,2.487,5.893,5.893,0,0,1-3.243.933Z"
                              transform="translate(-181.54 779.999)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  )}
                  {!!suGPTSays && !loader && (
                    <>
                      {!errorObject.show && (
                        <div className={gptClassList}>
                          <div
                            className={
                              showMore
                                ? 'su__show-more-height su__overflow-hide'
                                : 'su__overflow-visible'
                            }
                          >
                            <span
                              lang={variables.searchCallVariables.langAttr}
                              id="setFocusOnGPTResponse"
                              tabIndex={
                                !showMore || hasError
                                  ? tabIndexes.tabIndex_0
                                  : tabIndexes.tabIndex_1
                              }
                              ref={suGPTContentRef}
                              aria-live="polite"
                              className={hasError ? 'su__noresult_text_color' : 'su__remove_space'}
                              dangerouslySetInnerHTML={{ __html: suGPTSays?.trim?.() }}
                            ></span>
                            {!printing && !hasError && (
                              <div className="su__d-flex su__justify-content-end su__zindex-1">
                                {showCopy && <span className="su__copied-span">Copied</span>}
                                <>
                                  {!showCopy ? (
                                    <Tooltip text={t(StaticStrings.COPYCLIPBOARD)} position="left">
                                      <button
                                        type="button"
                                        className="a11y-btn su__padding-0"
                                        lang={variables.searchCallVariables.langAttr}
                                        tabIndex={
                                          showMore
                                            ? tabIndexes.tabIndex_minus_1
                                            : tabIndexes.tabIndex_0
                                        }
                                        role={a11y.ROLES.BTN}
                                        aria-label={t(StaticStrings.COPYCLIPBOARD)}
                                        onClick={handleCopyToClipboard}
                                      >
                                        <svg
                                          className="su__cursor su__gpt-feedback"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="28"
                                          height="28"
                                          viewBox="0 0 32 32"
                                        >
                                          <defs>
                                            <clipPath id="clip-Copt_to_clipboard_with_white_bg">
                                              <rect width="32" height="32" />
                                            </clipPath>
                                          </defs>
                                          <g
                                            id="Copt_to_clipboard_with_white_bg"
                                            data-name="Copt to clipboard with white bg"
                                            clipPath="url(#clip-Copt_to_clipboard_with_white_bg)"
                                          >
                                            <g
                                              id="Group_19987"
                                              data-name="Group 19987"
                                              transform="translate(-2942 4505)"
                                            >
                                              <g
                                                id="Group_19986"
                                                data-name="Group 19986"
                                                transform="translate(2025 -5248)"
                                              >
                                                <g
                                                  id="Rectangle_11220"
                                                  data-name="Rectangle 11220"
                                                  transform="translate(918 744)"
                                                  fill="#fff"
                                                  stroke="#b7b7b7"
                                                  strokeWidth="0.5"
                                                >
                                                  <rect
                                                    width="30"
                                                    height="30"
                                                    rx="3"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="0.25"
                                                    y="0.25"
                                                    width="29.5"
                                                    height="29.5"
                                                    rx="2.75"
                                                    fill="none"
                                                  />
                                                </g>
                                                <rect
                                                  id="Rectangle_7074"
                                                  data-name="Rectangle 7074"
                                                  width="23"
                                                  height="23"
                                                  transform="translate(922 748)"
                                                  fill="#c5c5c5"
                                                  opacity="0"
                                                />
                                              </g>
                                              <path
                                                id="svgexport-11"
                                                d="M12,4a2,2,0,0,0-2,2h4A2,2,0,0,0,12,4ZM8.535,4a4,4,0,0,1,6.93,0H17a3,3,0,0,1,3,3V19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V7A3,3,0,0,1,7,4ZM8,6H7A1,1,0,0,0,6,7V19a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H16a2,2,0,0,1-2,2H10A2,2,0,0,1,8,6Z"
                                                transform="translate(2946 -4501)"
                                                fill="#b7b7b7"
                                                fillRule="evenodd"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </button>
                                    </Tooltip>
                                  ) : (
                                    <button
                                      type="button"
                                      className="a11y-btn su__padding-0"
                                      tabIndex={tabIndexes.tabIndex_0}
                                      role={a11y.ROLES.BTN}
                                      lang={variables.searchCallVariables.langAttr}
                                      aria-label={t(StaticStrings.COPIED)}
                                      aria-live="assertive"
                                      aria-atomic="true"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 32 32"
                                      >
                                        <defs>
                                          <clipPath id="clip-copied_with_transparent_bg_1">
                                            <rect width="32" height="32" />
                                          </clipPath>
                                        </defs>
                                        <g
                                          id="copied_with_transparent_bg_1"
                                          data-name="copied with transparent bg – 1"
                                          clipPath="url(#clip-copied_with_transparent_bg_1)"
                                        >
                                          <g
                                            id="Group_19990"
                                            data-name="Group 19990"
                                            transform="translate(-3014 4465)"
                                          >
                                            <g
                                              id="Group_19727"
                                              data-name="Group 19727"
                                              transform="translate(1811 -4670)"
                                            >
                                              <g
                                                id="Rectangle_11220"
                                                data-name="Rectangle 11220"
                                                transform="translate(1204 206)"
                                                fill="none"
                                                stroke="#b7b7b7"
                                                strokeWidth="0.5"
                                              >
                                                <rect width="30" height="30" rx="3" stroke="none" />
                                                <rect
                                                  x="0.25"
                                                  y="0.25"
                                                  width="29.5"
                                                  height="29.5"
                                                  rx="2.75"
                                                  fill="none"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                          <path
                                            id="Icon_ionic-ios-checkmark"
                                            data-name="Icon ionic-ios-checkmark"
                                            d="M28.158,13.8,26.7,12.3a.313.313,0,0,0-.232-.1h0a.3.3,0,0,0-.232.1L16.144,22.475,12.47,18.8a.32.32,0,0,0-.463,0l-1.473,1.473a.33.33,0,0,0,0,.472l4.634,4.634a1.465,1.465,0,0,0,.968.472,1.535,1.535,0,0,0,.96-.455h0L28.166,14.275A.354.354,0,0,0,28.158,13.8Z"
                                            transform="translate(-3.434 -3.2)"
                                            fill="#206b05"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  )}
                                </>

                                {!showLike && !showDislike ? (
                                  <>
                                    <button
                                      type="button"
                                      className="a11y-btn su__margin-lr-5 su__padding-0"
                                      tabIndex={
                                        showMore
                                          ? tabIndexes.tabIndex_minus_1
                                          : tabIndexes.tabIndex_0
                                      }
                                      role={a11y.ROLES.BTN}
                                      aria-label={t(StaticStrings.positive_feedback)}
                                      lang={variables.searchCallVariables.langAttr}
                                      onClick={openModal}
                                    >
                                      <svg
                                        className="su__cursor su__gpt-feedback"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 32 32"
                                      >
                                        <defs>
                                          <clipPath id="clip-Thums_up_with_white_bg">
                                            <rect width="32" height="32" />
                                          </clipPath>
                                        </defs>
                                        <g
                                          id="Thums_up_with_white_bg"
                                          data-name="Thums up with white bg"
                                          clipPath="url(#clip-Thums_up_with_white_bg)"
                                        >
                                          <g
                                            id="Group_19988"
                                            data-name="Group 19988"
                                            transform="translate(-2979 4505)"
                                          >
                                            <g
                                              id="Group_19985"
                                              data-name="Group 19985"
                                              transform="translate(1816 -4710)"
                                            >
                                              <g
                                                id="Rectangle_11187"
                                                data-name="Rectangle 11187"
                                                transform="translate(1164 206)"
                                                fill="#fff"
                                                stroke="#b7b7b7"
                                                strokeWidth="0.5"
                                              >
                                                <rect width="30" height="30" rx="3" stroke="none" />
                                                <rect
                                                  x="0.25"
                                                  y="0.25"
                                                  width="29.5"
                                                  height="29.5"
                                                  rx="2.75"
                                                  fill="none"
                                                />
                                              </g>
                                            </g>
                                            <path
                                              id="svgexport-12"
                                              d="M12.132,2.5a1,1,0,0,1,.992-.5l.454.057A4,4,0,0,1,16.9,7.21L16.354,9h.718a4,4,0,0,1,3.859,5.053l-1.364,5A4,4,0,0,1,15.709,22H6a3,3,0,0,1-3-3V12A3,3,0,0,1,6,9H8a.723.723,0,0,0,.628-.364ZM10,20h5.709a2,2,0,0,0,1.929-1.474l1.364-5A2,2,0,0,0,17.072,11H15a1,1,0,0,1-.956-1.294l.949-3.084a2,2,0,0,0-1.461-2.537L10.364,9.628A2.723,2.723,0,0,1,9,10.81V19A1,1,0,0,0,10,20ZM7,11v8a3,3,0,0,0,.171,1H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1Z"
                                              transform="translate(2983 -4501)"
                                              fill="#b7b7b7"
                                              fillRule="evenodd"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                  </>
                                ) : (
                                  !showDislike && (
                                    <>
                                      <button
                                        role={a11y.ROLES.BTN}
                                        type="button"
                                        className="a11y-btn su__padding-0 su__margin-lr-5 "
                                        aria-live="assertive"
                                        aria-atomic="true"
                                        lang={variables.searchCallVariables.langAttr}
                                        aria-label={t(StaticStrings.positive_given)}
                                      >
                                        <svg
                                          className="su__other-icon   su__gpt-feedback "
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="28"
                                          height="28"
                                          viewBox="0 0 32 32"
                                        >
                                          <defs>
                                            <clipPath id="clip-Thums_up_with_transparent_bg">
                                              <rect width="32" height="32" />
                                            </clipPath>
                                          </defs>
                                          <g
                                            id="Thums_up_with_transparent_bg"
                                            data-name="Thums up with transparent bg"
                                            clipPath="url(#clip-Thums_up_with_transparent_bg)"
                                          >
                                            <g
                                              id="Group_19991"
                                              data-name="Group 19991"
                                              transform="translate(-2977 4465)"
                                            >
                                              <g
                                                id="Group_19728"
                                                data-name="Group 19728"
                                                transform="translate(1814 -4670)"
                                              >
                                                <g
                                                  id="Rectangle_11187"
                                                  data-name="Rectangle 11187"
                                                  transform="translate(1164 206)"
                                                  fill="none"
                                                  stroke="#b7b7b7"
                                                  strokeWidth="0.5"
                                                >
                                                  <rect
                                                    width="30"
                                                    height="30"
                                                    rx="3"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="0.25"
                                                    y="0.25"
                                                    width="29.5"
                                                    height="29.5"
                                                    rx="2.75"
                                                    fill="#1770D4"
                                                  />
                                                </g>
                                              </g>
                                              <path
                                                id="svgexport-12"
                                                d="M12.132,2.5a1,1,0,0,1,.992-.5l.454.057A4,4,0,0,1,16.9,7.21L16.354,9h.718a4,4,0,0,1,3.859,5.053l-1.364,5A4,4,0,0,1,15.709,22H6a3,3,0,0,1-3-3V12A3,3,0,0,1,6,9H8a.723.723,0,0,0,.628-.364ZM10,20h5.709a2,2,0,0,0,1.929-1.474l1.364-5A2,2,0,0,0,17.072,11H15a1,1,0,0,1-.956-1.294l.949-3.084a2,2,0,0,0-1.461-2.537L10.364,9.628A2.723,2.723,0,0,1,9,10.81V19A1,1,0,0,0,10,20ZM7,11v8a3,3,0,0,0,.171,1H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1Z"
                                                transform="translate(2981 -4461)"
                                                fill="#FFFFFF"
                                                fillRule="evenodd"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </button>
                                    </>
                                  )
                                )}
                                {showDislike && (
                                  <>
                                    <button
                                      type="button"
                                      lang={variables.searchCallVariables.langAttr}
                                      role={a11y.ROLES.BTN}
                                      className="a11y-btn su__padding-0 su__margin-lr-5 "
                                      aria-live="assertive"
                                      aria-atomic="true"
                                      aria-label={t(StaticStrings.negative_given)}
                                    >
                                      <svg
                                        className="su__other-icon  su__gpt-feedback "
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 32 32"
                                      >
                                        <defs>
                                          <clipPath id="clip-Thums_down_with_transparent_bg">
                                            <rect width="32" height="32" />
                                          </clipPath>
                                        </defs>
                                        <g
                                          id="Thums_down_with_transparent_bg"
                                          data-name="Thums down with transparent bg"
                                          clipPath="url(#clip-Thums_down_with_transparent_bg)"
                                        >
                                          <g
                                            id="Group_19990"
                                            data-name="Group 19990"
                                            transform="translate(-3014 4465)"
                                          >
                                            <g
                                              id="Group_19727"
                                              data-name="Group 19727"
                                              transform="translate(1811 -4670)"
                                            >
                                              <g
                                                id="Rectangle_11220"
                                                data-name="Rectangle 11220"
                                                transform="translate(1204 206)"
                                                fill="none"
                                                stroke="#b7b7b7"
                                                strokeWidth="0.5"
                                              >
                                                <rect width="30" height="30" rx="3" stroke="none" />
                                                <rect
                                                  x="0.25"
                                                  y="0.25"
                                                  width="29.5"
                                                  height="29.5"
                                                  rx="2.75"
                                                  fill="#1770D4"
                                                />
                                              </g>
                                            </g>
                                            <path
                                              id="svgexport-13"
                                              d="M11.873,21.5a1,1,0,0,1-.992.5l-.454-.057A4,4,0,0,1,7.1,16.79L7.651,15H6.932A4,4,0,0,1,3.073,9.948l1.364-5A4,4,0,0,1,8.3,2H18a3,3,0,0,1,3,3v7a3,3,0,0,1-3,3H16a.723.723,0,0,0-.628.364ZM14,4H8.3a2,2,0,0,0-1.93,1.474L5,10.474A2,2,0,0,0,6.932,13H9a1,1,0,0,1,.956,1.294l-.949,3.084a2,2,0,0,0,1.461,2.537l3.167-5.543A2.723,2.723,0,0,1,15,13.19V5A1,1,0,0,0,14,4Zm3,9V5a2.994,2.994,0,0,0-.171-1H18a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Z"
                                              transform="translate(3018.07 -4461)"
                                              fill="#FFFFFF"
                                              fillRule="evenodd"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                  </>
                                )}
                                {!showLike && !showDislike && (
                                  <>
                                    <button
                                      type="button"
                                      className="a11y-btn su__padding-0"
                                      tabIndex={
                                        showMore
                                          ? tabIndexes.tabIndex_minus_1
                                          : tabIndexes.tabIndex_0
                                      }
                                      lang={variables.searchCallVariables.langAttr}
                                      role={a11y.ROLES.BTN}
                                      aria-label={t(StaticStrings.negative_feedback)}
                                      onClick={openModalDislike}
                                    >
                                      <svg
                                        className="su__cursor su__gpt-feedback"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 32 32"
                                      >
                                        <defs>
                                          <clipPath id="clip-Thums_down_with_white_bg">
                                            <rect width="32" height="32" />
                                          </clipPath>
                                        </defs>
                                        <g
                                          id="Thums_down_with_white_bg"
                                          data-name="Thums down with white bg"
                                          clipPath="url(#clip-Thums_down_with_white_bg)"
                                        >
                                          <g
                                            id="Group_19989"
                                            data-name="Group 19989"
                                            transform="translate(-3016 4505)"
                                          >
                                            <g
                                              id="Group_19984"
                                              data-name="Group 19984"
                                              transform="translate(1813 -4710)"
                                            >
                                              <g
                                                id="Rectangle_11220"
                                                data-name="Rectangle 11220"
                                                transform="translate(1204 206)"
                                                fill="#fff"
                                                stroke="#b7b7b7"
                                                strokeWidth="0.5"
                                              >
                                                <rect width="30" height="30" rx="3" stroke="none" />
                                                <rect
                                                  x="0.25"
                                                  y="0.25"
                                                  width="29.5"
                                                  height="29.5"
                                                  rx="2.75"
                                                  fill="none"
                                                />
                                              </g>
                                            </g>
                                            <path
                                              id="svgexport-13"
                                              d="M11.873,21.5a1,1,0,0,1-.992.5l-.454-.057A4,4,0,0,1,7.1,16.79L7.651,15H6.932A4,4,0,0,1,3.073,9.948l1.364-5A4,4,0,0,1,8.3,2H18a3,3,0,0,1,3,3v7a3,3,0,0,1-3,3H16a.723.723,0,0,0-.628.364ZM14,4H8.3a2,2,0,0,0-1.93,1.474L5,10.474A2,2,0,0,0,6.932,13H9a1,1,0,0,1,.956,1.294l-.949,3.084a2,2,0,0,0,1.461,2.537l3.167-5.543A2.723,2.723,0,0,1,15,13.19V5A1,1,0,0,0,14,4Zm3,9V5a2.994,2.994,0,0,0-.171-1H18a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Z"
                                              transform="translate(3020.07 -4501)"
                                              fill="#b7b7b7"
                                              fillRule="evenodd"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </span>
                {!hasError && showMore && !errorObject.show && !loader && (
                  <div className="su__show-all su__show-more-bg su__d-flex su__justify-content-center su__flex-vcenter su__loading-view">
                    <button
                      type="button"
                      className="su__show-more-gpt su__cursor su__txt-capitalize a11y-btn"
                      tabIndex={tabIndexes.tabIndex_0}
                      lang={variables.searchCallVariables.langAttr}
                      role={a11y.ROLES.BTN}
                      aria-label={t(StaticStrings.show_more)}
                      onClick={handleShowMoreClick}
                    >
                      {t(StaticStrings.show_more)}
                    </button>
                    <span
                      className="su__arrow-down su__arrow-down-zendesk su__cursor"
                      onClick={handleShowMoreClick}
                    ></span>
                  </div>
                )}
              </div>
            </div>
            {feedbackGivenlike && (
              <GptFeedbackPopUp
                heading="Additional Feedback"
                IsShown={showPopup}
                likeBtn={likeBtn}
                textContent="How is your search experience with SearchUnifyGPT?"
                onAction={handlePopupAction}
                buttonContent1={buttonContent1}
                buttonContent2={buttonContent2}
                showThanks={showThankModal}
              />
            )}
            {feedbackGivendislike && (
              <GptFeedbackPopUp
                heading="Additional Feedback"
                IsShown={showPopup}
                likeBtn={likeBtn}
                textContent="How is your search experience with SearchUnifyGPT?"
                onAction={handlePopupAction}
                buttonContent1={buttonContent1}
                buttonContent2={buttonContent2}
                showThanks={showThankModal}
              />
            )}

            <div
              className={`su__text-right su__mt-0 su__mb-1 ${
                isDeviceMobile ? 'su__snippet_heading su__text-right' : 'su__snippet_heading'
              }`}
            >
              <span
                className={`${
                  isDeviceMobile
                    ? 'su__snippet_heading_color su__font-10'
                    : 'su__snippet_heading_color su__font-11'
                }`}
              >
                SearchUnifyGPT
              </span>
              <span className="su__snippet_fontStyles">TM</span>
            </div>
          </div>
        ) : null}
      </>
    );
  } catch (e) {
    console.log('Error in feature-snippet component', e);
    return <div />;
  }
};

export default React.memo(FeaturedSnippetAnimation);
