/* global gza */
import React, { useState, useEffect, Fragment } from 'react';
import Title from 'components/section-components/title/index.jsx';
import Href from 'components/section-components/href/index.jsx';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { a11y } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';

const FeaturedSnippetSteps = ({ featuredSnippetResult, linkOpened, isDeviceMobile }) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const [featuredSnippetGraphResponseRecorded, setFeaturedSnippetGraphFeedback] = useState(false);
    const [timeoutResponse, setTimeoutResponse] = useState(true);
    useEffect(() => {
      setFeaturedSnippetGraphFeedback(false);
      setTimeoutResponse(true);
      morefeaturedSnippetSteps({ itemsToShow: 3, expanded: false });
    }, [featuredSnippetResult]);
    const sendFeaturedSnippetGraphFeedback = (feedback, result) => {
      gza('featuredSnippet', {
        searchString: variables.searchCallVariables.searchString,
        url: result.href,
        t: result.highlight.TitleToDisplayString[0].substring(0, 300) || result.href,
        uid: variables.searchCallVariables.uid,
        feedback: feedback
      });
      setFeaturedSnippetGraphFeedback(true);
      setTimeout(function () {
        setTimeoutResponse(false);
      }, 5000);
    };
    const [featuredSnippetSteps, morefeaturedSnippetSteps] = useState({
      expanded: false,
      itemsToShow: 3
    });
    /**
     *
     * @param {show more facets} facet
     */
    const showMore = (item) => {
      featuredSnippetSteps.itemsToShow === 3
        ? morefeaturedSnippetSteps({ itemsToShow: item.steps.length, expanded: true })
        : morefeaturedSnippetSteps({ itemsToShow: 3, expanded: false });
    };
    return (
      <>
        {!isDeviceMobile && (
          <div className="su__FeaturedSnippet su__bg-white su__sm-shadow su__radius-1 su__position-relative su__mb-3 su__mt-2 su__p-3 su__d-md-flex su__flex-column su__align-items-center su__overflow-hide su__w-100">
            <div className="su__d-flex su__w-100">
              <div
                className={`su__featureSnippet-left su__flex-1 su__d-flex su__flex-column su__overflow-hide su__mr-4 ${
                  featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                    ? 'su__w-75'
                    : ''
                }`}
              >
                {featuredSnippetResult.header ? (
                  <div className="su__featureSnippet-title su__text-black su__font-16 su__f-bold su__mb-1 su__text-truncate">
                    {featuredSnippetResult.header}
                  </div>
                ) : null}
                <div className="su__featureSnippet-list su__mt-2 su__mb-3">
                  {featuredSnippetResult.steps
                    .slice(0, featuredSnippetSteps.itemsToShow)
                    .map((item, index, featuredSnippet) => {
                      return (
                        <div className={`su__text-black regular su__mb-2 su__d-flex`} key={uuid()}>
                          <span className="su__steps-bullets"></span>
                          <span className="su__font-13 su__text-truncate">{item}</span>
                          {featuredSnippetResult.steps.length > 3 &&
                          featuredSnippet.length === index + 1 ? (
                            <span
                              lang={variables.searchCallVariables.langAttr}
                              className="su__cursor su__position-relative su__font-12 su__ml-2 su__color-blue su__f-regular su__showmore-text su__d-inline-block"
                              onClick={() => showMore(featuredSnippetResult)}
                            >
                              {featuredSnippetSteps.expanded ? (
                                <Fragment>{t(StaticStrings.less)}</Fragment>
                              ) : (
                                <Fragment>{t(StaticStrings.more)}</Fragment>
                              )}
                            </span>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
                <Title item={featuredSnippetResult} linkOpened={linkOpened} index={1} />
                <Href item={featuredSnippetResult} />
              </div>
              <div
                className={`su__featureSnippet-right su__d-flex su__flex-column ${
                  featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                    ? 'su__featured-thumbnail'
                    : 'su__w-80px su__background-white'
                } ${
                  (featuredSnippetResult.multiMedia &&
                    featuredSnippetResult.multiMedia.length &&
                    featuredSnippetResult.multiMedia[0].video_url &&
                    featuredSnippetResult.multiMedia[0].thumbnail) ||
                  (featuredSnippetResult.multiMedia &&
                    featuredSnippetResult.multiMedia.length &&
                    featuredSnippetResult.multiMedia[0].image_urls)
                    ? null
                    : 'su__bg_grey_container'
                }`}
              >
                <div className="su__d-flex su__w-100 su__featureSnippet-response su__thumbs_icon_align">
                  {!featuredSnippetGraphResponseRecorded ? (
                    <div className="su__knowledge-feedback-row su__d-flex su__icon_align">
                      <span
                        className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                        onClick={() => sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsDown"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                      <span
                        className="su__featured-feedback su__cursor su__loading-view"
                        onClick={() => sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsUp"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                    </div>
                  ) : null}
                  {featuredSnippetGraphResponseRecorded && timeoutResponse ? (
                    <div className="su__feedback-thankyou su__feedback-steps su__font-14 su__mb-2 su__position-absolute su__loading-view">
                      <div className="su__flex-vcenter su__font-12">
                        <span
                          lang={variables.searchCallVariables.langAttr}
                          className="su__thankyou-text su__whitespace-no"
                        >
                          {t(StaticStrings.thanks_response)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                {featuredSnippetResult.multiMedia &&
                featuredSnippetResult.multiMedia.length != 0 ? (
                  <div
                    className={`su__featureSnippet-right su__align-self-center su__py-sm-2  ${
                      (featuredSnippetResult.multiMedia &&
                        featuredSnippetResult.multiMedia.length &&
                        featuredSnippetResult.multiMedia[0].video_url &&
                        featuredSnippetResult.multiMedia[0].thumbnail) ||
                      (featuredSnippetResult.multiMedia &&
                        featuredSnippetResult.multiMedia.length &&
                        featuredSnippetResult.multiMedia[0].image_urls)
                        ? null
                        : 'su__align_grey_icon'
                    }`}
                  >
                    <div className="su__featured-thumbnail">
                      <a
                        className="su__position-relative su__d-flex"
                        onClick={() => linkOpened(featuredSnippetResult, 1)}
                        target="_blank"
                        href={
                          featuredSnippetResult.multiMedia[0].href ||
                          featuredSnippetResult.multiMedia[0].video_url
                        }
                        rel="noopener noreferrer"
                      >
                        {featuredSnippetResult.multiMedia[0].video_url ? (
                          <>
                            {featuredSnippetResult.multiMedia[0].thumbnail ? (
                              <svg
                                className="su__video-thumbnail-play"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeaturedSnippetSvgFill}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            ) : (
                              <svg
                                className="su__align_icon_play_grey su__m-auto"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeatureSnippetSvgPlayGrey}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            )}
                          </>
                        ) : null}
                        <img
                          src={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                          className="su__img-featured"
                          alt={featuredSnippetResult.multiMedia[0].alt_attributes}
                          role={a11y.ROLES.IMAGE}
                          aria-label={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                        />
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {isDeviceMobile && (
          <div className="su__FeaturedSnippet su__bg-white su__sm-shadow su__radius-1 su__position-relative su__mb-2 su__p-3 su__d-md-flex su__flex-column su__align-items-center su__overflow-hide su__w-100">
            <div className="su__d-flex su__flex-column su__w-100 su__overflow-hide su__word-break">
              {featuredSnippetResult.header ? (
                <div className="su__featureSnippet-title su__text-black su__font-16 su__f-bold su__mb-1 su__text-truncate">
                  {featuredSnippetResult.header}
                </div>
              ) : null}
              <div className="su__featureSnippet-left su__flex-1 su__d-flex su__overflow-hide su__mt-2 su__mb-2">
                <div
                  className={`su__featureSnippet-list ${
                    featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                      ? 'su__w-70 su__pr-2'
                      : 'su__w-100'
                  }`}
                >
                  {featuredSnippetResult.steps
                    .slice(0, featuredSnippetSteps.itemsToShow)
                    .map((item, index, featuredSnippet) => {
                      return (
                        <div className={`su__text-black regular su__mb-2 su__d-flex`} key={uuid()}>
                          <span className="su__steps-bullets"></span>
                          <span className="su__font-13 su__text-truncate">{item}</span>
                          {featuredSnippetResult.steps.length > 3 &&
                          featuredSnippet.length === index + 1 ? (
                            <span
                              lang={variables.searchCallVariables.langAttr}
                              className="su__cursor su__position-relative su__text-nowrap su__font-12 su__ml-2 su__color-blue su__f-regular su__showmore-text su__d-inline-block"
                              onClick={() => showMore(featuredSnippetResult)}
                            >
                              {featuredSnippetSteps.expanded ? (
                                <Fragment>{t(StaticStrings.less)}</Fragment>
                              ) : (
                                <Fragment>{t(StaticStrings.more)}</Fragment>
                              )}
                            </span>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
                {featuredSnippetResult.multiMedia &&
                featuredSnippetResult.multiMedia.length != 0 ? (
                  <div
                    className={`su__featureSnippet-right su__d-flex su__flex-column ${
                      featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                        ? 'su__w-30'
                        : ''
                    } ${
                      (featuredSnippetResult.multiMedia &&
                        featuredSnippetResult.multiMedia.length &&
                        featuredSnippetResult.multiMedia[0].video_url &&
                        featuredSnippetResult.multiMedia[0].thumbnail) ||
                      featuredSnippetResult.multiMedia[0].image_urls
                        ? null
                        : 'su__grey_bg'
                    }`}
                  >
                    <div
                      className={`su__featureSnippet-right su__align-self-center ${
                        (featuredSnippetResult.multiMedia &&
                          featuredSnippetResult.multiMedia.length &&
                          featuredSnippetResult.multiMedia[0].video_url &&
                          featuredSnippetResult.multiMedia[0].thumbnail) ||
                        featuredSnippetResult.multiMedia[0].image_urls
                          ? null
                          : 'su__m-auto'
                      }`}
                    >
                      <div className="su__min-w-30">
                        <a
                          className="su__position-relative su__d-flex"
                          onClick={() => linkOpened(featuredSnippetResult, 1)}
                          target="_blank"
                          href={
                            featuredSnippetResult.multiMedia[0].href ||
                            featuredSnippetResult.multiMedia[0].video_url
                          }
                          rel="noopener noreferrer"
                        >
                          {featuredSnippetResult.multiMedia[0].video_url ? (
                            <>
                              {featuredSnippetResult.multiMedia[0].thumbnail ? (
                                <svg
                                  className="su__video-thumbnail-play"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  width="24px"
                                  fill={IconColors.FeaturedSnippetSvgFill}
                                >
                                  <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                                </svg>
                              ) : (
                                <svg
                                  className="su__align_icon_play_grey su__m-auto"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  width="24px"
                                  fill={IconColors.FeatureSnippetSvgPlayGrey}
                                >
                                  <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                                </svg>
                              )}
                            </>
                          ) : null}
                          <img
                            src={
                              featuredSnippetResult.multiMedia[0].image_urls ||
                              featuredSnippetResult.multiMedia[0].thumbnail
                            }
                            className="su__img-featured"
                            alt={featuredSnippetResult.multiMedia[0].alt_attributes}
                            role={a11y.ROLES.IMAGE}
                            aria-label={
                              featuredSnippetResult.multiMedia[0].image_urls ||
                              featuredSnippetResult.multiMedia[0].thumbnail
                            }
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <Title item={featuredSnippetResult} linkOpened={linkOpened} index={1} />
              <Href item={featuredSnippetResult} />
              <div className="su__d-flex su__w-100">
                {!featuredSnippetGraphResponseRecorded ? (
                  <div className="su__d-flex su__pt-2">
                    <span
                      className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                      onClick={() => sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult)}
                    >
                      <Icons
                        IconName="ThumbsDown"
                        width="24"
                        height="24"
                        color={IconColors.FeaturedSnippetThumbsup_down}
                      />
                    </span>
                    <span
                      className="su__featured-feedback su__cursor su__loading-view"
                      onClick={() => sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult)}
                    >
                      <Icons
                        IconName="ThumbsUp"
                        width="24"
                        height="24"
                        color={IconColors.FeaturedSnippetThumbsup_down}
                      />
                    </span>
                  </div>
                ) : null}
                {featuredSnippetGraphResponseRecorded && timeoutResponse ? (
                  <div className="su__pt-2 su__position-relative su__loading-view">
                    <div className="su__flex-vcenter su__font-12">
                      <span
                        lang={variables.searchCallVariables.langAttr}
                        className="su__thankyou-text su__whitespace-no"
                      >
                        {t(StaticStrings.thanks_response)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (e) {
    console.log('Error in feature-snippet component', e);
    return <div></div>;
  }
};

export default FeaturedSnippetSteps;
