import React, { Fragment } from 'react';
import SearchTuning from 'components/section-components/auto-tune/index.jsx';
import { v4 as uuid } from 'uuid';

const ResultsInAllContentsGridView = (props) => {
  try {
    let { item, getContentResults, linkOpened, mergeSourcesTypeIndex } = props;
    return (
      <div className="su__AllContentsGridView su__list-item-row su__d-md-flex su__w-100 su__overflow-hide ">
        <div className="su__w-100  su__loading-view su__bg-dark-gray su__pt-2 su__pb-2 su__pl-3 su__pr-3">
          <span className="su__color-blue su__d-inline-block su-sourceLabel su__text-decoration su__vertical-align-middle su__text-truncate su__font-14 su__font-bold">
            {mergeSourcesTypeIndex ? item.sourceLabel : item.objLabel}
          </span>
          <span
            onClick={getContentResults}
            className={
              mergeSourcesTypeIndex
                ? item.sourceName +
                  ' su__font-12 su__font-regular su__float-right su__vertical-align-middle su__cursor'
                : item.objName +
                  ' su__font-12 su__font-regular su__float-right su__vertical-align-middle su__cursor'
            }
          >
            More Results...
          </span>
        </div>
        <div className=" su__pr-3 su__pl-3 su__pb-3 su__overflow-hide">
          {item.hits &&
            React.Children.toArray(
              item.hits.map((mergedResults, index) => (
                <div
                  key={uuid()}
                  className="su__AllContentsGridView-child su__w-100 su__media-body su__overflow-hide su__word-break"
                >
                  <div className="su__list-item-title  su__flex-vcenter su__mw-100">
                    <Fragment>
                      <a
                        className="su__text-decoration su__text-black su__text-truncate  su__font-14 su__font-bold"
                        href={mergedResults.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseDown={(event) => {
                          if (event.button == 1) linkOpened(mergedResults, index + 1);
                        }}
                        onContextMenu={() => {
                          linkOpened(mergedResults, index + 1);
                        }}
                        onClick={() => linkOpened(mergedResults, index + 1)}
                      >
                        <span
                          className="su__text-truncate"
                          dangerouslySetInnerHTML={{
                            __html: mergedResults.highlight.TitleToDisplay[0] || mergedResults.href
                          }}
                        ></span>
                      </a>
                      <SearchTuning item={mergedResults} />
                    </Fragment>
                  </div>
                  <div className="su__href-txt su__text-decoration su__color-lgray su__font-12 su__font-italic">
                    {' '}
                    {mergedResults.href}
                  </div>
                  {React.Children.toArray(
                    mergedResults.highlight.SummaryToDisplay.map((data) => (
                      <div
                        key={uuid()}
                        className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__font-12"
                        dangerouslySetInnerHTML={{ __html: data }}
                      />
                    ))
                  )}
                </div>
              ))
            )}
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in ResultsInAllContentsGridView component', e);
    return <div></div>;
  }
};

export default ResultsInAllContentsGridView;
