import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import variables from '../../../redux/variables';
import StaticStrings from '../../../StaticStrings';
import { a11y } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';

const setLocalStorage = (languages) => {
  if (!languages) {
    localStorage.setItem('language', 'en');
  } else if (languages && languages?.config) {
    let storedLanguage = localStorage.getItem('language');
    if (!storedLanguage) {
      localStorage.setItem('language', languages.config.defaultLanguage.code || 'en');
      variables.searchCallVariables.language = localStorage.getItem('language');
    } else if (!languages.config.selectedLanguages.some((lang) => lang.code === storedLanguage)) {
      localStorage.setItem('language', languages.config.defaultLanguage.code);
      variables.searchCallVariables.language = languages.config.defaultLanguage.code;
    }
  } else {
    localStorage.setItem('language', 'en');
  }
};

const Language = () => {
  try {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    let languages = variables.languages;
    setLocalStorage(languages);

    const getlanges = localStorage.getItem('language');
    const geti18langes = localStorage.getItem('i18nextLng') || 'en';
    if (getlanges !== geti18langes) {
      i18n.changeLanguage(getlanges);
    }
    let changeLanguage = (e) => {
      let value = e.target.value;
      i18n.changeLanguage(value);
      variables.searchCallVariables.langAttr = e.target.value;
      localStorage.setItem('language', value);
      variables.searchCallVariables.language = localStorage.getItem('language');
      if (languages[value] && languages[value].type === 'RTL') {
        document.getElementsByTagName('body')[0].classList.add('su__rtl');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('su__rtl');
      }
    };
    useEffect(() => {
      if (languages) {
        let e = {
          target: {
            value: localStorage.getItem('language')
          }
        };
        changeLanguage(e);
      }
    }, [localStorage.getItem('language')]);
    return (
      <div className="su__w-auto su__Language-boxs su__flex-vcenter">
        <Tooltip
          text={t(StaticStrings.LANGUAGE)}
          position="top"
          className="position-relative"
          role={a11y.ROLES.GROUP}
          aria-labelledby="su__LanguageLabel"
        >
          <div
            id="su__LanguageLabel"
            className="su__LanguageLabel su__flex-vcenter su__border su__bg-white position-relative su__z-index su__radius-1 su__ml-2 su__rtlmr-2 su__rtlml-0 su__cursor"
          >
            <select
              lang={variables.searchCallVariables.langAttr}
              value={getlanges}
              name="su__LanguageName"
              id="select-lng"
              className="su__select-opt su__key-focus su__select-control su__border-none su__w-auto su__bg-white su__radius-1 su__pl-4 su__rtlpl-1 su__rtlpr-4 su__p-1 su__font-12 su__p-sm-1 su__cursor su__lang_mobile_btn_view"
              onChange={changeLanguage}
              aria-label={t(StaticStrings.select_language)}
            >
              {languages?.config.selectedLanguages.map((item) => (
                <option key={uuid()} lang={item.code} value={item.code}>
                  {item.name} ({item.label})
                </option>
              ))}
            </select>
          </div>
        </Tooltip>
      </div>
    );
  } catch (e) {
    console.log('Error in language component', e);
    return <div></div>;
  }
};

export default Language;
