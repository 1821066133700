/* global LITHIUM, gza */
import React, { useState, useEffect } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import Modal from 'components/feature-components/modal/index.jsx';

import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import FeedbackConfirmation from 'components/section-components/feedback-confirmation/index.jsx';
import FeedbackSearch from 'components/section-components/feedback-search/index.jsx';
// import FeedbackFollowUP from 'components/section-components/feedback-followup/index.jsx';
import FeedbackFollowUP from 'components/custom-components/feedback-followup-1742808851661/index.jsx';

import FeedbackFollowUPName from 'components/custom-components/feedback-followup-name-1738135326022/index.jsx'
import IconColors from '../../../IconColors';
import { A11Y_IDS, focusTrap, a11y, tabIndexes } from '../../../constants/a11y';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';

const SearchResultFeedback = (props) => {
  try {
    const { t } = useTranslation();
    const [isShowConversion, setShowConversion] = useState(false);
    const [rating, setRating] = useState(0);
    const [isFollowUp, setFollowUp] = useState(true);
    const [hoverRating, setHoverRating] = useState(0);
    const [isThanksModel, setThanksModel] = useState(false);
    const [followupEmail, setFollowupEmail] = useState('');
    const [followupName, setFollowupName] = useState('');
    const [textAreaFeedback, settextAreaFeedback] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [isTextDisabled, setTextDisabled] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true)
    const emailRegex = new RegExp(
      /^[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9]+(?:\.[a-z0-9]+)*\.[a-z]{2,6}$/i
    );
    const nameRegex = new RegExp(
      /^[a-zA-Z]+(?:[-' ]?[a-zA-Z]+)*$/
    );    
    let { pageRatingCustomization, searchFeedback } = useSelector(
      (state) => state.pageRatingResult
    );
    let pageRatingObj = {};
    let searchFeedbackObj = {};
    if (pageRatingCustomization) {
      pageRatingObj = JSON.parse(pageRatingCustomization);
    }
    if (searchFeedback) {
      searchFeedbackObj = JSON.parse(searchFeedback);
    }
    let { selectedAck, searchToggle, submitButton } = pageRatingObj;
    let {
      followUpToggle,
      searchEmailId,
      searchName,
      selectedSearchHeader,
      searchFeedbackToggle,
      selectedSearchFollowUp,
      selectedSearchFollowUpName,
      selectedSearchSubHeader,
      selectedSearchTemplate,
      selectedTextFeedback,
      selectedSearchAcknowledgement
    } = searchFeedbackObj;
    const handleSaveRating = (rate) => {
      setRating(rate);
    };
    useEffect(() => {
      if (variables.searchClientType === '2' && isFollowUp && followUpToggle) {
        variables.getUserEmailId = LITHIUM ? LITHIUM.CommunityJsonObject.User.emailRef : '';
        if (variables.getUserEmailId.length > 0 && !emailRegex.test(followupEmail)) {
          setFollowupEmail(variables.getUserEmailId);
          setTextDisabled(true);
          setDisabled(true);
        }
      }
    }, []);

    const huddleOnChange = (event) => {
      if (!emailRegex.test(event && followupEmail) && followupEmail != '') {
        setIsValid(true);
        setFollowupEmail('');
        setDisabled(true);
      } else {
        setIsValid(false);
        setDisabled(true);
      }
    };
    const handleOnChange = (event) => {
      if (!nameRegex.test(event && followupName) && followupName != '') {
        setIsValid(true);
        setFollowupName('');
        setDisabled(true);
      } else {
        setIsValid(false);
        setDisabled(true);
      }
    };

    const checkEmail = (email) => {        
        if( email == '' || emailRegex.test(email) ){
            setIsEmailValid(true)
        }
        else setIsEmailValid(false)
    }

    const onSubmitfeedback = (e, stateSub = true, stateMain = false) => {
      let getAllConversion = {
        referer: document.referrer,
        window_url: window.location.href,
        uid: variables.searchCallVariables.uid,
        rating: rating,
        reported_by: isFollowUp ? followupEmail : '',
        text_entered: variables.searchCallVariables.searchString,
        feedback: textAreaFeedback,
        name:followupName
      };
      let getPageRes = variables.searchResultClicked;
      if (getPageRes === true) {
        variables.searchResultClicked = true;
        getAllConversion['conversion_title'] = variables.visitedtitle;
        getAllConversion['conversion_url'] = variables.visitedUrl;
        getAllConversion['conversion_position'] = variables.visiteRank;
        getAllConversion['pageSize'] = variables.searchCallVariables.pageSize;
        getAllConversion['page_no'] = variables.searchCallVariables.pageNo;
      }
      gza('searchfeedback', getAllConversion);
      setShowConversion(stateMain);
      setThanksModel(stateSub);

      setRating(0);
      setFollowupEmail('');
      settextAreaFeedback('');
      setTextDisabled(false);
    };

    const hanleMouseEnter = (index) => {
      setHoverRating(index);
    };

    const handleMouseLeave = () => {
      setHoverRating(0);
    };

    // Focus trap
    useEffect(() => {
      focusTrap(!isThanksModel);
    }, [isThanksModel]);

    return (
      <>
        <Modal
          className={`su__feedback-modal ${isThanksModel ? 'thxOpen' : 'thxClose'} ${
            isValid ? 'su__formError' : 'su__formnot-error'
          }`}
          isOpen={isShowConversion}
          closeModal={() => {
            setThanksModel(false);
            setShowConversion(false);
            props.setModalFeedback(false);
          }}
        >
          <div id={A11Y_IDS.trap} role={a11y.ROLES.DIALOG}>
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.close_popup)}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              className="a11y-btn su__close-svg su__position-absolute su__flex-vcenter su__bg-light-gray  su__bg-white-circle su__radius-50"
              onClick={() => {
                setThanksModel(false);
                setShowConversion(false);
                props.setModalFeedback(false);
              }}
            >
              <Icons
                className="su__close-icon su__cursor"
                IconName="Close"
                width="12"
                height="12"
                color={IconColors.SearchResultFeedbackCloseIcon}
              />
            </button>
            <div className="su__feedback-row">
              <h2
                lang={variables.searchCallVariables.langAttr}
                aria-label={t(StaticStrings.GIVEFEEDBACK)}
                tabIndex={tabIndexes.tabIndex_0}
                className="su__feed-title su__pr-3 su__font-16 su__f-normal su__my-0"
              >
                {t(selectedSearchHeader)}
              </h2>
              <>
                <FeedbackSearch
                  textAreaFeedback={textAreaFeedback}
                  settextAreaFeedback={settextAreaFeedback}
                  searchFeedbackToggle={searchFeedbackToggle}
                  selectedTextFeedback={selectedTextFeedback}
                  selectedSearchSubHeader={selectedSearchSubHeader}
                  selectedSearchTemplate={selectedSearchTemplate}
                  rating={rating}
                  hoverRating={hoverRating}
                  hanleMouseEnter={hanleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleSaveRating={handleSaveRating}
                />
              </>
              <span className="su__optional_text">{StaticStrings.follow_up_optional_text}</span>
               <>
                <FeedbackFollowUPName
              isTextDisabled={isTextDisabled}
              isFollowUp={isFollowUp}
              setFollowUp={setFollowUp}
              searchName={searchName}
              selectedSearchFollowUp={selectedSearchFollowUpName}
              followupName={followupName}
              setFollowupName={setFollowupName}
              handleOnChange={handleOnChange}
               /> 
                {followUpToggle && (
                  <FeedbackFollowUP
                    isTextDisabled={isTextDisabled}
                    isFollowUp={isFollowUp}
                    setFollowUp={setFollowUp}
                    searchEmailId={searchEmailId}
                    selectedSearchFollowUp={selectedSearchFollowUp}
                    followupEmail={followupEmail}
                    setFollowupEmail={setFollowupEmail}
                    huddleOnChange={huddleOnChange}
                    checkEmail = {checkEmail}
                  />
                )}
              </>
            </div>
            {/* <button disabled={disable} onClick={() => props.searchPageFeedback ? onSubmitfeedback : onSubmitResult} className="su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__px-2 su__py-1 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue">{submitButton}</button> */}
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={`${t(submitButton)} ${
                !(rating || textAreaFeedback || (followupEmail && isDisabled))
                  ? t(StaticStrings.disabled_text)
                  : ''
              }`}
              tabIndex={tabIndexes.tabIndex_0}
              onClick={
               (rating) 
                  ? onSubmitfeedback
                  : null
              }
              className={`su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__p-2 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue ${
                ! (rating && isEmailValid) ? 'disabled-btn' : ''
              }`}
            >
              {t(submitButton)}
            </button>
          </div>
        </Modal>

        {isThanksModel && (
          <Modal
            className={'su__feedback-modal'}
            isOpen={isThanksModel}
            closeModal={() => {
              setThanksModel(false);
              setShowConversion(true);
              props.setModalFeedback(false);
            }}
          >
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.close_popup)}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              className="su__close-svg su__position-absolute su__flex-vcenter su__bg-light-gray  su__bg-white-circle su__radius-50 a11y-btn"
              onClick={() => setThanksModel(false)}
            >
              <Icons
                className="su__close-icon su__cursor"
                IconName="Close"
                width="12"
                height="12"
                color={IconColors.SearchResultFeedbackCloseIcon}
              />
            </button>
            <FeedbackConfirmation
              selectedAck={selectedAck}
              searchToggle={searchToggle}
              selectedSearchAcknowledgement={selectedSearchAcknowledgement}
              isSearchFeedback={true}
            />
          </Modal>
        )}
      </>
    );
  } catch (e) {
    console.log('Error in page rating component', e);
    return <div></div>;
  }
};

export default SearchResultFeedback;