import { bool, func, node, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target) || event.code === 'Tab') {
        return;
      }
      handler(event);
    };
    document.addEventListener('keydown', listener);
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('keydown', listener);
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);
}

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Modal = styled.div`
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
`;

const ModalWrapper = ({ isOpen, closeModal, children, className }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => closeModal(false));

  return (
    <>
      <ModalBackground className={isOpen ? 'su_modal_open' : 'su_modal_closed'}>
        <Modal className={`su__animate-fadow ${className}`} ref={ref}>
          {children}
        </Modal>
      </ModalBackground>
    </>
  );
};

ModalWrapper.propTypes = {
  isOpen: bool,
  closeModal: func,
  children: node,
  className: string
};

ModalWrapper.defaultProps = {
  isOpen: false,
  closeModal: () => {},
  children: <></>,
  className: ''
};

export default ModalWrapper;
