/* global gza */
import React from 'react';
import { useSelector } from 'react-redux';
import FeaturedSnippetSteps from 'components/feature-components/feature-snippets-steps/index.jsx';
import FeaturedSnippetQA from 'components/feature-components/feature-snippet-QA/index.jsx';
import FeaturedSnippetImages from 'components/feature-components/feature-snippet-multimedia/index.jsx';

import variables from '../../../redux/variables';

const FeaturedSnippet = (props) => {
  let { isDeviceMobile } = props;
  try {
    let featuredSnippetResult = useSelector((state) => state.searchResult.featuredSnippetResult);

    const linkOpened = (result, rank, href, title) => {
      if (
        variables.searchCallVariables.searchString.trim() !== '' ||
        variables.searchCallVariables.exactPhrase.trim() !== ''
      )
        gza('conversion', {
          index: result['sourceName'],
          type: result['objName'],
          id: result['_id'],
          rank: rank,
          convUrl: href || result['href'],
          convSub: title || result['highlight']['TitleToDisplayString'][0].substring(0, 300),
          pageSize: variables.searchCallVariables.pageSize,
          page_no: variables.searchCallVariables.pageNo,
          sc_analytics_fields: result['trackAnalytics']
        });
    };

    if (
      featuredSnippetResult &&
      featuredSnippetResult.steps &&
      featuredSnippetResult.steps.length &&
      featuredSnippetResult.header &&
      !variables.searchCallVariables.mergeSources
    ) {
      return (
        <FeaturedSnippetSteps
          featuredSnippetResult={featuredSnippetResult}
          linkOpened={linkOpened}
          isDeviceMobile={isDeviceMobile}
        />
      );
    } else if (
      featuredSnippetResult.questionAnswer &&
      featuredSnippetResult.questionAnswer.answer &&
      featuredSnippetResult.questionAnswer.short_summary &&
      !variables.searchCallVariables.mergeSources
    ) {
      return (
        <FeaturedSnippetQA
          featuredSnippetResult={featuredSnippetResult}
          linkOpened={linkOpened}
          isDeviceMobile={isDeviceMobile}
        />
      );
    } else if (
      featuredSnippetResult.multiMedia &&
      featuredSnippetResult.multiMedia.length &&
      !variables.searchCallVariables.mergeSources
    ) {
      return (
        <FeaturedSnippetImages
          featuredSnippetResult={featuredSnippetResult}
          linkOpened={linkOpened}
          isDeviceMobile={isDeviceMobile}
        />
      );
    } else {
      return <></>;
    }
  } catch (e) {
    console.log('Error in feature-snippet component', e);
    return <div></div>;
  }
};

export default FeaturedSnippet;
