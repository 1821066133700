import React, { useState, Fragment } from 'react';
import SavedResult from 'components/section-components/saved-result/index.jsx';
import SaveUnsaveText from 'components/section-components/saveUnsaveText/index.jsx';

const GridView = (props) => {
  try {
    let {
      item,
      child,
      savedResultBookmarkClicked,
      savedResultLimitReachedFunc,
      bookmarkListIconFunc
    } = props;

    const componentTobeRendered = child.props.children;

    const [showText, setShowText] = useState(false);
    const [saved, setSaved] = useState(false);

    const handleButtonClick = () => {
      setSaved(!saved);
      setShowText(true);
      setTimeout(() => {
        setShowText(false);
      }, 1000);
    };

    return (
      <div className="su__list-item-row su__d-md-flex su__w-100">
        {React.Children.map(componentTobeRendered, (child) => {
          if (!child || !child.props || child.props['position'] != 'above-title') return false;
          return React.cloneElement(child, props, null);
        })}
        <div
          className={`${
            item.imageSource ? 'su__list-item-text' : 'su__w-100'
          } su__sm-w-100 su__media-body  su__word-break`}
        >
          <div className={`su__list-item-title su__flex-vcenter su__mw-100`}>
            <Fragment>
              <div className="su__flex-vcenter su__flex-gap su__justify-content-between su__w-100">
                <div className="su__flex-vcenter su__sourceLabelSectionW-80">
                  {React.Children.map(componentTobeRendered, (child) => {
                    if (!child || !child.props || child.props['position'] != 'title-first')
                      return false;
                    return React.cloneElement(child, props, null);
                  })}
                </div>
                <div className="su__d-flex su__align-items-baseline su__IconsSectionW-20">
                  <div className="su__w-50">
                    <SavedResult
                      bookmarkListIconFunc={bookmarkListIconFunc}
                      savedResultLimitReachedFunc={savedResultLimitReachedFunc}
                      savedResultBookmarkClicked={savedResultBookmarkClicked}
                      btnclick={handleButtonClick}
                      item={item}
                    />
                  </div>
                  <SaveUnsaveText
                    savedResultBookmarkClicked={savedResultBookmarkClicked}
                    saved={saved}
                    setSaved={setSaved}
                    showText={showText}
                    item={item}
                  />
                  {React.Children.map(componentTobeRendered, (child) => {
                    if (!child || !child.props || child.props['position'] != 'after-sourceLabel')
                      return false;
                    return React.cloneElement(child, props, null);
                  })}
                </div>
              </div>
              {React.Children.map(componentTobeRendered, (child) => {
                if (!child || !child.props || child.props['position'] != 'title-second')
                  return false;
                return React.cloneElement(child, props, null);
              })}
            </Fragment>
          </div>
          {React.Children.map(componentTobeRendered, (child) => {
            if (!child || !child.props || child.props['position'] != 'below-title') return false;
            return React.cloneElement(child, props, null);
          })}

          {React.Children.map(componentTobeRendered, (child) => {
            if (!child || !child.props || child.props['position'] != 'below-metadata') return false;
            return React.cloneElement(child, props, null);
          })}
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in GridView component', e);
    return <div></div>;
  }
};

export default React.memo(GridView);
