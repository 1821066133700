/* global gza */
import React from 'react';
import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import RecentSearch from 'components/custom-components/auto-suggest-recent-search-1737695923966/index.jsx';
import AutoCompleteResultIcon from 'components/custom-components/auto-suggest-icon-1737695923964/index.jsx';
import AutoCompleteResultTitle from 'components/section-components/auto-suggest-title/index.jsx';
import AutoCompleteResultMetadata from 'components/section-components/auto-suggest-metadata/index.jsx';
import AutoLearningSuggestion from 'components/feature-components/auto-suggest-auto-learning/index.jsx';
import { tabIndexes } from '../../../constants/a11y';
import StaticStrings from 'StaticStrings';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
const Suggest = (props) => {
  try {
    const { t } = useTranslation();
    let urlOpensInNewTab = props.urlOpensInNewTab;
    let currentSearchString = props.data;
    let dataType = props.dataType;
    const autocompleteResult = useSelector((state) => state.autocomplete);
    /**
     *  autocomplete gza function
     */
    const resultOpenNewTab = (item, index) => {
      gza('search', {
        searchString: variables.autocompleteCallVariables.searchString,
        result_count: autocompleteResult.result.hits.length,
        page_no: 0,
        uid: variables.autocompleteCallVariables.uid,
        filter: [],
        conversion: [
          {
            rank: index + 1,
            url: item['href'],
            subject: item['highlight']['TitleToDisplayString'][0] || '',
            es_id:
              item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
            sc_analytics_fields: item['trackAnalytics']
          }
        ]
      });
    };

    return (
      <div
        lang={variables.searchCallVariables.langAttr}
        aria-label={
          autocompleteResult.result.hits.length + ' ' + `${t(StaticStrings.suggestions_found)}`
        }
        aria-live="assertive"
        aria-atomic="true"
        tabIndex={tabIndexes.tabIndex_0}
        className="su__autoSuggestion su__autosearchapp-suggestion su__w-100 su__bg-white su__sm-shadow su__position-absolute su__zindex-2"
      >
        <div id="my_div" className="su__suggestions-box su__minscroller">
          <div className="auto-suggestion">
            {autocompleteResult &&
              autocompleteResult.recentSearchHistory &&
              autocompleteResult.recentSearchHistory.length != 0 && (
                <RecentSearch
                  currentSearchString={currentSearchString}
                  component={props.component}
                  dataType={dataType}
                />
              )}
            {autocompleteResult.result &&
              variables.autocompleteCallVariables.searchString &&
              autocompleteResult.result.hits.length != 0 &&
              React.Children.toArray(
                autocompleteResult.result.hits.map((item, index) => (
                  <div
                    key={uuid()}
                    data-key={index}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__suggestions-list su__bg-gray-hover su__flex-vcenter su__font-14 su__text-black ${
                      currentSearchString ===
                        (item.highlight.TitleToDisplayString[0] || item.href) &&
                      dataType == 'autosuggest'
                        ? 'su__highlight_result'
                        : ''
                    }`}
                  >
                    <AutoCompleteResultIcon item={item} />
                    <div className="su__text-truncate">
                      <AutoCompleteResultTitle
                        item={item}
                        index={index}
                        resultOpenNewTab={resultOpenNewTab}
                        urlOpensInNewTab={urlOpensInNewTab}
                      />
                      <AutoCompleteResultMetadata item={item} />
                    </div>
                  </div>
                ))
              )}
            {autocompleteResult &&
              autocompleteResult.smartAggregations &&
              autocompleteResult.smartAggregations.length && (
                <div
                  className={`su__product-sugt-row su__minscroller su__product-length-${
                    autocompleteResult &&
                    autocompleteResult.smartAggregations &&
                    autocompleteResult.smartAggregations.length
                  }`}
                  style={{
                    position: autocompleteResult.result.hits.length ? 'absolute' : 'inherit'
                  }}
                >
                  <AutoLearningSuggestion autocompleteResult={autocompleteResult} />
                </div>
              )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in auto-suggest component', e);
    return <div></div>;
  }
};

export default Suggest;
