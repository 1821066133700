import { useEffect, useRef } from 'react';

export default function useOnClickOutsideRef(callback, initialValue = null) {
  const elementRef = useRef(initialValue);
  useEffect(() => {
    function handleAction(event) {
      if (!elementRef.current?.contains(event.target)) {
        callback();
      }
    }
    window.addEventListener('scroll', handleAction);
    document.addEventListener('keydown', handleAction);
    document.addEventListener('click', handleAction);
    document.addEventListener('change', handleAction);
    return () => {
      window.removeEventListener('scroll', handleAction);
      document.removeEventListener('keydown', handleAction);
      document.removeEventListener('click', handleAction);
      document.removeEventListener('change', handleAction);
    };
  }, [callback]);
  return elementRef;
}
