/* global path */
import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { search } from '../../../redux/ducks';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation, Trans } from 'react-i18next';
import ChildComponent from 'components/feature-components/filter-list-nested/index.jsx';
import FacetAlternateUI from 'components/feature-components/under-consruction/dummy-factes-UI.jsx';
import { searchUrlReq } from '../../../redux/searchClientTypes';
import { mergeFilters, mergeFilterClicked, traverseTheTree } from '../../../mergeFacet';
import setCookies from '../../../setCookie/setCookie';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { A11Y_HIDDEN, a11y, tabIndexes, focusTrap } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';

const filterList = (props) => {
  try {
    let { item, index } = props;
    const { t } = useTranslation();
    const inputValue = useRef(null);
    const wrapperRef = useRef(null);
    const searchResult = useSelector((state) => state.searchResult);
    const [selectedFacet, toggleFacet] = useState(-1);
    const [selectedSortByFacet, toggleSortByFacet] = useState(-1);
    const [selectedNestedFacet, toggleNestedFacet] = useState(Array(index).fill(false));
    const [facetSearchArray, setFacetSearchArray] = useState([]);
    const [setfacetSearch, showSearch] = useState(false);
    const [filterSuggestion, filterSuggestSet] = useState();
    const [facetSearching, facetSearchCheck] = useState(true);
    const [startIndex, setstartIndex] = useState(0);
    const [sortFacets, setSortFacets] = useState(item);
    const dispatch = useDispatch();
    const [facetItems, moreFacetItems] = useState({
      expanded: false,
      itemsToShow: 9
    });
    const [sortbyLabel, setsortbyLabel] = useState('');
    const parseEntities = (txt) => new DOMParser().parseFromString(txt, 'text/html').body.innerText;

    let parsed_array =
      searchResult && searchResult.result && searchResult.merged_facets
        ? JSON.parse(searchResult.merged_facets)
        : [];
    parsed_array.length &&
      parsed_array.forEach(function (o) {
        mergeFilters(o, searchResult.aggregationsArray);
      });

    /**
     * Call search result function if filter changes
     * @param {filter} contentName
     * @param {type} facet_type
     */
    const facetClick = (event, filter, type, order, childLevel, sortMethod) => {
      setCookies.setSmartFacetOff();
      if (variables.searchCallVariables.pagingAggregation)
        variables.searchCallVariables.pagingAggregation = {};
      variables.searchCallVariables.from = filter.filterSortingLoading
        ? variables.searchCallVariables.from
        : 0;
      variables.searchCallVariables.pageNo = filter.filterSortingLoading
        ? variables.searchCallVariables.pageNo
        : 1;
      let obj = {
        childName: filter.Contentname,
        level: childLevel
      };
      if (childLevel > 1) obj.path = path || [];

      let filterChecked = event.target.checked;
      variables.currentClickedOrder = searchResult.aggregationsArray.findIndex(
        (f) => f.order === order
      );

      // if aggregations empty
      if (!variables.searchCallVariables.aggregations.length && filter.Contentname == undefined) {
        variables.searchCallVariables.aggregations.push({
          type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
          sort: sortMethod
        });
      } else if (
        !variables.searchCallVariables.aggregations.length &&
        filter.Contentname.indexOf('merged_') == -1
      ) {
        searchResult.aggregationsArray[variables.currentClickedOrder].key &&
        (searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf('_nested') > 0 ||
          searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf('_navigation') >
            0)
          ? variables.searchCallVariables.aggregations.push({
              type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
              filter: [],
              children: [obj]
            })
          : variables.searchCallVariables.aggregations.push({
              type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
              filter: [filter.Contentname]
            });
      } else {
        //if aggregations exist
        let index = -1;
        variables.searchCallVariables.aggregations.some(function (facet, i) {
          if (facet.type == searchResult.aggregationsArray[variables.currentClickedOrder].key) {
            index = i;
            return true;
          }
        });
        //if facet present in aggregations
        if (index >= 0) {
          //if nested
          if (
            searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf('_nested') >
              0 ||
            searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf(
              '_navigation'
            ) > 0
          ) {
            let childrenArr = variables.searchCallVariables.aggregations[index].children || [];
            if (filterChecked) {
              childrenArr.forEach(function (filter, i) {
                if (filter.path && filter.path.indexOf(filter.Contentname) >= 0) {
                  childrenArr[i] = {};
                }
              });
              childrenArr.push(obj);
              if (
                variables.searchCallVariables.aggregations[index].sort &&
                !variables.searchCallVariables.aggregations[index].filter
              )
                variables.searchCallVariables.aggregations[index].filter = [];
            } else {
              if (
                (variables.searchCallVariables.aggregations[index].sort &&
                  filter.Contentname == undefined) ||
                (variables.searchCallVariables.aggregations[index].children &&
                  !variables.searchCallVariables.aggregations[index].sort &&
                  filter.Contentname == undefined)
              ) {
                variables.searchCallVariables.aggregations[index].sort =
                  sortMethod || variables.searchCallVariables.aggregations[index].sort;
              } else {
                if (!childLevel) {
                  variables.searchCallVariables.aggregations[index].children = [];
                  childrenArr = [];
                } else {
                  //remove all parent of unchecked filter
                  let filtersInAggr = childrenArr.filter(function (filterNew, i) {
                    if (
                      filter.Contentname == filterNew.childName ||
                      (filterNew.path && filterNew.path.indexOf(filter.Contentname) > -1)
                    ) {
                      childrenArr[i] = {};
                    }
                    return Object.keys(childrenArr[i]).length !== 0;
                  });
                  filtersInAggr = filtersInAggr.map(function (f) {
                    return f.childName;
                  });
                  traverseTheTree(
                    searchResult.aggregationsArray[variables.currentClickedOrder].values,
                    filter.Contentname,
                    undefined,
                    filtersInAggr,
                    childrenArr
                  );
                }
              }
            }
            if (filter.Contentname) {
              childrenArr = childrenArr.filter(function (value) {
                if (Object.keys(value).length !== 0) return value;
              });
              variables.searchCallVariables.aggregations[index].children = childrenArr;
            }
          } else if (filter.Contentname && filter.Contentname.indexOf('merged_') > -1) {
            if (!variables.searchCallVariables.aggregations[index].filter) {
              variables.searchCallVariables.aggregations[index].filter = [];
            }
            mergeFilterClicked(
              filter.Contentname,
              variables.searchCallVariables.aggregations[index].filter,
              searchResult.aggregationsArray[variables.currentClickedOrder].values,
              filterChecked
            );
          } else if (
            filter.Contentname &&
            !variables.searchCallVariables.aggregations[index].filter
          ) {
            variables.searchCallVariables.aggregations[index].filter = [filter.Contentname];
          } else {
            //if filter already in aggregations
            if (
              variables.searchCallVariables.aggregations[index].sort &&
              filter.Contentname &&
              filterChecked
            ) {
              variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
            } else if (!variables.searchCallVariables.aggregations[index].sort && sortMethod) {
              variables.searchCallVariables.aggregations[index].sort = sortMethod;
            } else if (
              variables.searchCallVariables.aggregations[index].sort &&
              !filter.Contentname
            ) {
              variables.searchCallVariables.aggregations[index].sort =
                sortMethod || variables.searchCallVariables.aggregations[index].sort;
            } else if (
              variables.searchCallVariables.aggregations[index].filter &&
              variables.searchCallVariables.aggregations[index].filter.indexOf(filter.Contentname) >
                -1 &&
              !filterChecked
            ) {
              variables.searchCallVariables.aggregations[index].filter.splice(
                variables.searchCallVariables.aggregations[index].filter.indexOf(
                  filter.Contentname
                ),
                1
              );
            } else if (
              (variables.searchCallVariables.aggregations[index].type == 'post_time' ||
                variables.searchCallVariables.aggregations[index].type == 'CreatedDate') &&
              filterChecked
            ) {
              variables.searchCallVariables.aggregations[index].filter = [];
              variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
            } else {
              variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
            }
          }
        } else {
          if (filter.Contentname == undefined) {
            variables.searchCallVariables.aggregations.push({
              type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
              sort: sortMethod || variables.searchCallVariables.aggregations[index].sort
            });
          } else if (filter.Contentname.indexOf('merged_') > -1) {
            let filterNew = [];
            mergeFilterClicked(
              filter.Contentname,
              filterNew,
              searchResult.aggregationsArray[variables.currentClickedOrder].values,
              filterChecked
            );
            variables.searchCallVariables.aggregations.push({
              type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
              filter: filterNew
            });
          } else {
            searchResult.aggregationsArray[variables.currentClickedOrder].key &&
            (searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf('_nested') >
              0 ||
              searchResult.aggregationsArray[variables.currentClickedOrder].key.indexOf(
                '_navigation'
              ) > 0)
              ? variables.searchCallVariables.aggregations.push({
                  type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
                  filter: [],
                  children: [obj]
                })
              : variables.searchCallVariables.aggregations.push({
                  type: searchResult.aggregationsArray[variables.currentClickedOrder].key,
                  filter: [filter.Contentname]
                });
          }
        }
      }

      //remove empty aggregations
      variables.searchCallVariables.aggregations =
        variables.searchCallVariables.aggregations.filter(function (facet) {
          if (!facet.sort) {
            if (
              facet.filter &&
              !facet.filter.length &&
              (!facet.children || (facet.children && !facet.children.length))
            ) {
              return false;
            }
          } else {
            if (
              facet.filter &&
              !facet.filter.length &&
              (!facet.children || (facet.children && !facet.children.length))
            ) {
              delete facet.filter;
              delete facet.children;
            }
          }
          return true;
        });

      setFacetSearchArray([]);
      variables.searchSource = 'facets';
      dispatch(search.start(variables.searchCallVariables));
    };

    /**
     *
     * @param {index of selected facet} index
     */
    const openFacet = (index) => {
      toggleFacet(selectedFacet === index ? -1 : index);
    };

    /**
     *
     * @param {index of nested selected facet} index
     */
    const openNestedFacet = (index) => {
      selectedNestedFacet[index] = !selectedNestedFacet[index];
      toggleNestedFacet([...selectedNestedFacet]);
    };
    /**
     *
     * @param {index of sortBy facet} index
     */
    const openSortByFacet = (index) => {
      toggleSortByFacet(selectedSortByFacet === index ? -1 : index);
    };

    /**
     * SortBy filters sorting
     */
    const filterSorting = (event, sortValue, filter) => {
      setFilterType(sortValue);
      variables.facetSearch = true;
      filter.filterSortingLoading = true;
      facetClick(event, filter, filter.key, filter.order, undefined, sortValue);
      variables.searchSource = 'facet-sorting';
    };

    const setFilterType = (sortValue) => {
      switch (sortValue) {
        case 'custom':
          setsortbyLabel(`${t(StaticStrings.custom)}`);
          break;

        case 'term_asc':
          setsortbyLabel(t(StaticStrings.alphabatical_order));
          break;

        case 'term_desc':
          setsortbyLabel(t(StaticStrings.alphabetical_opposite));
          break;

        case 'count_desc':
          setsortbyLabel(t(StaticStrings.count_descending));
          break;

        case 'count_asc':
          setsortbyLabel(t(StaticStrings.count_ascending));
          break;
      }
    };
    useEffect(() => {
      setFilterType(item.sort);
    });

    /**
     *
     * @param {facet select on searching} filter
     */
    const facetCheck = (filter) => {
      inputValue.current.value = '';
      // showSearch(!setfacetSearch)
      let name = filter.Contentname || filter.displayName;
      let index = variables.facetSearchCheck.indexOf(name);
      if (variables.facetSearchCheck.includes(name)) {
        variables.facetSearchCheck.splice(index, 0);
      } else {
        variables.facetSearchCheck.push(filter.Contentname || filter.displayName);
      }
    };

    /**
     * focus on facet search input
     */
    useEffect(() => {
      if (setfacetSearch) {
        inputValue.current.focus();
      }
    }, [setfacetSearch]);

    useEffect(() => {
      showSearch(false);
      facetSearchCheck(true);
    }, [searchResult]);
    /**
     *
     * @param {facet search keyword} event
     * @param {key of facet to search} key
     */
    const facetSearch = (event, key) => {
      let searchKeyword = [event.target.value.trim()];
      let testSearchKeyWord = searchKeyword[0];
      let pattern = /^[A-Za-z0-9]*$/;
      let testKeyWord = testSearchKeyWord.match(pattern);
      if (testKeyWord != null) {
        let finalKeyWord = searchKeyword[0].indexOf(testKeyWord[0]);
        searchKeyword[0] = searchKeyword[0].slice(finalKeyWord);
      }

      if (searchKeyword && searchKeyword[0].length) {
        let pagingAggregation = { field: key, keyword: searchKeyword[0].trim() };
        const found = parsed_array.filter(function (f) {
          return (
            f.facetName === key &&
            f.filterNewName.toLowerCase().indexOf(pagingAggregation.keyword.toLowerCase()) > -1
          );
        });
        searchKeyword = found
          ? searchKeyword.concat(
              ...found.map(function (f) {
                return f.filterList;
              })
            )
          : searchKeyword;
        pagingAggregation.merged = found && found.length ? true : false;
        if (pagingAggregation.merged) {
          pagingAggregation.mergedKeywords = searchKeyword;
        }
        if (variables.searchClientType == 2) {
          pagingAggregation = JSON.stringify(pagingAggregation);
        }
        let facetSearchVar = JSON.parse(JSON.stringify(variables.searchCallVariables));
        let aggregationsBck = [...facetSearchVar.aggregations];
        aggregationsBck.length &&
          aggregationsBck.forEach(function (filterSearch, i) {
            if (filterSearch.type === key) {
              aggregationsBck.splice(i, 1);
            }
          });
        let queryPassed = variables.searchCallVariables;
        queryPassed['pagingAggregation'] = pagingAggregation;
        (async function () {
          variables.searchCallVariables.searchString = queryPassed.searchString;
          let paramsUrlReq = await searchUrlReq(variables.searchClientType, queryPassed);
          let aggregationCheck = [];
          try {
            const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
            if (!response.ok) {
              throw Error(response.statusText);
            }
            const resp = response;
            const results = await resp.json();
            if (results.aggregationsArray.length) {
              found.forEach(function (f) {
                mergeFilters(f, results.aggregationsArray, pagingAggregation.keyword, true);
              });
              results.aggregationsArray.forEach(function (filter, index) {
                filter.values = filter.values.filter(function (u) {
                  const facet_Regex = new RegExp('.*' + searchKeyword[0] + '.*', 'gi');
                  return facet_Regex.test(u.Contentname);
                });
                if (filter.key === key) {
                  let aggregationsSent = facetSearchVar.aggregations.find(function (f) {
                    return f.type == key;
                  });
                  if (filter.values.length > 0) {
                    searchResult.aggregationsArray[index].values.some(function (value) {
                      if (value.selected === true) {
                        aggregationCheck.push(value.Contentname);
                      }
                      if (value.childArray) {
                        if (value.childArray.length > 0) {
                          value.childArray.forEach(function (f) {
                            if (f.selected === true) {
                              aggregationCheck.push(f.Contentname);
                            }
                          });
                        }
                      }
                    });
                    filter.values.forEach(function (filter) {
                      aggregationCheck.some(function (value) {
                        if (filter.Contentname === value) {
                          filter.selected = true;
                          return true;
                        }
                      });
                      filter.ContentnameFrontend = filter.Contentname;
                      filter.selected =
                        aggregationsSent &&
                        aggregationsSent.filter &&
                        aggregationsSent.filter.indexOf(filter.Contentname) > -1
                          ? true
                          : filter.selected;
                      if (filter.childArray && aggregationsSent && aggregationsSent.filter) {
                        checkSelected(filter.childArray, aggregationsSent);
                      }
                    });
                    setFacetSearchArray(filter.values);

                    filterSuggestSet(true);
                    facetSearchCheck(true);
                  } else {
                    setFacetSearchArray([]);
                    filterSuggestSet(false);
                    facetSearchCheck(false);
                  }
                }
              });
            } else {
              setFacetSearchArray([]);
              filterSuggestSet(false);
              facetSearchCheck(false);
            }
          } catch (err) {
            console.log('Error in facet searching', err);
          }
        })();
      } else {
        setFacetSearchArray([]);
        filterSuggestSet(false);
      }
    };

    const checkSelected = (childArray, aggregationsSent) => {
      childArray.forEach(function (f) {
        f.selected = aggregationsSent.filter.indexOf(f.Contentname) > -1 ? true : false;
        if (f.childArray) checkSelected(f.childArray, aggregationsSent);
      });
    };

    /**
     *
     * @param {show more facets} facet
     */
    const showMore = (item) => {
      facetItems.itemsToShow === 9
        ? moreFacetItems({ itemsToShow: item.values.length, expanded: true })
        : moreFacetItems({ itemsToShow: 9, expanded: false });
    };

    /**
     * Closing facet search on clicking outside the particular facet
     */
    useEffect(() => {
      document.addEventListener('click', handleClickOutside, false);
      return () => {
        document.removeEventListener('click', handleClickOutside, false);
      };
    }, []);

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        filterSuggestSet(false);
      }
    };

    const onClickresults = (e, myid) => {
      if (e.key === 'ArrowDown') {
        if (startIndex <= facetSearchArray.length - 1) {
          setstartIndex(startIndex + 1);
          document.getElementById(`filter_${startIndex}`).focus();
        }
      }
      if (e.key === 'ArrowUp') {
        if (startIndex != myid) {
          setstartIndex(startIndex - 1);
          document.getElementById(`filter_${myid - 1}`).focus();
        }
        if (startIndex == myid) {
          setstartIndex(startIndex - 1);
          document.getElementById(`filter_${startIndex}`).focus();
        }
      }
    };
    useEffect(() => {
      focusTrap(selectedSortByFacet === index ? true : false);
    }, [selectedSortByFacet === index ? true : false]);

    useEffect(() => {
      const sortedValues = [...item.values].sort((a, b) => {
        const aSelected =
          a.selected || (a.childArray && a.childArray.some((child) => child.selected));
        const bSelected =
          b.selected || (b.childArray && b.childArray.some((child) => child.selected));
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
      });
      setSortFacets(() => {
        return {
          ...item,
          values: sortedValues
        };
      });
    }, [item]);

    return (
      <div className={`facet ${selectedFacet === index ? 'su__close-facet' : 'su__open-facets'}`}>
        <div className="su__w-100 su__bg-white">
          {item.values.length ? (
            <div className="su__px-3 su__py-3 su__position-relative su__flex-vcenter su__border-t">
              {item.key !== 'post_time' ? (
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-expanded={selectedSortByFacet === index ? 'true' : 'false'}
                  aria-label={`${t(StaticStrings.sort_by)} ${t(item.label)} ${t(
                    StaticStrings.filter
                  )}`}
                  role={a11y.ROLES.BTN}
                  id="myResults"
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__sort-filter-icon su__flex-vcenter su__pr-2 su__mr-1 su__rtlmr-0 su__rtlml-1 su__px-1 su__cursor su__loading-view a11y-btn"
                  onClick={() => openSortByFacet(index)}
                >
                  <svg
                    fill="#57575C"
                    className="su__fill-black su__fill-hover-gray"
                    title="sort filter"
                    width="4"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <rect fill="none" height="402" width="582" y="-1" x="-1" />
                    </g>
                    <g>
                      <title> {t(StaticStrings.sort_by)}</title>
                      <path d="m2,4c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2l0,0zm0,2c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2l0,0zm0,6c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2l0,0z" />
                    </g>
                  </svg>
                </button>
              ) : null}
              <div
                className={`su__sort-filter su__sort-filter-postion su__w-155px su__position-absolute su__bg-white su__zindex-2 ${
                  selectedSortByFacet === index ? 'su__open-facet' : 'su__close-facet'
                }`}
              >
                <div className="su__p-0 su__m-0 su__lg-shadow">
                  <div className="su__font-12 su__color-lblue su__text-black su__font-regular su__flex-vcenter su__justify-content-left su__px-3 su__py-2">
                    <span className="su__mr-2 su__rtlmr-0 su__rtlml-2">
                      <svg
                        id="round-sort-24px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="Path_1530"
                          data-name="Path_1530"
                          className="cls-1"
                          fill="none"
                          d="M0,0H24V24H0Z"
                        />
                        <path
                          id="Path_1531"
                          data-name="Path_1531"
                          className="su__fill-lblue"
                          d="M4,18H8a1,1,0,0,0,0-2H4a1,1,0,0,0,0,2ZM3,7A1,1,0,0,0,4,8H20a1,1,0,0,0,0-2H4A1,1,0,0,0,3,7Zm1,6H14a1,1,0,0,0,0-2H4a1,1,0,0,0,0,2Z"
                        />
                      </svg>
                    </span>
                    <span
                      lang={variables.searchCallVariables.langAttr}
                      className="su__color-lblue su__text-bold"
                    >
                      {t(StaticStrings.sort_by)}
                    </span>
                  </div>
                  {item.key === '_type' ? (
                    <button
                      type="button"
                      lang={variables.searchCallVariables.langAttr}
                      aria-label={`${t(StaticStrings.sort_by)} ${t(StaticStrings.custom)} ${t(
                        StaticStrings.filter
                      )} ${item.sort === 'custom' ? t(StaticStrings.selected) : ''}`}
                      role={a11y.ROLES.BTN}
                      tabIndex={tabIndexes.tabIndex_0}
                      className={`su__font-12 su__px-3 su__py-2 su__cursor su__bg-gray-hover a11y-btn su__w-100 su__text-left ${
                        item.sort === 'custom' ? 'su__filters-sort-active' : ''
                      }`}
                      onClick={(event) => {
                        filterSorting(event, 'custom', item);
                        openSortByFacet(index);
                      }}
                    >
                      {t(StaticStrings.custom)}
                    </button>
                  ) : null}
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    aria-label={`${t(StaticStrings.sort_by)} ${t(
                      StaticStrings.alphabatical_order
                    )} ${t(StaticStrings.filter)} ${
                      item.sort === 'term_asc' ? t(StaticStrings.selected) : ''
                    }`}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__font-12 su__px-3 su__py-2 su__cursor su__bg-gray-hover a11y-btn su__w-100 su__text-left ${
                      item.sort === 'term_asc' ? 'su__filters-sort-active' : ''
                    }`}
                    onClick={(event) => {
                      filterSorting(event, 'term_asc', item);
                      openSortByFacet(index);
                    }}
                  >
                    {t(StaticStrings.alphabatical_order)}
                  </button>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    aria-label={`${t(StaticStrings.sort_by)} ${t(
                      StaticStrings.alphabetical_opposite
                    )} ${t(StaticStrings.filter)} ${
                      item.sort === 'term_desc' ? t(StaticStrings.selected) : ''
                    }`}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__font-12 su__px-3 su__py-2 su__cursor su__bg-gray-hover a11y-btn su__w-100 su__text-left ${
                      item.sort === 'term_desc' ? 'su__filters-sort-active' : ''
                    }`}
                    onClick={(event) => {
                      filterSorting(event, 'term_desc', item);
                      openSortByFacet(index);
                    }}
                  >
                    {t(StaticStrings.alphabetical_opposite)}
                  </button>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    aria-label={`${t(StaticStrings.sort_by)} ${t(
                      StaticStrings.count_descending
                    )} ${t(StaticStrings.filter)} ${
                      item.sort === 'count_desc' ? t(StaticStrings.selected) : ''
                    }`}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__font-12 su__px-3 su__py-2 su__cursor su__bg-gray-hover a11y-btn su__w-100 su__text-left ${
                      item.sort === 'count_desc' ? 'su__filters-sort-active' : ''
                    }`}
                    onClick={(event) => {
                      filterSorting(event, 'count_desc', item);
                      openSortByFacet(index);
                    }}
                  >
                    {t(StaticStrings.count_descending)}
                  </button>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    aria-label={`${t(StaticStrings.sort_by)} ${t(
                      StaticStrings.count_ascending
                    )} ${t(StaticStrings.filter)} ${
                      item.sort === 'count_asc' ? t(StaticStrings.selected) : ''
                    } `}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__font-12 su__px-3 su__py-2 su__cursor su__bg-gray-hover a11y-btn su__w-100 su__text-left ${
                      item.sort === 'count_asc' ? 'su__filters-sort-active' : ''
                    }`}
                    onClick={(event) => {
                      filterSorting(event, 'count_asc', item);
                      openSortByFacet(index);
                    }}
                  >
                    {t(StaticStrings.count_ascending)}
                  </button>
                </div>
              </div>
              <div
                className={`su__sort-filter su__overlay-transparent su__zindex-2 ${
                  selectedSortByFacet === index ? 'su__open-facet' : 'su__close-facet'
                }`}
                onClick={() => openSortByFacet(index)}
              ></div>
              <button
                type="button"
                lang={variables.searchCallVariables.langAttr}
                aria-expanded={selectedFacet === index ? 'false' : 'true'}
                aria-label={`${t(StaticStrings.heading_level_2)} ${t(item.label)}`}
                role={a11y.ROLES.BTN}
                tabIndex={tabIndexes.tabIndex_0}
                className="su__w-100 su__d-flex su__align-items-center su__text-truncate a11y-btn p-0 su__w-100 su__text-left"
                onClick={() => openFacet(index)}
              >
                <h2 className="su__flex-1 su__font-14 filter-label su__text-truncate su__pr-3 su__my-0">
                  {t(item.label)}
                </h2>
                <i className="su__arrow-down" />
              </button>
            </div>
          ) : null}
        </div>

        <Fragment>
          <div
            lang={variables.searchCallVariables.langAttr}
            className="su__w-100 su__bg-white"
            role={a11y.ROLES.GROUP}
          >
            <div
              tabIndex={tabIndexes.tabIndex_minus_1}
              className={A11Y_HIDDEN}
              aria-live="assertive"
              aria-atomic="true"
            >{`${t(StaticStrings.checkboxes)} ${t(StaticStrings.sort_by)} ${sortbyLabel}`}</div>
            {sortFacets.values.slice(0, facetItems.itemsToShow).map((value, index) => (
              <div
                key={
                  (value.displayName &&
                    `su__facet-${sortFacets.order}-${value.displayName.replace(/\s+/g, '')}`) ||
                  (value.Contentname &&
                    `su__facet-${sortFacets.order}-${value.Contentname.replace(/\s+/g, '')}`)
                }
                className={`su__filter-content-row su__position-relative su__pb-1 su__px-3 su__bg-gray-hover su__py-1 ${
                  value.parent +
                  (value.merged ? '-Merged' : '') +
                  (value.showChild === '1' ? '-showChild' : '')
                } ${sortFacets.filterSortingLoading ? 'su__facet-loading' : ''}`}
              >
                <div
                  className={`su__w-100 su__flex-vcenter su__box-sizing ${
                    value.childArray ? 'su__nested-filter-row ' : ''
                  }`}
                >
                  <div className="su__w-100 su__flex-vcenter">
                    {value.childArray &&
                    value.childArray.length &&
                    (!value.showChild || value.showChild == 1) ? (
                      <button
                        aria-label={value.displayName || value.Contentname}
                        tabIndex={tabIndexes.tabIndex_0}
                        className={`su__nested-arrow su__merged-icon a11y-btn p-0 ${
                          selectedNestedFacet === index
                            ? 'su__nested-close-filter'
                            : 'su__nested-open-filter'
                        }`}
                        onClick={() => openNestedFacet(index)}
                      >
                        {selectedNestedFacet[index] ? (
                          <Fragment>
                            <i className="su__arrow-down" />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <i className="su__arrow-right" />
                          </Fragment>
                        )}
                      </button>
                    ) : null}

                    <Fragment>
                      <div
                        className={`su__filter-toggle su__w-100 su__flex-vcenter su__border-color su__font-18 su__position-relative su__d-inline-block ${
                          sortFacets.indeterminateFlag &&
                          sortFacets.indeterminateFlag[value.Contentname] === true
                            ? 'su__indeterminate'
                            : ''
                        }`}
                      >
                        {sortFacets.key != 'post_time' ? (
                          <Fragment>
                            <input
                              lang={variables.searchCallVariables.langAttr}
                              tabIndex={tabIndexes.tabIndex_0}
                              aria-label={
                                (value.displayName || value.Contentname) +
                                ' ' +
                                value.value +
                                ' ' +
                                t(StaticStrings.results_available)
                              }
                              id={
                                (value.displayName &&
                                  `su__facet-${sortFacets.order}-${value.displayName.replace(
                                    /\s+/g,
                                    ''
                                  )}`) ||
                                (value.Contentname &&
                                  `su__facet-${sortFacets.order}-${value.Contentname.replace(
                                    /\s+/g,
                                    ''
                                  )}`)
                              }
                              type="checkbox"
                              checked={value.selected === true}
                              max={value.level}
                              name={
                                (value.displayName &&
                                  `su__facet-${sortFacets.order}-${value.displayName.replace(
                                    /\s+/g,
                                    ''
                                  )}`) ||
                                (value.Contentname &&
                                  `su__facet-${sortFacets.order}-${value.Contentname.replace(
                                    /\s+/g,
                                    ''
                                  )}`)
                              }
                              value={
                                (value.displayName &&
                                  `su__facet-${sortFacets.order}-${value.displayName.replace(
                                    /\s+/g,
                                    ''
                                  )}`) ||
                                (value.Contentname &&
                                  `su__facet-${sortFacets.order}-${value.Contentname.replace(
                                    /\s+/g,
                                    ''
                                  )}`)
                              }
                              className={`su__toggle-input su__cursor su__mr-2 ${
                                value.selected ? 'su__filter-checked' : ''
                              }`}
                              onChange={(event) =>
                                facetClick(
                                  event,
                                  value,
                                  sortFacets.key,
                                  sortFacets.order,
                                  value.level
                                )
                              }
                            />
                            <Trans>
                              <label
                                className="su__filter-label su__d-inline-block su__line-height-n su__flex-1 su__text-truncate su__font-12 su__ml-2 su__rtlmr-2 su__mr-3 su__rtlml-3 su__cursor"
                                htmlFor={
                                  (value.displayName &&
                                    `su__facet-${sortFacets.order}-${value.displayName.replace(
                                      /\s+/g,
                                      ''
                                    )}`) ||
                                  (value.Contentname &&
                                    `su__facet-${sortFacets.order}-${value.Contentname.replace(
                                      /\s+/g,
                                      ''
                                    )}`)
                                }
                                dangerouslySetInnerHTML={{
                                  __html: value.displayName || value.Contentname
                                }}
                                title={parseEntities(value.displayName || value.Contentname)}
                              ></label>
                              <span className="su__nested__text su__loading su__d-none su__position-absolute"></span>
                            </Trans>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <input
                              tabIndex={tabIndexes.tabIndex_0}
                              aria-label={
                                (value.displayName || value.Contentname) +
                                ' ' +
                                value.value +
                                ' results available'
                              }
                              id={
                                (value.displayName &&
                                  `su__radio-${sortFacets.order}-${value.displayName.replace(
                                    /\s+/g,
                                    ''
                                  )}`) ||
                                (value.Contentname &&
                                  `su__radio-${sortFacets.order}-${value.Contentname.replace(
                                    /\s+/g,
                                    ''
                                  )}`)
                              }
                              type="radio"
                              checked={value.selected === true}
                              value={value.Contentname}
                              max={value.level}
                              name="date"
                              className={`su__toggle-input su__cursor ${
                                value.selected ? 'su__filter-checked' : ''
                              }`}
                              onChange={(event) =>
                                facetClick(
                                  event,
                                  value,
                                  sortFacets.key,
                                  sortFacets.order,
                                  value.level
                                )
                              }
                            />
                            <Trans>
                              <label
                                className="su__filter-label su__d-inline-block su__line-height-n su__flex-1 su__text-truncate su__font-12 su__ml-2 su__rtlmr-2 su__mr-3 su__rtlml-3 su__cursor"
                                htmlFor={
                                  (value.displayName &&
                                    `su__radio-${sortFacets.order}-${value.displayName.replace(
                                      /\s+/g,
                                      ''
                                    )}`) ||
                                  (value.Contentname &&
                                    `su__radio-${sortFacets.order}-${value.Contentname.replace(
                                      /\s+/g,
                                      ''
                                    )}`)
                                }
                                dangerouslySetInnerHTML={{
                                  __html: value.displayName || value.Contentname
                                }}
                                title={parseEntities(value.displayName || value.Contentname)}
                              ></label>
                            </Trans>
                          </Fragment>
                        )}
                        <Fragment>
                          <div className="su__filter-badge su__font-12 label-badge su__bg-light-gray">
                            {value.value}
                          </div>
                        </Fragment>
                      </div>
                    </Fragment>
                  </div>
                  {selectedNestedFacet[index] ? (
                    <Fragment>
                      {value.childArray &&
                      value.childArray.length &&
                      (!value.showChild || value.showChild == 1) ? (
                        <ul
                          className={`su__filter-content-row ${
                            value.childArray ? 'su__nested-ul su_nested_align' : ''
                          }`}
                        >
                          <ChildComponent
                            type={sortFacets.key}
                            childArray={value.childArray}
                            order={sortFacets.order}
                          />
                        </ul>
                      ) : null}
                    </Fragment>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
        <div
          key={`item-${item.order}`}
          id={item.key}
          className={`${item.key} su__filter-content-row su__flex-vcenter su__justify-content-between su__position-relative su__py-2 su__px-3 su__bg-white su__font-14 su__filterContent_align`}
          ref={wrapperRef}
        >
          {!setfacetSearch ? (
            <Fragment>
              {item.values.length > 9 ? (
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={
                    facetItems.expanded ? t(StaticStrings.show_less) : t(StaticStrings.show_more)
                  }
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor su__position-relative su__font-12 su__color-blue su__f-regular su__showmore-text su__d-inline-block a11y-btn"
                  onClick={() => showMore(item)}
                >
                  {facetItems.expanded ? (
                    <Fragment>
                      {t(StaticStrings.show_less)}
                      <span className="su__position-absolute su__showless-icon">&#171;</span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {t(StaticStrings.show_more)}
                      <span className="su__position-absolute su__showmore-icon">&#187;</span>
                    </Fragment>
                  )}
                </button>
              ) : null}
              {(item.values.length > 9 || item.merged) && item.key !== '_type' ? (
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.search)} ${item.label}`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__search-head-icon su__flex-vcenter su__radius-50 su__cursor su__position-relative a11y-btn p-0"
                  onClick={() => {
                    showSearch(!setfacetSearch);
                    setFacetSearchArray([]);
                  }}
                >
                  <Icons
                    IconName="Search"
                    width="24"
                    height="24"
                    color={IconColors.FilterListFacetSearchIcon}
                  />
                </button>
              ) : null}
            </Fragment>
          ) : null}

          {setfacetSearch ? (
            <Fragment>
              <div
                tabIndex={tabIndexes.tabIndex_0}
                className="su__w-100 su__flex-vcenter su__facet-parent-div su__position-relative"
                id={`${item.key}-inputSearch`}
                onKeyDown={(event) => onClickresults(event)}
              >
                <span className="su__search-facet su__position-relative">
                  <div className="su__mb-sm-0">
                    <Icons
                      className="su__position-absolute su__search-facet-icon"
                      IconName="Search"
                      width="40"
                      height="24"
                      color={IconColors.FilterListFacetSearchIcon}
                    />
                    <input
                      lang={variables.searchCallVariables.langAttr}
                      aria-label={t(StaticStrings.search)}
                      aria-describedby={`${item.key}-no-results-found`}
                      ref={inputValue}
                      type="text"
                      className={`su__search-facet-input ${item.key}-search ${
                        facetSearching ? 'su__search-facet-not-empty' : 'su__search-facet-empty'
                      }`}
                      id={`${item.key}-search`}
                      placeholder={t(StaticStrings.search)}
                      autoComplete="off"
                      onChange={(event) => facetSearch(event, item.key)}
                    />
                    <span lang={variables.searchCallVariables.langAttr} className="su__error-msg">
                      {facetSearching ? '' : t(StaticStrings.not_found)}
                    </span>
                    <div
                      lang={variables.searchCallVariables.langAttr}
                      id={`${item.key}-no-results-found`}
                      aria-live="assertive"
                      aria-atomic="true"
                      className={A11Y_HIDDEN}
                      tabIndex={tabIndexes.tabIndex_minus_1}
                    >
                      {facetSearching ? '' : t(StaticStrings.not_found)}
                    </div>
                    <button
                      type="button"
                      lang={variables.searchCallVariables.langAttr}
                      aria-label={t(StaticStrings.clear)}
                      role={a11y.ROLES.BTN}
                      className="su__facet-close-icon su__cursor su__position-absolute a11y-btn p-0"
                      onClick={() => {
                        showSearch(!setfacetSearch);
                        facetSearchCheck(true);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                          fill={IconColors.FilterListFacetSearchCloseIcon}
                        />
                      </svg>
                    </button>
                  </div>
                </span>
              </div>
              <div
                className={`su__position-relative su__facet-main-block su__facet-main-div-${item.key}`}
              >
                {filterSuggestion ? (
                  <div
                    lang={variables.searchCallVariables.langAttr}
                    aria-label={`${facetSearchArray.length} ${t(StaticStrings.suggestions_found)}`}
                    aria-live="polite"
                    aria-atomic="true"
                    tabIndex={tabIndexes.tabIndex_2}
                    className="su__search-facet-drop su__sm-shadow su__border"
                    id={`su__facet${item.key}`}
                  >
                    {React.Children.toArray(
                      facetSearchArray.map((element, index) => (
                        <div
                          id={`filter_${index}`}
                          key={uuid()}
                          className="su__search-facet-filter-div su__position-relative su__filter-group su__font-14 hover__effect su__search-facet-items"
                          onKeyDown={(event) => onClickresults(event, index)}
                        >
                          <div className="su__w-100 su__flex-vcenter su__box-sizing su__search_facet-list">
                            <div className="su__filter-toggle su__w-100 su__flex-vcenter">
                              <Fragment>
                                <input
                                  id={
                                    (element.displayName &&
                                      `su__suggest-${element.displayName.replace(/\s+/g, '')}`) ||
                                    (element.Contentname &&
                                      `su__suggest-${element.Contentname.replace(/\s+/g, '')}`)
                                  }
                                  type="checkbox"
                                  checked={element.selected === true}
                                  max={element.level}
                                  name={
                                    (element.displayName &&
                                      `su__suggest-${element.displayName.replace(/\s+/g, '')}`) ||
                                    (element.Contentname &&
                                      `su__suggest-${element.Contentname.replace(/\s+/g, '')}`)
                                  }
                                  value={
                                    (element.displayName &&
                                      `su__suggest-${element.displayName.replace(/\s+/g, '')}`) ||
                                    (element.Contentname &&
                                      `su__suggest-${element.Contentname.replace(/\s+/g, '')}`)
                                  }
                                  className={`su__toggle-input su__cursor ${
                                    element.selected ? 'su__filter-checked' : ''
                                  }`}
                                  onChange={(event) => {
                                    facetClick(event, element, item.key, item.order);
                                    facetCheck(element);
                                  }}
                                />
                                <label
                                  tabIndex={tabIndexes.tabIndex_1}
                                  className="su__filter-label su__flex-1 su__text-truncate su__font-12 su__mx-2 su__rtlmx-2 su__line-height-n  su__cursor"
                                  htmlFor={
                                    (element.displayName &&
                                      `su__suggest-${element.displayName.replace(/\s+/g, '')}`) ||
                                    (element.Contentname &&
                                      `su__suggest-${element.Contentname.replace(/\s+/g, '')}`)
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: element.displayName || element.Contentname
                                  }}
                                  title={parseEntities(element.displayName || element.Contentname)}
                                ></label>
                                <div className="su__filter-badge su__font-12">
                                  <label className="label-badge su__bg-light-gray">
                                    {element.merged ? '' : element.value}
                                  </label>
                                </div>
                              </Fragment>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ) : null}
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in filterList component', e);
    return (
      <div>
        <FacetAlternateUI />
        <FacetAlternateUI />
        <FacetAlternateUI />
      </div>
    );
  }
};

export default filterList;
