import React from 'react';
import variables from '../../../redux/variables';
import { v4 as uuid } from 'uuid';

const Metadata = (props) => {
  try {
    let { item } = props;
    item.metadata.sort((a, b) => {
      return a.value.join('').length - b.value.join('').length;
    });

    return (
      <div className="su__d-flex su__flex-wrap su__align-content-around su__mt-2 font-12">
        {React.Children.toArray(
          item.metadata.map((data) => (
            <>
              {!variables.toggleDisplayKeys[3].hideEye &&
              data.value[0]?.toString?.().length &&
              data.value[0] !== '' &&
              data.key != 'Kudos' &&
              data.key != 'Views' &&
              data.key != 'Reply Count' ? (
                <div className="su__meta-data" key={uuid()}>
                  <div className="su__d-flex su__flex-wrap">
                    <span className="metaDataKey su__font-bold su__color-blue su__mr-2 su__rtlmr-0 su__rtlml-2 su__font-12">
                      {' '}
                      {data.key}{' '}
                    </span>
                    {data.value.length && (
                      <span className="su__flex-1 su__flex-vcenter su__flex-wrap">
                        {React.Children.toArray(
                          data.value.map((dataItem) => (
                            <span
                              key={uuid()}
                              className="su__color-black su__text-center su__tags  su__mb-1 su__radius-3 su__mr-1 su__rtlmr-0 su__rtlml-2 su__line-height-n su__font-10"
                              dangerouslySetInnerHTML={{ __html: dataItem }}
                            ></span>
                          ))
                        )}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
            </>
          ))
        )}
      </div>
    );
  } catch (e) {
    console.log('Error in Metadata component', e);
    return <div></div>;
  }
};

export default React.memo(Metadata);
