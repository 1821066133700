import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  search,
  recommendations,
  facetPreferenceCall,
  pageRating,
  advertisementSearch
} from './redux/ducks';
import variables from './redux/variables';
import SearchResultFeedback from 'components/feature-components/search-result-feedback/index.jsx';
import SearchPageFeedback from 'components/feature-components/search-page-feedback/index.jsx';
// import SearchFeedbackModal from 'components/feature-components/search-feedback-modal/index.jsx';
import SearchFeedbackModal from 'components/custom-components/search-feedback-modal-1742803290010/index.jsx';

import Search from 'components/custom-components/search-box-1737695923999/index.jsx';
import AdvanceSearch from 'components/custom-components/advance-search-1737695923962/index.jsx';
import ContentTotal from 'components/section-components/content-total/index.jsx';
import Bookmarks from 'components/custom-components/bookmarks-1737695923971/index.jsx';
import Language from 'components/feature-components/language/index.jsx';
import SortBy from 'components/custom-components/sort-by-1737695924007/index.jsx';
import DidYouMean from 'components/feature-components/did-you-mean/index.jsx';
import ClearFilter from 'components/custom-components/clear-filter-1737695923973/index.jsx';
import StickyFacets from 'components/feature-components/sticky-facets/index.jsx';
import Advertisement from 'components/feature-components/advertisement/index.jsx';
import FeaturedSnippet from 'components/feature-components/feature-snippet/index.jsx';
import ToggleView from 'components/custom-components/list-grid-view-1737695923986/index.jsx';
import Setting from 'components/custom-components/settings-1737695924006/index.jsx';
import Recommendations from 'components/feature-components/recommendations/index.jsx';
import SimilarSearch from 'components/feature-components/similar-search/index.jsx';
import NoResult from 'components/custom-components/no-result-1737695923992/index.jsx';
import 'components/feature-components/language/i18n.jsx';
import UnderConstruction404 from 'components/feature-components/under-consruction/error404.jsx';
import SandboxGptFeedback from 'components/feature-components/sandbox-gpt-feedback/index.jsx';

/**Component related to Facet and Content Source Tab  */
import Facet from 'components/feature-components/facets/index.jsx';
import MobileFacet from 'components/feature-components/facets-mobile/index.jsx';
import TopFacets from 'components/custom-components/content-source-tabs-1737695923975/index.jsx';

/**Component related to pagination  */
import NavigatePagination from 'components/custom-components/pagination-navigate-1737695923994/index.jsx';
import ResultsPerPage from 'components/feature-components/results-per-page/index.jsx';
import ScrollPagination from 'components/feature-components/pagination-scroll/index.jsx';
import LoadMoreResults from 'components/feature-components/pagination-load-more/index.jsx';
import ScrollToTop from 'components/custom-components/go-to-page-top-1737695923982/index.jsx';

/**Component related to Result section (content tile) */
import ContentTile from 'components/section-components/content-tile/index.jsx';
import ListView from 'components/section-components/list-view/index.jsx';
import GridView from 'components/section-components/grid-view/index.jsx';
import Image from 'components/section-components/image/index.jsx';
import Icon from 'components/section-components/icon/index.jsx';
import Title from 'components/section-components/title/index.jsx';
import SourceLabel from 'components/section-components/source-label/index.jsx';
import SearchTuning from 'components/section-components/auto-tune/index.jsx';
import Solved from 'components/section-components/solved/index.jsx';
import LiveCounts from 'components/section-components/live-counts/index.jsx';
import Href from 'components/section-components/href/index.jsx';
import Summary from 'components/section-components/summary/index.jsx';
import Metadata from 'components/section-components/meta-data/index.jsx';
import MultiVersion from 'components/section-components/multi-version/index.jsx';
import Preview from 'components/section-components/preview/index.jsx';
import AttachToTicketIcon from 'components/section-components/attach-to-ticket-icon/index.jsx';
import dataFormater from './function-library/dataFormatter';
import utilityMethods from './redux/utilities/utility-methods';
import { a11y, tabIndexes } from '../src/constants/a11y';
import StaticStrings from 'StaticStrings';
import { useTranslation } from 'react-i18next';
import { useDevice } from 'function-library/hooks';
import FeaturedSnippetAnimation from 'components/feature-components/feature-snippets-animation/index';
import CitationsPortal from 'components/feature-components/citations-portal/index.jsx';

import { refreshJwtToken } from './redux/searchClientTypes';

const App = () => {
  try {
    const { t } = useTranslation();
    let searchSuggest, facetPreferenceResults, loading, recommendationsResults;
    [searchSuggest, facetPreferenceResults, loading, recommendationsResults] = useSelector(
      (state) => {
        return [
          state.searchResult,
          state.facetPreferenceResult,
          state.inProgress,
          state.recommendationsResults
        ];
      }
    );
    /** Refs */
    const memoryRefs = [];
    /** Information deduced from search Result state */
    let {
      advertisements,
      didYouMean,
      featuredSnippetResult,
      languageManager,
      loadMoreResults,
      navigatePagination,
      noResult,
      recommendation,
      scrollPagination,
      similarSearches,
      viewedResult,
      allContentHideFacet,
      searchPageFeedback,
      searchResultFeedback
    } = dataFormater(searchSuggest);

    /** chrome sc sso loader */
    const [ssoLoader, setSSoLoader] = useState(true);

    /** conditionally render component for differnt devices */
    const { isDeviceDesktop, isDeviceIpad, isDeviceMobile } = useDevice();
    /**
     * isListView true - List View
     * isListView false - Grid View
     */
    const [isListView, setIsListView] = useState(true);
    const [isGridView, setIsGridView] = useState(false);
    const [isTitleClicked, setTitleClicked] = useState(null);
    const [limitReached, setLimitReached] = useState(false);
    const [bookmarkListIconActive, setBookmarkListIconActive] = useState(false);
    const [gptResponse, setGptResponse] = useState('');
    const dispatch = useDispatch();
    const [savedResultBookmarkClicked, setSavedResultBookmarkClicked] = useState(false);
    const [
      { height, width, href, target, diamondPositionX, diamondPositionY, Opacity },
      setDimensions
    ] = useState({
      height: '',
      width: '',
      diamondPositionX: '',
      diamondPositionY: '',
      Opacity: ''
    });
    const modalRef = useRef(null);
    const [showPortal, setshowPortal] = useState(false);
    const [isAdvanceSearchCall, setIsAdvanceSearchCall] = useState(false);

    let facetPreferenceData;
    if (variables.searchCallVariables.aggregations.length)
      facetPreferenceData = facetPreferenceResults.aggregationsArray;
    else facetPreferenceData = searchSuggest.aggregationsArray;
    let mergedFacet = [];
    if (facetPreferenceResults && facetPreferenceResults.merged_facets)
      mergedFacet = facetPreferenceResults.merged_facets;
    else if (searchSuggest && searchSuggest.merged_facets)
      mergedFacet = searchSuggest.merged_facets;

    let isSmartFacets =
      searchSuggest &&
      searchSuggest.searchClientSettings &&
      searchSuggest.searchClientSettings.smartFacets;
    let smartFacetsAggregation = searchSuggest && searchSuggest.smartAggregations;
    let arrayAggregation = searchSuggest && searchSuggest.aggregationsArray;
    let gptActive =
      searchSuggest &&
      searchSuggest.searchClientSettings &&
      searchSuggest.searchClientSettings.gptConfig &&
      searchSuggest.searchClientSettings.gptConfig.gptActive;
    let configurations =
      searchSuggest &&
      searchSuggest.searchClientSettings &&
      searchSuggest.searchClientSettings.SCsalesforceConsoleConfigurations;
    configurations = configurations && JSON.parse(configurations);
    let urlOpensInNewTab = configurations && configurations.searchResultsOpensNewBrowserTab == '1';

    let hideFacets = true;
    if (arrayAggregation && arrayAggregation.length !== 0) {
      for (let index = 1; index < arrayAggregation.length; index++) {
        if (arrayAggregation[index].values.length !== 0) {
          hideFacets = false;
        }
      }
    }

    const bookmarkListIconFunc = () => {
      setBookmarkListIconActive(!bookmarkListIconActive);
    };

    const savedResultLimitReachedFunc = () => {
      setLimitReached(!limitReached);
    };

    useEffect(() => {
      document.body.classList.add('su__app-running');
    });

    /** Initialize Application OnLoad Timers Here **/
    useEffect(() => {
      const clearIntervalsAndFetch = async () => {
        utilityMethods.clearIntervalsAndTimeouts(memoryRefs);
        await handleClientType();
      };

      const handleClientType = async () => {
        if (variables.searchClientType === 28) {
          await handleVanillaAuth();
        } else if (variables.searchClientType === 31) {
          await handleSSO();
        } else {
          setSSoLoader(false);
        }
      };

      const handleVanillaAuth = async () => {
        const apiStatus = await utilityMethods.vanillaAuthUpdater();
        if (apiStatus) {
          memoryRefs.push(
            utilityMethods.withInterval(utilityMethods.vanillaAuthUpdater, 60 * 1000)
          );
        }
      };

      const handleSSO = async () => {
        try {
          const hscToken = getHscToken();
          if (hscToken) {
            await processSSO(hscToken);
          } else {
            redirectToSAML();
          }
        } catch (error) {
          console.log(' [error]: ', error);
        }
      };

      const getHscToken = () => {
        return document?.cookie?.split('hscToken=')?.[1]?.split(';')?.[0];
      };

      const processSSO = async (hscToken) => {
        const su__chromeConfig = JSON.parse(decodeURIComponent(hscToken));
        window.jwtBearer = su__chromeConfig?.token;
        setSSoLoader(false);

        if (su__chromeConfig?.email) {
          // eslint-disable-next-line no-undef
          GzAnalytics.setUser(su__chromeConfig.email);
          variables.searchCallVariables['email'] = window.su_utm;
          variables.autocompleteCallVariables['email'] = window.su_utm;
        }
      };

      const redirectToSAML = () => {
        const searchString =
          window?.location?.search?.split('searchString=')?.[1]?.split('&')?.[0] || '';
        const aggregations =
          window?.location?.search?.split('aggregations=')?.[1]?.split('&')?.[0] || '';
        window.location = `${variables.searchClientProps.instanceName}/saml/auth?uid=${variables.searchCallVariables.uid}&searchString=${searchString}&aggregations=${aggregations}`;
      };

      clearIntervalsAndFetch();

      return () => utilityMethods.clearIntervalsAndTimeouts(memoryRefs); // Cleanup
    }, []);

    const resultBookmarkClickedFunc = () => {
      setSavedResultBookmarkClicked(!savedResultBookmarkClicked);
    };

    useEffect(() => {
      setTitleClicked(false);
      if (advertisements) {
        dispatch(advertisementSearch.advStart(variables.searchCallVariables));
      }
    }, [searchSuggest]);

    /**
     * refresh jwt and make search call for chrome sc
     */
    useEffect(() => {
      const refreshJwtAndSearch = async () => {
        if (
          searchSuggest.message === 'Authentication Expired' &&
          searchSuggest.statusCode === 402
        ) {
          try {
            let paramsUrlReq = await refreshJwtToken();
            const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
            if (!response.ok) {
              throw Error(response.statusText);
            }
            const resp = response;
            const results = await resp.json();
            window.jwtBearer = results.hscToken;
            performSearchCall();
          } catch (error) {
            console.error('[ Error during JWT refresh or search call ]:', error);
          }
        }
      };

      refreshJwtAndSearch();
    }, [searchSuggest.message, searchSuggest.statusCode]);

    /**
     * Initialising search call / facet-preference call
     */
    const performSearchCall = () => {
      variables.searchSource = 'searchbox';
      dispatch(search.start(variables.searchCallVariables));
      if (
        variables.searchClientType == 16 &&
        window.caseSubject == variables.searchCallVariables.searchString
      ) {
        variables.searchCallVariables.searchString = '';
      }

      let variablesClone = JSON.parse(JSON.stringify(variables.searchCallVariables));
      variablesClone.aggregations = [];
    //   if (variables.searchClientType != 16) {
    //     dispatch(facetPreferenceCall.start(variablesClone));
    //   }
    };
    useEffect(() => {
      let check = 0;
      if (
        variables.searchClientType == 16 ||
        variables.searchClientType == 11 ||
        variables.searchClientType == 12
      ) {
        if (variables.searchClientType == 16 && window.location.href.split('app_guid=')[1]) {
          window.su__zendeskAppId = window.location.href.split('app_guid=')[1].split('&origin')[0];
          window.zeneskAppUrl =
            `app_guid=${window.su__zendeskAppId}&origin=${decodeURIComponent(
              window.location.href.split('&origin=')[1]
            )}&` || '';
        }
        const intervalId = setInterval(() => {
          check++;
          if (
            (variables.searchClientType == 16 && window.caseSubject != '' && window.su_utm) ||
            ((variables.searchClientType == 11 || variables.searchClientType == 12) &&
              window.su_utm) ||
            check > 3
          ) {
            clearInterval(intervalId);
            performSearchCall();
          }
        }, 100);
      } else {
        performSearchCall();
      }
    }, []);

    /**
     * Initialising recommendations call
     */
    useEffect(() => {
      if (recommendation) {
        dispatch(recommendations.recommendationsStart(variables.searchCallVariables));
      }
    }, [recommendation]);

    /**
     *  Feedback state Initialize
     */
    const [isOpenFeedback, setOpenFeedback] = useState(false);
    useEffect(() => {
      if (searchResultFeedback || searchPageFeedback) {
        dispatch(pageRating.start(variables));
      }
    }, [searchResultFeedback, searchPageFeedback]);

    /**
     * This function calculates the position for citation preview modal to be opened.It returns X and Y position
     * where modal to be opened.
     * @param {*} svgContainer - it consists of bounding dimensions related to container
     * @param {*} modalWidth - static modal width
     * @param {*} modalHeight - static modal height
     * @param {*} posX - x-axis position
     * @param {*} posY - y-axis position
     * @returns {number} x and y axis position
     */
    const calculatePosForCitationModal = (svgContainer, modalWidth, modalHeight) => {
      const svgContainerRect = svgContainer.getBoundingClientRect();
      const svgContainerLeft = svgContainerRect.left;
      const svgContainerBottom = svgContainerRect.bottom;
      const svgContainerTop = svgContainerRect.top;
      const spaceRight = window.innerWidth - svgContainerLeft;
      const diamondHeight = 25;
      let pos_X =
        spaceRight > modalWidth
          ? svgContainerLeft - 31
          : svgContainerLeft - modalWidth + window.pageXOffset + 25;
      let pos_Y =
        svgContainerTop > modalHeight + diamondHeight
          ? svgContainerTop - (modalHeight + 25)
          : svgContainerBottom + 5;
      let diamondPosX = spaceRight > modalWidth ? 'right' : 'left';
      let diamondPosY = svgContainerTop > modalHeight + diamondHeight ? 'bottom' : 'top';
      return { pos_X, pos_Y, diamondPosX, diamondPosY };
    };

    /**
     * This funtion excutes when user hovers over citation article links and it will open the modal.
     * @param {*} event event contains all info related to citation svg element
     */
    const setPreview = (event) => {
      openCitationModal();
      let modalWidth = 454;
      let modalHeight = 75;
      const svgContainer = event.target;
      let posX, posY;
      setDimensions((prev) => ({
        ...prev,
        width: modalWidth,
        height: modalHeight,
        href: svgContainer && svgContainer.getAttribute('data-url'),
        target: '_blank',
        Opacity: 0
      }));
      if (isDeviceMobile && variables.searchClientType === 16) {
        posY = 0;
      }
      if (
        modalRef?.current?.childNodes[0]?.classList.contains('su__gpt-preview-article-link') &&
        !isDeviceMobile
      ) {
        const previewArticleLink = modalRef.current.childNodes[0];
        const targetElement = previewArticleLink.querySelector('.su__citation_modal_container');

        if (targetElement) {
          const height = targetElement.getBoundingClientRect().height;
          const width = targetElement.getBoundingClientRect().width;
          const {
            pos_X: newX,
            pos_Y: newY,
            diamondPosX: newdiamondPosX,
            diamondPosY: newdiamondPosY
          } = calculatePosForCitationModal(svgContainer, width, height);
          posX = newX;
          posY = newY;
          setDimensions((prev) => ({
            ...prev,
            diamondPositionX: newdiamondPosX,
            diamondPositionY: newdiamondPosY
          }));
        }
      }
      setDimensions((prev) => ({
        ...prev,
        width: posX,
        height: posY,
        href: svgContainer && svgContainer.getAttribute('data-url'),
        target: '_blank',
        Opacity: 1
      }));
    };
    /**
     * The function opens the preview modal for citations
     * @param {void}
     * @returns {void}
     */

    const openCitationModal = () => {
      setshowPortal(true);
    };
    /**
     * The function closes the preview modal for citations
     * @param {void}
     * @returns {void}
     */
    const closeCitationModal = () => {
      setshowPortal(false);
    };

    if (isSmartFacets && variables.searchCallVariables.smartFacets) {
      let alreadySelected =
        arrayAggregation &&
        arrayAggregation.length &&
        arrayAggregation.filter(
          (element) =>
            element.values.length && element.values.some((subElement) => subElement.selected)
        );
      if (alreadySelected && alreadySelected.length) {
        alreadySelected.forEach((item) => {
          let type = item.key;
          let Contentname = item.values.filter((sel) => sel.selected).map((obj) => obj.Contentname);

          if (
            !variables.searchCallVariables.aggregations ||
            variables.searchCallVariables.aggregations.length === 0
          ) {
            let aggregationEntry = {
              type: type,
              filter: [...new Set(Contentname)]
            };

            variables.searchCallVariables.aggregations.push(aggregationEntry);
          } else {
            let existingAggregation = variables.searchCallVariables.aggregations.find(
              (agg) => agg.type === type
            );

            if (existingAggregation) {
              if (!existingAggregation.filter.includes(...Contentname)) {
                existingAggregation.filter.push(...Contentname);
              }
            } else {
              let aggregationEntry = {
                type: type,
                filter: [...new Set(Contentname)]
              };

              variables.searchCallVariables.aggregations.push(aggregationEntry);
            }
          }
        });
      }
      utilityMethods.updateURLParams();
    }

    return (
      <main className="su__main-content">
        {ssoLoader ? (
          <div className="su__sso-loader"></div>
        ) : (
          <>
            <div
              className={`su__app ${
                viewedResult ? 'su__viewed-results' : 'su__no-view-results'
              } su__results_container`}
              id="su__wrapper"
            >
              <Search
                urlOpensInNewTab={urlOpensInNewTab}
                isAdvanceSearchCall={isAdvanceSearchCall}
                setIsAdvanceSearchCall={setIsAdvanceSearchCall}
              />
              {!variables.hasError && (
                <Fragment>
                  <section className="su__results-section" id={loading ? 'loading' : 'noloading'}>
                    {!noResult && (isDeviceIpad || isDeviceDesktop) ? (
                      <div className="su__container">
                        <div
                          className={
                            allContentHideFacet && variables.allSelected
                              ? 'su__allSelected-show su__ipadview-block su__d-md-flex su__py-2 su__col-md-12'
                              : 'su__allSelected-notshow su__ipadview-block su__d-md-flex su__py-2 su__col-md-12'
                          }
                        >
                          {isDeviceIpad && (
                            <MobileFacet aggregations={searchSuggest.aggregationsArray} />
                          )}
                          <AdvanceSearch
                            isAdvanceSearchCall={isAdvanceSearchCall}
                            setIsAdvanceSearchCall={setIsAdvanceSearchCall}
                          />
                          <StickyFacets standOut={true} />
                          <Bookmarks
                            bookmarkListIconActive={bookmarkListIconActive}
                            limitReached={limitReached}
                            resultBookmarkClickedFunc={resultBookmarkClickedFunc}
                            getArrAggregation={arrayAggregation}
                          />
                          {languageManager && <Language />}
                          <SortBy getArrAggregation={arrayAggregation} />
                          <ToggleView
                            isListView={isListView}
                            setIsListView={setIsListView}
                            isGridView={isGridView}
                            setIsGridView={setIsGridView}
                            variables={variables}
                          />
                        </div>
                      </div>
                    ) : null}
                    {!noResult ? <TopFacets /> : null}
                    {!noResult && isDeviceMobile && (
                      <section className="su__w-100 su__filter-with-lang su__width_filters">
                        <div className="su__d-flex su__w-100 su__m-lang-block su__container_filters_tab mobile-column-alignment su__align_filter_bar su__pl_5">
                          {allContentHideFacet && variables.allSelected ? (
                            ''
                          ) : (
                            <MobileFacet aggregations={searchSuggest.aggregationsArray} />
                          )}
                          <StickyFacets standOut={true} />
                          {languageManager && <Language />}
                          <SortBy getArrAggregation={arrayAggregation} />
                        </div>
                      </section>
                    )}
                    <section className="su__w-100 su__result-content" id="su__skiplink">
                      <div className="su__container">
                        {!noResult ? (
                          <div
                            className={
                              allContentHideFacet && variables.allSelected
                                ? 'su__allcontent-active su__content-gutter su__gutters su__col-md-12'
                                : 'su__allcontent-incactive su__content-gutter su__gutters su__col-md-12'
                            }
                          >
                            <div className="su__d-flex su__w-100 su__mx-sm-0 su__no-gutters su__pt-2 su__pt-sm-1 su__pb-4">
                              {isDeviceDesktop ? (
                                <div
                                  className={
                                    (allContentHideFacet && variables.allSelected) ||
                                    (hideFacets && !variables.allSelected)
                                      ? 'su__allcontentnot-show swapFilter su__col-xl-3 su__col-lg-4  su__d-none su__radius-1'
                                      : 'swapFilter su__col-xl-3 su__col-lg-4  su__d-xl-block su__radius-1'
                                  }
                                >
                                  <Facet smartFacetsAggregation={smartFacetsAggregation} />
                                </div>
                              ) : null}
                              <div
                                className={
                                  (allContentHideFacet && variables.allSelected) ||
                                  (hideFacets && !variables.allSelected)
                                    ? 'su__allcontentshow-full swapFilterLeft su__col-xl-12 su__col-xs-12 su__mt-sm-4 su__px-xs-2 su__overflow-hide'
                                    : 'swapFilterLeft su__col-xl-9 su__col-xs-12 su__mt-sm-4 su__px-xs-2 su__overflow-hide'
                                }
                              >
                                {didYouMean ? <DidYouMean /> : null}
                                <ContentTotal />
                                <ClearFilter />
                                <StickyFacets standOut={false} />
                                <Setting
                                  allContentHide={allContentHideFacet}
                                  isSmartFacets={isSmartFacets}
                                  smartFacetsAggregation={smartFacetsAggregation}
                                  data={facetPreferenceData}
                                  mergedFacet={mergedFacet}
                                />
                                {advertisements && <Advertisement />}
                                {featuredSnippetResult && (
                                  <FeaturedSnippet isDeviceMobile={isDeviceMobile} />
                                )}
                                {gptActive && (
                                  <FeaturedSnippetAnimation
                                    setGptResponse={setGptResponse}
                                    setPreview={setPreview}
                                    openCitationModal={openCitationModal}
                                    closeCitationModal={closeCitationModal}
                                    showPortal={showPortal}
                                    modalRef={modalRef}
                                    isDeviceMobile={isDeviceMobile}
                                  />
                                )}
                                {searchSuggest && searchSuggest.result && gptActive && (
                                  <SandboxGptFeedback
                                    searchSuggest={searchSuggest}
                                    gptResponse={gptResponse}
                                    setGptResponse={setGptResponse}
                                  />
                                )}
                                <ContentTile
                                  bookmarkListIconFunc={bookmarkListIconFunc}
                                  savedResultLimitReachedFunc={savedResultLimitReachedFunc}
                                  savedResultBookmarkClicked={savedResultBookmarkClicked}
                                  searchResult={searchSuggest}
                                  viewedResult={viewedResult}
                                  isListView={isListView}
                                  isDeviceDesktop={isDeviceDesktop}
                                  isDeviceMobile={isDeviceMobile}
                                  gptActive={false}
                                >
                                  {isListView && !isDeviceMobile && (
                                    <ListView>
                                      <Image position="left" />
                                      <Icon position="left" />
                                      <Title
                                        position="title"
                                        setTitleClicked={setTitleClicked}
                                        urlOpensInNewTab={urlOpensInNewTab}
                                      />
                                      <SourceLabel position="title" />
                                      {searchResultFeedback && isTitleClicked && (
                                        <SearchResultFeedback
                                          isTitleClicked={isTitleClicked}
                                          isOpenFeedback={isOpenFeedback}
                                          setOpenFeedback={setOpenFeedback}
                                          position="title"
                                        />
                                      )}
                                      <SearchTuning position="title" />
                                      <Solved position="title" />
                                      <Href position="center" />
                                      <Summary position="center" />
                                      <Metadata position="center" />
                                      <MultiVersion position="end" />
                                      {variables.searchClientType == 2 && (
                                        <LiveCounts position="below-title" />
                                      )}
                                      <Preview position="right" />
                                      {variables.searchClientType == 16 && (
                                        <AttachToTicketIcon
                                          position="right"
                                          isListView={isListView}
                                        />
                                      )}
                                    </ListView>
                                  )}

                                  {(isGridView || isDeviceMobile) && (
                                    <GridView>
                                      <Image position="above-title" />
                                      <Icon position="above-title" />
                                      <SourceLabel position="title-first" />
                                      {searchResultFeedback && isTitleClicked && (
                                        <SearchResultFeedback
                                          isTitleClicked={isTitleClicked}
                                          isOpenFeedback={isOpenFeedback}
                                          setOpenFeedback={setOpenFeedback}
                                          position="title-first"
                                        />
                                      )}
                                      <SearchTuning position="title-first" />
                                      <Preview position="after-sourceLabel" />
                                      {variables.searchClientType == 16 && (
                                        <AttachToTicketIcon
                                          position="after-sourceLabel"
                                          isListView={isListView}
                                        />
                                      )}
                                      <Title
                                        position="title-second"
                                        isTitleClicked={isTitleClicked}
                                        setTitleClicked={setTitleClicked}
                                        urlOpensInNewTab={urlOpensInNewTab}
                                      />
                                      <Solved position="title-second" />
                                      <Href position="below-title" />
                                      <Summary position="below-title" />
                                      <Metadata position="below-title" />
                                      <MultiVersion position="below-metadata" />
                                      {variables.searchClientType == 2 && (
                                        <LiveCounts position="below-title" />
                                      )}
                                    </GridView>
                                  )}
                                </ContentTile>
                                <div className="su__Recommended_flex">
                                  {recommendation &&
                                    recommendationsResults &&
                                    recommendationsResults.result &&
                                    recommendationsResults.result.hits.length !== 0 && (
                                      <Recommendations
                                        recommendationsResults={recommendationsResults}
                                      />
                                    )}
                                  {similarSearches && <SimilarSearch />}
                                </div>
                                {loadMoreResults && <LoadMoreResults />}
                                {(navigatePagination && !variables.mergeResults) ||
                                (variables.mergeResults &&
                                  !variables.resultsInAllContentSources) ? (
                                  <div className="su__d-inline-block su__mb__25 su__w-100  su__text-right footerSection su__resultPaginationbtn_align su__resultbtn">
                                    <ResultsPerPage />
                                    <NavigatePagination />
                                  </div>
                                ) : null}
                                {scrollPagination && <ScrollPagination />}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="su__p-3">
                           {/*   {noResult && didYouMean && <DidYouMean />} */}
                            <NoResult />
                          </div>
                        )}
                      </div>
                    </section>
                    {(isDeviceIpad || isDeviceDesktop) && (
                      <section className="su__w-100 su__ScrollToTop">
                        <div className="su__container">
                          <ScrollToTop isDeviceMobile={isDeviceMobile} />
                        </div>
                      </section>
                    )}
                    {isDeviceMobile && (
                      <section className="su__mobile-feature su__w-100 su__position-fixed su__bottom-0 su__bg-white su__d-xs-block su__bottom_navbar_mobile">
                        <div
                          lang={variables.searchCallVariables.langAttr}
                          tabIndex={tabIndexes.tabIndex_0}
                          role={a11y.ROLES.NAVIGATION}
                          aria-label={t(StaticStrings.bottom_navigation)}
                          className="su__p-2 su__bg-white su__px-sm-1 su__py-2 su__flex-vcenter su__justify-content-between su__align-items-start su__flex_start"
                        >
                          <ScrollToTop isDeviceMobile={isDeviceMobile} />
                          <AdvanceSearch
                            isAdvanceSearchCall={isAdvanceSearchCall}
                            setIsAdvanceSearchCall={setIsAdvanceSearchCall}
                          />
                          <Bookmarks
                            bookmarkListIconActive={bookmarkListIconActive}
                            limitReached={limitReached}
                            resultBookmarkClickedFunc={resultBookmarkClickedFunc}
                            getArrAggregation={arrayAggregation}
                          />
                          <Setting
                            allContentHide={allContentHideFacet}
                            data={facetPreferenceData}
                            merged={mergedFacet}
                          />
                        </div>
                      </section>
                    )}
                  </section>
                  {searchPageFeedback && (
                    <SearchPageFeedback
                      isOpenFeedback={isOpenFeedback}
                      setOpenFeedback={setOpenFeedback}
                    />
                  )}
                  {isOpenFeedback && (
                    <SearchFeedbackModal
                      searchPageFeedback={searchPageFeedback}
                      searchResult={searchSuggest}
                      isModelFeedback={isOpenFeedback}
                      setModalFeedback={setOpenFeedback}
                    />
                  )}
                </Fragment>
              )}
            </div>
            {showPortal && (
              <CitationsPortal
                width={width}
                height={height}
                href={href}
                target={target}
                searchResult={searchSuggest}
                showPortal={showPortal}
                setshowPortal={setshowPortal}
                modalRef={modalRef}
                isDeviceMobile={isDeviceMobile}
                diamondPositionX={diamondPositionX}
                diamondPositionY={diamondPositionY}
                Opacity={Opacity}
                viewedResult={viewedResult}
              />
            )}
          </>
        )}
      </main>
    );
  } catch (e) {
    console.log(`Error in App.js ${e}`);
    return (
      <div>
        <UnderConstruction404 />
      </div>
    );
  }
};

export default App;