import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import { bool, func, number } from 'prop-types';

const SearchResultFeedback = (props) => {
  let { isTitleClicked, isOpenFeedback, index } = props;
  const { t } = useTranslation();
  const handleClick = () => {
    variables.searchResultClicked = true;
  };
  return (
    <>
      <div
        className={`su__fs-search-result ${
          isTitleClicked === index + 1 ? 'su__click-title' : 'su__noclicked-title'
        } ${isOpenFeedback ? 'active' : ''}`}
        onClick={() => props.setOpenFeedback(!isOpenFeedback)}
      >
        <Tooltip text={t(StaticStrings.GIVEFEEDBACK)} position="left">
          <span
            onClick={handleClick}
            className="su__font-12 su__flex-hcenter su__radius-2 su__bg-white su__cursor"
          >
            <Icons
              className="su__cursor"
              IconName="ViewMore"
              width="22"
              height="22"
              color={IconColors.SearchResultFeedbackViewMore}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
};

SearchResultFeedback.propTypes = {
  isTitleClicked: number,
  isOpenFeedback: bool,
  index: number,
  setOpenFeedback: func
};

SearchResultFeedback.defaultProps = {
  isTitleClicked: 0,
  isOpenFeedback: false,
  index: 0,
  setOpenFeedback: () => {}
};

export default SearchResultFeedback;
