import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { useTranslation } from 'react-i18next';
import StaticStrings from '../../../StaticStrings';
import { htmlSanitizer } from 'function-library/dataFormatter';
import { v4 as uuid } from 'uuid';

const DidYouMean = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const searchSuggest = useSelector((state) => state.searchResult);
    const dispatch = useDispatch();
    const searchClick = (text) => {
      variables.searchCallVariables.pagingAggregation = [];
      variables.previousDymSearchString = text;
      variables.searchCallVariables.searchString = text;
      variables.searchSource = 'did-you-mean';
      dispatch(search.start(variables.searchCallVariables));
      variables.searchAnalyticsObject = null;
    };
    return (
      <div className="did-you-mean">
        {searchSuggest.result &&
          Object.keys(searchSuggest.suggest).length > 0 &&
          searchSuggest.suggest.simple_phrase.map((item) => (
            <div key={uuid()}>
              {React.Children.toArray(
                item.options.map((item) => (
                  <div className="su__didumean su__mb-2" key={uuid()}>
                    <div>
                      <span
                        lang={variables.searchCallVariables.langAttr}
                        className="su__font-bold su__font-12 su__mr-2 su__text-blue su__loading-view custom_did-you-mean-color"
                      >
                        {t(StaticStrings.DIDYOUMEAN)}:{' '}
                      </span>
                      <span className="su__loading-view">
                        <span
                          href="#!"
                          dangerouslySetInnerHTML={{ __html: htmlSanitizer(item.text) }}
                          className="su__font-11 su__text-decoration su__color-lgray su__font-bold su__cursor custom_did-you-mean-text"
                          onClick={() => searchClick(item.text)}
                        />
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          ))}
      </div>
    );
  } catch (e) {
    console.log('Error in did you mean component', e);
    return <div></div>;
  }
};

export default DidYouMean;