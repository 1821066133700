/* global gza */
import React, { useState, useEffect } from 'react';
import Href from 'components/section-components/href/index.jsx';
import Title from 'components/section-components/title/index.jsx';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { a11y } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';
const FeaturedSnippetImages = ({ featuredSnippetResult, linkOpened, isDeviceMobile }) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const [featuredSnippetGraphResponseRecorded, setFeaturedSnippetGraphFeedback] = useState(false);
    const [timeoutResponse, setTimeoutResponse] = useState(true);
    const [updateComponent, setUpdateComponent] = useState(true);
    useEffect(() => {
      setFeaturedSnippetGraphFeedback(false);
      setTimeoutResponse(true);
    }, [featuredSnippetResult]);
    const sendFeaturedSnippetGraphFeedback = (feedback, result, index, item) => {
      gza('featuredSnippet', {
        searchString: variables.searchCallVariables.searchString,
        url: (item && item.href) || result.href,
        t:
          (item && item.title[0].substring(0, 300)) ||
          result.highlight.TitleToDisplayString[0].substring(0, 300) ||
          result.href,
        uid: variables.searchCallVariables.uid,
        feedback: feedback
      });
      if (item) item.isFeedbackHidden = true;
      setFeaturedSnippetGraphFeedback(true);

      if (index >= 0) {
        document.getElementById(index + '_hiddenSnippet').style.display = 'block';
        setTimeout(function () {
          document.getElementById(index + '_hiddenSnippet').style.display = 'none';
        }, 5000);
      }
      if (index < 0 || index == undefined) {
        setTimeout(function () {
          setTimeoutResponse(false);
        }, 5000);
      }

      setUpdateComponent(!updateComponent);
    };
    return (
      <>
        {featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length > 1 && (
          <div className="su__w-100 su__fs-multimedia-main su__d-flex">
            {featuredSnippetResult.multiMedia.slice(0, 4).map((item, index) => {
              return (
                <div
                  className={`${
                    (item.thumbnail && item.video_url) || item.image_urls
                      ? 'su__fs-media su__mt-2 su__position-relative su__bg-white su__sm-shadow'
                      : 'su__fs-media su__mt-2 su__position-relative su__bg-white su__sm-shadow su_background_grey'
                  }`}
                  key={uuid()}
                >
                  <div className="su__d-flex su__right-0 su__feedback-icon-above su__position-absolute su__zindex-1 su__feedback_icon_align">
                    <div
                      className={`${
                        item.isFeedbackHidden
                          ? 'su__snippet-none'
                          : 'su__feedback-row su__d-flex su__pt-2 su__pr-2'
                      } `}
                    >
                      <span
                        className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                        onClick={() =>
                          sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult, index, item)
                        }
                      >
                        <Icons
                          IconName="ThumbsDown"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                      <span
                        className="su__featured-feedback su__cursor su__loading-view"
                        onClick={() =>
                          sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult, index, item)
                        }
                      >
                        <Icons
                          IconName="ThumbsUp"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                    </div>
                    <div
                      id={`${index}_hiddenSnippet`}
                      className={`${
                        item.isFeedbackHidden
                          ? 'su__feedback-thankyou su__font-14 su__mb-2 su__position-relative su__loading-view'
                          : 'su__snippet-none'
                      }`}
                    >
                      <div className="su__flex-vcenter su__font-12">
                        <span
                          lang={variables.searchCallVariables.langAttr}
                          className="su__thankyou-text su__whitespace-no"
                        >
                          {t(StaticStrings.thanks_response)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <a
                    href={item.href || item.video_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseDown={(event) => {
                      if (event.button == 1)
                        linkOpened(featuredSnippetResult, index + 1, item.href, item.title[0]);
                    }}
                    onContextMenu={() =>
                      linkOpened(featuredSnippetResult, index + 1, item.href, item.title[0])
                    }
                    onClick={() =>
                      linkOpened(featuredSnippetResult, index + 1, item.href, item.title[0])
                    }
                  >
                    <div className="su__featured-thumbnail su__position-relative">
                      {item.video_url ? (
                        <>
                          {item.thumbnail ? (
                            <svg
                              className="su__video-thumbnail-play"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill={IconColors.FeaturedSnippetSvgFill}
                            >
                              <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                            </svg>
                          ) : (
                            <svg
                              className="su__align_icon_play_grey"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill={IconColors.FeatureSnippetSvgPlayGrey}
                            >
                              <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                            </svg>
                          )}
                        </>
                      ) : null}
                      <img
                        src={item.image_urls || item.thumbnail}
                        className="su__img-featured"
                        alt={item.alt_attributes}
                        role={a11y.ROLES.IMAGE}
                        aria-label={item.image_urls || item.thumbnail}
                      />
                    </div>
                    <div className="su__href-txt su__text-decoration su__color-lgray su__font-12 su__font-italic su__align_url">
                      {' '}
                      {item.href || item.video_url}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        )}
        {featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length == 1 && (
          <div className="su__fs-media-content su__mb-3 su__bg-white su__sm-shadow">
            <div className="su__fs-inner-media su__d-md-flex su__overflow-hide">
              <div className={`${isDeviceMobile ? 'su__w-100' : 'su__w-25'}`}>
                <div className="su__featured-thumbnail">
                  <a
                    className="su__d-flex su__position-relative"
                    onClick={() => linkOpened(featuredSnippetResult, 1)}
                    target="_blank"
                    href={
                      featuredSnippetResult.multiMedia[0].href ||
                      featuredSnippetResult.multiMedia[0].video_url
                    }
                    rel="noopener noreferrer"
                  >
                    {featuredSnippetResult.multiMedia[0].thumbnail ? (
                      <svg
                        className="su__video-thumbnail-play"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill={IconColors.FeaturedSnippetSvgFill}
                      >
                        <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                      </svg>
                    ) : null}
                    <img
                      src={
                        featuredSnippetResult.multiMedia[0].image_urls ||
                        featuredSnippetResult.multiMedia[0].thumbnail
                      }
                      className="su__img-featured"
                      alt={featuredSnippetResult.multiMedia[0].alt_attributes}
                      role={a11y.ROLES.IMAGE}
                      aria-label={
                        featuredSnippetResult.multiMedia[0].image_urls ||
                        featuredSnippetResult.multiMedia[0].thumbnail
                      }
                    />
                  </a>
                </div>
              </div>
              <div
                className={`su__d-flex su__flex-column su__p-3 su__word-break ${
                  isDeviceMobile ? 'su__w-100' : 'su__w-75'
                }`}
              >
                <Title item={featuredSnippetResult} linkOpened={linkOpened} index={1} />
                <Href item={featuredSnippetResult} />
                <div
                  className={`su__d-flex su__w-100 su__mt-2 ${
                    isDeviceMobile ? '' : 'su__featureSnippet-response'
                  }`}
                >
                  {!featuredSnippetGraphResponseRecorded ? (
                    <div className="su__d-flex">
                      <span
                        className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                        onClick={() => sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsDown"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                      <span
                        className="su__featured-feedback su__cursor su__loading-view"
                        onClick={() => sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsUp"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                    </div>
                  ) : null}
                  {featuredSnippetGraphResponseRecorded && timeoutResponse ? (
                    <div
                      id="single_hiddenSnippet"
                      className="su__font-14 su__mb-2 su__position-relative su__loading-view"
                    >
                      <div className="su__flex-vcenter su__font-12">
                        <span
                          lang={variables.searchCallVariables.langAttr}
                          className="su__thankyou-text su__whitespace-no"
                        >
                          {t(StaticStrings.thanks_response)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (e) {
    console.log('Error in feature-snippet component', e);
    return <div></div>;
  }
};

export default FeaturedSnippetImages;
