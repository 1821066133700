export const setWildcardToggle = (v) =>
  v
    ? sessionStorage.setItem('toggleWildcardSearch', 'true')
    : sessionStorage.removeItem('toggleWildcardSearch');
export const getWildcardToggle = () => !!sessionStorage.getItem('toggleWildcardSearch');

/**
 * sleep - adds wait time for a specified duration
 * @param {number} t - time in ms
 * @returns {Promise} - promise resolve
 */
export const sleep = async (t) => await new Promise((res) => setTimeout(() => res(), [t]));
