import React, { useState, Fragment, useEffect } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import { search } from '../../../redux/ducks';
import { useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import UnderConstruction from 'components/feature-components/under-consruction/index.jsx';
import setCookies from '../../../setCookie/setCookie';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import { A11Y_IDS, focusTrap, a11y, tabIndexes } from '../../../constants/a11y';
import 'components/feature-components/language/i18n.jsx';
import { getWildcardToggle, setWildcardToggle } from 'function-library/commonFunctions';

const AdvanceSearch = ({ setIsAdvanceSearchCall }) => {
  const { t } = useTranslation();
  try {
    /**
     *  Change Languages state
     */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const handleHideDropdown = (e) => {
      e.key === 'Escape' && setIsModalOpen(false);
    };
    useEffect(() => {
      focusTrap(isModalOpen);
    }, [isModalOpen]);

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
      };
    });

    let [advancedState, setAdvancedState] = useState({
      exactPhrase: variables.searchCallVariables.exactPhrase,
      withOneOrMore: variables.searchCallVariables.exactPhrase,
      withoutTheWords: variables.searchCallVariables.withoutTheWords,
      wildcardSearch: getWildcardToggle() ? variables.searchCallVariables.searchString : ''
    });
    let [advanceSearchEnabled, setAdvanceSearchEnabled] = useState(
      advancedState.exactPhrase ||
        advancedState.withOneOrMore ||
        advancedState.withoutTheWords ||
        advancedState.wildcardSearch
    );

    const closeAdvancedSearch = () => {
      advancedState.exactPhrase = variables.searchCallVariables.exactPhrase;
      advancedState.withOneOrMore = variables.searchCallVariables.withOneOrMore;
      advancedState.withoutTheWords = variables.searchCallVariables.withoutTheWords;
      advancedState.wildcardSearch = variables.searchCallVariables.searchString;
    };

    const clearAdvancedFilters = () => {
      setShowPopup(false);
      setIsModalOpen(false);
      setAdvancedState({
        exactPhrase: '',
        withOneOrMore: '',
        withoutTheWords: '',
        wildcardSearch: ''
      });
      setCookies.setSmartFacetOff();
      variables.searchCallVariables.pagingAggregation = [];
      variables.searchCallVariables.exactPhrase = '';
      variables.searchCallVariables.withOneOrMore = '';
      variables.searchCallVariables.withoutTheWords = '';
      variables.searchSource = 'clear-advance-search';
      setWildcardToggle(false);
      dispatch(search.start(variables.searchCallVariables));
    };

    const openClearFiltersModal = () => {
      setShowPopup(!showPopup);
    };
    const dispatch = useDispatch();

    const advanceSearchKeyword = (data, action) => {
      let advanceKeywords = advancedState;
      if (action == 'exactPhrase') {
        advanceKeywords.exactPhrase = data;
      } else if (action == 'withOneOrMore') {
        advanceKeywords.withOneOrMore = data;
      } else if (action == 'withoutTheWords') {
        advanceKeywords.withoutTheWords = data;
      } else if (action == 'wildcardSearch') {
        advanceKeywords.wildcardSearch = data;
      } else {
        /** this case is required for Bookmark search  */
        advanceKeywords = {
          exactPhrase: data.exactPhrase,
          withOneOrMore: data.withOneOrMore,
          withoutTheWords: data.withoutTheWords,
          wildcardSearch: getWildcardToggle() ? data.searchString : ''
        };
      }
      setAdvancedState({
        exactPhrase: advanceKeywords.exactPhrase,
        withOneOrMore: advanceKeywords.withOneOrMore,
        withoutTheWords: advanceKeywords.withoutTheWords,
        wildcardSearch: advanceKeywords.wildcardSearch
      });
    };

    useEffect(() => {
      setAdvanceSearchEnabled(
        variables.searchCallVariables.exactPhrase ||
          variables.searchCallVariables.withOneOrMore ||
          variables.searchCallVariables.withoutTheWords ||
          getWildcardToggle()
      );
      advanceSearchKeyword(variables.searchCallVariables, null);
    }, [
      variables.searchCallVariables.exactPhrase,
      variables.searchCallVariables.withOneOrMore,
      variables.searchCallVariables.withoutTheWords,
      variables.searchCallVariables.searchString,
      getWildcardToggle()
    ]);

    const handleWildcard = () => {
      setWildcardToggle(advancedState.wildcardSearch !== '');
      if (!getWildcardToggle()) {
        setAdvancedState({ wildcardSearch: '' });
      }
    };

    const advancedSearchSubmit = (e) => {
      setIsAdvanceSearchCall(false);
      variables.searchCallVariables.pagingAggregation = [];
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      e.preventDefault();
      setIsModalOpen(false);
      variables.searchCallVariables.exactPhrase =
        advancedState && advancedState.exactPhrase && advancedState.exactPhrase.trim();
      variables.searchCallVariables.withOneOrMore =
        advancedState && advancedState.withOneOrMore && advancedState.withOneOrMore.trim();
      variables.searchCallVariables.withoutTheWords =
        advancedState && advancedState.withoutTheWords && advancedState.withoutTheWords.trim();
      variables.searchCallVariables.searchString =
        advancedState && advancedState.wildcardSearch.length > 0
          ? advancedState.wildcardSearch.trim()
          : variables.searchCallVariables.searchString;
      handleWildcard();
      variables.searchSource = 'advance-search';
      setCookies.setSmartFacetOff();
      dispatch(search.start(variables.searchCallVariables));
      setIsAdvanceSearchCall(true);
    };

    const openModel = () => {
      setIsModalOpen(true);
      setShowPopup(false);
    };

    useEffect(() => {
      focusTrap(showPopup);
    }, [showPopup]);

    return (
      <div className="su__mobile-child-block su__advance-blocks">
        <div className="su__search_advance su__d-xs-none su__d-md-flex  su__font-12 su__radius-1 su__mr-2 su__rtlmr-0 su__rtlml-0">
          <Tooltip
            text={t(StaticStrings.ADVANCEDSEARCH)}
            position="top"
            className="position-relative"
          >
            <button
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.ADVANCEDSEARCH)}
              type="button"
              onClick={openModel}
              className={`su__filters-button su__btn btn_advance-search su__radius-2 su__bg-white su__flex-hcenter su__rtlmr-0 su__rtlml-3 su__whitespace-no ${
                advanceSearchEnabled ? 'su__advance-background' : ''
              }`}
            >
              <Icons
                onClick={closeAdvancedSearch}
                className="su__close-icon su__bg-white-hover-50"
                IconName="Advancesearch"
                width="16"
                widthInner="18"
                height="16"
                heightInner="1.928"
                rx="0.964"
                cx="2.785"
                cy="2.785"
                r="2.785"
                transform="translate(0 0)"
                fill={
                  advanceSearchEnabled
                    ? IconColors.AdvanceSearchEnabledIconFill
                    : IconColors.AdvanceSearchIconFill
                }
              />
              <span
                className={` ${
                  advanceSearchEnabled ? 'su_text-white' : 'su__text-black'
                }  su__font-13 su__p-1 su__rtlp-1 su__font-regular`}
              >
                {t(StaticStrings.ADVANCEDSEARCH)}
              </span>
            </button>
          </Tooltip>
        </div>
        <div className="su__d-md-none su__d-xs-block su__text-center su__px-sm-1">
          <button
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(StaticStrings.ADVANCEDSEARCH)}
            type="button"
            role={a11y.ROLES.BTN}
            tabIndex={tabIndexes.tabIndex_0}
            className={`su__mob-search-iner a11y-btn p-0 ${isModalOpen ? 'su__mob-active' : ''}`}
            onClick={() => setIsModalOpen(true)}
          >
            <div className="su__mob-icon">
              <svg width="20" height="20" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
                <g
                  id="Advance_search_1"
                  data-name="Advance search 1"
                  clipPath="url(#clip-Advance_search_1)"
                >
                  <g
                    id="Group_1450"
                    data-name="Group 1450"
                    transform="translate(1774.646 -2045.384)"
                  >
                    <path
                      id="Path_1602"
                      data-name="Path 1602"
                      d="M0,0H63.354V63.354H0Z"
                      transform="translate(-1780 2040)"
                      fill="none"
                    ></path>
                    <path
                      id="Subtraction_2"
                      className="su__active-path"
                      data-name="Subtraction 2"
                      d="M40.682,58.136H5.783A5.8,5.8,0,0,1,0,52.322L.029,5.814A5.8,5.8,0,0,1,5.811,0H26.646a5.742,5.742,0,0,1,4.1,1.715L44.779,15.755a5.746,5.746,0,0,1,1.715,4.1V51.13L35.364,39.968a14.52,14.52,0,0,0-9.589-22.324,15.236,15.236,0,0,0-2.527-.214A14.564,14.564,0,0,0,8.834,33.952,14.692,14.692,0,0,0,22.55,46.48c.256.014.516.021.771.021a14.55,14.55,0,0,0,7.946-2.4L44.14,56.973A5.733,5.733,0,0,1,40.682,58.136ZM23.291,8.629a2.185,2.185,0,0,0,0,4.37h7.646a2.185,2.185,0,0,0,0-4.37ZM8,8.629A2.185,2.185,0,1,0,8,13h7.646a2.185,2.185,0,1,0,0-4.37ZM23.247,40.7a8.708,8.708,0,1,1,6.167-2.551A8.661,8.661,0,0,1,23.247,40.7Z"
                      transform="translate(-1769.43 2045.571)"
                      fill="#464646"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__mob-advance-text su__font-9 su__font-bold su__line-height-n su__active-text"
            >
              {t(StaticStrings.ADVANCEDSEARCH)}
            </div>
          </button>
        </div>
        {isModalOpen && (
          <Fragment>
            <div
              className="su__flex-hcenter su__position-fixed su__px-xs-2 su__trbl-0 su__zindex-3"
            >
              <form
                onSubmit={advancedSearchSubmit}
                className="su__modal-inner su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1"
              >
                <div tabIndex={-1} className="AdvanceSearchPopUp su__bookmark-popup-title su__border-b su__radius su__px-3 su__py-3 su__flex-vcenter">
                  {/* <Icons className="su__advance-icon" IconName="Advancesearch" width="20" widthInner="20" height="20" heightInner="1.928" rx="0.964" cx="2.785" cy="2.785" r="2.785" transform="translate(0 0)" fill={IconColors.AdvanceSearchIconFill} /> */}
                  <h2 lang={variables.searchCallVariables.langAttr} className="su__font-16 su__text-black su__f-regular su__text-truncate su__d-inline-block su__flex-1 su__position-relative su__text-left su__ml-2 su__pr-3 su__my-0">{t(StaticStrings.ADVANCEDSEARCHINNER)}</h2>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    className="a11y-btn p-0 order-4"
                    aria-label={t(StaticStrings.close_popup)}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    onClick={() => setIsModalOpen(false)}
                  >
                    <Icons
                      className="su__close-icon su__cursor"
                      IconName="Close"
                      width="16"
                      height="16"
                      color="white"
                    />
                  </button>
                {/* <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    tabIndex={tabIndexes.tabIndex_0}
                    className={`su__font-12 su__text-blue su__pr-3 su__cursor su__text-bold a11y-btn ${
                      advancedState.exactPhrase || advancedState.withOneOrMore || advancedState.withoutTheWords ? 'd-block' : 'd-none'
                    } `}
                    onClick={openClearFiltersModal}
                  >
                    {t(StaticStrings.Clear)}
                  </button> */}
                </div>
                {showPopup && (
                  <Fragment>
                    <div
                      role={a11y.ROLES.ALERT}
                      className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-4 su__px-sm-1"
                    >
                      <div
                        className="su__modal-inner su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1"
                      >
                        <div className="su__bookmark-popup-title su__px-4 su__py-3 su__flex-vcenter">
                          <div
                            lang={variables.searchCallVariables.langAttr}
                            className="su__font-14 su__text-black su__f-regular su__flex-1 su__position-relative su__mb-3"
                          >
                            {t(StaticStrings.reset_changes)}
                          </div>
                        </div>
                        <div className="su__Conform-block su__flex-vcenter su__p-3 su__justify-content-end">
                          <button
                            lang={variables.searchCallVariables.langAttr}
                            tabIndex={tabIndexes.tabIndex_0}
                            className="su__btn su__bg-gray su__bg-gray su__px-4 su__py-2 su__mr-3 su__rtlml-3 su__rtlmr-0 su__radius su__cursor"
                            onClick={() => setShowPopup(false)}
                          >
                            {t(StaticStrings.su_no)}
                          </button>
                          <button
                            lang={variables.searchCallVariables.langAttr}
                            tabIndex={tabIndexes.tabIndex_0}
                            className="su__btn su__bg-gray su__px-4 su__py-2 su__radius su__cursor"
                            onClick={clearAdvancedFilters}
                          >
                            {t(StaticStrings.su_yes)}
                          </button>
                        </div>
                      </div>
                      <div
                        className="su__overlay su__zindex-1"
                        onClick={() => setShowPopup(false)}
                      />
                    </div>
                  </Fragment>
                )}
                {/* <input lang={variables.searchCallVariables.langAttr} className="su__form-control" type="text" name="su__exactPhrase" value={advancedState.exactPhrase} onChange={(e) => advanceSearchKeyword(e.target.value, 'exactPhrase' )} aria-label={t(StaticStrings.WITHTHEEXACTPHRASE)}/> */}
                <div
                  className="su__bookmark-inner su__py-4 su__px-0"
                  role={a11y.ROLES.GROUP}
                  aria-labelledby="su__advanceLabel"
                >
                  <div className="su__w-100" id="su__advanceLabel">
                    <div className="su__col-md-12 su__mb-2 su__advance_search_padding">
                      {/* <label lang={variables.searchCallVariables.langAttr} className="su__font-12 su__d-block su__mb-1 su__f-regular">{t(StaticStrings.WITHTHEEXACTPHRASE)}</label> */}
                      <input
                        placeholder={t(StaticStrings.WITHTHEEXACTPHRASE)}
                        lang={variables.searchCallVariables.langAttr}
                        className="su__form-control"
                        type="text"
                        name="su__exactPhrase"
                        value={advancedState.exactPhrase}
                        onChange={(e) => advanceSearchKeyword(e.target.value, 'exactPhrase')}
                        aria-label={t(StaticStrings.WITHTHEEXACTPHRASE)}
                      />
                    </div>
                    <div className="su__col-md-12 su__mb-2 su__advance_search_padding">
                      {/* <label lang={variables.searchCallVariables.langAttr} className="su__font-12 su__d-block su__mb-1 su__f-regular">{t(StaticStrings.WITHONEORMOREWORDS)}</label> */}
                      <input
                        placeholder={t(StaticStrings.WITHONEORMOREWORDS)}
                        lang={variables.searchCallVariables.langAttr}
                        className="su__form-control"
                        type="text"
                        name="su__withOneOrMore"
                        value={advancedState.withOneOrMore}
                        onChange={(e) => advanceSearchKeyword(e.target.value, 'withOneOrMore')}
                        aria-label={t(StaticStrings.WITHONEORMOREWORDS)}
                      />
                    </div>
                    <div className="su__col-md-12 su__mb-2 su__advance_search_padding">
                      {/* <label lang={variables.searchCallVariables.langAttr} className="su__font-12 su__d-block su__mb-1 su__f-regular">{t(StaticStrings.WITHOUTTHEWORDS)}</label> */}
                      <input
                        placeholder={t(StaticStrings.WITHOUTTHEWORDS)}
                        lang={variables.searchCallVariables.langAttr}
                        className="su__form-control"
                        type="text"
                        name="su__withoutTheWords"
                        value={advancedState.withoutTheWords}
                        onChange={(e) => advanceSearchKeyword(e.target.value, 'withoutTheWords')}
                        aria-label={t(StaticStrings.WITHOUTTHEWORDS)}
                      />
                    </div>
                    <div className="su__col-md-12 su__mb-2 su__advance_search_padding">
                      {/* <label lang={variables.searchCallVariables.langAttr} className="su__font-12 su__d-block su__mb-1 su__f-regular">{t(StaticStrings.WILDCARDSEARCH)}</label> */}
                      <input
                        placeholder={t(StaticStrings.WILDCARDSEARCH)}
                        lang={variables.searchCallVariables.langAttr}
                        className="su__form-control"
                        type="text"
                        name="su__wildcardSearch"
                        value={advancedState.wildcardSearch}
                        onChange={(e) => advanceSearchKeyword(e.target.value, 'wildcardSearch')}
                        aria-label={t(StaticStrings.WILDCARDSEARCH)}
                      />
                    </div>
                    <div className="su__advance_group su__col-md-12 su__box-sizing">
                      <button
                        tabIndex={tabIndexes.tabIndex_0}
                        type="submit"
                        className="su__btn-block su__font-regular su__full-btn su__text-white su__mt-2 su__bg-blue-grd su__radius su__font-14 su__refine__search"
                      >
                        {t(StaticStrings.REFINESEARCH)}
                      </button>
                      {(advancedState.exactPhrase ||
                        advancedState.withOneOrMore ||
                        advancedState.withoutTheWords ||
                        advancedState.wildcardSearch) && (
                        <button
                          type="button"
                          tabIndex={tabIndexes.tabIndex_0}
                          className="su_clear_all_btn su__bg-gray su__font-italic su__text-primary su__btn-block su__font-regular su__full-btn su__mt-2 su__radius su__font-14 su__refine__search"
                          onClick={openClearFiltersModal}
                        >
                          {t(StaticStrings.CLEARALL)}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="su__overlay su__zindex-1" onClick={() => setIsModalOpen(false)}></div>
            </div>
          </Fragment>
        )}
      </div>
    );
  } catch (e) {
    console.log(`Error in Advance Search Componnent ${e}`);
    /** Handled alternate UI for Advance search on error in above component */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showPopUp = () => {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1500);
    };
    return (
      <Fragment>
        <div className="su__w-100">
          <div className="su__search_advance su__d-xs-none su__d-md-flex  su__font-12 su__radius-1 su__mr-2 su__rtlmr-0 su__rtlml-0">
            <Tooltip
              text={t(StaticStrings.advance_search)}
              position="top"
              className="position-relative"
            >
              <button
                type="button"
                onClick={() => showPopUp()}
                className="su__filters-button su__btn btn_advance-search su__radius-2 su__bg-white su__flex-hcenter su__rtlmr-0 su__rtlml-3"
              >
                <Icons
                  className="su__advance-icon su__bg-white-hover-50"
                  IconName="Advancesearch"
                  width="16"
                  widthInner="18"
                  height="16"
                  heightInner="1.928"
                  rx="0.964"
                  cx="2.785"
                  cy="2.785"
                  r="2.785"
                  transform="translate(0 0)"
                  fill="#919bb0"
                />
                <span
                  lang={variables.searchCallVariables.langAttr}
                  className="su__text-black su__font-13 su__p-1 su__rtlp-1 su__font-regular"
                >
                  {t(StaticStrings.advance_search)}
                </span>
              </button>
            </Tooltip>
          </div>
        </div>
        {isModalOpen && <UnderConstruction component={'Advance Search'} />}
      </Fragment>
    );
  }
};
export default AdvanceSearch;