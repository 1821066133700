import React from 'react';

const UnderConstruction404 = () => {
  return (
    <div>
      <h1 style={{ textAlign: 'center', color: 'rgb(98 77 220)', marginTop: '50px' }}>
        Something went wrong
      </h1>
      <div style={{ width: '250px', display: 'block', margin: 'auto ' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLa"
            x1="30"
            x2="30"
            y1="30.875"
            y2="38.891"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#6dc7ff"></stop>
            <stop offset="1" stopColor="#e6abff"></stop>
          </linearGradient>
          <path
            fill="url(#6AFvr_ZazLzZCXHhpXkvLa)"
            d="M30.001 32A3 3 0 1 0 30.001 38A3 3 0 1 0 30.001 32Z"
          ></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLb"
            x1="42.001"
            x2="42.001"
            y1="30.875"
            y2="38.891"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#6dc7ff"></stop>
            <stop offset="1" stopColor="#e6abff"></stop>
          </linearGradient>
          <path
            fill="url(#6AFvr_ZazLzZCXHhpXkvLb)"
            d="M42.001 32A3 3 0 1 0 42.001 38A3 3 0 1 0 42.001 32Z"
          ></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLc"
            x1="36"
            x2="36"
            y1="38.125"
            y2="44.701"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#6dc7ff"></stop>
            <stop offset="1" stopColor="#e6abff"></stop>
          </linearGradient>
          <path
            fill="url(#6AFvr_ZazLzZCXHhpXkvLc)"
            d="M36.001,44c-2.209,0-4-1.75-4-4h0v0c0-0.552,0.448-1,1-1h6c0.552,0,1,0.448,1,1 C40.001,42.209,38.21,44,36.001,44z"
          ></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLd"
            x1="44.868"
            x2="44.868"
            y1="4.667"
            y2="59.505"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#1a6dff"></stop>
            <stop offset="1" stopColor="#c822ff"></stop>
          </linearGradient>
          <path
            fill="url(#6AFvr_ZazLzZCXHhpXkvLd)"
            d="M39.2,16.319l-0.398,1.961c4.63,0.94,8.564,4.269,10.267,8.686l1.866-0.719 C48.989,21.198,44.492,17.395,39.2,16.319z"
          ></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLe"
            x1="32"
            x2="32"
            y1="4.667"
            y2="59.505"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#1a6dff"></stop>
            <stop offset="1" stopColor="#c822ff"></stop>
          </linearGradient>
          <path
            fill="url(#6AFvr_ZazLzZCXHhpXkvLe)"
            d="M36,12c-0.432,0-0.862,0.018-1.289,0.045c-0.103,0.007-0.206,0.017-0.309,0.025 c-0.346,0.027-0.689,0.063-1.031,0.108c-0.088,0.011-0.175,0.023-0.263,0.036c-0.411,0.059-0.819,0.129-1.224,0.214 c-0.01,0.002-0.02,0.004-0.03,0.006c-0.862,0.182-1.71,0.423-2.542,0.719c-0.089,0.031-0.177,0.065-0.265,0.098 c-0.31,0.115-0.618,0.239-0.923,0.37c-0.108,0.046-0.216,0.092-0.323,0.14c-0.074,0.033-0.149,0.062-0.222,0.096l0.004,0.009 c-0.362,0.168-0.721,0.344-1.073,0.534l-3.823-6.835C22.141,6.584,21.136,6,20.001,6s-2.14,0.584-2.687,1.563l-8.93,15.969 c-0.521,0.932-0.51,2.038,0.03,2.959C8.967,27.436,9.96,28,11.07,28H16v25c0,2.757,2.243,5,5,5s5-2.243,5-5v-0.5 c0-1.379,1.122-2.5,2.5-2.5s2.5,1.121,2.5,2.5V53c0,2.757,2.243,5,5,5s5-2.243,5-5v-0.5c0-1.379,1.122-2.5,2.5-2.5 s2.5,1.121,2.5,2.5V53c0,2.757,2.243,5,5,5s5-2.243,5-5V32C56,20.972,47.028,12,36,12z M10.138,25.479 c-0.085-0.146-0.256-0.53-0.01-0.971l8.93-15.969C19.248,8.201,19.6,8,20.001,8s0.753,0.201,0.942,0.539l8.93,15.969 c0.247,0.44,0.075,0.825-0.01,0.971C29.671,25.805,29.323,26,28.931,26H11.07C10.678,26,10.33,25.805,10.138,25.479z M54,53 c0,1.654-1.346,3-3,3s-3-1.346-3-3v-0.5c0-2.481-2.019-4.5-4.5-4.5S39,50.019,39,52.5V53c0,1.654-1.346,3-3,3s-3-1.346-3-3v-0.5 c0-2.481-2.019-4.5-4.5-4.5S24,50.019,24,52.5V53c0,1.654-1.346,3-3,3s-3-1.346-3-3V28h10.931c1.109,0,2.103-0.564,2.657-1.51 c0.54-0.921,0.551-2.027,0.03-2.959l-4.132-7.389c0.344-0.185,0.695-0.357,1.05-0.519c0.51-0.233,1.028-0.439,1.552-0.622 c0.141-0.049,0.283-0.091,0.425-0.137c0.436-0.14,0.876-0.262,1.32-0.368c0.128-0.031,0.255-0.067,0.383-0.095 c0.53-0.114,1.066-0.198,1.607-0.262c0.152-0.019,0.305-0.033,0.458-0.048C34.85,14.038,35.421,14,36,14c9.925,0,18,8.075,18,18V53z"
          ></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLf"
            x1="20"
            x2="20"
            y1="4.667"
            y2="59.505"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#1a6dff"></stop>
            <stop offset="1" stopColor="#c822ff"></stop>
          </linearGradient>
          <path fill="url(#6AFvr_ZazLzZCXHhpXkvLf)" d="M19 22H21V24H19z"></path>
          <linearGradient
            id="6AFvr_ZazLzZCXHhpXkvLg"
            x1="20"
            x2="20"
            y1="4.667"
            y2="59.505"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#1a6dff"></stop>
            <stop offset="1" stopColor="#c822ff"></stop>
          </linearGradient>
          <path fill="url(#6AFvr_ZazLzZCXHhpXkvLg)" d="M19 12H21V20H19z"></path>
        </svg>
      </div>
    </div>
  );
};

export default UnderConstruction404;
