import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { search } from '../../../redux/ducks';
import variables from '../../../redux/variables';
import { facetClickNested } from '../../../mergeFacet';
import setCookies from '../../../setCookie/setCookie';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';
import { v4 as uuid } from 'uuid';
const ChildComponent = (props) => {
  try {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [childArray] = useState(false);
    const [selectedFacet, toggleFacet] = useState(-1);
    const searchResult = useSelector((state) => state.searchResult);
    const item = props.childArray;
    let key = props.type;
    let order = props.order;
    /**
     * Call search result function if filter changes
     * @param {filter} contentName
     * @param {type} facet_type
     */
    const facetClick = (filter, type, order, level, pathNew) => {
      setCookies.setSmartFacetOff();
      facetClickNested(filter, type, order, level, pathNew, searchResult);
      variables.searchSource = 'facets';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
    };

    /**
     *
     * @param {index of selected facet} index
     */
    const openFacet = (index) => {
      item[index].open = !item[index].open;
      toggleFacet(selectedFacet === index ? -1 : index);
    };

    return (
      <Fragment>
        {item &&
          item.map((childObject, index) => (
            <li
              key={uuid()}
              className={`su__w-100 su__flex-vcente su__py-1 ${
                childArray ? 'su__nested-show' : ''
              } su__pl-custom ${item[index].open ? 'close-filter' : 'open-filter'}`}
            >
              <div className="su__w-100">
                <div className="su__w-100 su__flex-vcenter">
                  <div className={`su__w-auto ${childArray ? 'su__d-non' : ''}`}>
                    <div className="su__pl-custom">
                      {childObject.childArray && childObject.childArray.length > 0 && (
                        <div className="su__nested-arrow" onClick={() => openFacet(index)}>
                          {item[index].open ? (
                            <Fragment>
                              <i className="su__arrow-down" />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <i className="su__arrow-right" />
                            </Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="su__filter-toggle su__w-100 su__flex-vcenter su__border-color su__font-18 su__position-relative su__overflow-hide">
                    <Fragment>
                      <input
                        lang={variables.searchCallVariables.langAttr}
                        aria-label={
                          (childObject.displayName || childObject.Contentname) +
                          ' ' +
                          childObject.value +
                          ' ' +
                          t(StaticStrings.results_available)
                        }
                        id={
                          (childObject.displayName &&
                            `su__nested-${childObject.displayName.replace(/\s+/g, '')}`) ||
                          (childObject.Contentname &&
                            `su__nested-${childObject.Contentname.replace(/\s+/g, '')}`)
                        }
                        type="checkbox"
                        checked={childObject.selected === true}
                        max={childObject.level}
                        name={
                          (childObject.displayName &&
                            `su__nested-${childObject.displayName.replace(/\s+/g, '')}`) ||
                          (childObject.Contentname &&
                            `su__nested-${childObject.Contentname.replace(/\s+/g, '')}`)
                        }
                        value={
                          (childObject.displayName &&
                            `su__nested-${childObject.displayName.replace(/\s+/g, '')}`) ||
                          (childObject.Contentname &&
                            `su__nested-${childObject.Contentname.replace(/\s+/g, '')}`)
                        }
                        className={`su__toggle-input su__cursor ${
                          childObject.selected ? 'su__filter-checked' : ''
                        }`}
                        onChange={() =>
                          facetClick(childObject, key, order, childObject.level, childObject.path)
                        }
                      />
                      <label
                        className="su__filter-label su__flex-1 su__text-truncate su__font-12 su__mx-2 su__rtlmx-2 su__line-height-n  su__cursor"
                        htmlFor={
                          (childObject.displayName &&
                            `su__nested-${childObject.displayName.replace(/\s+/g, '')}`) ||
                          (childObject.Contentname &&
                            `su__nested-${childObject.Contentname.replace(/\s+/g, '')}`)
                        }
                        title={`${childObject.displayName || childObject.Contentname}`}
                        dangerouslySetInnerHTML={{
                          __html: childObject.displayName || childObject.Contentname
                        }}
                      ></label>
                      <div className="su__filter-badge su__font-12">
                        <div className="label-badge su__bg-light-gray ">{childObject.value}</div>
                      </div>
                    </Fragment>
                  </div>
                </div>
              </div>

              <Fragment>
                {' '}
                <div className={`${childObject.open === true ? 'su__d-block' : 'su__d-none'}`}>
                  <ul
                    className={`su__filter-content-row ${
                      childObject.childArray ? 'su__nested-ul su_nested_align' : ''
                    }`}
                  >
                    {childObject.childArray ? (
                      <ChildComponent
                        type={key}
                        childArray={childObject.childArray}
                        order={order}
                      />
                    ) : null}
                  </ul>
                </div>
              </Fragment>
            </li>
          ))}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in nestedIndex component', e);
    return <div></div>;
  }
};

export default ChildComponent;
