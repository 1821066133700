import React from 'react';
import { useDispatch } from 'react-redux';
import { search } from '../../../redux/ducks';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import setCookies from '../../../setCookie/setCookie';
import StaticStrings from '../../../StaticStrings';

let checkAggregations = false;
const ClearFilter = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    checkAggregations = false;
    const dispatch = useDispatch();
    let aggregations = variables.searchCallVariables.aggregations;
    let advanceSearhFacets =
      variables.searchCallVariables.exactPhrase ||
      variables.searchCallVariables.withOneOrMore ||
      variables.searchCallVariables.withoutTheWords;
    if (aggregations.length) {
      for (let i of aggregations) {
        if (i.filter) {
          checkAggregations = true;
        }
      }
    }
    /**
     * Reset the facets selected
     */
    const clearReset = () => {
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.aggregations = [];
      variables.searchCallVariables.pagingAggregation = [];
      // variables.searchCallVariables.sortby = '_score'
      variables.selectedStickyFilters = [];
      variables.allSelected = true;
      variables.activeType = 'all';
      setCookies.setSmartFacetOff();

      // to be removed searchResult aggregations selected object false
      // for (let count = 0; count < searchResult.aggregationsArray[0].values.length; count++) {
      //   searchResult.aggregationsArray[0].values[count].selected = false;
      // }
      variables.searchSource = 'clear-reset';
      variables.searchCallVariables.exactPhrase = '';
      variables.searchCallVariables.withOneOrMore = '';
      variables.searchCallVariables.withoutTheWords = '';
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.from = 0;
      dispatch(search.start(variables.searchCallVariables));
    };

    return (
      <div className="su__w-auto su__clear-filters">
        {checkAggregations || advanceSearhFacets ? (
          <button
            data-test-id={'su__clear_filters'}
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(StaticStrings.CLEARALL)}
            type="button"
            className="su__clear-all-btn su__border-none su__cursor su__bg-transparent su__font-bold su__font-12 su__mb-1 su__p-0 su__color-lblue su__loading-view"
            onClick={clearReset}
          >
            {t(StaticStrings.CLEARALL)} Filters
          </button>
        ) : null}
      </div>
    );
  } catch (e) {
    console.log('Error in clear filter component', e);
    return <div></div>;
  }
};

export default ClearFilter;
