import React, { useState, Fragment, useEffect, useRef } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import FacetPreference from 'components/custom-components/facet-preference-1737695923979/index.jsx';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { useDispatch } from 'react-redux';
import StaticStrings from 'StaticStrings';
import IconColors from 'IconColors';
import setCookies from '../../../setCookie/setCookie';
import { a11y, tabIndexes } from '../../../constants/a11y';
import { useDevice } from 'function-library/hooks';

const Settings = (props) => {
  try {
    let facetsData = props.data;
    let mergedFacet = props.mergedFacet;
    let isSmartFacets = props.isSmartFacets;
    let smartFacetsAggregation = props.smartFacetsAggregation;
    let isAllContentShow = props.allContentHide;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const [showSearchTips, setComponentVisible] = useState(false);
    const [editPageLayout, toggleEditMode] = useState(false);
    const [isAutoLearning, setIsAutoLearning] = useState(true);
    const ref = useRef(null);
    const handleHideDropdown = (e) => {
      e.key === 'Escape' &&
        (setIsComponentVisible(false), setComponentVisible(false), toggleEditMode(false));
    };

    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsComponentVisible(false);
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown);
      document.addEventListener('click', handleClickOutside, true);
      if (
        localStorage.getItem('AutoLearning') == null ||
        localStorage.getItem('AutoLearning') == undefined
      ) {
        localStorage.setItem('AutoLearning', true);
      }
      let isAutoLearning = localStorage.getItem('AutoLearning') === 'false' ? false : true;
      setIsAutoLearning(isAutoLearning);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    const onChangeAutoLearning = (e) => {
      if (!e.target.checked) {
        localStorage.setItem('AutoLearning', false);
        setCookies.setSmartFacetOff();
        setIsAutoLearning(false);
      } else {
        localStorage.setItem('AutoLearning', true);
        setIsAutoLearning(true);
      }
    };

    return (
      <Fragment>
        <div className="su__mobile-child-block su__d-md-none su__d-xs-block su__col su__searchTips su__text-center su__font-9 su__font-bold">
          <div
            className={`su__mob-search-iner ${showSearchTips ? 'su__mob-active' : ''}`}
            onClick={() => {
              setComponentVisible(!showSearchTips);
              setIsComponentVisible(!isComponentVisible);
            }}
          >
            <div className="su__mob-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><clipPath id="clip-Search_tips"><rect width="24" height="24"></rect></clipPath></defs><g id="Search_tips" data-name="Search tips" clip-path="url(#clip-Search_tips)"><g id="bulb" transform="translate(12 12)"><g id="bulb-2" data-name="bulb" transform="translate(-12 -12)"><g id="bulb-3" data-name="bulb"><rect id="Rectangle_6680" data-name="Rectangle 6680" width="24" height="24" transform="translate(24 24) rotate(180)" fill="#9aa3bf" opacity="0"></rect><path id="Path_13364" data-name="Path 13364" d="M11.945,7a4.945,4.945,0,0,0-2.967,8.9v3.956a1.978,1.978,0,0,0,1.978,1.978h1.978a1.978,1.978,0,0,0,1.978-1.978V15.9A4.945,4.945,0,0,0,11.945,7Z" transform="translate(0.055 0.188)" fill="#9aa3bf"></path><path id="Path_13365" data-name="Path 13365" d="M11.989,5.956a.989.989,0,0,0,.989-.989V2.989a.989.989,0,0,0-1.978,0V4.967A.989.989,0,0,0,11.989,5.956Z" transform="translate(0.011 -0.022)" fill="#9aa3bf"></path><path id="Path_13366" data-name="Path 13366" d="M20.967,11H18.989a.989.989,0,1,0,0,1.978h1.978a.989.989,0,1,0,0-1.978Z" transform="translate(0.066 0.011)" fill="#9aa3bf"></path><path id="Path_13367" data-name="Path 13367" d="M4.967,11H2.989a.989.989,0,0,0,0,1.978H4.967a.989.989,0,0,0,0-1.978Z" transform="translate(-0.022 0.011)" fill="#9aa3bf"></path><path id="Path_13368" data-name="Path 13368" d="M7.625,6.4,6.2,5A1,1,0,1,0,4.826,6.451L6.251,7.825A.99.99,0,1,0,7.625,6.4Z" transform="translate(0.008 0.009)" fill="#9aa3bf"></path><path id="Path_13369" data-name="Path 13369" d="M19.155,5.047a.989.989,0,0,0-1.394,0L16.337,6.4a1,1,0,0,0,1.394,1.424l1.424-1.375a.989.989,0,0,0,0-1.4Z" transform="translate(0.03 0.009)" fill="#9aa3bf"></path></g></g></g></g></svg>
            </div>
          </div>
          <div className="su-editMode su__d-flex">
            <Icons
              className="su__cursor su__d-md-block su__d-xs-none"
              IconName="editLayout"
              width="24"
              height="24"
              color="#ffffff"
            />
            <div className="su__mob-txt su__line-height-n su__active-text">{t('Tips')}</div>
          </div>
        </div>
        <div
          id="customizedSettings"
          className={`su__side-Search-tips su__rtlleft su__d-md-block su__d-xs-nonef su__position-fixed su__cursor su__zindex-2 su__d-lg-flex su__d-xs-none su__minscroller custom-border ${
            isComponentVisible ? 'su__hide-gear' : 'su__hide-gear'
          }`}
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);

          }}
        >
          {/* <span id="gear">
            <Tooltip text={t('Settings')} position="left" className="position-relative">
               <Icons className="gear-icon" IconName="settings" width="20" height="20" color="#fff" />
            </Tooltip>
          </span> */}
        </div>
        <div
          id="gear-sidebar"
          ref={ref}
          className={`sidebar su__customized__settings su__position-fixed ${
            !variables.mergeResults ||
            (variables.mergeResults && !variables.resultsInAllContentSources)
              ? 'su__side-customized-gear'
              : 'su__side-customized-gear-all-cs'
          } ${isComponentVisible ? 'su__show-gear' : 'su__show-gear'}`}
        >
          {isSmartFacets && (
            <>
              <div className="su__customized__settings-inner">
                <label className="su__customized_switch su__gear-min-width">
                  <input
                    id="auto-learning"
                    type="checkbox"
                    value={isAutoLearning}
                    checked={!!isAutoLearning}
                    onChange={(e) => onChangeAutoLearning(e)}
                  />
                  <span className="su__customized_switch_slider round"></span>
                </label>
                <label
                  htmlFor="auto-learning"
                  className="su__font-13 su__pl-2 su__rtlpl-0 su__rtlpr-2 su___show-more-summary"
                >
                  Auto Learning
                </label>
                <span className="su__mt-1 su__tooltip-gear">
                  <Tooltip
                    text={t(
                      'Turn on this option to enable Auto Learning as well as Facet Pre Selection'
                    )}
                    position="left"
                    className="position-relative"
                  >
                    <span className="su___show-more-summary su__ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                      >
                        <defs>
                          <clipPath id="clip-Info">
                            <rect width="14" height="14" />
                          </clipPath>
                        </defs>
                        <g id="Info" clipPath="url(#clip-Info)">
                          <g id="info_black_24dp_2_" data-name="info_black_24dp (2)">
                            <path
                              id="Path_10984"
                              data-name="Path 10984"
                              d="M0,0H14V14H0Z"
                              fill="none"
                            />
                            <path
                              id="Path_10985"
                              data-name="Path 10985"
                              d="M7.833,2a5.833,5.833,0,1,0,5.833,5.833A5.835,5.835,0,0,0,7.833,2Zm0,8.75a.585.585,0,0,1-.583-.583V7.833a.583.583,0,0,1,1.167,0v2.333A.585.585,0,0,1,7.833,10.75Zm.583-4.667H7.25V4.917H8.417Z"
                              transform="translate(-0.833 -0.833)"
                              fill="#b2b5b9"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </Tooltip>
                </span>
              </div>
              <hr className="su-customize-hr" />
            </>
          )}
          {(!variables.mergeResults ||
            (variables.mergeResults && !variables.resultsInAllContentSources)) && (
            <Tooltip text={t('Set preferences')} position="left" className="position-relative">
              <div
                className="su__customized__settings-inner"
                onClick={() => {
                  toggleEditMode(!editPageLayout);
                  setIsComponentVisible(!isComponentVisible);
                }}
              >
                {/* <svg className="su__gear-min-width  su___show-more-summary" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <defs>
                      <clipPath id="clip-Customize">
                        <rect width="24" height="24"/>
                      </clipPath>
                    </defs>
                    <g id="Customize" clipPath="url(#clip-Customize)">
                      <g id="edit_note_black_24dp_1_" data-name="edit_note_black_24dp (1)">
                        <rect id="Rectangle_5709" data-name="Rectangle 5709" width="24" height="24" fill="none"/>
                        <path id="Path_10983" data-name="Path 10983" d="M14,11a1,1,0,0,1-1,1H4a1,1,0,0,1,0-2h9A1,1,0,0,1,14,11ZM3,7A1,1,0,0,0,4,8h9a1,1,0,0,0,0-2H4A1,1,0,0,0,3,7Zm7,8a1,1,0,0,0-1-1H4a1,1,0,0,0,0,2H9A1,1,0,0,0,10,15Zm8.01-2.13.71-.71a1,1,0,0,1,1.41,0l.71.71a1,1,0,0,1,0,1.41l-.71.71Zm-.71.71-5.16,5.16a.483.483,0,0,0-.14.35V20.5a.5.5,0,0,0,.5.5h1.41a.469.469,0,0,0,.35-.15l5.16-5.16Z" fill="#919bb0"/>
                      </g>
                    </g>
                  </svg> */}

                <svg width="22" height="22" viewBox="0 0 22 22">
                  <defs>
                    <clipPath id="clip-Pref">
                      <rect width="22" height="22" />
                    </clipPath>
                  </defs>
                  <g id="Pref" clip-path="url(#clip-Pref)">
                    <g id="Layer_2" data-name="Layer 2" transform="translate(1 -0.32)">
                      <g id="invisible_box" data-name="invisible box">
                        <rect
                          id="Rectangle_5939"
                          data-name="Rectangle 5939"
                          width="21"
                          height="21"
                          transform="translate(0 0.32)"
                          fill="none"
                        />
                      </g>
                      <g id="icons_Q2" data-name="icons Q2" transform="translate(4 3.985)">
                        <path
                          id="Path_11382"
                          data-name="Path 11382"
                          d="M17.131,8.922l-4.7-4.732a.633.633,0,0,0-.933,0l-7.3,7.3a.667.667,0,0,0-.2.467v4.7a.667.667,0,0,0,.667.667h4.7a.667.667,0,0,0,.467-.2l7.3-7.265a.633.633,0,0,0,0-.933ZM9.1,15.987H5.333V12.221L9.5,8.089l3.733,3.766Zm5.1-5.066L10.432,7.156l1.533-1.566,3.766,3.8Z"
                          transform="translate(-4 -3.985)"
                          fill="#00c"
                        />
                      </g>
                    </g>
                  </g>
                </svg>

                <p
                  className="su__font-13 su__pl-2 su___show-more-summary"
                  style={{ display: 'none' }}
                >
                  Customized
                </p>
              </div>
            </Tooltip>
          )}
          <Tooltip text={t('Search Tips')} position="left" className="position-relative">
            <div
              className="su__customized__settings-inner"
              onClick={() => {
                setComponentVisible(!showSearchTips);
                setIsComponentVisible(!isComponentVisible);
              }}
            >
              {/* <svg
                className="su__gear-min-width  su___show-more-summary"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <defs>
                  <clipPath id="clip-Tips1">
                    <rect width="24" height="24" />
                  </clipPath>
                </defs>
                <g id="Tips1" clipPath="url(#clip-Tips1)">
                  <g id="tips_and_updates_black_24dp" transform="translate(1.816 1.909)">
                    <rect
                      id="Rectangle_5707"
                      data-name="Rectangle 5707"
                      width="20"
                      height="20"
                      transform="translate(0.183 0.183)"
                      fill="none"
                    />
                    <path
                      id="Path_10981"
                      data-name="Path 10981"
                      d="M6.167,16.971H9.561a1.7,1.7,0,1,1-3.394,0Zm-1.7-.849h6.788v-1.7H4.47Zm9.758-8.061a6.394,6.394,0,0,1-3.2,5.516H4.7a6.362,6.362,0,1,1,9.529-5.516Zm4.132-1.807L17.2,6.788l1.163.535L18.9,8.486l.535-1.163,1.163-.535L19.43,6.254,18.9,5.091ZM16.35,5.091l.8-1.748,1.748-.8-1.748-.8L16.35,0l-.8,1.748-1.748.8,1.748.8Z"
                      transform="translate(-0.227)"
                      fill="#919bb0"
                    />
                  </g>
                </g>
              </svg> */}

              <svg width="22" height="22" viewBox="0 0 22 22">
                <defs>
                  <clipPath id="clip-Tip">
                    <rect width="22" height="22" />
                  </clipPath>
                </defs>
                <g id="Tip" clip-path="url(#clip-Tip)">
                  <g id="Layer_2" data-name="Layer 2" transform="translate(9.121 2.646)">
                    <g id="invisible_box" data-name="invisible box">
                      <rect
                        id="Rectangle_5940"
                        data-name="Rectangle 5940"
                        width="20"
                        height="20"
                        transform="translate(0.354 0.354)"
                        fill="none"
                      />
                    </g>
                    <g id="Q3_icons" data-name="Q3 icons" transform="translate(-5.121 -1.138)">
                      <path
                        id="Path_11383"
                        data-name="Path 11383"
                        d="M21.894,7.651a6.771,6.771,0,0,0-4.875-5.479A6.342,6.342,0,0,0,15.466,2,6.428,6.428,0,0,0,9,8.082a6.255,6.255,0,0,0,1.855,4.659,5.181,5.181,0,0,1,1.6,3.063v3.451a.906.906,0,0,0,.345.69l1.38,1.035h2.588l1.38-1.035a.906.906,0,0,0,.345-.69V15.8a4.852,4.852,0,0,1,1.6-3.02,6.3,6.3,0,0,0,1.812-5.134Zm-5.134,9.016H14.172V15.8h2.588ZM16.2,19.255H14.733l-.561-.431v-.431h2.588v.431Zm2.675-7.722a8.24,8.24,0,0,0-1.769,2.545H13.784a7.636,7.636,0,0,0-1.726-2.545,4.53,4.53,0,0,1-1.337-3.365,4.7,4.7,0,0,1,4.745-4.443,5.349,5.349,0,0,1,1.165.129,5.042,5.042,0,0,1,3.537,3.969,4.357,4.357,0,0,1-1.294,3.71Z"
                        transform="translate(-8.992 -1.999)"
                        fill="#00c"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <p
                className="su__font-13 su__pl-2  su___show-more-summary"
                style={{ display: 'none' }}
              >
                Search Tips
              </p>
            </div>
          </Tooltip>
        </div>
        {/* {isComponentVisible && (
          <div className="su__Side-tips su__right-0 su__rtlleft su__radius-1 su__d-md-block su__d-xs-none su__position-fixed su__cursor su__loading-view su__zindex-1">
            <div className="su__search-tips su__search-tips_rtl su__radius-1 su__bg-blue-grd">
              <div className="su__tips-btn su__tips-btn_rtl su__animate-tada su_radius-left" onClick={() => { setComponentVisible(!showSearchTips); setIsComponentVisible(!isComponentVisible); }}>
                <div className="su-searchTips toggleLeft su__d-flex su__align-content-end">
                  <Tooltip text={t('Search Tips')} position="left" className="position-relative">
                    <svg className="su__margin-auto" width="30" height="30"   version="1.1" id="Capa_1"  xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 229.018 229.018" space="preserve">
                      <g>
                        <path d="M114.512,51.743c-32.669,0-59.248,26.579-59.248,59.248c0,13.37,4.384,26.003,12.672,36.527
                          c1.636,2.083,3.355,4.077,5.175,6.188l0.1,0.116c5.568,6.46,11.325,13.142,13.475,21.01c0.486,1.803,0.555,5.26,0.527,7.923v4.526
                          c0,4.142,3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5v-4.579c-0.025-3.478,0.155-6.016,0.518-7.333
                          c2.162-7.816,8.542-15.321,14.222-22.001l0.245-0.288c1.639-1.926,3.188-3.745,4.598-5.538c8.3-10.53,12.687-23.169,12.687-36.551
                          C173.754,78.322,147.178,51.743,114.512,51.743z M149.281,138.264c-1.235,1.571-2.691,3.282-4.233,5.093l-0.302,0.355
                          c-6.635,7.804-14.155,16.649-17.203,27.667c-0.431,1.564-0.91,3.904-1.032,8.402h-24.312c-0.104-4.777-0.591-7.227-1.036-8.877
                          c-3.05-11.164-10.557-19.875-16.59-26.876l-0.101-0.118c-1.705-1.977-3.315-3.845-4.746-5.665
                          c-6.19-7.86-9.462-17.285-9.462-27.254c0-24.398,19.85-44.248,44.248-44.248c24.395,0,44.242,19.85,44.242,44.248
                          C158.754,120.971,155.48,130.399,149.281,138.264z" fill="#fff"/>
                        <path d="M133.984,196.91H94.713c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5
                          S138.126,196.91,133.984,196.91z" fill="#fff"/>
                        <path d="M124.166,214.018h-19.635c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h19.635c4.142,0,7.5-3.358,7.5-7.5
                          C131.666,217.376,128.308,214.018,124.166,214.018z" fill="#fff"/>
                        <path d="M218,103.491h-25.873c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H218c4.142,0,7.5-3.358,7.5-7.5
                          C225.5,106.849,222.142,103.491,218,103.491z" fill="#fff"/>
                        <path d="M44.394,110.991c0-4.142-3.358-7.5-7.5-7.5H11.018c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h25.876
                          C41.036,118.491,44.394,115.133,44.394,110.991z" fill="#fff"/>
                        <path d="M114.51,40.881c4.142,0,7.5-3.358,7.5-7.5V7.5c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v25.881
                          C107.01,37.523,110.368,40.881,114.51,40.881z" fill="#fff"/>
                        <path d="M174.693,160.569c-2.929-2.929-7.677-2.93-10.607-0.001c-2.929,2.929-2.93,7.677-0.001,10.607l18.296,18.301
                          c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.838-0.732,5.303-2.196c2.929-2.929,2.93-7.677,0.001-10.607L174.693,160.569z" fill="#fff"/>
                        <path d="M54.317,61.408c1.465,1.465,3.384,2.198,5.304,2.198c1.919,0,3.838-0.732,5.302-2.196c2.93-2.929,2.93-7.677,0.002-10.606
                          L46.636,32.508c-2.929-2.93-7.677-2.93-10.606-0.002c-2.93,2.929-2.93,7.677-0.002,10.606L54.317,61.408z" fill="#fff"/>
                        <path d="M54.325,160.569L36.028,178.87c-2.929,2.929-2.928,7.678,0.001,10.606c1.464,1.464,3.384,2.196,5.303,2.196
                          c1.919,0,3.839-0.732,5.304-2.197l18.297-18.301c2.929-2.929,2.928-7.678-0.001-10.606C62.003,157.64,57.254,157.64,54.325,160.569
                          z" fill="#fff"/>
                        <path d="M169.393,63.605c1.919,0,3.839-0.732,5.304-2.197l18.292-18.295c2.929-2.929,2.928-7.678-0.001-10.606
                          c-2.929-2.929-7.678-2.929-10.606,0.001l-18.292,18.295c-2.929,2.929-2.928,7.678,0.001,10.606
                          C165.554,62.874,167.473,63.605,169.393,63.605z" fill="#fff"/>
                      </g>
                    </svg>
                  </Tooltip>
                </div>
              </div>
              {(!variables.mergeResults || (variables.mergeResults && !variables.resultsInAllContentSources)) && (
              <div className="su__edit-btn su__edit-btn_rtl su__animate-tada su_radius-left su__d-lg-block su__d-xs-none" onClick={() => { toggleEditMode(!editPageLayout); setIsComponentVisible(!isComponentVisible);}}>
                <div className="su-editMode su__d-flex su__align-content-end ">
                  <Tooltip text={t('Customize')} position="left" className="position-relative">
                    <Icons className="su__cursor su__d-block" IconName="editLayout" width="24" height="24" color="#ffffff" />
                  </Tooltip>
                </div>
              </div>
              )}
            </div>
          </div>
        )} */}
        {showSearchTips && (
          <React.Fragment>
            <div
              className={`searchTips-sidenav su__with-overlay su__Search-tips su__rtlleft su__rtlanimate-fadeLeft su__position-fixed su__zindex-3 su__bg-white div-right md-whiteframe-4dp su__xs-w-100 ${
                showSearchTips ? 'su__searchTips-show su__animate-fadeRight custom-sidebar-position' : ''
              }`}
            >
              <div className="su__searchTip-header su__p-3 su__bg-blue-grd su__text-white su__rtltext-right su__position-relative custom_tips_blue_bar">
                <h4 className="su__m-0" style={{display:"none"}}> {t('Search Tips')} </h4>
                <div
                  onClick={() => setComponentVisible(!showSearchTips)}
                  className="su__searchTip-icon su__position-absolute su__cursor"
                >
                  {/* <svg
                    width="30"
                    height="30"
                    viewBox="0 0 49.722 49.722"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0,0H49.722V49.722H0Z" />
                    <path
                      className="su__fill-white"
                      d="M32.117,6.034a2.063,2.063,0,0,0-2.921,0L19.065,16.144,8.935,6.013A2.066,2.066,0,0,0,6.013,8.935L16.144,19.065,6.013,29.2a2.066,2.066,0,0,0,2.921,2.921L19.065,21.987,29.2,32.117A2.066,2.066,0,0,0,32.117,29.2L21.987,19.065,32.117,8.935A2.076,2.076,0,0,0,32.117,6.034Z"
                      transform="translate(5.795 5.795)"
                    />
                  </svg> */}

<svg width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-Clossse">
      <rect width="26" height="26"/>
    </clipPath>
  </defs>
  <g id="Clossse" clip-path="url(#clip-Clossse)">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_2-2" data-name="Layer 2" transform="translate(0.027 0.027)" opacity="0.33">
        <g id="invisible_box" data-name="invisible box" transform="translate(-0.027 -0.027)">
          <rect id="Rectangle_5947" data-name="Rectangle 5947" width="26" height="26" fill="none"/>
        </g>
        <g id="icons_Q2" data-name="icons Q2" transform="translate(1.075 1.075)">
          <path id="Path_11385" data-name="Path 11385" d="M13.9,4.163A9.734,9.734,0,1,1,4.163,13.9,9.734,9.734,0,0,1,13.9,4.163M13.9,2a11.843,11.843,0,1,0,8.429,3.469A11.9,11.9,0,0,0,13.9,2Z" transform="translate(-2 -2)" fill="#231f20"/>
          <path id="Path_11386" data-name="Path 11386" d="M21.382,19.848l3.028-2.974a1.136,1.136,0,0,0,.108-1.46,1.028,1.028,0,0,0-1.622-.108l-3.028,3.028-3.028-3.028a1.028,1.028,0,0,0-1.622.108,1.136,1.136,0,0,0,.108,1.46l3.028,2.974-3.028,2.974a1.136,1.136,0,0,0-.108,1.46,1.028,1.028,0,0,0,1.622.108l3.028-3.028L22.9,24.391a1.028,1.028,0,0,0,1.622-.108,1.136,1.136,0,0,0-.108-1.46Z" transform="translate(-7.97 -7.951)" fill="#231f20"/>
        </g>
      </g>
    </g>
  </g>
</svg>

                </div>
              </div>
              <div className="su__searchTip-content su__bg-white su__overflow-auto">
                <div className="su__search-tipicons su__w-100 su__m-auto su__text-center">
                  <svg className="su__w-50 su__my-3" width="140" height="140" viewBox="0 0 140 140">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_5854" data-name="Rectangle 5854" width="35.581" height="2.917" fill="none"/>
                    </clipPath>
                    <linearGradient id="linear-gradient" x1="-6.834" y1="1.096" x2="-6.805" y2="1.096" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#444242"/>
                      <stop offset="1" stop-color="#1f1d1d"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="-6.833" y1="0.939" x2="-6.805" y2="0.939" />
                    <clipPath id="clip-path-3">
                      <rect id="Rectangle_5857" data-name="Rectangle 5857" width="130.068" height="140" fill="none"/>
                    </clipPath>
                    <clipPath id="clip-Tips__1">
                      <rect width="140" height="140"/>
                    </clipPath>
                  </defs>
                  <g id="Tips__1" data-name="Tips _1" clip-path="url(#clip-Tips__1)">
                    <g id="Group_13673" data-name="Group 13673" transform="translate(-419 -25)">
                      <g id="Group_12694" data-name="Group 12694" transform="translate(419 25)">
                        <g id="Group_12692" data-name="Group 12692">
                          <g id="Group_12685" data-name="Group 12685">
                            <g id="Group_12693" data-name="Group 12693">
                              <path id="Path_11115" data-name="Path 11115" d="M250.831,20.813a2.916,2.916,0,0,1-2.918-2.918V2.917a2.918,2.918,0,0,1,5.836,0V17.9a2.916,2.916,0,0,1-2.918,2.918" transform="translate(-181.347)" fill="#ffce00"/>
                              <path id="Path_11116" data-name="Path 11116" d="M134.361,52.005a2.911,2.911,0,0,1-2.524-1.459l-7.489-12.971a2.916,2.916,0,0,1,5.049-2.918l7.489,12.971a2.919,2.919,0,0,1-2.525,4.377" transform="translate(-90.668 -24.302)" fill="#ffce00"/>
                              <path id="Path_11117" data-name="Path 11117" d="M49.094,137.283a2.893,2.893,0,0,1-1.459-.393L34.664,129.4a2.916,2.916,0,0,1,2.918-5.049l12.971,7.489a2.917,2.917,0,0,1-1.459,5.443" transform="translate(-24.309 -90.286)" fill="#ffce00"/>
                              <path id="Path_11118" data-name="Path 11118" d="M17.9,253.729H2.918a2.918,2.918,0,0,1,0-5.836H17.9a2.918,2.918,0,0,1,0,5.836" transform="translate(0 -180.929)" fill="#ffce00"/>
                              <path id="Path_11119" data-name="Path 11119" d="M36.123,357.213a2.917,2.917,0,0,1-1.459-5.443l12.971-7.489a2.916,2.916,0,1,1,2.918,5.049L37.582,356.82a2.894,2.894,0,0,1-1.459.393" transform="translate(-24.309 -250.946)" fill="#ffce00"/>
                              <path id="Path_11120" data-name="Path 11120" d="M417.1,137.273a2.917,2.917,0,0,1-1.459-5.443l12.971-7.489a2.916,2.916,0,0,1,2.918,5.049l-12.971,7.489a2.893,2.893,0,0,1-1.459.393" transform="translate(-302.92 -90.271)" fill="#ffce00"/>
                              <path id="Path_11121" data-name="Path 11121" d="M346.834,52.019a2.92,2.92,0,0,1-2.525-4.377L351.8,34.671a2.916,2.916,0,0,1,5.049,2.918L349.359,50.56a2.912,2.912,0,0,1-2.525,1.459" transform="translate(-251.561 -24.316)" fill="#ffce00"/>
                              <path id="Path_11122" data-name="Path 11122" d="M142.583,106.428c-18.734,2.106-33.858,17.477-35.9,36.468a41.467,41.467,0,0,0,19.546,40.055,6.728,6.728,0,0,1,3.234,5.735v.029a.329.329,0,0,0,.334.336h34.913a.336.336,0,0,0,.334-.338v-.038a6.706,6.706,0,0,1,3.231-5.722,41.4,41.4,0,0,0,19.8-35.416c0-24.408-20.854-43.879-45.489-41.109" transform="translate(-77.771 -77.723)" fill="#ffce00"/>
                              <path id="Path_11123" data-name="Path 11123" d="M308.577,154.74a1.464,1.464,0,0,1-1.239-.687,30.934,30.934,0,0,0-17.052-13.193,1.459,1.459,0,1,1,.866-2.786,33.865,33.865,0,0,1,18.664,14.436,1.458,1.458,0,0,1-1.239,2.231" transform="translate(-211.571 -100.697)" fill="#fff"/>
                              <path id="Path_11124" data-name="Path 11124" d="M233.511,501.424H222.3A5.427,5.427,0,0,1,216.875,496v-.4a.4.4,0,0,1,.4-.4h21.663a.4.4,0,0,1,.4.4,5.828,5.828,0,0,1-5.828,5.828" transform="translate(-158.625 -361.424)" fill="#231f20"/>
                              <path id="Path_11125" data-name="Path 11125" d="M192.4,421V432.41a6.554,6.554,0,0,0,6.553,6.554h22.474a6.554,6.554,0,0,0,6.553-6.554V421a.34.34,0,0,0-.34-.34h-34.9a.34.34,0,0,0-.34.34" transform="translate(-140.704 -306.86)" fill="#231f20"/>
                              <path id="Path_11127" data-name="Path 11127" d="M17.9,253.729H2.918a2.918,2.918,0,0,1,0-5.836H17.9a2.918,2.918,0,0,1,0,5.836" transform="translate(119.187 -180.929)" fill="#ffce00"/>
                              <path id="Path_11128" data-name="Path 11128" d="M49.094,137.283a2.893,2.893,0,0,1-1.459-.393L34.664,129.4a2.916,2.916,0,0,1,2.918-5.049l12.971,7.489a2.917,2.917,0,0,1-1.459,5.443" transform="translate(78.058 -31.022)" fill="#ffce00"/>
                            </g>
                          </g>
                          <g id="Group_12687" data-name="Group 12687" transform="translate(51.691 117.378)">
                            <g id="Group_12686" data-name="Group 12686" clip-path="url(#clip-path)">
                              <rect id="Rectangle_5853" data-name="Rectangle 5853" width="9.454" height="35.506" transform="translate(-0.531 2.817) rotate(-79.33)" fill="url(#linear-gradient)"/>
                            </g>
                          </g>
                          <g id="Group_12689" data-name="Group 12689" transform="translate(51.691 122.954)">
                            <g id="Group_12688" data-name="Group 12688" clip-path="url(#clip-path)">
                              <rect id="Rectangle_5855" data-name="Rectangle 5855" width="9.455" height="35.506" transform="translate(-0.531 2.817) rotate(-79.33)" fill="url(#linear-gradient-2)"/>
                            </g>
                          </g>
                          <g id="Group_12691" data-name="Group 12691">
                            <g id="Group_12690" data-name="Group 12690" clip-path="url(#clip-path-3)">
                              <path id="Path_11126" data-name="Path 11126" d="M220.263,244.125a7.038,7.038,0,0,0-7.011,7.051v4.11h-8.523v-4.11a7.012,7.012,0,1,0-7.012,7.051H201.8V289.3h2.925V258.228h8.551V289.3H216.2V258.228h4.058a7.051,7.051,0,0,0,0-14.1M201.8,255.287h-4.087a4.11,4.11,0,1,1,4.087-4.11Zm18.459,0h-4.087v-4.11a4.087,4.087,0,1,1,4.087,4.11" transform="translate(-139.496 -178.202)" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                     <p
                    className="su__font-13 su__pl-2  su___show-more-summary custom_search_tips_text">
                    Search Tips
                  </p>
                </div>
                <ul className="su__searchTip-list su__m-0 su__pr-3 su__pl-5 su__rtlpr-5 su__rtlpl-3 su__font-12">
                 <li className='custom-list'>{t('Enter just a few key words related to your question or problem')}</li>
                  <li className='custom-list'>{t('Add Key words to refine your search as necessary')}</li>
                  <li className='custom-list'>{t('Do not use punctuation')}</li>
                  <li className='custom-list'>{t('Search is not case sensitive')}</li>
                  <li className='custom-list'>{t('Avoid non-descriptive filler words like "how", "the", "what", etc.')}</li>
                  <li className='custom-list'>{t('If you do not find what you are looking for the first time,reduce the number of key words you enter and try searching again.')}</li>
                   <li className="custom-list">
                    {t('For the wild card search, use # *"Search String".')}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="su__bg-overlay su__zindex-1"
              onClick={() => setComponentVisible(!showSearchTips)}
            ></div>
          </React.Fragment>
        )}
        {editPageLayout ? (
          <FacetPreference
            isAllContentShow={isAllContentShow}
            data={facetsData}
            toggleEditMode={toggleEditMode}
            smartFacetsAggregation={smartFacetsAggregation}
            mergedFacet={mergedFacet}
          />
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in facet-preference component', e);
    return <div></div>;
  }
};

export default Settings;