import { useEffect, useState } from 'react';

/** set initial values of device type flags to false */
const INITIAL_DEVICES = {
  isDeviceMobile: false,
  isDeviceIpad: false,
  isDeviceDesktop: false,
  isTouchScreen: 'ontouchstart' in window
};

/**
 * useDevice - A custom hook to manage device information based on innerWidth.
 * Detects if the device is mobile, iPad, or desktop.
 * @returns {Object} devices - Object containing device type flags
 */
const useDevice = () => {
  const [devices, setDevices] = useState(INITIAL_DEVICES);
  const [debouncedWidth, setDebouncedWidth] = useState(window.innerWidth);
  let memoryRef;

  /**
   * handleResize - sets debouncedWidth ( debounced window.innerWidth )
   * @param {void}
   * @returns {void}
   */
  const handleResize = () => {
    clearTimeout(memoryRef);
    memoryRef = setTimeout(() => {
      setDebouncedWidth(window.innerWidth);
    }, 300);
  };

  /**
   * Effect for adding / removing resize listener on first mount
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Effect for setting device based on debouncedWidth ( debounced window.innerWidth )
   */
  useEffect(() => {
    setDevices((v) => ({
      ...v,
      isDeviceMobile: 320 <= window.innerWidth && window.innerWidth <= 767,
      isDeviceIpad: 768 <= window.innerWidth && window.innerWidth <= 1024,
      isDeviceDesktop: window.innerWidth >= 1025
    }));
  }, [debouncedWidth]);

  return devices;
};

export default useDevice;
