import React, { useState, Fragment, useEffect } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import UnderConstruction from 'components/feature-components/under-consruction/index.jsx';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import { a11y, useFocusTrap, tabIndexes, A11Y_IDS } from '../../../constants/a11y';
import { getWildcardToggle } from 'function-library/commonFunctions';

const SaveBookmarks = (props) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bookmarkListing, setList] = useState(
      JSON.parse(
        window.localStorage.getItem('bookmark_searches_' + variables.searchCallVariables.uid) ||
          '[]'
      )
    );
    const [name, setBookmarkName] = useState('');
    const [DuplicateBookmark, setDuplicateBookmark] = useState('');
    const [focusTrap] = useFocusTrap();

    useEffect(() => {
      focusTrap(isModalOpen);
    }, [isModalOpen]);

    const handleHideDropdown = (e) => {
      e.key === 'Escape' && setIsModalOpen(false);
    };
    useEffect(() => {
      if (props.list.length) {
        setList(props.list);
      }
      document.addEventListener('keydown', handleHideDropdown);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
      };
    });

    let bookmarks = (name) => {
      setBookmarkName(name.target.value.trim());
    };

    /**
     * Save Bookmark in local Storage
     */
    const saveBookmark = (e) => {
      variables.searchCallVariables.pagingAggregation = [];
      e.preventDefault();
      let list = JSON.parse(
        localStorage.getItem('bookmark_searches_' + variables.searchCallVariables.uid) || '[]'
      );
      let isBookmarkDuplicate = false;
      list.length &&
        list.forEach((listItem) => {
          if (listItem.title == name) isBookmarkDuplicate = true;
        });
      if (isBookmarkDuplicate) {
        setDuplicateBookmark(StaticStrings.BOOKMARKALREADYEXISTS);
      } else {
        list.push({
          title: name,
          href: variables.searchCallVariables,
          toggle: getWildcardToggle(),
          bookmark: false
        });
        localStorage.setItem(
          'bookmark_searches_' + variables.searchCallVariables.uid,
          JSON.stringify(list)
        );
        props.callBack(list);
        setBookmarkName('');
        setIsModalOpen(false);
        setList(list);
        setDuplicateBookmark('');
      }
    };

    const closeSaveBookmark = () => {
      setIsModalOpen(false);
      setBookmarkName('');
    };

    return (
      <Fragment>
        <div className="su__mobile-child-block su__d-md-none su__d-xs-block su__col su__mob-search su__text-center su__font-9 su__font-bold">
          <button
            type="button"
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(StaticStrings.SAVEBOOKMARK)}
            role={a11y.ROLES.BTN}
            tabIndex={tabIndexes.tabIndex_0}
            className={`su__mob-search-iner a11y-btn p-0 ${isModalOpen ? 'su__mob-active' : ''}`}
            onClick={() => {
              setIsModalOpen(true);
              setDuplicateBookmark('');
            }}
          >
            <div className="su__mob-icon">
              <Icons
                tabIndex={tabIndexes.tabIndex_0}
                className="su__bokmark-icon su__bg-white-hover-50"
                IconName="Bookmark"
                width="24"
                height="24"
                color="#919BAF" // Custom color
              />
            </div>
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__mob-txt su__line-height-n su__active-text su__font-9 su__font-bold"
            >
              {t(StaticStrings.SAVEBOOKMARK)}
            </div>
          </button>
        </div>
        <div className="su__mobile-child-block su__d-md-block su__d-xs-none">
          <Tooltip
            text={t(StaticStrings.SAVEBOOKMARK)}
            position="top"
            className="position-relative"
          >
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              tabIndex={tabIndexes.tabIndex_0}
              role={a11y.ROLES.BTN}
              aria-label={t(StaticStrings.SAVEBOOKMARK)}
              className="su__save-bookmarks su__flex-hcenter a11y-btn p-0"
              onClick={() => {
                setIsModalOpen(true);
                setDuplicateBookmark('');
              }}
            >
              <span className="su__bookmark-list su__mt-1 su__ml-2 su__rtlmr-2 su__rtlml-0 su__cursor su__outline-none su__bg-white-hover-50 su__radius-50 bookmark-list-dots su__loading-view">
                <Icons
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__bokmark-icon su__bg-white-hover-50"
                  IconName="Bookmark"
                  width="24"
                  height="24"
                  color="#919BAF" // Custom color
                />
              </span>
            </button>
          </Tooltip>
        </div>
        {isModalOpen && (
          <Fragment>
            <div
              className="su__flex-hcenter su__position-fixed su__trbl-0 su__zindex-3 su__px-sm-1"
              id={A11Y_IDS.trap}
              role={a11y.ROLES.DIALOG}
              aria-labelledby="dialog1_label"
              aria-modal="true"
            >
              <div className="su__modal-inner su__radius su__animate-fadown su__zindex-1 su__shadow-lg su__bg-white su__radius-1">
                <div className="su__bookmark-popup-title su__px-3 su__py-2 su__flex-vcenter su__bg-blue-grd">
                  
                <svg className="su__savebokmark-icon su__p-2 su__mr-2 su__rtlmr-0 su__rtlml-2 su__radius-50" title="Bookmarks" width="40" height="40" viewBox="0 0 24 24">
                    <defs>
                      <clipPath id="clip-Saved_Book_mark-77">
                        <rect width="24" height="24"></rect>
                      </clipPath>
                    </defs>
                    <g id="Saved_Book_mark" data-name="Saved Book mark" clip-path="url(#clip-Saved_Book_mark-77)">
                      <g id="Group_1350" data-name="Group 1350" transform="translate(3977 10079)">
                        <g id="Group_1345" data-name="Group 1345" transform="translate(-5280 -10120)">
                          <g id="round-collections_bookmark-24px" transform="translate(1303 41)">
                            <path id="Path_1546" data-name="Path 1546" d="M0,0H24V24H0Z" fill="none"></path>
                            <path id="Path_1547" data-name="Path 1547" d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#fff"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <h2
                    id={'dialog1_label'}
                    lang={variables.searchCallVariables.langAttr}
                    className="su__font-16 su__text-white su__f-regular su__flex-1 su__position-relative su__my-0 su__font_save_bookmark"
                  >
                    {t(StaticStrings.SAVEASBOOKMARK)}
                  </h2>
                  <button
                    type="button"
                    lang={variables.searchCallVariables.langAttr}
                    className="a11y-btn p-0 su__close_bookmark"
                    aria-label={t(StaticStrings.close_popup)}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    onClick={() => closeSaveBookmark()}
                  >
                    {' '}
                    <Icons
                      className="su__close-icon su__cursor"
                      IconName="Close"
                      width="18"
                      height="18"
                      color={IconColors.SaveBookmarksCrossIcon}
                    />
                  </button>
                </div>
                <form onSubmit={saveBookmark}>
                  <div className="su__bookmark-inner su__p-3">
                    <div className="su__w-100">
                      <div className="su__w-100 su__text-center">
                        <svg class="su__savebokmark-icon" width="71" height="75" viewBox="0 0 24 24">
                          <path d="M6,21a1,1,0,0,1-1-1V5.33A2.28,2.28,0,0,1,7.2,3h9.6A2.28,2.28,0,0,1,19,5.33V20a1,1,0,0,1-1.5.86l-5.67-3.21L6.5,20.85A1,1,0,0,1,6,21Z" fill="#d3d6de"></path>
                        </svg>
                      </div>
                      <input
                        lang={variables.searchCallVariables.langAttr}
                        aria-label={t(StaticStrings.TITLEFORTHISSEARCH)}
                        id="Save-bookmark"
                        className="su__bookmark-input su__form-control su__mb-3 su__font-14 su__mw-100"
                        type="text"
                        onChange={bookmarks}
                        placeholder="Title for this search" // Custom placeholder
                      />
                      {DuplicateBookmark ? (
                        <h5 role={a11y.ROLES.ALERT} className="su__duplicate-bookmark">
                          {DuplicateBookmark}
                        </h5>
                      ) : null}
                      <button
                        type="button"
                        lang={variables.searchCallVariables.langAttr}
                        className="su__btn su__bg-blue-grd su__py-3 su__w-100 su__radius su__bookmark-save"
                        onClick={saveBookmark}
                        disabled={!name.length || bookmarkListing.length > 19}
                      >
                        {t(StaticStrings.su__save)}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="su__overlay su__zindex-1" onClick={() => closeSaveBookmark()} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Save bookmark Component', e);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showPopUp = () => {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1500);
    };
    return (
      <Fragment>
        <div className="su__d-md-none su__d-xs-block su__col su__mob-search su__text-center su__font-9 su__font-bold">
          <div
            className={`su__mob-search-iner ${isModalOpen ? 'su__mob-active' : ''}`}
            onClick={() => showPopUp()}
          >
            <div className="su__mob-icon">
              <Icons
                className="su__bokmark-icon su__bg-white-hover-50"
                IconName="Bookmark"
                width="24"
                height="24"
                color={IconColors.AdvanceSearchIconFill}
                IconClass="su__active-path"
              />
            </div>
            <div
              lang={variables.searchCallVariables.langAttr}
              className="su__mob-txt su__line-height-n su__active-text"
            >
              {t(StaticStrings.add_bookmark)}
            </div>
          </div>
        </div>
        <div className="su__d-md-block su__d-xs-none">
          <div className="su__save-bookmarks su__flex-hcenter" onClick={() => showPopUp()}>
            <span className="su__bookmark-list su__mt-1 su__ml-2 su__rtlmr-2 su__rtlml-0 su__cursor su__outline-none su__bg-white-hover-50 su__radius-50 bookmark-list-dots su__loading-view">
              <Tooltip
                text={t(StaticStrings.add_bookmark)}
                position="top"
                className="position-relative"
              >
                <Icons
                  className="su__bookmark-icon su__bg-white-hover-50"
                  IconName="Bookmark"
                  width="24"
                  height="24"
                  color={IconColors.AdvanceSearchIconFill}
                />
              </Tooltip>
            </span>
          </div>
        </div>
        {isModalOpen && <UnderConstruction component="Save Bookmarks" />}
      </Fragment>
    );
  }
};
export default SaveBookmarks;