/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import KnowledgeGraph from 'components/feature-components/knowledge-graph/index.jsx';
import ContentAlternateUI from 'components/feature-components/under-consruction/dummy-content-UI.jsx';
import ResultsInAllContentsGridView from 'components/section-components/results-In-all-content-source-grid-view/index.jsx';
import { tabIndexes } from 'constants/a11y';
import FeaturedSnippetAnimation from 'components/feature-components/feature-snippets-animation/index';

const Content = (props) => {
  try {
    const {
      isListView,
      searchResult,
      isDeviceMobile,
      viewedResult,
      gptActive,
      savedResultBookmarkClicked,
      savedResultLimitReachedFunc,
      bookmarkListIconFunc
    } = props;
    const [activeAttachIndex, setActiveAttachIndex] = useState(null);
    const [selectedFacet, toggleFacet] = useState(-1);
    const [listGridViewClasses, setFinalClasses] = useState('');
    const isMobile = window.innerWidth < 768;
    const showItems = Boolean(isMobile);
    const [summaryData, toggleSummary] = useState(
      Array(variables.searchCallVariables.resultsPerPage).fill(false)
    );
    const dispatch = useDispatch();
    let metaStatus = Boolean(
      searchResult.metaGraph && Object.keys(searchResult.metaGraph).length > 0
    );
    let relatedTilesStatus = Boolean(
      searchResult.relatedTiles && searchResult.relatedTiles.length > 0
    );
    let mergeSourcesTypeIndex;

    if (
      searchResult.searchClientSettings &&
      searchResult.searchClientSettings.mergeSourcesTypeIndex == undefined
    ) {
      mergeSourcesTypeIndex = mergeSourcesTypeIndex == undefined;
    } else {
      mergeSourcesTypeIndex =
        searchResult.searchClientSettings &&
        searchResult.searchClientSettings.mergeSourcesTypeIndex;
    }

    if (searchResult.result && searchResult.result.hits.length !== 0) {
      variables.previousSearches = searchResult.result.hits;
      variables.mergeResults = searchResult.searchClientSettings.mergeSources;
      let filterAggregations = variables.searchCallVariables.aggregations;

      filterAggregations = filterAggregations?.filter(function (filter) {
        return filter.filter;
      });

      if (!filterAggregations.length && variables.mergeResults) {
        variables.resultsInAllContentSources = true;
      } else {
        variables.resultsInAllContentSources = false;
      }
      for (let i of searchResult.result.hits) {
        for (let j of i.metadata) {
          if (j.key == 'Tag' && !Array.isArray(j.value[0])) {
            j.value[0] = j.value[0].split(',');
          }
        }
      }
    }

    useEffect(() => {
      // Define classes for content view
      const contentViewClasses =
        gptActive ||
        (searchResult.metaGraph &&
          Object.keys(searchResult.metaGraph).length !== 0 &&
          ((variables.mergeResults && !variables.resultsInAllContentSources) ||
            !variables.mergeResults))
          ? 'su__content-view'
          : '';

      // Define classes for list or grid view
      let listViewClasses = 'su__grid-content';

      // Define classes for grid content
      const gridContentClasses = isListView ? '' : 'su__grid-content';

      // Define classes for list view with specific conditions
      const listViewSpecificClasses =
        isListView && !showItems ? 'su__listed-item' : 'su__grid-content';

      // Determine final classes based on conditions
      const computedFinalClasses =
        (showItems && !variables.mergeResults) ||
        (variables.mergeResults && variables.resultsInAllContentSources)
          ? `${contentViewClasses} su__mt-1 su__mb-3 su__my-sm-0 su__flex-wrap su__sm-fullwidth list ${listViewClasses} ${gridContentClasses}`
          : `${contentViewClasses} su__my-1 su__flex-wrap su__sm-fullwidth list ${listViewSpecificClasses}`;

      setFinalClasses(computedFinalClasses);
    }, [showItems, window.innerWidth, variables, gptActive, searchResult, isListView]);

    const linkOpened = (result, rank) => {
      variables.visitedtitle = result['highlight']['TitleToDisplayString'][0];
      variables.visitedUrl = result['href'];
      variables.visiteRank = rank;
      if (
        (variables.searchCallVariables.searchString.trim() !== '' ||
          variables.searchCallVariables.exactPhrase.trim() !== '' ||
          (variables.searchClientType == 16 &&
            window.caseSubject != '' &&
            variables.searchCallVariables.searchString == '')) &&
        !variables.searchCallVariables.suCaseCreate
      )
        gza('conversion', {
          index: result['sourceName'],
          type: result['objName'],
          id: result['_id'],
          rank: rank,
          convUrl: result['href'],
          convSub: result['highlight']['TitleToDisplayString'][0],
          pageSize: variables.searchCallVariables.pageSize,
          page_no: variables.searchCallVariables.pageNo,
          sc_analytics_fields: result['trackAnalytics']
        });
      else if (
        variables.searchAnalyticsObject !== null &&
        variables.searchAnalyticsObject.searchString
      ) {
        variables.searchCallVariables = variables.searchAnalyticsObject.searchString;
        gza('search', variables.searchAnalyticsObject, function () {
          gza('conversion', {
            index: result['sourceName'],
            type: result['objName'],
            id: result['_id'],
            rank: rank,
            convUrl: result['href'],
            convSub: result['highlight']['TitleToDisplayString'][0],
            pageSize: variables.searchCallVariables.pageSize,
            page_no: variables.searchCallVariables.pageNo,
            sc_analytics_fields: result['trackAnalytics']
          });
        });
      } else if (variables.searchCallVariables.suCaseCreate) {
        let searchString = variables.searchCallVariables.searchString;
        if (!variables.searchCallVariables.isConversion) {
          variables.searchCallVariables.isConversion = true;
          const dym = variables.dymClicked ? variables.previousDymSearchString : undefined;
          gza('search', {
            searchString: searchString || variables.searchCallVariables.exactPhrase,
            result_count: searchResult.result.total,
            page_no: variables.searchCallVariables.pageNo,
            uid: variables.searchCallVariables.uid,
            isFreshSearch: [
              'searchbox',
              'advance-search',
              'listBookmark',
              'similar-search',
              'recent-search'
            ].includes(variables.searchSource),
            filter: variables.searchCallVariables.aggregations,
            exactPhrase: '',
            withOneOrMore: '',
            withoutTheWords: '',
            dym: dym,
            responseTime: variables.searchResposeTimer,
            conversion: [
              {
                es_id:
                  result['sourceName'] +
                  '/' +
                  result['objName'] +
                  '/' +
                  encodeURIComponent(result['_id']),
                rank: rank,
                url: result['href'],
                subject: result['highlight']['TitleToDisplayString'][0],
                sc_analytics_fields: result['trackAnalytics']
              }
            ]
          });
        } else {
          gza('conversion', {
            index: result['sourceName'],
            type: result['objName'],
            id: result['_id'],
            rank: rank,
            convUrl: result['href'],
            convSub: result['highlight']['TitleToDisplayString'][0],
            pageSize: variables.searchCallVariables.pageSize,
            page_no: variables.searchCallVariables.pageNo,
            sc_analytics_fields: result['trackAnalytics']
          });
        }
      }
      if (result['autotuned']) {
        gza('autoTuneConversion', {
          index: result['sourceName'],
          type: result['objName'],
          id: result['_id'],
          rank: rank,
          convUrl: result['href'],
          convSub: result['highlight']['TitleToDisplayString'][0]
        });
      }
    };
    const getContentResults = (e) => {
      let contentName = '';
      let type;
      contentName = e.currentTarget.classList[0];
      variables.allSelected = false;
      if (
        mergeSourcesTypeIndex ||
        (!mergeSourcesTypeIndex && searchResult.aggregationsArray[0].key != '')
      ) {
        type = searchResult.aggregationsArray[0].key;
      } else {
        type = searchResult.aggregationsArray[1].key;
      }
      variables.searchCallVariables.aggregations.push({ type: type, filter: [contentName] });
      variables.searchSource = 'facets';
      variables.activeType = variables.searchCallVariables.aggregations[0].filter[0];
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
    };

    /**
     *
     * @param {index of selected facet} index
     */
    const openFacet = (index) => {
      toggleFacet(selectedFacet === index ? -1 : index);
    };
    /** on show more / show less button click */
    const openSummary = (index) => {
      summaryData[index] = !summaryData[index];
      toggleSummary([...summaryData]);
    };
    useEffect(() => {
      toggleSummary(Array(variables.searchCallVariables.resultsPerPage).fill(false));
    }, [searchResult]);
    /**
     *
     * @param {index of selected facet} index
     */

    return (
      <div
        className={`su__w-100 su__knowledgeGraph-show su__d-md-flex${
          searchResult.metaGraph &&
          Object.keys(searchResult.metaGraph).length !== 0 &&
          ((variables.mergeResults && !variables.resultsInAllContentSources) ||
            !variables.mergeResults)
            ? 'su__knowledgeGraph-show su__d-md-flex'
            : ''
        }`}
      >
        <div
          tabIndex={tabIndexes.tabIndex_minus_1}
          className="su__acc-hidden"
          aria-live="assertive"
          aria-atomic="true"
        >
          {isListView ? 'View updated to list view' : 'View updated to grid view'}
        </div>
        <div className={listGridViewClasses} id="resultsSection">
          {searchResult.result &&
            React.Children.toArray(
              searchResult.result.hits.map((item, index) => (
                <div
                  key={`${item?.Id || item?._id}-${index}`}
                  className="su__list-items su__bg-white su__sm-shadow su__radius-1 su__position-relative su__mb-3 su__p-3 su__content_tile_padding su__flex-column"
                >
                  {variables.mergeResults && variables.resultsInAllContentSources ? (
                    <ResultsInAllContentsGridView
                      item={item}
                      getContentResults={getContentResults}
                      linkOpened={linkOpened}
                      mergeSourcesTypeIndex={mergeSourcesTypeIndex}
                    />
                  ) : null}
                  {(variables.mergeResults && !variables.resultsInAllContentSources) ||
                  !variables.mergeResults ? (
                    <Fragment>
                      {React.Children.map(props.children, (child) => {
                        if (!child) return false;
                        return React.cloneElement(
                          child,
                          {
                            item,
                            isListView,
                            index,
                            showAttachToTicket: index === activeAttachIndex,
                            activeAttachIndex,
                            setActiveAttachIndex,
                            searchResult,
                            linkOpened,
                            summaryData,
                            openSummary,
                            isDeviceMobile,
                            selectedFacet,
                            openFacet,
                            child,
                            viewedResult,
                            savedResultBookmarkClicked,
                            savedResultLimitReachedFunc,
                            bookmarkListIconFunc
                          },
                          null
                        );
                      })}
                    </Fragment>
                  ) : null}
                </div>
              ))
            )}
        </div>

        <div className="su__d-flex su__flex-column su__knowledgeGraph-block">
          {!isDeviceMobile && gptActive && <FeaturedSnippetAnimation />}
          {((metaStatus || relatedTilesStatus) && !variables.mergeResults) ||
          (variables.mergeResults &&
            !variables.resultsInAllContentSources &&
            (metaStatus || relatedTilesStatus)) ? (
            <KnowledgeGraph />
          ) : null}
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in Content Tile componet', e);
    return (
      /** Handle Error cases */
      <Fragment>
        <ContentAlternateUI />
        <ContentAlternateUI />
        <ContentAlternateUI />
        <ContentAlternateUI />
        <ContentAlternateUI />
      </Fragment>
    );
  }
};

export default Content;
