/* eslint-disable no-self-assign */
import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';

const AutoCompleteResultMetadata = (props) => {
  try {
    let { item } = props;
    return (
      <Fragment>
        {item.autosuggestData
          .filter(
            (autosuggestItem) =>
              autosuggestItem.key !== 'Title' &&
              autosuggestItem.value &&
              autosuggestItem.value.toString().length !== 0
          )
          .reverse()
          .map((autosuggestItem) => {
            return (
              <div
                key={uuid()}
                className={`su__suggestion-desc su__align_flex su__line-height-n su__font-11 su__color-lgray su__suggest${autosuggestItem.key
                  .split(' ')
                  .join('')}`}
              >
                <span className="su__color-gray su__f-bold">{autosuggestItem.key} : </span>
                <span
                  className="su__description-truncate"
                  dangerouslySetInnerHTML={{ __html: autosuggestItem.value }}
                ></span>
              </div>
            );
          })}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in AutoCompleteResultMetadata component', e);
    return <div></div>;
  }
};

export default React.memo(AutoCompleteResultMetadata);
