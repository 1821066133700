/* global gza */
import React, { useState, useEffect, useRef } from 'react';
import { search, autocomplete, recommendations } from '../../../redux/ducks';
import { useDispatch, useSelector } from 'react-redux';
import AutoSuggestion from 'components/custom-components/auto-suggest-search-app-1737695923968/index.jsx';
// Removed the old import

import variables from '../../../redux/variables';
import Icons from '../../../assets/svg-icon/svg';
import useComponentVisible from '../../../event-handler/event';
import { useTranslation } from 'react-i18next';
import utilityMethods from '../../../redux/utilities/utility-methods';
import UnderConstruction404 from 'components/feature-components/under-consruction/error404.jsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconColors from '../../../IconColors';

import StaticStrings from '../../../StaticStrings';
import { A11Y_IDS, a11y, tabIndexes } from '../../../constants/a11y';
// autosuggestion end

const Search = (props) => {
  try {
    let urlOpensInNewTab = props.urlOpensInNewTab;
    let isAdvanceSearchCallTriggered = props.isAdvanceSearchCall;
    let setAdvanceSearchCallTriggered = props.setIsAdvanceSearchCall;

    window.onpopstate = () => {
      variables.onpopstate = true;
      utilityMethods.importQueryParamsToVariables();
      variables.searchSource = 'searchbox';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      if (searchResult.searchClientSettings?.recommendations) {
        dispatch(recommendations.recommendationsStart(variables.searchCallVariables));
      }
      inputValue.current.value = variables.searchCallVariables.searchString;
    };

    let searchResult = useSelector((state) => state.searchResult);
    let autocompleteResult = useSelector((state) => state.autocomplete);

    let instantSearch = searchResult.searchClientSettings?.autoCompleteInstant == 1 ? true : false;
    const { t } = useTranslation();
    const inputValue = useRef(null);
    const [showIcons, setShowIcons] = useState(false);
    const [prevIdCall, setPrevIdCall] = useState();
    const [isComponentShow, setIsComponentShow] = useState();
    const [changeSearchDataOnArrowKey, setChangeSearchDataOnArrowKey] = useState(
      Array(variables.searchCallVariables.resultsPerPage)
        .fill(null)
        .map((_, i) => i)
    );
    const [previousAction, setPreviousAction] = useState('');
    /** it includes data from recent seach , autosuggestiona and hits */
    let [tempData, setTempData] = useState([]);
    useEffect(() => {
      let tempDataInner = [];
      autocompleteResult &&
        autocompleteResult.recentSearchHistory &&
        autocompleteResult.recentSearchHistory.length != 0 &&
        tempDataInner.push(...autocompleteResult.recentSearchHistory);
      autocompleteResult &&
        autocompleteResult.result &&
        tempDataInner.push(...autocompleteResult.result.hits);
      variables.autocompleteCallVariables.searchString &&
        tempDataInner.push({
          title: variables.autocompleteCallVariables.searchString,
          type: 'userTypedData'
        });
      if (autocompleteResult && autocompleteResult.searchClientSettings && tempDataInner.length) {
        setChangeSearchDataOnArrowKey(
          Array(Number(tempDataInner.length - 1))
            .fill(null)
            .map((_, i) => i)
        );
      }
      setTempData(tempDataInner);
    }, [autocompleteResult]);

    /**
     * dispatches the search action.
     * @param {args received from the debounce method} text
     */

    const dispatchSearchAction = (event) => {
      /**on first load , intialing the states */
      let tempDataInner = [];
      autocompleteResult &&
        autocompleteResult.recentSearchHistory &&
        autocompleteResult.recentSearchHistory.length != 0 &&
        tempDataInner.push(...autocompleteResult.recentSearchHistory);
      autocompleteResult &&
        autocompleteResult.result &&
        tempDataInner.push(...autocompleteResult.result.hits);
      variables.autocompleteCallVariables.searchString &&
        tempDataInner.push({
          title: variables.autocompleteCallVariables.searchString,
          type: 'userTypedData'
        });
      if (tempDataInner && tempDataInner.length != 0) {
        setChangeSearchDataOnArrowKey(
          Array(Number(tempDataInner.length - 1))
            .fill(null)
            .map((_, i) => i)
        );
      }
      setTempData(tempDataInner);
      setPreviousAction('');

      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      // control,esc,shift, spacebar, 4 arrow key ,Alt
      // no search hit on these keys
      if (
        ![17, 27, 16, 32, 37, 38, 39, 40, 18].includes(event.keyCode) &&
        !event.ctrlKey &&
        !event.altKey
      ) {
        if (event.currentTarget.value) {
          setShowIcons(true);
        } else {
          setShowIcons(false);
        }

        if (instantSearch) {
          if (event.keyCode !== 13) {
            clearTimeout(prevIdCall);
            variables.searchSource = 'instantSearch';
            searchCall(event);
          }
        } else {
          setIsComponentShow(true);
          if (event.keyCode === 13 && !isAdvanceSearchCallTriggered) {
            if (tempData[changeSearchDataOnArrowKey[0]]?.href && previousAction) {
              inputValue.current.value = tempData[tempData.length - 1].title;
              window.open(tempData[changeSearchDataOnArrowKey[0]].href);
              resultOpenNewTab(
                tempData[changeSearchDataOnArrowKey[0]],
                changeSearchDataOnArrowKey[0]
              );
            } else {
              variables.searchSource = 'searchbox';
              searchCall(event);
            }
            setIsComponentShow(false);
          } else {
            setAdvanceSearchCallTriggered(false);
            autocompleteResult.result = null;
            variables.autocompleteCallVariables.searchString = event.currentTarget.value;
            variables.autocompleteCallVariables.aggregations =
              variables.searchCallVariables.aggregations;
            if (event.currentTarget.value !== '') {
              event.persist();
              let hasProp = Object.prototype.hasOwnProperty.call(event.currentTarget, 'value');
              clearTimeout(prevIdCall);
              setPrevIdCall(
                setTimeout(() => {
                  autoComplete(event, hasProp);
                }, 1000)
              );
            } else {
              setIsComponentShow(false);
            }
            if (event.currentTarget.value !== '' && event.keyCode === 116) {
              document.getElementById('search-box-search').value = '';
            }
          }
        }
      } else if ([40].includes(event.keyCode)) {
        // down
        autocompleteResult && autocompleteResult.result?.hits.length && keyPressed('down');
      } else if ([38].includes(event.keyCode)) {
        // up
        autocompleteResult && autocompleteResult.result?.hits.length && keyPressed('up');
      } else if ([27].includes(event.keyCode)) {
        setIsComponentShow(false);
      }
    };

    const resultOpenNewTab = (item, index) => {
      gza('search', {
        searchString: variables.autocompleteCallVariables.searchString,
        result_count: autocompleteResult.result.hits.length,
        page_no: 0,
        uid: variables.autocompleteCallVariables.uid,
        filter: [],
        conversion: [
          {
            rank: index + 1,
            url: item['href'],
            subject: item['highlight']['TitleToDisplayString'][0] || '',
            es_id:
              item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
            sc_analytics_fields: item['trackAnalytics']
          }
        ]
      });
    };

    let keyPressed = (action) => {
      let data = changeSearchDataOnArrowKey;
      if (action == 'down' && previousAction) {
        data.push(data.shift());
      } else if (action == 'up' && previousAction) {
        data.unshift(data.pop());
      }
      if (
        tempData[data[0]] &&
        tempData[data[0]].type &&
        ['userTypedData', 'recentSearch', 'autoSuggestion'].includes(tempData[data[0]].type)
      ) {
        inputValue.current.value = tempData[data[0]].title;
      } else {
        inputValue.current.value =
          tempData[data[0]].highlight.TitleToDisplayString[0] || tempData[data[0]].href;
      }
      tempData[data[0]].type != 'userTypedData' &&
        document.getElementsByClassName('su__suggestions-list')[data[0]].focus();
      document.getElementById('search-box-search').focus();
      setPreviousAction(action);
      setChangeSearchDataOnArrowKey([...data]);
    };

    const autoComplete = (event, hasProp) => {
      if (hasProp) {
        dispatch(autocomplete.start(variables.autocompleteCallVariables));
      }
    };

    const searchCall = (e) => {
      variables.searchCallVariables.searchString = e.currentTarget.value;
      variables.autocompleteCallVariables.smartFacetsClicked = false;
      variables.searchSource = 'searchbox';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      inputValue.current.value = variables.searchCallVariables.searchString;
      if (searchResult?.searchClientSettings?.recommendations) {
        dispatch(recommendations.recommendationsStart(variables.searchCallVariables));
      }
    };

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

    const dispatch = useDispatch();
    useEffect(() => {
      inputValue.current.focus();
      inputValue.current.value = variables.searchCallVariables.searchString;
    }, [variables.searchCallVariables.searchString]);

    const searchClick = () => {
      setIsComponentVisible(false);
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.autocompleteCallVariables.smartFacetsClicked = false;
      variables.searchCallVariables.searchString =
        document.getElementById('search-box-search').value;
      variables.searchSource = 'searchbox';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      if (searchResult.searchClientSettings?.recommendations) {
        dispatch(recommendations.recommendationsStart(variables.searchCallVariables));
      }
    };
    /**
     * clear input box Value.
     * @param { On click clear input box Value}
     */
    const searchCallEventFromAutocomplete = () => {
      setShowIcons(true);
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.autocompleteCallVariables.smartFacetsClicked = false;
      variables.searchSource = 'searchbox';
      variables.searchCallVariables.searchString =
        document.getElementById('search-box-search').value;
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      let restValue = document.getElementById('search-box-search').value;
      if (restValue === '') {
        setShowIcons(false);
      }
    };

    const ClearIcon = () => {
      let restValue = (document.getElementById('search-box-search').value = '');
      setIsComponentShow(false);
      if (restValue === '') {
        setShowIcons(false);
        variables.searchCallVariables.searchString = '';
        variables.searchSource = 'clear-icon';
        variables.searchCallVariables.from = 0;
        variables.searchCallVariables.pageNo = 1;
        variables.searchCallVariables.pagingAggregation = [];
        if (variables.searchClientType != 16) {
          dispatch(search.start(variables.searchCallVariables));
        }
        if (searchResult.searchClientSettings?.recommendations) {
          dispatch(recommendations.recommendationsStart(variables.searchCallVariables));
        }
      }
      variables.searchAnalyticsObject = {
        searchString: searchResult.searchQuery,
        result_count: searchResult.result.hits.length,
        page_no: variables.searchCallVariables.pageNo,
        uid: variables.searchCallVariables.uid,
        filter: variables.searchCallVariables.aggregations,
        exactPhrase: variables.searchCallVariables.exactPhrase,
        withOneOrMore: variables.searchCallVariables.withOneOrMore,
        withoutTheWords: variables.searchCallVariables.withoutTheWords,
        dym: undefined
      };
    };

    return (
      <section
        className={`su__w-100 su__py-4 su__search_section su__bg-blue-grd ${
          variables.searchClientType == 31 ? 'su__chrome-extension' : ''
        }`}
      >
        <a
          id={A11Y_IDS.skipToMainContent}
          lang={variables.searchCallVariables.langAttr}
          href="#su__skiplink"
          aria-label={t(StaticStrings.skip_to_main_content)}
          tabIndex={tabIndexes.tabIndex_0}
          className="su__skip-link"
        >
          {t(StaticStrings.skip_to_main_content)}
        </a>
        <button
          type="button"
          id="hit-me"
          className="su__d-none"
          onClick={searchCallEventFromAutocomplete}
          value={instantSearch}
        >
          {StaticStrings.SEARCHCALL}
        </button>
        <div className="su__container su__searchBox-header">
          <form
            autoComplete="off"
            id="searchForm"
            className="su__search-forms su__m-0 su__px-3"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div ref={ref} className="su__form-block su__w-100 su__position-relative">
              <div className="su__radius-2 su__d-flex su__position-relative">
                <input
                  lang={variables.searchCallVariables.langAttr}
                  ref={inputValue}
                  id="search-box-search"
                  className="su__input-search su__w-100 su__su__font-14 su__text-black su__border-none su__radius-2"
                  type="input"
                  placeholder={t(StaticStrings.search_here)}
                  onKeyUp={(e) => dispatchSearchAction(e)}
                  aria-label={t(StaticStrings.search_here)}
                  onChange={(e) => (variables.searchCallVariables.searchString = e.target.value)}
                />
                {showIcons || variables.searchCallVariables.searchString ? (
                  <button
                    lang={variables.searchCallVariables.langAttr}
                    type="button"
                    tabIndex={tabIndexes.tabIndex_0}
                    aria-label={t(StaticStrings.Clear)}
                    className="su__input-close su__mr-3 su__animate-zoom su__position-absolute su__cursor a11y-btn p-0"
                    onClick={ClearIcon}
                  >
                    {/* <Icons IconName="Close" width="12" height="12" color={IconColors.SearchCrossIcon}/> */}
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <defs>
                            <clipPath id="clip-Close">
                                <rect width="24" height="24"></rect>
                            </clipPath>
                        </defs>
                        <g id="Close" clipPath="url(#clip-Close)">
                            <g id="close-outline" transform="translate(12 12)">
                                <g id="close-outline-2" data-name="close-outline" transform="translate(-12 -12)">
                                    <g id="close-2" data-name="close">
                                        <rect id="Rectangle_6581" data-name="Rectangle 6581" width="24" height="24"
                                            transform="translate(24 24) rotate(180)" opacity="0"></rect>
                                        <path id="Path_13354" fill='#757575' data-name="Path 13354"
                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,1,0,1.42,1.42L12,13.41l4.29,4.3a1,1,0,1,0,1.42-1.42Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                  </button>
                ) : null}
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  aria-label={t(StaticStrings.search)}
                  className="su__btn su__search_btn su__animate-zoom su__flex-vcenter su__position-absolute su__zindex su__bg-transparent su__rtlleft"
                  onClick={searchClick}
                >
                  <Icons IconName="Search" width="24" height="24" color={IconColors.SearchIcon} />
                </button>
              </div>
              {!autocompleteResult.result && variables.autocompleteCallVariables.searchString ? (
                <div className="su__linear-loader">
                  <LinearProgress />
                </div>
              ) : (
                <div className="su__w-100">
                  {isComponentVisible &&
                    autocompleteResult &&
                    autocompleteResult.result &&
                    autocompleteResult.result.hits &&
                    autocompleteResult.result.hits.length != 0 &&
                    (instantSearch || (!instantSearch && !isComponentShow) ? null : (
                      <AutoSuggestion
                        data={inputValue.current.value}
                        dataType={
                          tempData[changeSearchDataOnArrowKey[0]] &&
                          tempData[changeSearchDataOnArrowKey[0]].type
                            ? 'recentSearch'
                            : 'autosuggest'
                        }
                        component="searchbox"
                        urlOpensInNewTab={urlOpensInNewTab}
                      />
                    ))}
                </div>
              )}
            </div>
          </form>
          {variables.searchClientType == 31 ? (
            <div className="su__profile-section">
              <Tooltip
                text={t(window.su_utm || 'Guest')}
                position="bottom"
                className="position-relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" />
                </svg>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </section>
    );
  } catch (e) {
    /**Handle error in Search-box component */
    console.log('Error in Search-box component', e);
    variables.hasError = true;
    /** show 404 svg */
    return (
      <div>
        {' '}
        <UnderConstruction404 />{' '}
      </div>
    );
  }
};

export default Search;