import React, { Fragment } from 'react';
import variables from '../../../redux/variables';
const SourceLabel = (props) => {
  try {
    let { item } = props;
    return (
      <Fragment>
        {!variables.toggleDisplayKeys[5].hideEye &&
          variables.searchCallVariables.showContentTag && (
            <span
              title={item.contentTag}
              className="su__ribbon-title su__bg-blue su__font-12 su__font-bold su__px-2 su__rtlmr-1 su__ml-1 su__rtlml-0 su__d-inline-block su__radius-3 su_source_label_font"
            >
              {' '}
              {item.contentTag}
            </span>
          )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in SourceLabel component', e);
    return <div></div>;
  }
};

export default React.memo(SourceLabel);
