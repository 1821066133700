/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import variables from '../../../redux/variables';
const AttachToTicketIcon = (props) => {
  try {
    let { item, index, showAttachToTicket, setActiveAttachIndex, isListView } = props;
    const attachToTicketIconRef = useRef(null);
    const attachToTicketRef = useRef(null);
    const [Ticket, isTicket] = useState(false);
    const outsideClickHandler = (event) => {
      if (attachToTicketRef.current && attachToTicketIconRef.current) {
        if (
          !attachToTicketRef.current.contains(event.target) &&
          !attachToTicketIconRef.current.contains(event.target)
        ) {
          setActiveAttachIndex(null);
          isTicket((Ticket) => Ticket === false);
        }
      }
    };

    useEffect(() => {
      document.removeEventListener('click', outsideClickHandler);
      document.addEventListener('click', outsideClickHandler);
      return () => document.removeEventListener('click', outsideClickHandler);
    }, []);

    const openTicket = (item, id) => {
      setActiveAttachIndex(id);
      isTicket(!Ticket);
    };

    const clickToAttachTicket = (result) => {
      client.get('ticket').then(function (data) {
        client.get('currentUser').then(function (user) {
          let agentEmail = user.currentUser.email;
          client
            .invoke('ticket.comment.appendText', 'Hi. Check out this article: ' + result.href)
            .then(function () {});
          gza('attachToCaseComment', {
            searchString: variables.searchCallVariables.searchString,
            id: result['_id'],
            url: result.href,
            t: result.highlight.TitleToDisplayString,
            subject: data.ticket.subject,
            caseNumber: data.ticket.id,
            author: agentEmail,
            index: result.sourceName,
            type: result.objName
          });
        });
      });
      isTicket(false);
    };

    return (
      <>
        <div className="su__attachTicket su__ml-2">
          <span
            ref={attachToTicketIconRef}
            className="su__attachIcon"
            onClick={() => {
              openTicket(item, index);
            }}
          >
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <defs>
                <clipPath id="clip-Send">
                  <rect width="20" height="20" />
                </clipPath>
              </defs>
              <g id="Send" clipPath="url(#clip-Send)">
                <path
                  id="Path_2121"
                  data-name="Path 2121"
                  d="M3.5,1.5H18.42A2.08,2.08,0,0,1,20.5,3.58V18.5a2,2,0,0,1-2,2H3.5a2,2,0,0,1-2-2V3.5a2,2,0,0,1,2-2Z"
                  transform="translate(-1 -1)"
                  fill="none"
                  stroke="#dddbda"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
                <g id="_24px_13_" data-name="24px (13)" transform="translate(3 3)">
                  <path
                    id="Path_2110"
                    data-name="Path 2110"
                    d="M2.005,11,13,7,2.005,3,2,6.111,9.857,7,2,7.889Z"
                    fill="#706e6b"
                  />
                  <path id="Path_2111" data-name="Path 2111" d="M0,0H14V14H0Z" fill="none" />
                </g>
              </g>
            </svg>
          </span>
          <span>
            {showAttachToTicket && Ticket && (
              <div
                ref={attachToTicketRef}
                className={`su__ticketIcon ${isListView ? 'su__right-0' : ''}`}
                onClick={() => {
                  clickToAttachTicket(item);
                }}
              >
                <ul className="su__ticket">
                  <li className="su-other-elements-section su-attach-case su_ticket_attach">
                    <a className="su-case-attach su__cursor">Attach To Ticket</a>
                  </li>
                </ul>
              </div>
            )}
          </span>
        </div>
      </>
    );
  } catch (e) {
    console.log('Error in SourceLabel component', e);
    return <div></div>;
  }
};

export default React.memo(AttachToTicketIcon);
