import React from 'react';
import IconColors from '../../../IconColors';
const Solved = (props) => {
  try {
    let { item } = props;
    return (
      item.solved != 'Unsolved' && (
        <span className="su__solved su__whitespace-no su__font-12 su__px-1 su__rtlmr-1 su__ml-1 su__rtlml-0 su__d-inline-block">
          <span className="su__solved-val su__d-inline-block">
            <svg
              className="ft-green-tick"
              height="100"
              width="100"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="su__circle"
                fill={IconColors.SolvedsvgFill}
                cx="24"
                cy="24"
                r="22"
              />
              <path
                className="su__tick"
                fill="none"
                stroke="#FFF"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M14 27l5.917 4.917L34 17"
              />
            </svg>
          </span>
        </span>
      )
    );
  } catch (e) {
    console.log('Error in Solved component', e);
    return <div></div>;
  }
};

export default React.memo(Solved);
