import React, { Fragment, useState } from 'react';
// import SaveBookmark from 'components/feature-components/save-bookmarks/index.jsx';
import SaveBookmark from 'components/custom-components/save-bookmarks-1737695923997/index.jsx';
// import ListBookmark from 'components/feature-components/list-bookmarks/index.jsx';
import ListBookmark from 'components/custom-components/list-bookmarks-1737695923984/index.jsx';
import { array, bool, func } from 'prop-types';

const Bookmarks = ({
  getArrAggregation,
  resultBookmarkClickedFunc,
  limitReached,
  bookmarkListIconActive
}) => {
  const [bookmark, setBookmark] = useState([]);
  const [list, setList] = useState([]);

  const savedResultBookmark = () => {
    resultBookmarkClickedFunc();
  };

  return (
    <Fragment>
      <SaveBookmark callBack={setBookmark} list={list} />
      <ListBookmark
        bookmarkListIconActive={bookmarkListIconActive}
        limitReached={limitReached}
        savedResultBookmark={savedResultBookmark}
        getArrAggregation={getArrAggregation}
        bookmark={bookmark}
        callBack={setList}
      />
    </Fragment>
  );
};

Bookmarks.propTypes = {
  getArrAggregation: array,
  resultBookmarkClickedFunc: func,
  limitReached: bool,
  bookmarkListIconActive: bool
};

Bookmarks.defaultProps = {
  getArrAggregation: [],
  resultBookmarkClickedFunc: () => {},
  limitReached: false,
  bookmarkListIconActive: false
};

export default Bookmarks;