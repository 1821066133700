import React, { useState, useRef, useEffect } from 'react';
import variables from '../../../redux/variables';
import { useSelector } from 'react-redux';
import { any, func, string } from 'prop-types';

const SandboxGptFeedback = ({ searchSuggest, gptResponse, setGptResponse }) => {
  const [selectedOptions, setSelectedOptions] = useState('');
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState('');
  const [showHideWigetValue, setShowHideWigetValue] = useState(true);
  const [thanksModalValue, setThanksValue] = useState(false);
  const inputRef = useRef(null);
  const [showHideSandboxFeedback, setShowHideSandboxFeedback] = useState(false);

  const gptContext = useSelector(
    (state) =>
      state.searchResult &&
      state.searchResult.searchClientSettings &&
      state.searchResult.searchClientSettings.gptConfig &&
      state.searchResult.searchClientSettings.gptConfig.gptContext
  );

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleOptionClick = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleFeedbackOptionClick = (event) => {
    setSelectedFeedbackOptions(event.target.value);
  };

  const submitSandboxFeedback = () => {
    setThanksValue(true);
    let queryPassed = {};
    queryPassed.uuid = variables.searchCallVariables.uid;
    if (
      variables.searchClientType == 6 ||
      variables.searchClientType == 11 ||
      variables.searchClientType == 12 ||
      variables.searchClientType == 16 ||
      variables.searchClientType == 19 ||
      variables.searchClientType == 25 ||
      variables.searchClientType == 26 ||
      variables.searchClientType == 28
    ) {
      queryPassed.accessToken = variables.searchCallVariables.accessToken;
    }
    if (
      variables.searchClientType == 11 ||
      variables.searchClientType == 12 ||
      variables.searchClientType == 16
    ) {
      queryPassed.email = variables.searchCallVariables.email;
    }
    queryPassed.performance = selectedOptions;
    queryPassed.citationRelevance = selectedFeedbackOptions;
    queryPassed.responseIssue = selectedRadioValue;
    queryPassed.ExpectedResult =
      inputRef.current.value.length > 500
        ? inputRef.current.value.substring(0, 500)
        : inputRef.current.value;
    queryPassed.searchString = variables.searchCallVariables.searchString;
    queryPassed.gptContext = gptContext;
    queryPassed.gptResponse = gptResponse;
    let url;
    let instanceName = variables.searchClientProps.instanceName;
    let searchEndpoint = '/resources/gptfeedback/add-feedback';
    url = `${instanceName}${searchEndpoint}`;

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      credentials: 'include',
      body: JSON.stringify(queryPassed)
    };

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        console.log('successfully submitted the feeback.');
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setSelectedOptions('');
    setSelectedFeedbackOptions('');
    setSelectedRadioValue('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setTimeout(() => {
      setShowHideWigetValue(false);
    }, 300);

    setTimeout(() => {
      setThanksValue(false);
    }, 1000);
    setGptResponse('');
  };

  const cancelSandboxFeedback = () => {
    setShowHideWigetValue(false);
  };

  useEffect(()=>{
    setSelectedOptions('');
    setSelectedFeedbackOptions('');
    setSelectedRadioValue('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  },[gptResponse]);

  useEffect(() => {
    setShowHideWigetValue(true);
    if (
      window.scConfiguration &&
      window.scConfiguration?.instanceName?.toLowerCase() == 'sandbox'
    ) {
      setShowHideSandboxFeedback(true);
    } else {
      setShowHideSandboxFeedback(false);
    }
  }, [searchSuggest]);

  return (
    <>
      {showHideSandboxFeedback && (
        <div>
          {thanksModalValue && (
            <div>
              <div className="su__feedshow-center su__bg-white  su__feedback-modal su__zindex-2">
                <div className="su__feedback-row su__text-center su__p-4">
                  <div className="su__svg-ok">
                    <svg
                      className="ft-green-tick su__animate-zoom su__mr-2"
                      height="100"
                      width="100"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle className="su__circle" fill="#5bb543" cx="24" cy="24" r="22" />
                      <path
                        className="su__tick"
                        fill="none"
                        stroke="#FFF"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M14 27l5.917 4.917L34 17"
                      />
                    </svg>
                  </div>
                  <div className="su__feed-title su__font-14 su__text-black su__word-break">
                    thanks for your feedback
                  </div>
                </div>
              </div>
              <div className="su__zindex-1 su__overlay "></div>
            </div>
          )}
          {showHideWigetValue && (
            <div className="su__w-100 su__font-12 su__bg-white su__p-4 su__my-4 su__sm-shadow su__radius-1">
              <div className="su__text-center su__font-16 su__font-bold">
                SearchUnifyGPT Feedback
              </div>
              <div className="su__d-flex su__mt-4">
                <div className="su__w-45">How relevant was the generated response?</div>
                <div className="su__w-55 su__d-flex su__align-items-start ">
                  <input
                    type="radio"
                    checked={selectedOptions === 'Relevant'}
                    name="relevantResponseData"
                    value="Relevant"
                    className="su__mr-1 su__radius-1 su__border-none  su__cursor  su__bg-blue"
                    onChange={handleOptionClick}
                  />{' '}
                  <label htmlFor="Relevant" className="su__mr-3">
                    Relevant
                  </label>
                  <input
                    type="radio"
                    checked={selectedOptions === 'Irrelevant'}
                    name="relevantResponseData"
                    value="Irrelevant"
                    className="su__mr-1 su__radius-1 su__border-none  su__cursor  su__bg-blue "
                    onChange={handleOptionClick}
                  />
                  <label htmlFor="Irrelevant" className="su__mr-3">
                    Irrelevant
                  </label>
                  <input
                    type="radio"
                    checked={selectedOptions === 'Misleading'}
                    name="relevantResponseData"
                    value="Misleading"
                    className="su__mr-1 su__radius-1 su__border-none  su__cursor  su__bg-blue "
                    onChange={handleOptionClick}
                  />
                  <label htmlFor="Misleading" className="su__mr-3">
                    Misleading
                  </label>
                  <input
                    type="radio"
                    checked={selectedOptions === 'No response generated'}
                    name="relevantResponseData"
                    value="No response generated"
                    className="su__mr-1 su__radius-1 su__border-none  su__cursor su__bg-blue "
                    onChange={handleOptionClick}
                  />
                  <label htmlFor="No response generated">No response generated</label>
                </div>
              </div>
              <div className="su__d-flex su__mt-4">
                <div className="su__w-45">Issue with the response - </div>
                <div className="su__w-55 ">
                  <span className="su__d-flex  su__align-items-start ">
                    <input
                      className="su__cursor su__mr-2"
                      checked={selectedRadioValue === 'Generated response was relevant but top 5 search results were irrelevant'}
                      type="radio"
                      name="issueWithResponse"
                      value="Generated response was relevant but top 5 search results were irrelevant"
                      onChange={handleRadioChange}
                    />{' '}
                    <label htmlFor="one">
                      Generated response was relevant but top 5 search results were irrelevant
                    </label>
                  </span>
                  <span className="su__d-flex su__mt-2  su__align-items-start">
                    <input
                      className="su__cursor su__mr-2"
                      checked={selectedRadioValue === 'Both generated response and top 5 search results were irrelevant'}
                      type="radio"
                      name="issueWithResponse"
                      value="Both generated response and top 5 search results were irrelevant"
                      onChange={handleRadioChange}
                    />{' '}
                    <label htmlFor="two">
                      Both generated response and top 5 search results were irrelevant
                    </label>
                  </span>
                  <span className="su__d-flex su__mt-2 su__align-items-start">
                    <input
                      className="su__cursor su__mr-2"
                      checked={selectedRadioValue === 'Generated response was not perfect but close to expectation'}
                      type="radio"
                      name="issueWithResponse"
                      value="Generated response was not perfect but close to expectation"
                      onChange={handleRadioChange}
                    />{' '}
                    <label htmlFor="three">
                      Generated response was not perfect but close to expectation
                    </label>
                  </span>
                  <span className="su__d-flex su__mt-2 su__align-items-start">
                    <input
                      className="su__cursor su__mr-2"
                      checked={selectedRadioValue === 'None of the above'}
                      type="radio"
                      name="issueWithResponse"
                      value="None of the above"
                      onChange={handleRadioChange}
                    />{' '}
                    <label htmlFor="four">None of the above</label>
                  </span>
                </div>
              </div>
              <div className="su__d-flex su__mt-4">
                <div className="su__w-45">Please share the expected document/response</div>
                <div className="su__w-55 ">
                  <input
                    className="su__w-100 su__border-t su__search-facet-input su__p-0"
                    type="text"
                    placeholder="Enter expected response or paste document link"
                    name="documentLink"
                    ref={inputRef}
                    maxLength="500"
                  />
                </div>
              </div>
              <div className="su__d-flex su__mt-4">
                <div className="su__w-45">Citations/reference links feedback</div>
                <div className="su__w-55 su__d-flex  su__align-items-start">
                  <input
                    type="radio"
                    checked={selectedFeedbackOptions === 'Relevant'}
                    name="expectedDocumentResponse"
                    value="Relevant"
                    className="su__mr-2 su__radius-1 su__border-none su__px-2 su__py-1 su__cursor  su__bg-blue"
                    onChange={handleFeedbackOptionClick}
                  />
                  <label htmlFor="Relevant" className="su__mr-3">
                    Relevant
                  </label>
                  <input
                    type="radio"
                    checked={selectedFeedbackOptions === 'Irrelevant'}
                    name="expectedDocumentResponse"
                    value="Irrelevant"
                    className="su__mr-2 su__radius-1 su__border-none su__px-2 su__py-1 su__cursor  su__bg-blue"
                    onChange={handleFeedbackOptionClick}
                  />
                  <label htmlFor="Irrelevant" className="su__mr-3">
                    Irrelevant
                  </label>
                  <input
                    type="radio"
                    checked={selectedFeedbackOptions === 'Some of them were relevant'}
                    name="expectedDocumentResponse"
                    value="Some of them were relevant"
                    className="su__mr-2 su__radius-1 su__border-none su__px-2 su__py-1 su__cursor  su__bg-blue "
                    onChange={handleFeedbackOptionClick}
                  />
                  <label htmlFor="Some of them were relevant" className="su__mr-3">
                    Some of them were relevant
                  </label>
                </div>
              </div>
              <div className="su__text-center">
                <button
                  onClick={submitSandboxFeedback}
                  className=" su__mt-4 su__bg-black-50 su__text-white su__font-13  su__radius-1 su__border-none su__px-3 su__py-2 su__cursor su__d-inline-block"
                >
                  Submit feedback
                </button>
                <button
                  onClick={cancelSandboxFeedback}
                  className=" su__mt-4 su__ml-2 su__bg-black-50 su__text-white su__font-13  su__radius-1 su__border-none su__px-3 su__py-2 su__cursor su__d-inline-block"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

SandboxGptFeedback.propTypes = {
  searchSuggest: any,
  gptResponse: string,
  setGptResponse: func
};

SandboxGptFeedback.defaultProps = {
  searchSuggest: {},
  gptResponse: '',
  setGptResponse: () => {}
};

export default SandboxGptFeedback;
