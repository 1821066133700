import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import StaticStrings from 'StaticStrings';

const SaveUnsaveText = ({ saved, setSaved, showText, item, savedResultBookmarkClicked }) => {
  const { t } = useTranslation();

  const [savedResultsKey] = useState(
    localStorage.getItem('savedResult_' + variables.searchCallVariables.uid) || '[]'
  );
  const savedResultsKey1 = 'savedResult_' + variables.searchCallVariables.uid;

  useEffect(() => {
    const savedResults = JSON.parse(localStorage.getItem(savedResultsKey1) || '[]');
    setSaved(savedResults.some((result) => result.href === item.href));
  }, [item.href, savedResultsKey1, savedResultsKey, savedResultBookmarkClicked]);

  try {
    return (
      <Fragment>
        <div>
          {showText ? (
            <div className="su__resultSaved su__resultSavedArabic">
              <div className="su__flex-vcenter">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14px"
                  height="14px"
                  viewBox="0 0 14.763 14.763"
                >
                  <g
                    id="Icon_ionic-ios-checkmark-circle-outline"
                    data-name="Icon ionic-ios-checkmark-circle-outline"
                    transform="translate(-3.375 -3.375)"
                  >
                    <path
                      id="Path_13618"
                      data-name="Path 13618"
                      d="M18.036,12.891l-.625-.642a.134.134,0,0,0-.1-.043h0a.129.129,0,0,0-.1.043l-4.33,4.362-1.576-1.576a.137.137,0,0,0-.2,0l-.632.632a.141.141,0,0,0,0,.2l1.987,1.987a.629.629,0,0,0,.415.2.659.659,0,0,0,.412-.2h0l4.745-4.77A.152.152,0,0,0,18.036,12.891Z"
                      transform="translate(-3.496 -4.374)"
                      fill="#fff"
                    />
                    <path
                      id="Path_13619"
                      data-name="Path 13619"
                      d="M10.757,4.369a6.385,6.385,0,1,1-4.518,1.87,6.346,6.346,0,0,1,4.518-1.87m0-.994a7.382,7.382,0,1,0,7.382,7.382,7.38,7.38,0,0,0-7.382-7.382Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>
              <div
                lang={variables.searchCallVariables.langAttr}
                aria-hidden="true"
                aria-label={t(saved ? StaticStrings.Result_Saved : StaticStrings.Result_Unsaved)}
                className="su__ml-1"
              >
                {' '}
              </div>
              <span
                lang={variables.searchCallVariables.langAttr}
                id={'invalidEmailText'}
                aria-live="polite"
                aria-atomic="true"
              >
                {saved ? t(StaticStrings.Result_Saved) : t(StaticStrings.Result_Unsaved)}
              </span>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  } catch (e) {
    console.log('Error in SourceLabel component', e);
    return <div></div>;
  }
};

export default SaveUnsaveText;
