import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import StaticStrings from 'StaticStrings';
import { a11y, tabIndexes, A11Y_IDS, focusTrap } from '../../../constants/a11y';
const ResultsPerPage = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const modalRef = useRef(null);
    const [showResultsPerPage, setIsModalOpen] = useState(false);
    let searchSuggest = useSelector((state) => state.searchResult);
    /** Update Results per page */
    let [advancedState, setAdvancedState] = useState({
      resultsPerPage: variables.searchCallVariables.resultsPerPage
    });
    const setResultsPerPage = (resultsPerPage) => {
      setIsModalOpen(false);
      setAdvancedState({ resultsPerPage: resultsPerPage });
      variables.searchCallVariables.resultsPerPage = resultsPerPage;
      variables.searchCallVariables.pageSize = resultsPerPage;
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.searchSource = 'pagination';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      let focus =
        document.querySelector(`[su-container]`) ||
        document.querySelector(`[ng-app = "searchUnifyApp"]`);
      window.scroll({
        top: focus.offsetTop,
        behavior: 'smooth'
      });
    };

    useEffect(() => {
      setIsModalOpen(false);
      setAdvancedState({ resultsPerPage: variables.searchCallVariables.resultsPerPage });
    }, [searchSuggest]);

    const dispatch = useDispatch();

    useEffect(() => {
      focusTrap(showResultsPerPage);
    }, [showResultsPerPage]);

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });
    const handleHideDropdown = (e) => {
      e.key === 'Escape' && setIsModalOpen(false);
    };

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };

    return (
      <Fragment>
        {searchSuggest && searchSuggest.result ? (
          <div
            className="su__d-inline-block su__position-relative su__align_resultPerPage"
            ref={modalRef}
          >
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={`${t(StaticStrings.results_perpage)} ${advancedState.resultsPerPage}`}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              onClick={() => setIsModalOpen(!showResultsPerPage)}
              className="su__d-inline-block resultsPerPage su__cursor su__radius-1 su__mr-3  su__font-12 su__f-regular su__pr-2 su__pl-2 su__pt-1 su__pb-1"
            >
              <span
                lang={variables.searchCallVariables.langAttr}
                className="su__text-black su__pr-1"
              >
                {' '}
                {t(StaticStrings.results_perpage)}{' '}
              </span>
              <span className="su__pr-2">{advancedState.resultsPerPage}</span>
              <i className="su__arrow-down"></i>
            </button>
            {showResultsPerPage && (
              <div
                id={A11Y_IDS.trap}
                className="resultsPerPage pageSize su__bg-white su__position-absolute su__d-inline-block su__font-12 su__radius-1 su__f-regular su__pr-2 su__pl-2 su__pt-1 su__pb-1"
              >
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.results_perpage)} 10`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor a11y-btn d-block p-0 su__font-12 su__f-regular"
                  onClick={() => setResultsPerPage(10)}
                  value="10"
                >
                  10
                </button>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.results_perpage)} 20`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor a11y-btn d-block p-0 su__font-12 su__f-regular"
                  onClick={() => setResultsPerPage(20)}
                  value="20"
                >
                  20
                </button>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.results_perpage)} 30`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor a11y-btn d-block p-0 su__font-12 su__f-regular"
                  onClick={() => setResultsPerPage(30)}
                  value="30"
                >
                  30
                </button>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.results_perpage)} 40`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor a11y-btn d-block p-0 su__font-12 su__f-regular"
                  onClick={() => setResultsPerPage(40)}
                  value="40"
                >
                  40
                </button>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  aria-label={`${t(StaticStrings.results_perpage)} 50`}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__cursor a11y-btn d-block p-0 su__font-12 su__f-regular"
                  onClick={() => setResultsPerPage(50)}
                  value="50"
                >
                  50
                </button>
              </div>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in navigation paginaition component', e);
    return <div></div>;
  }
};

export default ResultsPerPage;
