import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import { useTranslation } from 'react-i18next';
import { mergeFilters, mergeFilterClicked } from '../../../mergeFacet';
import Menu from '@material-ui/core/Menu';
import setCookies from '../../../setCookie/setCookie';
import StaticStrings from '../../../StaticStrings';
import { a11y, tabIndexes } from '../../../constants/a11y';

const TopFacets = () => {
  try {
    const { t } = useTranslation();
    const searchResult = useSelector((state) => state.searchResult);
    const dispatch = useDispatch();
    const [showSlider, setshowSlider] = useState(false);

    let parsed_array =
      searchResult && searchResult.result && searchResult.merged_facets
        ? JSON.parse(searchResult.merged_facets)
        : [];
    parsed_array.length &&
      parsed_array.forEach(function (o) {
        mergeFilters(o, searchResult.aggregationsArray);
      });

    /**
     * Get content results based on tab selection.
     * @param {tab name} tab
     */
    const getContentResults = (contentName, label) => {
      setCookies.setSmartFacetOff();
      variables.searchCallVariables.pagingAggregation = [];
      for (let element of searchResult.aggregationsArray[0].values) {
        element.selected = false;

        // Custom Change to select Relevance in case of Tab change
        variables.searchCallVariables.sortby = "_score";

        if (contentName == 'All Content') {
          variables.allSelected = true;
        } else {
          variables.allSelected = false;
          if (element.displayName == contentName) {
            element.selected = true;
          }
        }
      }

      if (label === 'all') contentName = 'all';
      variables.searchCallVariables.aggregations = [];
      if (contentName !== 'all') {
        let arr = [];
        if (contentName.indexOf('merged_') > -1) {
          let filterChecked = true;
          mergeFilterClicked(
            contentName,
            arr,
            searchResult.aggregationsArray[0].values,
            filterChecked
          );
        } else {
          arr.push(contentName);
        }
        variables.searchCallVariables.aggregations.push({ type: label, filter: arr });
        variables.searchCallVariables.versionResults = false;
      } else {
        variables.searchCallVariables.aggregations = [];
        variables.searchCallVariables.versionResults = true;
      }

      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.searchSource = 'content-source-tabs';
      variables.activeType = contentName;
      dispatch(search.start(variables.searchCallVariables));
    };

    const isPropsLoaded = useRef(true);

    useEffect(() => {
      if (!isPropsLoaded.current) {
        const { result, aggregationsArray } = searchResult;
        const hasEmptyKeyAndLabel =
          result && aggregationsArray[0]?.key === '' && aggregationsArray[0]?.label === '';
        if (hasEmptyKeyAndLabel && variables.searchCallVariables.aggregations.length) {
          variables.searchCallVariables.aggregations =
            variables.searchCallVariables.aggregations.filter((item) => item.type !== '_index');
          if (!variables.searchCallVariables.aggregations.length) {
            variables.allSelected = true;
            variables.activeType = 'all';
          }
          isPropsLoaded.current = true;
        }
      } else isPropsLoaded.current = false;

      let outer_div = document.getElementById('su__top_nav_outer_div');
      let inner_div = document.getElementById('su__top_nav_inner_div');
      let inner_div_width = inner_div?.offsetWidth;
      let outer_div_width = outer_div?.offsetWidth;
      setshowSlider(false);
      if (inner_div_width && outer_div_width && inner_div_width > outer_div_width) {
        setshowSlider(true);
      }
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event, id) => {
      setAnchorEl(event.currentTarget);
      const checkId = setInterval(function () {
        if (document.getElementById('su__MCS' + id)) {
          clearInterval(checkId);
          let clickedOpen = document.getElementById('su__MCS' + id);
          clickedOpen.classList.add('su__d-block');
        }
      });
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    /* Horizontal slider */
    let box = document.querySelector('.box-inner');
    let arrows = document.querySelectorAll('.arrow');

    arrows.forEach(function (arrow) {
      arrow.addEventListener('click', function () {
        let x;
        if (arrow.classList.contains('arrow-right')) {
          x = box.clientWidth / 2 + box.scrollLeft;
          box.scrollTo({
            left: x,
            behavior: 'smooth'
          });
        } else {
          x = box.clientWidth / 2 - box.scrollLeft;
          box.scrollTo({
            left: -x,
            behavior: 'smooth'
          });
        }
      });
    });

    return (
      <section className="su__contentTabs-section su__w-100">
        <div className="su__container">
          <div className="su__col-md-12 su__contentTabs-block su__cs-tabs su__cs_sm_tab">
            {searchResult.result &&
              searchResult.aggregationsArray
                .filter((item, index) => index === 0)
                .map((item) => (
                  <div key={'searchResult.aggregationsArray' + (item?.key || item?.label)}>
                    {item.key !== '' && item.label !== '' ? (
                      <div className="su__slider-outer">
                        {showSlider && (
                          <button
                            lang={variables.searchCallVariables.langAttr}
                            aria-label={t(StaticStrings.slide_left)}
                            className="arrow-left arrow su__slider-button su__slider-button-left su__slider_btn_position"
                          >
                            {' '}
                          </button>
                        )}
                        <div
                          className="box-inner su-tabsSection su__bg-white su__w-100 su__border-b su__border-t su__text-nowrap su__font-14 su__overflowx-auto su__minscroller su__slider-list"
                          id="su__top_nav_outer_div"
                          role={a11y.ROLES.TABLIST}
                        >
                          <div id="su__top_nav_inner_div">
                            <button
                              data-test-id={`su__top_facet_all_btn`}
                              lang={variables.searchCallVariables.langAttr}
                              aria-label={`${t(StaticStrings.ALLCONTENT)}`}
                              role={a11y.ROLES.TAB}
                              aria-selected={'' + !!variables.allSelected}
                              tabIndex={tabIndexes.tabIndex_0}
                              className={
                                variables.allSelected
                                  ? 'all-content su__tabs su__active-tab a11y-btn'
                                  : 'all-content su__tabs su__inactive-type a11y-btn'
                              }
                              onClick={() => getContentResults('All Content', 'all')}
                            >
                              {t(StaticStrings.ALLCONTENT)}
                            </button>
                            {React.Children.toArray(
                              item.values.map((itemInner, index) => (
                                <button
                                  data-test-id={`su__top_facet_${itemInner.displayName}_btn_${index}`}
                                  lang={variables.searchCallVariables.langAttr}
                                  key={`${itemInner}_${itemInner?.Contentnameex}`}
                                  aria-label={`${itemInner.displayName}`}
                                  role={a11y.ROLES.TAB}
                                  aria-selected={
                                    '' +
                                    !!(
                                      itemInner.selected ||
                                      (item.indeterminateFlag &&
                                        item.indeterminateFlag[itemInner.Contentname] === true)
                                    )
                                  }
                                  tabIndex={tabIndexes.tabIndex_0}
                                  className={
                                    itemInner.Contentname +
                                    ' su__tabs a11y-btn ' +
                                    (itemInner.selected ||
                                    Boolean(
                                      item.indeterminateFlag &&
                                        item.indeterminateFlag[itemInner.Contentname]
                                    )
                                      ? 'su__active-tab'
                                      : 'su__inactive-type')
                                  }
                                  onClick={() =>
                                    !itemInner.merged ||
                                    (itemInner.merged && itemInner.showChild != 1)
                                      ? getContentResults(itemInner.Contentname, item.key)
                                      : null
                                  }
                                >
                                  {!itemInner.merged || itemInner.showChild != 1
                                    ? itemInner.displayName
                                    : ''}
                                  {itemInner.merged && itemInner.showChild == 1 ? (
                                    <div className="su__mergedFilter-CS-tab">
                                      <div
                                        data-test-id={'su__merged_filter_svg'}
                                        className="su__mergedFilter-CS-button"
                                        onClick={(e) => handleClick(e, itemInner.Contentname)}
                                      >
                                        <span className="su__mergedFilter-CS-svg">
                                          {itemInner.displayName}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="10"
                                            viewBox="0 0 11.175 6.584"
                                          >
                                            <path
                                              className="su__mergedFilter-CS-svg-path"
                                              d="M9.462.292,5.582,4.172,1.7.292A1,1,0,0,0,.292,1.7l4.59,4.589a1,1,0,0,0,1.41,0L10.882,1.7a1,1,0,0,0,0-1.41A1.017,1.017,0,0,0,9.462.292Z"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      <Menu
                                        id={'su__MCS' + itemInner.Contentname}
                                        className="su__merge-options su__d-none"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        getContentAnchorEl={null}
                                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                      >
                                        {React.Children.toArray(
                                          itemInner.childArray.map((child) => (
                                            <div
                                              key={child?.displayName || child?.Contentname}
                                              className={
                                                child.Contentname +
                                                ' su__merge-options-item ' +
                                                (child.selected ? 'su__merge-options-active' : '')
                                              }
                                              onClick={() =>
                                                getContentResults(child.Contentname, item.key)
                                              }
                                            >
                                              {child.displayName || child.Contentname}
                                            </div>
                                          ))
                                        )}
                                      </Menu>
                                    </div>
                                  ) : null}
                                </button>
                              ))
                            )}
                          </div>
                        </div>
                        {showSlider && (
                          <button
                            lang={variables.searchCallVariables.langAttr}
                            aria-label={t(StaticStrings.slide_right)}
                            className="arrow-right arrow su__slider-button su__slider-button-right"
                          >
                            {' '}
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
          </div>
        </div>
      </section>
    );
  } catch (e) {
    console.log('Error in component content source tab', e);
    /**Handle error in conyent source tab component */
    return (
      <div data-test-id={'su__top_facet_loading'} className="su__loading1">
        <div className="su-tabsSection su__bg-white su__w-100 su__border-b su__border-t su__text-nowrap su__font-14 su__overflowx-auto su__minscroller">
          <div className="all-content su__tabs  su__loading-view ">All Content</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
          <div className="su__loading-view su__tabs ">{StaticStrings.CONTENTSOURCE}</div>
        </div>
      </div>
    );
  }
};

export default TopFacets;
