import React, { Fragment } from 'react';

const FacetAlternateUI = () => {
  return (
    <Fragment>
      <div className="su__loading1">
        <div className="facet su__open-facet">
          <div className="su__w-100 su__bg-white">
            <div className="su__px-3 su__py-3 su__position-relative su__flex-vcenter su__border-t">
              <div className="su__sort-filter-icon su__flex-vcenter su__pr-2 su__mr-1 su__rtlmr-0 su__rtlml-1 su__px-1 su__cursor">
                <svg
                  className="su__fill-gray su__fill-hover-gray"
                  title="sort filter"
                  width="4"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <rect
                      id="clip-Save_as_bookmark_listdata"
                      fill="none"
                      height="402"
                      width="582"
                      y="-1"
                      x="-1"
                    ></rect>
                  </g>
                  <g>
                    <title>Sort By</title>
                    <path d="m2,4c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2l0,0zm0,2c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2l0,0zm0,6c-1.1,0 -2,0.9 -2,2c0,1.1 0.9,2 2,2c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2l0,0z"></path>
                  </g>
                </svg>
              </div>
              <div className="su__sort-filter su__overlay-transparent su__zindex-2 close"></div>
              <div className="su__w-100 su__d-flex su__align-items-center">
                <span className="su__flex-1 su__font-14 filter-label su__loading-view ">
                  Sources
                </span>
                <i className="su__arrow-down"></i>
              </div>
              <div className="su__sort-filter su__w-155px su__position-absolute su__bg-white su__zindex-2 su__close-facet">
                <div className="su__p-0 su__m-0 su__lg-shadow">
                  <div className="su__font-12 su__color-lblue su__text-black su__font-regular su__flex-vcenter su__justify-content-left su__px-3 su__py-2">
                    <span className="su__mr-2 su__rtlmr-0 su__rtlml-2">
                      <svg
                        id="round-sort-24px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="Path_1530"
                          data-name="Path 1530"
                          className="cls-1"
                          fill="none"
                          d="M0,0H24V24H0Z"
                        ></path>
                        <path
                          id="Path_1531"
                          data-name="Path 1531"
                          className="su__fill-lblue"
                          d="M4,18H8a1,1,0,0,0,0-2H4a1,1,0,0,0,0,2ZM3,7A1,1,0,0,0,4,8H20a1,1,0,0,0,0-2H4A1,1,0,0,0,3,7Zm1,6H14a1,1,0,0,0,0-2H4a1,1,0,0,0,0,2Z"
                        ></path>
                      </svg>
                    </span>
                    <span className="su__color-lblue">Sort By</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="su__w-100 su__bg-white su__pb-2">
          <div className="su__filter-content-row su__position-relative su__px-3 su__bg-gray-hover su__pb-2">
            <div className="su__w-100 su__flex-vcenter su__box-sizing ">
              <div className="su__w-100 su__flex-vcenter su__py-1">
                <div></div>
                <div className="su__filter-checkbox su__flex-vcenter su__mr-2 su__rtlml-2 su__rtlmr-0">
                  <label className="su__filter-toggle su__flex-vcenter su__border-color su__font-18 su__position-relative su__d-inline-block">
                    <input
                      type="checkbox"
                      name="Question"
                      className="su__toggle-input su__cursor "
                      value="Question"
                    />
                  </label>
                </div>
                <div className="su__filter-label su__flex-1 su__text-truncate su__font-12 su__mr-3 su__rtlml-3 su__rtlmr-0">
                  <div className="su__line-height-n su__loading-view">
                    <span>Question</span>
                  </div>
                </div>
                <div className="su__filter-badge su__font-12">
                  <div className="label-badge su__bg-light-gray su__loading-view">2976</div>
                </div>
              </div>
            </div>
          </div>
          <div className="su__filter-content-row su__position-relative su__px-3 su__bg-gray-hover su__pb-2">
            <div className="su__w-100 su__flex-vcenter su__box-sizing ">
              <div className="su__w-100 su__flex-vcenter su__py-1">
                <div></div>
                <div className="su__filter-checkbox su__flex-vcenter su__mr-2 su__rtlml-2 su__rtlmr-0">
                  <label className="su__filter-toggle su__flex-vcenter su__border-color su__font-18 su__position-relative su__d-inline-block">
                    <input
                      type="checkbox"
                      name="Youtube Content"
                      className="su__toggle-input su__cursor"
                      value="Youtube Content"
                    />
                  </label>
                </div>
                <div className="su__filter-label su__flex-1 su__text-truncate su__font-12 su__mr-3 su__rtlml-3 su__rtlmr-0">
                  <div className="su__line-height-n su__loading-view">
                    <span>Youtube Content</span>
                  </div>
                </div>
                <div className="su__filter-badge su__font-12">
                  <div className="label-badge su__bg-light-gray su__loading-view">90</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FacetAlternateUI;
