import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import StaticStrings from '../../../StaticStrings';
import { a11y } from '../../../constants/a11y';

const ContentTotal = () => {
  /**
   *  Change Languages state
   */
  try {
    const { t } = useTranslation();
    let searchResult = useSelector((state) => state.searchResult);

    return (
      ((variables.mergeResults && !variables.resultsInAllContentSources) ||
        !variables.mergeResults) && (
        <div className="su__search-view su__flex-vcenter">
          <div className="su__d-flex su__search-view su__d-xs-block su__mb-sm-1 su__text-truncate su__pb-1">
            <div
              role={a11y.ROLES.ALERT}
              aria-live="polite"
              className="su__showing-lable su__color-lgray su__font-12 su__loading-view"
            >
              {searchResult.result &&
              searchResult.result.total &&
              searchResult.searchClientSettings.pageNoButton ? (
                <span lang={variables.searchCallVariables.langAttr}>
                  {t(StaticStrings.SHOWINGPAGE)} <b>{variables.searchCallVariables.pageNo}</b>{' '}
                  {t('of')}{' '}
                  <b>
                    {Math.ceil(searchResult.result.total / variables.searchCallVariables.pageSize)}
                  </b>{' '}
                  {t('of')} {searchResult.result && searchResult.result.total}{' '}
                  {t(StaticStrings.results)}{' '}
                  {'(' + variables.searchResposeTimer / 1000 + ' ' + t(StaticStrings.seconds) + ')'}
                  .
                </span>
              ) : (
                <span>
                  {searchResult.result &&
                  searchResult.result.total &&
                  (searchResult.searchClientSettings.moreResultButton ||
                    searchResult.searchClientSettings.scrollPagination) ? (
                    <span lang={variables.searchCallVariables.langAttr}>
                      {t(StaticStrings.SHOWING)}{' '}
                      <b>
                        {Math.min(
                          searchResult.result.total,
                          variables.searchCallVariables.resultsPerPage
                        )}
                      </b>{' '}
                      {t('of')} <b>{searchResult.result && searchResult.result.total}</b>{' '}
                      {t(StaticStrings.results)}{' '}
                      {'(' +
                        variables.searchResposeTimer / 1000 +
                        ' ' +
                        t(StaticStrings.seconds) +
                        ')'}
                      .
                    </span>
                  ) : (
                    <b lang={variables.searchCallVariables.langAttr}>
                      {t(StaticStrings.SHOWINGTOPRESULTFROMPREVIOUSSEARCHES)}.
                    </b>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )
    );
  } catch (e) {
    console.log('Error in Content Total component', e);
    return <div> </div>;
  }
};

export default ContentTotal;
