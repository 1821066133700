import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import useOnClickOutsideRef from '../../../event-handler/outside-click';

const MultiVersion = (props) => {
  try {
    const [isShowMultiversion, setShowMultiversion] = useState(false);

    let { item, openFacet, linkOpened, index } = props;
    const multiVersionClicked = () => {
      setShowMultiversion(!isShowMultiversion);
      openFacet(index);
    };
    const multiVerClickedResult = () => {
      setShowMultiversion(!isShowMultiversion);
    };
    const modalRef = useOnClickOutsideRef(() => setShowMultiversion(false));

    return (
      item.hits &&
      !!item.version && (
        <div
          className={`su__multiple-versions-block su__d-flex su__flex-vcenter su__loading-view su__mt-1  ${
            isShowMultiversion ? 'su__open-facet' : 'su__close-facet'
          } `}
        >
          <div className="su__multiple-version-label su__custom-grey-color su__text-truncate  su__font-11 su__text-right su__f-bold su__multiversion_font">
            {item.versionFieldName}:
          </div>
          <div
            ref={modalRef}
            className="su__text-nowrap su__position-relative su__ml-2 su__ribbon-grid su__d-block"
          >
            <div
              className="su__multiple-versions-row su__flex-vcenter su__justify-content-end"
              onClick={multiVersionClicked}
            >
              <div className="su__flex-vcenter su__parent-version su__w-135px su__cursor su__filter-arows">
                <a
                  className="su__custom-grey-color su__margin-right-auto su__f-bold su__text-decoration su__font-regular su__text-truncate su__font-10"
                  dangerouslySetInnerHTML={{ __html: item.version }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
                <span className="su__mr-2"></span>
                <span className="su__multiversion-arrow">
                  <i className="su__arrow-down su__arrow-position"></i>
                </span>
              </div>
            </div>
            {isShowMultiversion && (
              <div className="su__minscroller su__radius-1 su__position-absolute su__w-135px su__background-white su__sm-shadow su__multiple-versions-dropdown su__text-left">
                {React.Children.toArray(
                  item.hits.map((multipleVersions, index) => (
                    <div
                      key={uuid()}
                      className="su__bg-gray-hover su__multiple-versions-results"
                      onClick={() => {
                        multiVerClickedResult();
                        linkOpened(multipleVersions, index + 1);
                      }}
                    >
                      <div className="su__custom-border-color su__multiple-versions su__text-truncate">
                        <a
                          className="su__multi-version-txt su__cursor su__custom-grey-color su__text-truncate su__text-decoration su__font-regular su__font-10"
                          target="_blank"
                          href={multipleVersions.href}
                          dangerouslySetInnerHTML={{ __html: multipleVersions.version }}
                          rel="noopener noreferrer"
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      )
    );
  } catch (e) {
    console.log('Error in MultiVersion component', e);
    return <div></div>;
  }
};

export default React.memo(MultiVersion);
