import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import variables from '../../../redux/variables';

// eslint-disable-next-line no-undef
const result = JSON.parse(scConfiguration.language);
variables.languages = result;
localStorage.setItem('language', variables.languages?.config.defaultLanguage.code);
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: result
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    returnEmptyString: false,
    ns: ['mapping'],
    defaultNS: 'mapping',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
