/* global gza */
import a11y, { tabIndexes } from 'constants/a11y';
import { any, bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import variables from 'redux/variables';
const CitationsPortal = ({
  height,
  width,
  href,
  target,
  searchResult,
  showPortal,
  modalRef,
  isDeviceMobile,
  setshowPortal,
  diamondPositionX,
  diamondPositionY,
  Opacity,
  viewedResult
}) => {
  let articleLinkObjectIndex;
  let articleLinkObject = searchResult?.result?.hits?.find?.((element, index) => {
    if (element.href.includes(href)) {
      articleLinkObjectIndex = index;
      return true;
    }
    return false;
  });

  let citationHref, citationTitle, citationDescription;
  citationHref = articleLinkObject?.href;
  citationTitle = articleLinkObject?.highlight?.TitleToDisplayString[0] || articleLinkObject?.href;
  citationDescription = articleLinkObject?.highlight?.SummaryToDisplay;

  const [showTransition, setshowTransition] = useState(false);

  /**
   * It closes the citations modal
   * @param {void}
   * @returns {void}
   */
  const closeModal = () => {
    setshowPortal(false);
  };

  /**
   * It sets the variable to true after some time to show transition effect on mobile view on citations modal
   * @param {void}
   * @returns {void}
   */
  useEffect(() => {
    let animationFrameId;
    const timeoutId = setTimeout(() => {
      animationFrameId = requestAnimationFrame(() => {
        setshowTransition(true);
      });
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      cancelAnimationFrame(animationFrameId);
    };
  });
  /**
   * linkOpened - When user clicks on href of citATION modal then it tracks the conversion analytics
   * @param {*} result
   * @param {*} rank
   * @param {*} href
   * @param {*} title
   */
  const linkOpened = (result, rank, href, title) => {
    gza('conversion', {
      index: result['sourceName'],
      type: result['objName'],
      id: result['_id'],
      rank: rank,
      convUrl: href || result['href'],
      convSub: title || result['highlight']['TitleToDisplayString'][0].substring(0, 300),
      pageSize: variables.searchCallVariables.pageSize,
      page_no: variables.searchCallVariables.pageNo,
      sc_analytics_fields: result['trackAnalytics']
    });
    closeModal();
  };
  return (
    <div>
      {showPortal &&
        ReactDOM.createPortal(
          <div
            ref={modalRef}
            style={{
              top: height,
              left: width,
              opacity: Opacity,
              position: 'fixed',
              ...(isDeviceMobile && variables.searchClientType === 16 ? { bottom: 'unset' } : {})
            }}
            className={`${showPortal ? 'su__fadeIn_animation ' : 'su__fadeOut_animation'} ${
              isDeviceMobile
                ? 'su__fix_preview_bottom su__transition_citation'
                : 'su__invisible_div'
            } ${showTransition && isDeviceMobile ? 'su__transition_in' : ''} ${
              viewedResult ? 'su__viewed-results' : ''
            }`}
          >
            <div
              className={`su__gpt-preview-article-link ${
                isDeviceMobile ? 'su__fix_preview_bottom su__article_links_mobile_view' : ''
              } `}
            >
              {articleLinkObject && articleLinkObject.href && (
                <div
                  className={`${
                    isDeviceMobile
                      ? 'su__padding_bottom_17 su__citation_modal_container'
                      : 'su__citation_modal_container su__position-relative'
                  }`}
                >
                  {isDeviceMobile && (
                    <div className="su__heading_container_citation">
                      <span className="su__heading_citation">CITATION</span>
                      <figure
                        data-test-id={'su__citation_close'}
                        className="su__cross_svg su__cursor_pointer"
                        onClick={closeModal}
                      >
                        <svg
                          tabIndex={tabIndexes.tabIndex_0}
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                        >
                          <path
                            id="Path_18325"
                            data-name="Path 18325"
                            d="M10.214,10.2a.71.71,0,0,1,1.006,0L15,13.975,18.78,10.2A.712.712,0,1,1,19.786,11.2L16.01,14.986l3.776,3.783a.712.712,0,0,1-1.006,1.007L15,16l-3.78,3.779a.712.712,0,0,1-1.006-1.007l3.776-3.783L10.214,11.2A.712.712,0,0,1,10.214,10.2Z"
                            transform="translate(-10.005 -9.977)"
                            fill="#666"
                          />
                        </svg>
                      </figure>
                    </div>
                  )}
                  <div className="su__d-flex su__justify-content-between">
                    <div className="su__citationModalSection">
                      <div
                        className={`su__article_href_container  ${
                          isDeviceMobile ? 'su__margin_top_15' : ''
                        }`}
                      >
                        <a
                          className={`${
                            isDeviceMobile
                              ? 'su__href_mobile'
                              : 'su__article_href_dimension su__cursor_pointer'
                          }`}
                          target={target}
                          href={citationHref}
                          tabIndex={tabIndexes.tabIndex_0}
                          aria-label={citationTitle}
                        >
                          <span
                            data-test-id={'su__citation_title'}
                            className="su__line_clamp_2 "
                            onClick={() =>
                              linkOpened(articleLinkObject, articleLinkObjectIndex + 1)
                            }
                          >
                            {citationTitle}
                          </span>
                        </a>
                      </div>
                      <div
                        className={`${
                          isDeviceMobile
                            ? 'su__title_mobile sample'
                            : 'su__article_desc_dimensions su__line_clamp_2'
                        }`}
                      >
                        <span
                          data-test-id={'su__citation_desc'}
                          className="su__citation_desc su__line_clamp_2 "
                          dangerouslySetInnerHTML={{
                            __html: citationDescription.join('#<span><br>').split('#')
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="su__ml-33">
                      {!isDeviceMobile && (
                        <a
                          className="su__open_article_icon"
                          href={citationHref}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <figure
                            data-test-id={'su__citation_svg'}
                            className="su__open_href_svg"
                            onClick={() =>
                              linkOpened(articleLinkObject, articleLinkObjectIndex + 1)
                            }
                          >
                            <svg
                              id="Group_19941"
                              data-name="Group 19941"
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.5"
                              height="11.5"
                              viewBox="0 0 11.5 11.5"
                              className="su__cursor_pointer"
                              tabIndex={a11y.tabIndex_0}
                            >
                              <path
                                id="Path_18321"
                                data-name="Path 18321"
                                d="M12.471,19H5.029A2.032,2.032,0,0,1,3,16.971V9.529A2.032,2.032,0,0,1,5.029,7.5H7.284a.676.676,0,0,1,0,1.353H5.029a.677.677,0,0,0-.676.676v7.441a.677.677,0,0,0,.676.676h7.441a.677.677,0,0,0,.676-.676V14.716a.676.676,0,1,1,1.353,0v2.255A2.032,2.032,0,0,1,12.471,19Z"
                                transform="translate(-3 -7.5)"
                                fill="#535355"
                              />
                              <path
                                id="Path_18322"
                                data-name="Path 18322"
                                d="M25.735,8.412a.676.676,0,0,1-.676-.676V4.353H21.676a.676.676,0,0,1,0-1.353h4.059a.676.676,0,0,1,.676.676V7.735A.676.676,0,0,1,25.735,8.412Z"
                                transform="translate(-14.912 -3)"
                                fill="#535355"
                              />
                              <path
                                id="Path_18323"
                                data-name="Path 18323"
                                d="M14.176,11.794a.676.676,0,0,1-.478-1.155L21.139,3.2a.676.676,0,1,1,.957.957L14.655,11.6A.674.674,0,0,1,14.176,11.794Z"
                                transform="translate(-10.794 -3)"
                                fill="#535355"
                              />
                            </svg>
                          </figure>
                        </a>
                      )}
                    </div>
                  </div>
                  {window.innerWidth > 768 && (
                    <div
                      className={`su__diamond ${
                        diamondPositionX === 'left'
                          ? 'su__left_pos_diamond'
                          : 'su__right_pos_diamond'
                      } ${diamondPositionY === 'top' ? 'su__top_pos_diamond' : ''}`}
                    ></div>
                  )}
                </div>
              )}
            </div>
          </div>,

          document.body
        )}
    </div>
  );
};

CitationsPortal.propTypes = {
  height: string,
  width: string,
  href: string,
  target: string,
  searchResult: any,
  showPortal: bool,
  modalRef: func,
  isDeviceMobile: bool,
  setshowPortal: func,
  diamondPositionX: string,
  diamondPositionY: string,
  Opacity: string,
  viewedResult: bool
};

CitationsPortal.defaultProps = {
  height: '',
  width: '',
  href: '',
  target: '',
  searchResult: {},
  showPortal: false,
  modalRef: () => {},
  isDeviceMobile: false,
  setshowPortal: () => {},
  diamondPositionX: '',
  diamondPositionY: '',
  Opacity: '',
  viewedResult: false
};

export default CitationsPortal;
