import React, { Fragment, useState, useEffect } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { A11Y_IDS, a11y, tabIndexes } from '../../../constants/a11y';

const ScrollToTop = (props) => {
  const { isDeviceMobile } = props;
  /**
   *  Change Languages state
   */
  try {
    const [scroll, setScroll] = useState('active');

    useEffect(() => {
      const scrollHandler = () => {
        const scrollCheck = window.scrollY < 40;
        setScroll(scrollCheck);
      };
      document.addEventListener('scroll', scrollHandler);
      return () => document.removeEventListener('scroll', scrollHandler);
    }, []);
    const { t } = useTranslation();
    const handleScroll = () => {
      if (document.getElementById('goToTopBtn')) {
        if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
          document.getElementById('goToTopBtn').style.display = 'block';
        } else document.getElementById('goToTopBtn').style.display = 'none';
      }
    };
    if (variables.searchClientType == 25) {
      let scrol = document.querySelectorAll('[data-automation-id="contentScrollRegion"]')[0];
      scrol.onscroll = function () {
        if (scrol > 20 || scrol.scrollTop > 20) {
          document.getElementById('goToTopBtn').style.display = 'block';
        } else {
          document.getElementById('goToTopBtn').style.display = 'none';
        }
      };
    }
    const goToTop = () => {
      let focus;
      if (variables.searchClientType == 25) {
        focus = document.querySelectorAll('.su__app-running')[0];
        window.scroll({
          top: focus.offsetTop,
          behavior: 'smooth'
        });
      }
      focus = document.querySelector('[su-container]') || document.querySelector('[ng-app = "searchUnifyApp"]');
      window.scroll({
        top: focus.offsetTop,
        behavior: 'smooth'
      });
      focus.scrollIntoView();

      document.querySelector(`#${A11Y_IDS.skipToMainContent}`)?.focus();
    };
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    });
    return (
      <Fragment>
        {isDeviceMobile ? (
          <div className="su__mobile-child-block su__d-xs-block su__font-9 su__font-bold su__text-center  su__px-sm-1 ">
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.search)}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              className={`su__mob-search-iner a11y-btn p-0 ${!scroll ? 'su__mob-active' : ''}`}
              onClick={goToTop}
            >
              <div className="su__mob-icon">
                <Icons
                  IconName="Search"
                  width="20"
                  height="20"
                  color="#333"
                  IconClass="su__active-path"
                />
              </div>
              <div
                lang={variables.searchCallVariables.langAttr}
                className="su__mob-txt su__line-height-n su__active-text su__font-9 su__font-bold"
              >
                {t(StaticStrings.search)}
              </div>
            </button>
          </div>
        ) : (
          <div className="su__back-to-top su__position-fixed su__rtlright-auto su__cursor su__zindex">
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.page_top)}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              id="goToTopBtn"
              className="su__btn-back-to-top su__animate-slideSpDown su_radius-top su__px-2 su__py-1 su__shadow docs-scroll-fab scrolling  su__bg-blue-grd su__d-block border-0"
              onClick={goToTop}
              docs-scroll-class="scrolling"
            >
              <span
                lang={variables.searchCallVariables.langAttr}
                className="su__text-white su__font-13 su__f-bold"
              >
                {t(StaticStrings.page_top)}
              </span>
              <Icons
                className="su__position-relative"
                IconName="goToTop"
                width="20"
                height="20"
                color={IconColors.ScrollToTopIcon}
              />
            </button>
          </div>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in goToTop component', e);
    return <div></div>;
  }
};
export default React.memo(ScrollToTop);