/* global gza */
import React, { useState, useEffect } from 'react';
import Metadata from 'components/section-components/meta-data/index.jsx';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { a11y } from '../../../constants/a11y';

const FeaturedSnippetQA = ({ featuredSnippetResult, linkOpened, isDeviceMobile }) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const [featuredSnippetGraphResponseRecorded, setFeaturedSnippetGraphFeedback] = useState(false);
    const [timeoutResponse, setTimeoutResponse] = useState(true);
    useEffect(() => {
      setFeaturedSnippetGraphFeedback(false);
      setTimeoutResponse(true);
    }, [featuredSnippetResult]);
    const sendFeaturedSnippetGraphFeedback = (feedback, result) => {
      gza('featuredSnippet', {
        searchString: variables.searchCallVariables.searchString,
        url: result.href,
        t: result.highlight.TitleToDisplayString[0].substring(0, 300) || result.href,
        uid: variables.searchCallVariables.uid,
        feedback: feedback
      });
      setFeaturedSnippetGraphFeedback(true);
      setTimeout(function () {
        setTimeoutResponse(false);
      }, 5000);
    };
    return (
      <>
        {!isDeviceMobile &&
        featuredSnippetResult.questionAnswer.answer &&
        featuredSnippetResult.questionAnswer.short_summary ? (
          <div className="su__FeaturedSnippet su__fs-bg-blue su__radius-1 su__position-relative su__mb-3 su__mt-2 su__p-3 su__d-md-flex su__flex-column su__overflow-hide su__w-100">
            <div className="su__d-md-flex su__w-100">
              <div
                className={`su__featureSnippet-left su__flex-1 su__overflow-hide su__mr-4 ${
                  featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                    ? 'su__w-75'
                    : ''
                }`}
              >
                <div className="su__featureSnippet-title su__font-20 su__f-bold su__text-truncate su__mb-2">
                  {featuredSnippetResult.questionAnswer.answer}
                </div>
                <div className="su__su__featureSnippet-desc su__mb-2">
                  <span className="su__text-black su__font-12 su__f-regular">
                    {featuredSnippetResult.questionAnswer.short_summary}
                  </span>
                </div>
                <div className="su__flex-vcenter">
                  <a
                    className="su__href-txt su__text-decoration su__color-lgray su__font-12 su__font-italic"
                    href={featuredSnippetResult.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseDown={(event) => {
                      if (event.button == 1) linkOpened(featuredSnippetResult, 1);
                    }}
                    onContextMenu={() => linkOpened(featuredSnippetResult, 1)}
                    onClick={() => linkOpened(featuredSnippetResult, 1)}
                  >
                    {featuredSnippetResult.href}
                  </a>
                </div>
                <div className="su__su__featureSnippet-meta su__font-12">
                  <Metadata item={featuredSnippetResult} />
                </div>
              </div>
              <div
                className={`su__featureSnippet-right su__d-flex su__flex-column su__position-relative ${
                  featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                    ? 'su__featured-thumbnail'
                    : 'su__w-80px'
                } ${
                  (featuredSnippetResult.multiMedia &&
                    featuredSnippetResult.multiMedia.length &&
                    featuredSnippetResult.multiMedia[0].video_url &&
                    featuredSnippetResult.multiMedia[0].thumbnail) ||
                  featuredSnippetResult.multiMedia[0].image_urls
                    ? null
                    : 'su__grey_bg'
                }`}
              >
                <div className="su__d-flex su__w-100 su__feedback-icon-above  su__zindex-1">
                  {!featuredSnippetGraphResponseRecorded ? (
                    <div
                      className={`su__d-flex su__position-absolute su__right-0 ${
                        featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                          ? 'su__pt-2 su__pr-2'
                          : ''
                      }`}
                    >
                      <span
                        className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                        onClick={() => sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsDown"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                      <span
                        className="su__featured-feedback su__cursor su__loading-view"
                        onClick={() => sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult)}
                      >
                        <Icons
                          IconName="ThumbsUp"
                          width="24"
                          height="24"
                          color={IconColors.FeaturedSnippetThumbsup_down}
                        />
                      </span>
                    </div>
                  ) : null}
                  {featuredSnippetGraphResponseRecorded && timeoutResponse ? (
                    <div
                      className={`su__text-success su__font-14 su__position-absolute su__right-0 su__loading-view ${
                        featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                          ? 'su__feedback-thankyou'
                          : ''
                      }`}
                    >
                      <div className="su__flex-vcenter su__font-12">
                        <span
                          lang={variables.searchCallVariables.langAttr}
                          className="su__thankyou-text su__whitespace-no"
                        >
                          {t(StaticStrings.thanks_response)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                {featuredSnippetResult.multiMedia &&
                featuredSnippetResult.multiMedia.length != 0 ? (
                  <div
                    className={`su__featureSnippet-right su__py-sm-2 su__align-self-center ${
                      (featuredSnippetResult.multiMedia &&
                        featuredSnippetResult.multiMedia.length &&
                        featuredSnippetResult.multiMedia[0].video_url &&
                        featuredSnippetResult.multiMedia[0].thumbnail) ||
                      featuredSnippetResult.multiMedia[0].image_urls
                        ? null
                        : 'su__m-auto'
                    }`}
                  >
                    <div className="su__featured-thumbnail">
                      <a
                        className="su__position-relative su__d-flex"
                        onClick={() => linkOpened(featuredSnippetResult, 1)}
                        target="_blank"
                        href={
                          featuredSnippetResult.multiMedia[0].href ||
                          featuredSnippetResult.multiMedia[0].video_url
                        }
                        rel="noopener noreferrer"
                      >
                        {featuredSnippetResult.multiMedia[0].video_url ? (
                          <>
                            {featuredSnippetResult.multiMedia[0].thumbnail ? (
                              <svg
                                className="su__video-thumbnail-play"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeaturedSnippetSvgFill}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            ) : (
                              <svg
                                className="su__align_icon_play_grey su__m-auto"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeatureSnippetSvgPlayGrey}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            )}
                          </>
                        ) : null}
                        <img
                          src={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                          className="su__img-featured"
                          alt={featuredSnippetResult.multiMedia[0].alt_attributes}
                          role={a11y.ROLES.IMAGE}
                          aria-label={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                        />
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {isDeviceMobile &&
        featuredSnippetResult.questionAnswer.answer &&
        featuredSnippetResult.questionAnswer.short_summary ? (
          <div className="su__FeaturedSnippet su__fs-bg-blue su__radius-1 su__position-relative su__mb-3 su__p-3 su__d-md-flex su__flex-column su__overflow-hide su__w-100">
            <div className="su__d-md-flex su__w-100 d-flex">
              <div className="su__snippet_container-width-mobile">
                <div className="su__featureSnippet-title su__font-20 su__f-bold su__text-truncate su__mb-2">
                  {featuredSnippetResult.questionAnswer.answer}
                </div>
                <div
                  className={`su__featureSnippet-left su__d-flex su__overflow-hide su__mb-2 su__w-100 ${
                    featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                      ? 'su__w-100'
                      : ''
                  }`}
                >
                  <div
                    className={`su__su__featureSnippet-desc su__pr-2 ${
                      featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                        ? 'su__w-70'
                        : ''
                    }`}
                  >
                    <span className="su__text-black su__font-12 su__f-regular">
                      {featuredSnippetResult.questionAnswer.short_summary}
                    </span>
                  </div>
                </div>
                <div className="su__flex-vcenter su__word-break">
                  <a
                    className="su__href-txt su__text-decoration su__color-lgray su__font-12 su__font-italic"
                    href={featuredSnippetResult.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseDown={(event) => {
                      if (event.button == 1) linkOpened(featuredSnippetResult, 1);
                    }}
                    onContextMenu={() => linkOpened(featuredSnippetResult, 1)}
                    onClick={() => linkOpened(featuredSnippetResult, 1)}
                  >
                    {featuredSnippetResult.href}
                  </a>
                </div>
                <div className="su__su__featureSnippet-meta su__font-12">
                  <Metadata item={featuredSnippetResult} />
                </div>
                <div
                  className={`su__featureSnippet-right su__d-flex su__flex-column su__position-relative su__mt-2 ${
                    featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length
                      ? 'su__featured-thumbnail'
                      : ''
                  }`}
                >
                  <div className="su__d-flex su__w-100 su__zindex-1">
                    {!featuredSnippetGraphResponseRecorded ? (
                      <div className="su__d-flex">
                        <span
                          className="su__featured-feedback su__cursor su__loading-view su__mr-2"
                          onClick={() => sendFeaturedSnippetGraphFeedback(0, featuredSnippetResult)}
                        >
                          <Icons
                            IconName="ThumbsDown"
                            width="24"
                            height="24"
                            color={IconColors.FeaturedSnippetThumbsup_down}
                          />
                        </span>
                        <span
                          className="su__featured-feedback su__cursor su__loading-view"
                          onClick={() => sendFeaturedSnippetGraphFeedback(1, featuredSnippetResult)}
                        >
                          <Icons
                            IconName="ThumbsUp"
                            width="24"
                            height="24"
                            color={IconColors.FeaturedSnippetThumbsup_down}
                          />
                        </span>
                      </div>
                    ) : null}
                    {featuredSnippetGraphResponseRecorded && timeoutResponse ? (
                      <div className="su__position-relative su__loading-view">
                        <div className="su__flex-vcenter su__font-12">
                          <span
                            lang={variables.searchCallVariables.langAttr}
                            className="su__thankyou-text su__whitespace-no"
                          >
                            {t(StaticStrings.thanks_response)}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {featuredSnippetResult.multiMedia && featuredSnippetResult.multiMedia.length != 0 ? (
                <div
                  className={` ${
                    (featuredSnippetResult.multiMedia &&
                      featuredSnippetResult.multiMedia.length &&
                      featuredSnippetResult.multiMedia[0].video_url &&
                      featuredSnippetResult.multiMedia[0].thumbnail) ||
                    featuredSnippetResult.multiMedia[0].image_urls
                      ? 'su__snippets_mobile'
                      : 'su__grey-container'
                  }`}
                >
                  <div className="su__featureSnippet-right">
                    <div className="su__min-w-30">
                      <a
                        className="su__position-relative su__d-flex"
                        onClick={() => linkOpened(featuredSnippetResult, 1)}
                        target="_blank"
                        href={
                          featuredSnippetResult.multiMedia[0].href ||
                          featuredSnippetResult.multiMedia[0].video_url
                        }
                        rel="noopener noreferrer"
                      >
                        {featuredSnippetResult.multiMedia[0].video_url ? (
                          <>
                            {featuredSnippetResult.multiMedia[0].thumbnail ? (
                              <svg
                                className="su__video-thumbnail-play"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeaturedSnippetSvgFill}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            ) : (
                              <svg
                                className="su__align_icon_play_grey su__m-auto"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill={IconColors.FeatureSnippetSvgPlayGrey}
                              >
                                <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z" />
                              </svg>
                            )}
                          </>
                        ) : null}
                        <img
                          src={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                          className="su__img-featured"
                          alt={featuredSnippetResult.multiMedia[0].alt_attributes}
                          role={a11y.ROLES.IMAGE}
                          aria-label={
                            featuredSnippetResult.multiMedia[0].image_urls ||
                            featuredSnippetResult.multiMedia[0].thumbnail
                          }
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </>
    );
  } catch (e) {
    console.log('Error in feature-snippet component', e);
    return <div></div>;
  }
};

export default FeaturedSnippetQA;
