/* eslint-disable react/prop-types */
import React from 'react';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import { useTranslation } from 'react-i18next';
import StaticStrings from '../../../StaticStrings';
import IconColors from '../../../IconColors';

const SearchTuning = (props) => {
  try {
    const { t } = useTranslation();
    let { item } = props;
    return (
      (item.bypass_filter || item.autotuned) && (
        <div className="su__flex-vcenter  su__search_tuning_icon">
          <div className="su__boosted-documents-positioning">
            {item.bypass_filter ? (
              <div className="su_boosted-documents">
                <Tooltip text={t(StaticStrings.BOOSTED)} position="bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.505"
                    height="13.561"
                    viewBox="0 0 22.505 13.561"
                  >
                    <g
                      id="Component_69_3"
                      data-name="Component 69 – 3"
                      transform="translate(1.414 1.414)"
                    >
                      <path
                        id="Path_13515"
                        data-name="Path 13515"
                        d="M21.177,9l-8.5,8.5L8.208,13.025,1.5,19.733"
                        transform="translate(-1.5 -9)"
                        fill="none"
                        stroke="#1770d4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_13516"
                        data-name="Path 13516"
                        d="M25.5,9h7.415v7.415"
                        transform="translate(-13.238 -9)"
                        fill={IconColors.boostedSvgFill}
                        stroke="#1770d4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </Tooltip>
              </div>
            ) : (
              <div>
                {item.autotuned ? (
                  <Tooltip text={t(StaticStrings.AUTOTUNED)} position="bottom">
                    <div className="su__flex-vcenter su__mx-2">
                      <svg
                        title="autotuning"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <clipPath id="clip-Tunning-11">
                            <rect width="16" height="16" />
                          </clipPath>
                        </defs>
                        <g id="Tunning" clipPath="url(#clip-Tunning-11)">
                          <path
                            id="Path_1567"
                            data-name="Path 1567"
                            d="M3,15.444v1.778H8.333V15.444ZM3,4.778V6.556h8.889V4.778ZM11.889,19V17.222H19V15.444H11.889V13.667H10.111V19ZM6.556,8.333v1.778H3v1.778H6.556v1.778H8.333V8.333ZM19,11.889V10.111H10.111v1.778ZM13.667,8.333h1.778V6.556H19V4.778H15.444V3H13.667Z"
                            transform="translate(-3 -3)"
                            fill={IconColors.AutoTunesvgFill}
                          />
                        </g>
                      </svg>
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            )}
          </div>
        </div>
      )
    );
  } catch (e) {
    console.log('Error in Solved component', e);
    return <div></div>;
  }
};

export default React.memo(SearchTuning);
