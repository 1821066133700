/* global $scope */
import variables from 'redux/variables';
/**
 *
 * @param {filters to be merged} h
 * @param {aggregations} aggrData
 */
const mergeFilters = (h, aggrData, pagingAggregationKeyword, isSearched = false) => {
  let aggregations = variables.searchCallVariables.aggregations;
  let k = -1;
  k = aggrData.findIndex((f) => f.key === h.facetName);
  if (k == -1) return;
  h.filterList = h.filterList.map((filter) => {
    return decodeURIComponent(filter);
  });
  let l =
    k > -1
      ? aggrData[k].values.filter((f) => {
          return h.filterList.indexOf(f.Contentname) > -1;
        })
      : [];
  let v = 0;
  l.forEach((f) => {
    v += f.value;
  });

  if (l.length) {
    let place = aggrData[k].values.length;
    aggrData[k].merged = true;
    let childArray = [];
    let s = {};
    l.forEach((f, ind) => {
      let index = -1;
      aggrData[k].values.some((d, i) => {
        if (d.Contentname == f.Contentname) {
          index = i;
          return true;
        }
      });
      if (index > -1) {
        childArray.push(aggrData[k].values[index]);
        if (index < place) place = index;
        let regex = new RegExp('.' + pagingAggregationKeyword + '.', 'gi');
        if (
          (index > -1 && !isSearched) ||
          (isSearched && !regex.test(aggrData[k].values[index].Contentname))
        )
          aggrData[k].values.splice(index, 1);
      } else {
        s = {};
        ind = h.filterList.indexOf(f);
        let displayName = h.filterDisplayName[ind];
        let filterobj = {
          displayName: displayName,
          Contentname: f.Contentname || f,
          parent: h.facetName,
          value: 0
        };
        childArray.push(filterobj);
      }
    });

    childArray.forEach((f) => {
      f.immediateParent = 'merged_' + h.filterNewName;
      f.level = 2;
      f.parent = h.facetName;
      f.childName = f.Contentname;
    });
    s.displayName = h.filterNewName;
    s.parent = h.facetName;
    s.Contentname = 'merged_' + h.filterNewName;
    s.Contentname_short = h.filterNewName;
    s.displayNameFrontend = h.filterNewName;
    s.value = isSearched ? null : v;
    s.level = 1;
    s.merged = true;
    s.showChild = h.showChild;
    s.childArray = JSON.parse(JSON.stringify(childArray));
    s.selected = false;
    s.filterList = h.filterList;
    if (aggrData[k].indeterminateFlag) {
      aggrData[k].indeterminateFlag[s.Contentname] = false;
    } else {
      aggrData[k].indeterminateFlag = {};
    }
    if (aggregations.length != 0) {
      let index = -1;
      aggregations.some((facet, i) => {
        if (facet.type == h.facetName) {
          index = i;
          return true;
        }
      });
      if (index >= 0) {
        if (
          aggregations[index] &&
          aggregations[index].filter &&
          aggregations[index].filter.length
        ) {
          let filtersInAggr = aggregations[index].filter.filter(function (ele) {
            return h.filterList.indexOf(ele) > -1;
          });
          let arrayToCompare = l.map(function (m) {
            return m.Contentname;
          });
          if (filtersInAggr.length == arrayToCompare.length) {
            s.selected = true;
          } else if (filtersInAggr.length) {
            aggrData[k].indeterminateFlag[s.Contentname] = true;
          }
        }
      }
    }
    if (aggrData[k].values) {
      aggrData[k].values.splice(place, 0, s);
      aggrData[k].values =
        aggrData[k].sort && k !== 0
          ? sortMergeFacet(aggrData[k].values, aggrData[k].sort)
          : aggrData[k].values;
    }
  }
};

const sortMergeFacet = (filters, sort) => {
  let sortBy = sort.split('_')[0];
  let orderBy = sort.split('_')[1];
  if (sortBy === 'term')
    filters = filters.sort((a, b) => {
      if (
        (a.displayNameFrontend || a.ContentnameFrontend) >
        (b.displayNameFrontend || b.ContentnameFrontend)
      )
        return 1;
      else if (
        (a.displayNameFrontend || a.ContentnameFrontend) <
        (b.displayNameFrontend || b.ContentnameFrontend)
      )
        return -1;
      else return 0;
    });
  if (sortBy === 'count') filters = filters.sort((a, b) => a.value - b.value);
  if (orderBy === 'desc') filters = filters.reverse();
  let arr = [];
  return filters
    .filter((f) => {
      if (f.selected) return f;
      arr.push(f);
    })
    .concat(arr);
};

const mergeFilterClicked = (contentName, aggrFilter, childArray, filterChecked) => {
  childArray.some(function (child) {
    if (child.Contentname == contentName && child.childArray) {
      child.childArray.forEach(function (f) {
        if (filterChecked && aggrFilter.indexOf(f.Contentname) == -1) {
          aggrFilter.push(f.Contentname);
        } else {
          if (!filterChecked && aggrFilter.indexOf(f.Contentname) > -1) {
            aggrFilter.splice(aggrFilter.indexOf(f.Contentname), 1);
          }
        }
      });
    }
  });
};

const traverseTheTree = (childArray, contentName, path, filtersInAggr, childrenArr) => {
  for (let i of childArray) {
    if (!path) continue;
    let filter = i;
    if (filter.Contentname == path[0]) {
      filter.selected = false;
      if (path.length > 1) {
        traverseTheTree(
          filter.childArray,
          contentName,
          path.splice(1, path.length),
          filtersInAggr,
          childrenArr
        );
      }
      filter.childArray.forEach(function (child) {
        if (
          contentName != child.Contentname &&
          child.selected &&
          filtersInAggr.indexOf(child.Contentname) == -1
        ) {
          childrenArr.push({
            childName: child.Contentname,
            level: child.level,
            path: child.path
          });
        }
      });
    }
  }
};
let filterChecked = false;
const facetClickNested = (filter, type, order, level, pathNew, searchResult) => {
  filterChecked = !filter.selected;
  variables.currentClickedOrder = searchResult.aggregationsArray.findIndex(
    (f) => f.order === order
  );
  let path = pathNew;
  let childLevel = level;
  let clientFilters = searchResult.aggregationsArray;
  let aggregations = variables.searchCallVariables.aggregations;
  let obj = {
    childName:
      variables.searchClientType === 2 || variables.searchClientType === '2'
        ? encodeURIComponent(filter.Contentname)
        : filter.Contentname,
    level: JSON.stringify(childLevel)
  };
  if (childLevel > 1)
    obj.path =
      variables.searchClientType === 2 || variables.searchClientType === '2'
        ? encodeURIComponent(path)
        : path || [];

  if (
    !variables.searchCallVariables.aggregations.length &&
    filter.Contentname.indexOf('merged_') == -1
  ) {
    clientFilters[variables.currentClickedOrder].key &&
    (clientFilters[variables.currentClickedOrder].key.indexOf('_nested') > 0 ||
      clientFilters[variables.currentClickedOrder].key.indexOf('_navigation') > 0)
      ? aggregations.push({
          type: clientFilters[variables.currentClickedOrder].key,
          filter: [],
          children: [obj]
        })
      : aggregations.push({
          type: clientFilters[variables.currentClickedOrder].key,
          filter: [filter.Contentname]
        });
  } else {
    let index = -1;
    aggregations.some(function (facet, i) {
      if (facet.type == clientFilters[variables.currentClickedOrder].key) {
        index = i;
        return true;
      }
    });
    //if facet present in aggregations
    if (index >= 0) {
      //if nested
      if (
        clientFilters[variables.currentClickedOrder].key.indexOf('_nested') > 0 ||
        clientFilters[variables.currentClickedOrder].key.indexOf('_navigation') > 0
      ) {
        let childrenArr = aggregations[index].children || [];
        if (filterChecked) {
          childrenArr.forEach(function (filter, i) {
            if (filter.path && filter.path.indexOf(filter.Contentname) >= 0) {
              childrenArr[i] = {};
            }
          });
          childrenArr.push(obj);
          if (aggregations[index].sort && !aggregations[index].filter)
            aggregations[index].filter = [];
        } else {
          if (aggregations[index].sort && filter.Contentname == undefined) {
            aggregations[index].sort = $scope.sortMethod;
          } else if (
            aggregations[index].children &&
            !aggregations[index].sort &&
            filter.Contentname == undefined
          ) {
            aggregations[index].sort = $scope.sortMethod;
          } else {
            if (!childLevel) {
              aggregations[index].children = [];
              childrenArr = [];
            } else {
              //remove all parent of unchecked filter

              let filtersInAggr = childrenArr.filter(function (filter1, i) {
                filter1.childName =
                  variables.searchClientType === 2 || variables.searchClientType === '2'
                    ? decodeURIComponent(filter1.childName)
                    : filter1.childName;
                filter1.path =
                  variables.searchClientType === 2 || variables.searchClientType === '2'
                    ? decodeURIComponent(filter1.path)
                    : filter1.path;
                if (
                  filter.Contentname == filter1.childName ||
                  (path && path.indexOf(filter1.childName) >= 0) ||
                  (filter1.path && filter1.path.indexOf(filter.Contentname) > -1)
                ) {
                  childrenArr[i] = {};
                }
                return Object.keys(childrenArr[i]).length !== 0;
              });
              filtersInAggr = filtersInAggr.map(function (f) {
                return f.childName;
              });
              traverseTheTree(
                clientFilters[variables.currentClickedOrder].values,
                filter.Contentname,
                path,
                filtersInAggr,
                childrenArr
              );
            }
          }
        }
        if (filter.Contentname) {
          childrenArr = childrenArr.filter(function (value) {
            if (Object.keys(value).length !== 0) return value;
          });
          aggregations[index].children = childrenArr;
        }
      } else if (filter.Contentname.indexOf('merged_') > -1) {
        aggregations[index].filter =
          filterChecked && (!aggregations[index].filter || aggregations[index].filter.length)
            ? []
            : aggregations[index].filter;
        mergeFilterClicked(
          filter.Contentname,
          aggregations[index].filter,
          clientFilters[variables.currentClickedOrder].values,
          filterChecked
        );
      } else if (filter.Contentname && !aggregations[index].filter) {
        aggregations[index].filter = [filter.Contentname];
      } else {
        //if filter already in aggregations
        if (aggregations[index].filter.indexOf(filter.Contentname) > -1) {
          aggregations[index].filter.splice(
            aggregations[index].filter.indexOf(filter.Contentname),
            1
          );
        } else {
          aggregations[index].filter.push(filter.Contentname);
        }
      }
    } else {
      if (filter.Contentname.indexOf('merged_') > -1) {
        let filterNew = [];
        mergeFilterClicked(
          filter.Contentname,
          filterNew,
          clientFilters[variables.currentClickedOrder].values,
          filterChecked
        );
        aggregations.push({
          type: clientFilters[variables.currentClickedOrder].key,
          filter: filterNew
        });
      } else {
        clientFilters[variables.currentClickedOrder].key &&
        (clientFilters[variables.currentClickedOrder].key.indexOf('_nested') > 0 ||
          clientFilters[variables.currentClickedOrder].key.indexOf('_navigation') > 0)
          ? aggregations.push({
              type: clientFilters[variables.currentClickedOrder].key,
              filter: [],
              children: [obj]
            })
          : aggregations.push({
              type: clientFilters[variables.currentClickedOrder].key,
              filter: [filter.Contentname]
            });
      }
    }
  }

  //remove empty aggregations
  aggregations = aggregations.filter(function (facet) {
    if (
      facet.filter &&
      !facet.filter.length &&
      (!facet.children || (facet.children && !facet.children.length))
    ) {
      if (facet.sort != undefined && !facet.sort) {
        return false;
      }
    }
    return true;
  });

  for (
    let countAggregations = aggregations.length - 1;
    countAggregations >= 0;
    countAggregations--
  ) {
    if (
      aggregations[countAggregations].children &&
      !aggregations[countAggregations].children.length
    ) {
      delete aggregations[countAggregations].children;
      if (!aggregations[countAggregations].filter.length) {
        aggregations.splice(countAggregations, 1);
        break;
      }
    }
    if (
      !aggregations[countAggregations].sort &&
      aggregations[countAggregations].filter &&
      !aggregations[countAggregations].filter.length &&
      (!aggregations[countAggregations].children ||
        !aggregations[countAggregations].children.length)
    ) {
      aggregations.splice(countAggregations, 1);
    } else if (
      aggregations[countAggregations].sort &&
      aggregations[countAggregations].filter &&
      !aggregations[countAggregations].filter.length &&
      (!aggregations[countAggregations].children ||
        !aggregations[countAggregations].children.length)
    ) {
      delete aggregations[countAggregations].filter;
    }
  }
  variables.searchCallVariables.aggregations = aggregations;
};

export { mergeFilters, mergeFilterClicked, traverseTheTree, facetClickNested };
