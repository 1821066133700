import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import Facets from 'components/feature-components/sticky-facets-child/index.jsx';
import StaticStrings from 'StaticStrings';
import { a11y } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';

const StickyFacets = (props) => {
  try {
    const { t } = useTranslation();
    const searchResult = useSelector((state) => state.searchResult);
    const [totalFacets, showMoreTotalFacets] = useState({
      expanded: false,
      itemsToShow: 2
    });
    let clientFilters = searchResult.aggregationsArray;
    let selectedStickyFilter = [];

    const checkChildArray = (tempValues, childArray, name) => {
      childArray.forEach(function (y) {
        if (y.selected) {
          y.sticky_name = name + ' > ' + (y.displayName || y.Contentname);
          tempValues.push(y);
        }
        if (y.childArray) {
          checkChildArray(
            tempValues,
            y.childArray,
            name + ' > ' + (y.displayName || y.Contentname)
          );
        }
      });
    };

    if (clientFilters) {
      let tempAggregations = [];
      tempAggregations = JSON.parse(JSON.stringify(clientFilters));
      selectedStickyFilter = tempAggregations.filter(function (x) {
        x.tempValues = [];
        if (x.order === 0 && x.key == '_index' && variables.activeType !== 'all') {
          x.sticky_label = 'Tab';
          x.values.map(function (o) {
            if (o.Contentname == variables.activeType || (o.merged && o.selected)) {
              o.sticky_name = o.displayName;
              x.tempValues.push(o);
            }
            if (o.childArray && (x.order != 0 || !o.merged || (o.merged && o.showChild != 0))) {
              checkChildArray(x.tempValues, o.childArray, o.displayName || o.Contentname);
            }
          });
        }
        if (x.key != '_index') {
          x.values.map(function (f) {
            if (f.selected) x.tempValues.push(f);
            if (f.childArray && (x.order != 0 || !f.merged || (f.merged && f.showChild != 0))) {
              checkChildArray(x.tempValues, f.childArray, f.displayName || f.Contentname);
            }
          });
        }
        if (x.tempValues.length) {
          x.values = JSON.parse(JSON.stringify(x.tempValues || []));
          delete x.tempValues;
          return x;
        }
      });
    }

    /**
     *
     * @param {sticky Facet} facet
     */
    const showMore = (facet) => {
      totalFacets.itemsToShow === 2
        ? showMoreTotalFacets({ itemsToShow: facet.length, expanded: true })
        : showMoreTotalFacets({ itemsToShow: 2, expanded: false });
    };

    let advanceSearchAsStickyFacet = (key, label, value) => {
      return {
        key: key,
        label: label,
        values: [{ selected: true, Contentname: value }]
      };
    };

    let { exactPhrase, withOneOrMore, withoutTheWords } = variables.searchCallVariables;
    if (exactPhrase)
      selectedStickyFilter.unshift(
        advanceSearchAsStickyFacet('exactPhrase', 'With the exact phrase', exactPhrase)
      );
    if (withOneOrMore)
      selectedStickyFilter.unshift(
        advanceSearchAsStickyFacet('withOneOrMore', 'With one or more words', withOneOrMore)
      );
    if (withoutTheWords)
      selectedStickyFilter.unshift(
        advanceSearchAsStickyFacet('withoutTheWords', 'Without the words', withoutTheWords)
      );
    if (sessionStorage.getItem('toggleWildcardSearch'))
      selectedStickyFilter.unshift(advanceSearchAsStickyFacet('wildCard', '', 'Wildcard Search'));
    useEffect(() => {
      totalFacets.expanded
        ? showMoreTotalFacets({ itemsToShow: selectedStickyFilter.length, expanded: true })
        : showMoreTotalFacets({ itemsToShow: 2, expanded: false });
    }, [searchResult]);

    return (
      <div
        className={`su__clear-filter-row su__flex-vcenter su__flex-wrap su__pb-sm-1 su__loading-view su__pb-sm-0 ${
          props.standOut ? 'su__w-100' : ''
        }`}
      >
        {selectedStickyFilter.length
          ? selectedStickyFilter
              .slice(0, totalFacets.itemsToShow)
              .filter((item) =>
                props.standOut ? item.key === 'wildCard' : item.key !== 'wildCard'
              )
              .map((item, index) => (
                <div
                  key={uuid()}
                  className="su__sticky__top su__d-flex su__flex-wrap su__overflow-hide"
                >
                  <div
                    lang={variables.searchCallVariables.langAttr}
                    className="su__sticky__head su__font-12 su__color-lgray su__font-bold"
                  >
                    {t(item.label)}
                    {item.key === 'wildCard' ? '' : ':'}
                  </div>
                  <Facets item={item} index={index} standOut={props.standOut} />
                </div>
              ))
          : null}
        {!props.standOut && selectedStickyFilter.length > 2 ? (
          <div className="su__width-100">
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-expanded={Boolean(totalFacets.expanded)}
              aria-label={
                totalFacets.expanded ? t(StaticStrings.show_less) : t(StaticStrings.show_all)
              }
              role={a11y.ROLES.BTN}
              className="su__d-md-flex su__align-items-center su__justify-content-center su__color-blue a11y-btn m-auto w-auto"
              onClick={() => showMore(selectedStickyFilter)}
            >
              {totalFacets.expanded ? (
                <div className="su__show-less su__cursor su__loading-view su__text-center su__show_all_align">
                  <span className="su__arrow-up"></span>
                  <span
                    lang={variables.searchCallVariables.langAttr}
                    className="su__ml-3 su__rtlmr-3 su__rtlml-0 su__font-12"
                  >
                    {t(StaticStrings.show_less)}
                  </span>
                </div>
              ) : (
                <div className="su__show-all su__cursor su__loading-view">
                  <span className="su__arrow-down"></span>
                  <span
                    lang={variables.searchCallVariables.langAttr}
                    className="su__ml-2 su__rtlmr-3 su__rtlml-0 su__font-12"
                  >
                    {t(StaticStrings.show_all)}
                  </span>
                </div>
              )}
            </button>
          </div>
        ) : null}
      </div>
    );
  } catch (e) {
    console.log('Error in Stickfacet component', e);
    return <div></div>;
  }
};

export default StickyFacets;
