import React, { useState, Fragment, useEffect } from 'react';
import Icons from '../../../assets/svg-icon/svg';
import { useTranslation } from 'react-i18next';
import Facets from 'components/feature-components/facets/index.jsx';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import variables from '../../../redux/variables';
import { A11Y_IDS, focusTrap, a11y, tabIndexes } from '../../../constants/a11y';

const mobileFacets = ({ aggregations }) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [noFiltersMessage, setNoFiltersMessage] = useState(true);

    useEffect(() => {
      setNoFiltersMessage(true);
      if (aggregations && aggregations.length) {
        let arrayToCheck = aggregations.filter((item, index) => index > 0);
        arrayToCheck.map((item) => {
          if (item.values && item.values.length) {
            setNoFiltersMessage(false);
          }
        });
      }
    }, [aggregations]);

    useEffect(() => {
      focusTrap(isModalOpen);
    }, [!isModalOpen]);

    return (
      <Fragment>
        <div className="su__d-xl-none su__d-xs-block su__mob-facetshow su__d-lg-block su__flex-1 su__font-12 su__font-bold">
          <div
            className={`su__px-3 su__flex-vcenter su__h-100 su__ml-10${
              isModalOpen ? 'su__mob-active' : ''
            }`}
            onClick={() => setIsModalOpen(true)}
          >
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              aria-expanded={isModalOpen ? 'true' : 'false'}
              role={a11y.ROLES.BTN}
              tabIndex={tabIndexes.tabIndex_0}
              className="su__flex-vcenter su__text-black su__facet-icon su__line-height-n su__active-text su__p-2 su__p-sm-2 su__bg-white su__border su__radius-1 su__shadow su__py-1 su__font-10 su__font-bold su__filter-icon"
            >
              <Icons
                className="su__pr-1"
                IconName="Filter"
                width="13"
                height="13"
                color="#919bb0"
                IconClass="su__active-path"
              />{' '}
              {t(StaticStrings.filter)}
            </button>
          </div>
        </div>
        {isModalOpen && (
          <Fragment>
            <div className="su__position-fixed su__trbl-0 su__zindex-2">
              <div
                id={A11Y_IDS.trap}
                className={`su__modal-inner su__radius su__position-absolute su__h-100 su__zindex-1 su__shadow-lg su__bg-white su__modal_fullwidth ${
                  isModalOpen ? 'su__animate-fadeLeft' : ''
                }`}
              >
                <div className="su__bookmark-popup-title su__px-3 su__py-2 su__flex-vcenter su__bg-blue-grd">
                  <h2
                    lang={variables.searchCallVariables.langAttr}
                    tabIndex={tabIndexes.tabIndex_0}
                    className="su__font-16 su__text-white su__flex-1 su__position-relative su__my-0 su__f-normal"
                  >
                    <span>{t(StaticStrings.NARROWYOURSEARCH)}</span>
                  </h2>
                  <button
                    lang={variables.searchCallVariables.langAttr}
                    className="a11y-btn p-0"
                    aria-label={t(StaticStrings.close_popup)}
                    role={a11y.ROLES.BTN}
                    tabIndex={tabIndexes.tabIndex_0}
                    onClick={() => setIsModalOpen(false)}
                  >
                    {' '}
                    <Icons
                      className="su__close-icon su__cursor"
                      IconName="Close"
                      width="15"
                      height="15"
                      color={IconColors.mobileFacetsCloseIcon}
                    />
                  </button>
                </div>
                {noFiltersMessage && (
                  <div className="su_no-filters-mobile">
                    <span className="no_filter-msg"> {'No Filters available.'}</span>
                  </div>
                )}
                <div className="su__filter-inner su__h-100 su__overflow-auto">
                  <Facets />
                </div>
              </div>
              <div className="su__overlay su__zindex-1" onClick={() => setIsModalOpen(false)} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Mobile Facets', e);
    return <div></div>;
  }
};
export default mobileFacets;
