import React, { useState, useEffect, Fragment } from 'react';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import { useTranslation } from 'react-i18next';
import { a11y } from '../../../constants/a11y';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';

const SavedResult = (props) => {
  let { btnclick, item, savedResultBookmarkClicked, savedResultLimitReachedFunc } = props;

  try {
    const [isSaved, setIsSaved] = useState(false);
    const [savedResultsKey] = useState(
      localStorage.getItem('savedResult_' + variables.searchCallVariables.uid) || '[]'
    );
    const savedResultsKey1 = 'savedResult_' + variables.searchCallVariables.uid;
    useEffect(() => {
      const savedResults = JSON.parse(localStorage.getItem(savedResultsKey1) || '[]');
      setIsSaved(savedResults.some((result) => result.savedResultId === item._id));
    }, [item.href, savedResultsKey1, savedResultsKey, savedResultBookmarkClicked]);

    let saveUnsaveResult = () => {
      props.bookmarkListIconFunc();
      let data = JSON.parse(localStorage.getItem(savedResultsKey1) || '[]');
      if (data.length >= 50) {
        const isAlreadySaved = data.some((result) => result.savedResultId === item._id);
        if (isAlreadySaved) {
          data = data.filter((result) => result.savedResultId !== item._id);
          localStorage.setItem(savedResultsKey1, JSON.stringify(data));
          setIsSaved(false);
          btnclick();
          return;
        } else {
          savedResultLimitReachedFunc();
          return;
        }
      }
      const isAlreadySaved = data.some((result) => result.savedResultId === item._id);
      if (isAlreadySaved) {
        data = data.filter((result) => result.savedResultId !== item._id);
      } else {
        data.push({
          title: item.highlight.TitleToDisplay[0] || item.href,
          href: item.href,
          savedResult: false,
          csIndexName: item.sourceName,
          savedResultId: item._id,
          objName: item.objName,
          uniqueField: item.uniqueField ? item.uniqueField : '',
          savedResultExists: 0
        });
      }
      localStorage.setItem(savedResultsKey1, JSON.stringify(data));
      setIsSaved(!isAlreadySaved);
      btnclick();
    };

    const { t } = useTranslation();
    return (
      <Fragment>
        <Tooltip
          text={t(isSaved ? StaticStrings.Unsave : StaticStrings.Save_for_Later)}
          position="bottom"
          className="position-relative"
        >
          <button
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(isSaved ? StaticStrings.Unsave : StaticStrings.Save_for_Later)}
            role={a11y.ROLES.BTN}
            className="su_preview-startblock su__cursor su__ml-1 a11y-btn su__savedResultBookmarkIcon"
            onClick={saveUnsaveResult}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Component_39_15"
              data-name="Component 39 – 15"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <rect
                id="Rectangle_11246"
                data-name="Rectangle 11246"
                width="24"
                height="24"
                fill="none"
              />
              <g id="bookmark" transform="translate(2 2)">
                <g id="bookmark-2" data-name="bookmark" transform="translate(0 0)">
                  <rect
                    id="Rectangle_6570"
                    data-name="Rectangle 6570"
                    width="20"
                    height="20"
                    transform="translate(0 0)"
                    fill="#57575c"
                    opacity="0"
                  />
                  <path
                    id="Path_7844"
                    data-name="Path 7844"
                    d="M6.009,20.361a1.046,1.046,0,0,1-.495-.125A.958.958,0,0,1,5,19.4V5.247A2.245,2.245,0,0,1,7.22,3h9.688a2.245,2.245,0,0,1,2.22,2.247V19.4a.958.958,0,0,1-.5.829,1.05,1.05,0,0,1-1.009,0l-5.722-3.1L6.514,20.216A1.043,1.043,0,0,1,6.009,20.361Z"
                    transform="translate(-2.064 -1.68)"
                    fill={isSaved ? IconColors.saved_result_fill : IconColors.PreviewIconFill}
                  />
                </g>
              </g>
            </svg>
          </button>
        </Tooltip>
      </Fragment>
    );
  } catch (e) {
    console.log('Error in SourceLabel component', e);
    return <div></div>;
  }
};

export default SavedResult;
