import React, { useState, Fragment, useEffect } from 'react';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
import StaticStrings from 'StaticStrings';
import { A11Y_IDS, focusTrap } from 'constants/a11y';
import { a11y, tabIndexes } from '../../../constants/a11y';

const Preview = (props) => {
  try {
    const { t } = useTranslation();
    const { item, searchResult, linkOpened, index } = props;

    const [titleForModal, setTitleForModal] = useState('');
    const [sourceLabelForModal, setSourceLabelForModal] = useState('');
    const [openPreviewModalUrl, setOpenPreviewModalUrl] = useState('');
    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    const openPreview = (item) => {
      document.body.style.overflow = 'hidden';
      setSourceLabelForModal(item.sourceLabel);
      setTitleForModal(item.highlight.TitleToDisplay[0] || item.href);
      setOpenPreviewModalUrl(modifyUrlForPreview(item.href));
      setOpenPreviewModal(true);
    };
    const closePreview = () => {
      document.body.style.overflow = 'auto';
      setOpenPreviewModal(false);
      setOpenPreviewModalUrl('');
      document.getElementsByClassName('su__iframe-src')[0].setAttribute('src', '');
    };
    const modifyUrlForPreview = (url) => {
      if (url.toLowerCase().includes('youtube.com')) {
        return url.replace('watch?v=', 'embed/');
      } else if (url.toLowerCase().includes('vimeo.com')) {
        return 'https://player.vimeo.com/video/' + url.split('.com/')[1];
      } else {
        return url;
      }
    };
    useEffect(() => {
      focusTrap(openPreviewModal);
    }, [openPreviewModal]);

    return (
      <Fragment>
        {(item.href.toLowerCase().includes('youtube.com') ||
          (item.href.toLowerCase().includes('vimeo.com') &&
            /^\d+$/.test(item.href.split('.com/')[1])) ||
          item.href.includes(window.location.origin)) &&
          searchResult &&
          searchResult.searchClientSettings &&
          searchResult.searchClientSettings.preview &&
          !variables.toggleDisplayKeys[2].hideEye && (
            <Tooltip text={t(StaticStrings.preview)} position="top" className="position-relative">
              <button
                type="button"
                lang={variables.searchCallVariables.langAttr}
                aria-label={t(StaticStrings.preview)}
                role={a11y.ROLES.BTN}
                className="su_preview-startblock su__ml-1 a11y-btn"
                onClick={() => {
                  openPreview(item);
                  linkOpened(item, index + 1);
                }}
              >
                <div className="su__results-preview">
                  <div className="su__preview-block-content su__d-inline-block su__cursor">
                    <div
                      aria-label="Preview"
                      role={a11y.ROLES.BTN}
                      tabIndex={tabIndexes.tabIndex_0}
                      className="su__flex-vcenter su__justify-content-end"
                    >
                      <svg
                        height="20px"
                        viewBox="0 0 24 24"
                        width="20px"
                        fill={IconColors.PreviewIconFill}
                      >
                        <g>
                          <rect fill="none" height="24" width="24" />
                          <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M12,10.5 c1.84,0,3.48,0.96,4.34,2.5c-0.86,1.54-2.5,2.5-4.34,2.5S8.52,14.54,7.66,13C8.52,11.46,10.16,10.5,12,10.5 M12,9 c-2.73,0-5.06,1.66-6,4c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9L12,9z M12,14.5c-0.83,0-1.5-0.67-1.5-1.5 s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S12.83,14.5,12,14.5z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </button>
            </Tooltip>
          )}
        {openPreviewModal && (
          <div
            id={A11Y_IDS.trap}
            className={`su__bookmark-row su__position-fixed su__trbl-0 su__zindex-3 ${
              variables.searchClientType == 11 ? 'su__align_preview' : ''
            }`}
          >
            <div className="su__py-2 su__iframe-modal su__animate-fadeInRight su__position-fixed su__shadow-lg su__bg-white su__radius-1 su__mx-auto su__zindex-2 su__sm-w-100 su__sm-h-100">
              <div className="su__close-icon su__d-flex su__align-items-start su__py-2 su__px-3">
                <div className="su__text-left su__d-flex su__align-items-start su__flex-1 su__font-14 su__color-gray su__f-regular su__overflow-hide ">
                  <span className="su__word-break">
                    <h2
                      tabIndex={tabIndexes.tabIndex_0}
                      className="su__line-clamp su__font-14 su__f-regular su__my-0"
                      dangerouslySetInnerHTML={{ __html: titleForModal }}
                    ></h2>
                  </span>
                  {!variables.toggleDisplayKeys[5].hideEye &&
                    variables.searchCallVariables.showContentTag && (
                      <span
                        tabIndex={tabIndexes.tabIndex_0}
                        className="su__px-3 su__ml-2 su__f-regular su__color-black su__preview-contentLabel su__text-truncate su__font-14 su__font-bold"
                      >
                        {sourceLabelForModal}
                      </span>
                    )}
                </div>
                <button
                  type="button"
                  lang={variables.searchCallVariables.langAttr}
                  className="a11y-btn p-0"
                  aria-label={t(StaticStrings.close_popup)}
                  role={a11y.ROLES.BTN}
                  tabIndex={tabIndexes.tabIndex_0}
                  onClick={() => {
                    closePreview();
                  }}
                >
                  <svg
                    className="su__cursor"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="su__fill-black"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </button>
              </div>
              <div tabIndex={tabIndexes.tabIndex_0} className="su__bookmark-inner su__p-3">
                <iframe
                  tabIndex={tabIndexes.tabIndex_0}
                  className="su__iframe-height su__iframe-src"
                  width="100%"
                  height="100%"
                  target="parent"
                  src={openPreviewModalUrl}
                  title={titleForModal}
                ></iframe>
              </div>
            </div>
            <div
              tabIndex={tabIndexes.tabIndex_0}
              className="su__bg-overlay"
              onClick={() => {
                closePreview();
              }}
            ></div>
          </div>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in preview component', e);
    return <div></div>;
  }
};

export default Preview;
