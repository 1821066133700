import React, { Fragment } from 'react';
import variables from '../../../redux/variables';

const Href = (props) => {
  try {
    let { item } = props;
    return (
      <Fragment>
        <div className="su__flex-vcenter">
          {!variables.toggleDisplayKeys[2].hideEye && (
            <div className="su__href-txt su__text-decoration su__color-lgray su__font-12 su__font-italic su_letter_space">
              {' '}
              {item.href}
            </div>
          )}
        </div>
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Href component', e);
    return <div></div>;
  }
};

export default React.memo(Href);
