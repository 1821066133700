import React from 'react';
import Tooltip from 'components/feature-components/tooltip/index';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import StaticStrings from 'StaticStrings';
import { a11y, tabIndexes } from '../../../constants/a11y';
import { bool, func } from 'prop-types';

const SearchPageFeedback = (props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    variables.searchResultClicked = false;
  };
  return (
    <>
      <div
        className="su__feedback-text"
        onClick={() => props.setOpenFeedback(!props.isOpenFeedback)}
      >
        <Tooltip
          text={t(StaticStrings.give_search_feedback)}
          position="left"
          className="position-relative"
        >
          <button
            type="button"
            lang={variables.searchCallVariables.langAttr}
            aria-label={t(StaticStrings.GIVEFEEDBACK)}
            role={a11y.ROLES.BTN}
            tabIndex={tabIndexes.tabIndex_0}
            onClick={handleClick}
            className="su__feedback-searchsvg su__font-12 su__position-fixed su__zindex su__cursor a11y-btn"
          >
            <svg width="80" height="80" viewBox="0 0 80 80">
              <defs>
                <filter
                  id="Ellipse_763"
                  x="0"
                  y="0"
                  width="80"
                  height="80"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g id="Component_11_17" data-name="Component 11 – 17" transform="translate(9 6)">
                <g id="Group_13423" data-name="Group 13423" transform="translate(-1241 -1113)">
                  <g transform="matrix(1, 0, 0, 1, 1232, 1107)" filter="url(#Ellipse_763)">
                    <circle
                      id="Ellipse_763-2"
                      data-name="Ellipse 763"
                      cx="31"
                      cy="31"
                      r="31"
                      transform="translate(9 6)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_13310"
                    data-name="Group 13310"
                    transform="translate(1248.618 1120.618)"
                  >
                    <path
                      id="Path_11276"
                      data-name="Path 11276"
                      d="M-481.668,59.582q0-2.432,0-4.864c0-.983.34-1.333,1.3-1.4a4.075,4.075,0,0,0,3.109-1.92,5.041,5.041,0,0,0,.882-3c0-.986-.165-1.957-.2-2.938a2.931,2.931,0,0,1,.1-1.023,1.48,1.48,0,0,1,1.636-1.029,3.294,3.294,0,0,1,2.65,2.169,8.079,8.079,0,0,1,.351,3.665c-.063.774-.185,1.544-.283,2.316-.077.6.138.854.752.855,1.9,0,3.8-.006,5.706,0a1.955,1.955,0,0,1,1.732,2.958,1.9,1.9,0,0,1-1.049.85c-.219.077-.256.144-.088.338a1.916,1.916,0,0,1-.612,3.214c-.272.148-.08.221.018.319a1.934,1.934,0,0,1-.083,2.932,1.314,1.314,0,0,1-.418.261c-.315.1-.377.216-.181.53a1.929,1.929,0,0,1-1.067,2.857,1.562,1.562,0,0,1-.6.065l-6.261-.433-4.886-.334c-.445-.03-.892-.051-1.336-.092a1.162,1.162,0,0,1-1.169-1.287C-481.671,62.927-481.668,61.254-481.668,59.582Z"
                      transform="translate(495.658 -37.604)"
                      fill="#56c5ff"
                    />
                    <path
                      id="Path_11277"
                      data-name="Path 11277"
                      d="M-437.527,124.206a11.741,11.741,0,0,1,4.71,3.186,11.827,11.827,0,0,1,.962,14.682.879.879,0,0,0-.16.533c0,1.456,0,2.912,0,4.368a2.184,2.184,0,0,1-1.332,2.178,2.219,2.219,0,0,1-2.519-.44c-.688-.61-1.388-1.206-2.074-1.816a.483.483,0,0,0-.507-.13,11.822,11.822,0,0,1-13.618-5.822,11.712,11.712,0,0,1-.985-2.443c-.078-.27,0-.331.258-.3.444.045.891.082,1.337.092.262.006.361.123.449.358a9.809,9.809,0,0,0,7.125,6.393,9.488,9.488,0,0,0,5.934-.437.467.467,0,0,1,.57.091c.913.817,1.84,1.619,2.762,2.426.122.107.238.266.427.19s.142-.279.142-.436c0-1.558.006-3.116,0-4.674a.988.988,0,0,1,.229-.662,9.945,9.945,0,0,0-3.4-15.135c-.211-.1-.271-.2-.213-.429A3.273,3.273,0,0,0-437.527,124.206Z"
                      transform="translate(470.569 -108.518)"
                      fill="#3e4753"
                    />
                    <path
                      id="Path_11278"
                      data-name="Path 11278"
                      d="M-541.431,123.826c0,2.35,0,4.7.006,7.049,0,.274-.051.376-.351.364-.676-.025-1.353-.015-2.03,0-.2,0-.278-.054-.259-.259a3.157,3.157,0,0,0,0-.5,1.291,1.291,0,0,1,.437-1.195,1.589,1.589,0,0,0-.088-2.3,1.656,1.656,0,0,0-2.367.076,1.614,1.614,0,0,0,.058,2.3.938.938,0,0,1,.366.916,2.073,2.073,0,0,0,0,.23c0,.775,0,.775-.789.711a2.008,2.008,0,0,1-1.836-2c-.01-2.184,0-4.367,0-6.551,0-1.4,0-2.809,0-4.214a2.008,2.008,0,0,1,2.094-2.1c1.468-.006,2.937.009,4.405-.011.323,0,.362.117.361.39C-541.434,119.1-541.43,121.464-541.431,123.826Z"
                      transform="translate(554.119 -101.623)"
                      fill="#3e4753"
                    />
                    <rect
                      id="Rectangle_5828"
                      data-name="Rectangle 5828"
                      width="46"
                      height="46"
                      transform="translate(0.383 0.382)"
                      fill="none"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </Tooltip>
      </div>
    </>
  );
};

SearchPageFeedback.propTypes = {
  setOpenFeedback: func,
  isOpenFeedback: bool
};

SearchPageFeedback.defaultProps = {
  setOpenFeedback: () => {},
  isOpenFeedback: false
};

export default SearchPageFeedback;
