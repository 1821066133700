/* eslint-disable react/jsx-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import StaticStrings from '../../../StaticStrings';
import { tabIndexes, a11y } from '../../../constants/a11y';
import variables from '../../../redux/variables';

const Recommendations = ({ recommendationsResults, redirectURL }) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();

    return (
      <>
        {recommendationsResults &&
        recommendationsResults.result &&
        recommendationsResults.result.hits.length !== 0 ? (
          <div className="su__Recommended_Article-section su__d-block su__border-radius">
            <div className="su__w-100">
              <div className="su__bg-white su__Recommended_Articles-R su__border su__w-100 su__pr-3 su__pt-3 su__pb-1 su__pl-3">
                <div className="su__position-relative su__border-b su__d-flex su__align-items-center su__pb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.929"
                    height="13.102"
                    viewBox="0 0 12.929 13.102"
                  >
                    <path
                      id="Icon_feather-thumbs-up"
                      data-name="Icon feather-thumbs-up"
                      d="M9.661,6.886V4.665A1.665,1.665,0,0,0,8,3L5.776,8V14.1h6.262a1.11,1.11,0,0,0,1.11-.944l.766-5A1.11,1.11,0,0,0,12.8,6.886ZM5.776,14.1H4.11A1.11,1.11,0,0,1,3,12.992V9.106A1.11,1.11,0,0,1,4.11,8H5.776"
                      transform="translate(-2 -2)"
                      fill="none"
                      stroke="#1770d4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <h2
                    lang={variables.searchCallVariables.langAttr}
                    tabIndex={tabIndexes.tabIndex_0}
                    className="su__Recommended_Article su__pl-2 su__font-weight-bold su__my-0"
                  >
                    {t(StaticStrings.recommended_articles)}
                  </h2>
                </div>
                <div className="su__p-0 ">
                  {React.Children.toArray(
                    recommendationsResults.result.hits.map((item) => (
                      <>
                        {item.highlight.TitleToDisplay[0] ? (
                          <div
                            className={`su__py-1 su__d-block ${
                              redirectURL
                                ? 'su__Recomended_border-widget-b'
                                : 'su__Recomended_border-b'
                            }`}
                          >
                            <div className="su__py-1">
                              <div className="su__search-title su__list-item-title su__text-truncate su__m-0 su__loading-view su__d-xl-flex su__align-items-center  su__justify-content-between">
                                <div
                                  className={`su__font-12 su__whitespace-initial su__recommendations-title ${
                                    item.contentTag ? 'su__Recommended_Article-max-width' : ''
                                  }`}
                                >
                                  <a
                                    tabIndex={tabIndexes.tabIndex_minus_1}
                                    className="su__text-decoration su__recommendations-content "
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <h3
                                      role={a11y.ROLES.LNK}
                                      tabIndex={tabIndexes.tabIndex_0}
                                      className="su__my-0 su__font-12 su__f-regular su__line-height-n"
                                      dangerouslySetInnerHTML={{
                                        __html: item.highlight.TitleToDisplay[0] || item.href
                                      }}
                                    ></h3>
                                  </a>
                                </div>
                                {item.contentTag && (
                                  <div className="su__recommendations-tag-content">
                                    <p
                                      title={item.contentTag}
                                      className="su__recommendation-label su__text-truncate su__text-center su__whitespace-initial"
                                    >
                                      {' '}
                                      {item.contentTag}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))
                  )}
                </div>
                {redirectURL && (
                  <div className="su__recommendations-ViewMore">
                    <a
                      href={redirectURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="su__text-decoration"
                    >
                      <span className="su__recommendations-ViewMore-text">View more articles</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  } catch (e) {
    console.log('Error in recommendation component', e);
    return <div></div>;
  }
};

export default Recommendations;
