import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { search } from '../../../redux/ducks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';

const ScrollPagination = () => {
  try {
    /**
     *  Change Languages state
     */

    let [percentage, setPercentage] = useState(1);
    window.addEventListener('scroll', function () {
      let facteH = document.getElementById('facets-section').clientHeight;
      let resultSection = document.getElementById('resultsSection').clientHeight;
      let screenWidth = window.screen.width;
      const calculatePercentage = (resultSection - screenWidth) / screenWidth;
      if (resultSection >= facteH) {
        setPercentage(1);
      } else {
        setPercentage(calculatePercentage);
      }
    });
    const { t } = useTranslation();
    let searchSuggest = useSelector((state) => state.searchResult);
    let totalResults = Math.ceil(
      (Object.keys(searchSuggest).length && searchSuggest.result.total) /
        variables.searchCallVariables.pageSize
    );
    const onScroll = () => {
      variables.searchCallVariables.pageNo = 1;
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageSize =
        parseInt(variables.searchCallVariables.pageSize) + 10;
      variables.searchCallVariables.resultsPerPage = variables.searchCallVariables.pageSize;
      variables.searchSource = 'pagination';
      dispatch(search.start(variables.searchCallVariables));
    };
    const dispatch = useDispatch();

    return (
      <div className="su__d-inline-block su__w-100">
        {totalResults > 1 ? (
          <div className="su__pagination-row su__pagination-section su__my-4">
            <div className="su__pagination su__justify-content-end su__loading-view">
              <InfiniteScroll
                dataLength={totalResults}
                next={onScroll}
                hasMore={true}
                scrollThreshold={percentage}
                loader={<h4>{t(StaticStrings.loading)}...</h4>}
              ></InfiniteScroll>
            </div>
          </div>
        ) : null}
      </div>
    );
  } catch (e) {
    console.log('Error in scroll component', e);
    return <div></div>;
  }
};

export default ScrollPagination;
