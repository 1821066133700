/* global gza */
import React, { useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import variables from '../../../redux/variables';

const Advertisement = () => {
  try {
    const [listofHrefs, setlistofHrefs] = useState([]);
    const advertisement = useSelector((state) => state);
    const advResult = advertisement.adHtml;

    useEffect(() => {
      if (advResult && advResult.htmlString !== '') {
        gza('adv_view', {
          textSearched: variables.searchCallVariables.searchString,
          advId: variables.searchCallVariables.searchString,
          url: window.location.href,
          page_no: variables.searchCallVariables.pageNo
        });
      }
    }, [advResult]);

    useEffect(() => {
      setlistofHrefs([]);
    }, [variables.searchCallVariables.aggregations]);

    /**
     * this function calls when user clicks on advertisement
     * @param {*} event event contains clicked element contaning anchor tag
     */

    const advertisementClick = (event) => {
      const closestHrefElement = event.target.closest('[href]:not([href=""])');
      if (closestHrefElement) {
        const href = closestHrefElement.getAttribute('href');
        if (href && href.trim() !== '') {
          if (!listofHrefs.includes(href)) {
            listofHrefs.push(href);
            gza('adv_click', {
              textSearched: variables.searchCallVariables.searchString,
              advId: variables.searchCallVariables.searchString,
              url: window.location.href,
              advUrl: href,
              page_no: variables.searchCallVariables.pageNo
            });
          }
        }
      }
    };
    return (
      <Fragment>
        {advResult.htmlString && (
          <div
            onClick={advertisementClick}
            id="advertisement-link"
            data-test-id="advertisement-link"
            dangerouslySetInnerHTML={{ __html: advResult.htmlString }}
          ></div>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in advertisment component', e);
    return <div></div>;
  }
};

export default Advertisement;
