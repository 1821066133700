import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/feature-components/tooltip/index.jsx';
import IconColors from '../../../IconColors';
import StaticStrings from '../../../StaticStrings';
import { a11y, tabIndexes } from '../../../constants/a11y';

const ToggleView = (props) => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();

    const { isListView, setIsListView, isGridView, setIsGridView, variables } = props;
    /**
     * Toggle list/grid view
     */
    const switchListView = () => {
      setIsListView(true);
      setIsGridView(false);
    };

    const switchGridView = () => {
      setIsGridView(true);
      setIsListView(false);
    };

    return (
      ((variables.mergeResults && !variables.resultsInAllContentSources) ||
        !variables.mergeResults) && (
        <div className="su__switch-view-layout-2 su__switch-view su__d-md-flex su__d-xs-none su__flex-vcenter su__justify-content-between su__pl-0">
          <Tooltip text={t(StaticStrings.grid_view)} position="top">
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              tabIndex={tabIndexes.tabIndex_0}
              role={a11y.ROLES.BTN}
              aria-label={`${t(StaticStrings.grid_view)} ${
                isGridView ? t(StaticStrings.selected) : ''
              }`}
              className="su__grid-view su__radius-50 a11y-btn p-0"
              onClick={switchGridView}
            >
              <div className={`su__cursor ${isGridView ? 'su__view-active' : ''}`}>
                <Icons
                  className="su__switch-icon-size custom-grid-svg"
                  IconName="gridView"
                  width="21"
                  height="21"
                  rx="2"
                  widthInner="9.677"
                  heightInner="9.677"
                  fill="#919BAF"
                  transform="translate(-17220 20062)"
                />
              </div>
            </button>
          </Tooltip>
          <Tooltip text={t('List View')} position="top" className="position-relative">
            <button
              type="button"
              lang={variables.searchCallVariables.langAttr}
              tabIndex={tabIndexes.tabIndex_0}
              role={a11y.ROLES.BTN}
              aria-label={`${t(StaticStrings.LISTVIEW)} ${
                isListView ? t(StaticStrings.selected) : ''
              }`}
              className="su__list-view su__radius-50 su__viewing su__text-center a11y-btn p-0"
              onClick={switchListView}
            >
              <div
                className={`su__d-inline-block su__cursor ${isListView ? 'su__view-active' : ''}`}
                data-toggle="tooltip"
              >
                <svg
                  className="su__switch-icon-size custom-list-svg"
                  preserveAspectRatio="xMidYMid meet"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <defs>
                    <clipPath id="clip-List">
                      <rect width="24" height="24"></rect>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#clip-List)">
                    <g transform="translate(-1310 -189)" data-name="Group 1352" fill="#919bb0">
                      <g transform="translate(1310 206.77)" data-name="Group 1268">
                        <g transform="translate(4.838)" data-name="Group 1266">
                          <rect
                            width="16.128"
                            height="3.226"
                            rx="1.613"
                            data-name="Rectangle 368"
                          ></rect>
                        </g>
                        <rect width="3.226" height="3.226" rx="1.613" data-name="Rectangle 371"></rect>
                      </g>
                      <g transform="translate(1310 194.92)" data-name="Group 1269">
                        <g transform="translate(4.838)" data-name="Group 1266">
                          <rect
                            width="16.128"
                            height="3.226"
                            rx="1.613"
                            data-name="Rectangle 368"
                          ></rect>
                        </g>
                        <rect width="3.226" height="3.226" rx="1.613" data-name="Rectangle 371"></rect>
                      </g>
                      <g transform="translate(1310 200.85)" data-name="Group 1270">
                        <g transform="translate(4.838)" data-name="Group 1266">
                          <rect
                            width="16.128"
                            height="3.226"
                            rx="1.613"
                            data-name="Rectangle 368"
                          ></rect>
                        </g>
                        <rect width="3.226" height="3.226" rx="1.613" data-name="Rectangle 371"></rect>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </button>
          </Tooltip>
        </div>
      )
    );
  } catch (e) {
    console.log('Error in listGridView component', e);
    return <div></div>;
  }
};

export default ToggleView;