import React, { Fragment, useState } from 'react';
import SavedResult from 'components/section-components/saved-result/index.jsx';
import SaveUnsaveText from 'components/section-components/saveUnsaveText/index.jsx';

const ListView = (props) => {
  try {
    let {
      item,
      child,
      savedResultBookmarkClicked,
      savedResultLimitReachedFunc,
      bookmarkListIconFunc
    } = props;
    const componentTobeRendered = child.props.children;
    const [showText, setShowText] = useState(false);
    const [saved, setSaved] = useState(false);

    const handleButtonClick = () => {
      setSaved(!saved);
      setShowText(true);
      setTimeout(() => {
        setShowText(false);
      }, 1000);
    };
    return (
      <Fragment>
        <div className="su__list-item-row su__d-md-flex su__w-100">
          {React.Children.map(componentTobeRendered, (child) => {
            if (!child || !child.props || child.props['position'] != 'left') return false;
            return React.cloneElement(child, props, null);
          })}
          <div
            className={`${
              item.imageSource ? 'su__list-item-text' : 'su__w-100'
            } su__overflow-hide su__media-body su__word-break`}
          >
            <div className={`su__list-item-title su__flex-vcenter su__mw-100`}>
              <Fragment>
                {React.Children.map(componentTobeRendered, (child) => {
                  if (!child || !child.props || child.props['position'] != 'title') return false;
                  return React.cloneElement(child, props, null);
                })}
              </Fragment>
            </div>
            {React.Children.map(componentTobeRendered, (child) => {
              if (!child || !child.props || child.props['position'] != 'center') return false;
              return React.cloneElement(child, props, null);
            })}
          </div>
          <div className="su__ml-2 su__text-right su__loading-view su__d-flex su__align-items-baseline">
            <SavedResult
              bookmarkListIconFunc={bookmarkListIconFunc}
              savedResultLimitReachedFunc={savedResultLimitReachedFunc}
              savedResultBookmarkClicked={savedResultBookmarkClicked}
              btnclick={handleButtonClick}
              item={item}
            />
            <SaveUnsaveText
              savedResultBookmarkClicked={savedResultBookmarkClicked}
              saved={saved}
              setSaved={setSaved}
              showText={showText}
              item={item}
            />
            {React.Children.map(componentTobeRendered, (child) => {
              if (!child || !child.props || child.props['position'] != 'right') return false;
              return React.cloneElement(child, props, null);
            })}
          </div>
        </div>
        <div className="multiVersionGridView">
          {React.Children.map(componentTobeRendered, (child) => {
            if (!child || !child.props || child.props['position'] != 'end') return false;
            return React.cloneElement(child, props, null);
          })}
        </div>
      </Fragment>
    );
  } catch (e) {
    console.log('Error in ListView component', e);
    return <div></div>;
  }
};

export default React.memo(ListView);
