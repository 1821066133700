import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import variables from '../../../redux/variables';
import { useTranslation } from 'react-i18next';
import { search } from '../../../redux/ducks';
import StaticStrings from 'StaticStrings';
import { a11y, tabIndexes } from '../../../constants/a11y';
import { v4 as uuid } from 'uuid';
const SimilarSearches = () => {
  try {
    /**
     *  Change Languages state
     */
    const { t } = useTranslation();
    const searchResult = useSelector((state) => state.searchResult);
    const dispatch = useDispatch();
    let searchCall = (value) => {
      document.getElementById('search-box-search').value = value;
      variables.searchCallVariables.searchString = value;
      variables.searchSource = 'similar-search';
      variables.searchCallVariables.pagingAggregation = [];
      dispatch(search.start(variables.searchCallVariables));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <Fragment>
        {variables.searchCallVariables.searchString &&
        searchResult &&
        searchResult.similarSearches &&
        searchResult.similarSearches.length !== 0 ? (
          <div className="su__similarSearches">
            <div className="su__recommendations-results su__position-relative su__mt-4 su__p-3 su__sm-shadow su__bg-white">
              <div className="su__ribbon-block su__position-absolute">
                <div className="su__ribbon-row-block su__w-auto su__clearfix su__loading-view">
                  <h2
                    lang={variables.searchCallVariables.langAttr}
                    tabIndex={tabIndexes.tabIndex_0}
                    className="su__ribbon-text su__font-12 su__float-left su__text-center su__text-black su__font-bold su__mb-2 su__radius-3 su__my-0"
                  >
                    {t(StaticStrings.similar_searches)}
                  </h2>
                </div>
              </div>
              <div className="su__p-0 su__mb-3 su__mt-2">
                {variables.searchCallVariables.searchString &&
                  searchResult &&
                  searchResult.similarSearches &&
                  searchResult.similarSearches.map((item) => (
                    <div
                      key={uuid()}
                      onClick={() => searchCall(item)}
                      className="su__mb-2 su__px-3 su__py-1 su__d-flex su__border su__radius su__align-items-start hover__effect"
                    >
                      <div className="su__w-100 su__cursor">
                        <div className="su__recommendations-title">
                          <h3
                            role={a11y.ROLES.BTN}
                            tabIndex={tabIndexes.tabIndex_0}
                            className="su__search-title su__list-item-title su__text-truncate su__m-0 su__loading-view"
                          >
                            <a className="su__font-14 su__text-decoration su__font-weight-bold su__text-black hover-color-dblue su__font-bold">
                              {item}
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in Similar searches component', e);
    return <div></div>;
  }
};

export default SimilarSearches;
