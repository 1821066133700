import React from 'react';

const ContentAlternateUI = () => {
  return (
    <div className={`su__w-100`}>
      <div
        className={
          'su__content-view su__mt-1 su__mb-3 su__my-sm-0 su__flex-wrap su__sm-fullwidth list su__grid-content'
        }
        id="resultsSection"
      >
        <div className="su__loading1">
          <div className="su__list-item-text su__media-body su__overflow-hide su__word-break">
            <div className="su__list-item-title  su__mb-1 su__flex-vcenter su__mw-100">
              <span className="su__ribbon-title  su__ribbon-grid su__loading-view su__bg-blue su__font-12 su__font-bold su__mr-1 su__rtlmr-0 su__rtlml-1  su__px-2 su__d-none su__radius-3">
                {' '}
                Stackoverflow bhawna
              </span>
              <div className="su__w-100">
                <a
                  className="su__text-decoration su__loading-view  su__text-black su__text-truncate  su__font-14 su__font-bold"
                  href="https://stackoverflow.com/questions/56498078/not-able-to-hit-the-router-in-the-browser-when-i-hit-the-url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className=" su__text-truncate su__loading-view ">
                    main heading at content tile
                  </span>
                </a>
              </div>
              <span className=" su__loading-view su__ribbon-title su__ribbon-list su__bg-blue su__font-12 su__font-bold su__px-2 su__rtlmr-1 su__ml-1 su__rtlml-0 su__d-inline-block su__radius-3">
                {' '}
                Content source type{' '}
              </span>
            </div>
            <div className="su__href-txt su__loading-view  su__text-decoration su__color-lgray su__font-12 su__font-italic">
              {' '}
              https://stackoverflow.com/questions/56498078/not-able-to-hit-the-router-in-the-browser-when-i-hit-the-url
            </div>
            <div>
              <div className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__loading-view  su__font-12">
                {' '}
                at 1010 port but when I <span className="highlight">hit</span> this url
                http://localhost:1010/sampletes....
              </div>
            </div>
            <div>
              <div className="su__loading-view su__list-item-desc su__loading-view  su__w-100 su__my-1 su__font-regular su__font-12">
                56498078....
              </div>
            </div>
            <div>
              <div className="su__list-item-desc su__w-100 su__my-1 su__font-regular su__loading-view  su__font-12">
                javascript....
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentAlternateUI;
